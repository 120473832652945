import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import path from 'src/constants/path'
import { useAdminConfig } from 'src/libs/hooks/useAdminConfig'

export default function NavBar() {
  const { navbarSettings } = useAdminConfig()
  const [currentLink, setCurrentLink] = useState(location.pathname)
  const { navigateLinks } = navbarSettings || {}

  useEffect(() => {
    if (location.pathname === path.casino) {
      return
    } else setCurrentLink(location.pathname)
  }, [location.pathname])

  return (
    <nav className='w-full hidden lg:block h-[5dvh]'>
      <div className='mx-auto h-full flex justify-center items-center gap-[40px] w-70'>
        {navigateLinks?.map((item, index) => (
          <NavLink
            to={item.link}
            onClick={() => {
              setCurrentLink(item.link)
            }}
            className={() =>
              `font-bold text-15 hover:text-primary ${currentLink === item.link ? 'text-primary' : 'text-white'}`
            }
            key={index}
          >
            {item.value}
          </NavLink>
        ))}
      </div>
    </nav>
  )
}
