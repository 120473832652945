import { gql } from '@apollo/client'

export const GET_POPUP = gql`
  query PopupList {
    popupList {
      poNo
      poLink
      poContent
      poLoggined
      poOpenNewWindow
      poMarginLeft
      poMarginTop
    }
  }
`
