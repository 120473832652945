/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import clockIcon from '../../../public/assets/images/icons/clock.png'
import './listPoint.scss'
import Button from 'src/components/ui/Button'
import { ConfigProvider, TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'
import { useQuery } from '@apollo/client'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { convertAdminAssetUrl } from 'src/libs/utils/url'
interface IPoint {
  key: number
  id: number
  title: string
  created_at: string
  content: string
  type: string
  money: string
}
export default function ListPoint() {
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [filter, setFilter] = useState<string>('1')
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  const BoardColumns: TableColumnsType<IPoint> = [
    {
      title: '발생시간',
      key: 'created_at',
      width: '15%',
      align: 'center',
      dataIndex: 'created_at'
    },
    { title: '스포츠캐쉬', key: 'type', width: '10%', align: 'center', dataIndex: 'type' },
    {
      title: '내용',
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      align: 'center'
    },
    {
      title: '금액',
      key: 'money',
      width: '15%',
      align: 'center',
      dataIndex: 'money',
      render: (record) => <span className={`${record.includes('-') ? 'text-red-1' : 'text-white'}`}>{record} 원</span>
    },
    {
      title: '보유캐쉬합',
      key: 'title',
      width: '10%',
      align: 'center'
    }
  ]

  const actionFilter = [
    {
      title: '전체',
      type: ''
    },
    {
      title: '스포츠캐쉬',
      type: ''
    },
    {
      title: '카지노캐쉬',
      type: ''
    },
    {
      title: '머니이동',
      type: ''
    },
    {
      title: '지인추천',
      type: ''
    },
    {
      title: '출석보상',
      type: ''
    },
    {
      title: '명예의 전당',
      type: ''
    },
    {
      title: '포인트',
      type: ''
    }
  ]

  const handleCheckActive = (type: string) => {
    return filter.includes(type)
  }

  const data = [
    {
      key: 1,
      title: '[당첨] CQ9 [적중금액: 50]',
      created_at: '2024-04-19 17:42:03',
      type: '카지노캐쉬',
      money: '50',
      content: '2024-04-19 17:42:03 - [당첨] CQ9 [적중금액: 50]'
    },
    {
      key: 2,
      title: '[당첨] CQ9 [적중금액: 50]',
      created_at: '2024-04-19 17:42:03',
      type: '카지노캐쉬',
      money: '-150',
      content: '2024-04-19 17:42:03 - [당첨] CQ9 [적중금액: 50]'
    },
    {
      key: 3,
      title: '[당첨] CQ9 [적중금액: 50]',
      created_at: '2024-04-19 17:42:03',
      type: '카지노캐쉬',
      money: '550',
      content: '2024-04-19 17:42:03 - [당첨] CQ9 [적중금액: 50]'
    }
  ]
  return (
    <div className='px-4 point__container mt-3' data-aos='fade-up'>
      {/* <div className='point__container-header p-2.5'>
        <img src={clockIcon} alt='ring-icon' />
        <span>캐쉬/포인트 내역</span>
      </div> */}
      <div className='point__container-action'>
        {actionFilter.map((item, index) => {
          return (
            <Button
              className={`point__container-action--item ${handleCheckActive(String(index + 1)) ? 'active' : ''}`}
              data-type={item.type}
              key={index}
              onClick={() => setFilter(String(index + 1))}
            >
              {item.title}
            </Button>
          )
        })}
      </div>
      <div className='point__container-table'>
        <ConfigProvider
          renderEmpty={() => {
            return (
              <img
                src={pageSiteQuery?.PageSiteQuery?.siLogo1}
                alt='Logo'
                title='Logo'
                width='118'
                height='59'
                className='max-h-[59px] object-contain max-w-[118px] mx-auto'
              />
            )
          }}
        >
          <DataTable
            columns={BoardColumns}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            expandable={{
              expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>,
              expandRowByClick: true,
              expandIconColumnIndex: -1
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            dataSource={data}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
