import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'

export const useVirtualSportSearchStore = () => {
  const { dataVirtualSportSearch, rules, enableMultiMatch } = useSelector((state: RootState) => state.virtualSport)

  function getRulesByVirtualSportCategory(category: string) {
    return rules.find((rule) => rule.category === category) || null
  }
  return {
    dataVirtualSportSearch,
    getRulesByVirtualSportCategory,
    enableMultiMatch
  }
}
