import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { setShowCart } from 'src/libs/stores/common.reducer'
import { addItemToCartMiniGame, BetMiniGameItem } from 'src/libs/stores/minigame.reducer'
import { formatRate } from 'src/libs/utils/utilFuncs'

interface TextRender {
  banker: string
  banker_pair: string
  tie: string
  player: string
  player_pair: string
}

export const BaccaratBetItem = (data: { key: string; title: string; item: any; transId: string; isLock: boolean }) => {
  const dispatch = useDispatch()
  const { cart } = useCartMiniGameStore()
  const { width } = useViewport()

  const addToCart = ({ betMiniGameItem }: { betMiniGameItem: BetMiniGameItem }) => {
    if (data.isLock) {
      toast.error('Locking time')
      return
    }
    if (width < 1280) {
      dispatch(setShowCart(true))
    }
    // implement when BE implement multi choose in 1 request
    // if (cart.pick_list.length === MAXIMUM_BET_ONE_TIME) {
    //   toast.warning('Can not pick')
    // }
    dispatch(addItemToCartMiniGame(betMiniGameItem))
  }

  const textRender: TextRender = {
    banker: 'BANKER',
    banker_pair: 'BANKER PAIR',
    tie: 'TIE',
    player: 'PLAYER',
    player_pair: 'PLAYER PAIR'
  }

  return (
    <button
      className={cn(
        `w-full bg-[#181a20] hover:bg-primary text-white p-4 rounded flex flex-col md:flex-row items-center justify-center gap-4 shadow-[inset_0_1px_0_hsla(0,0%,100%,.2),0_1px_2px_rgba(0,0,0,.05)]`,
        {
          'flex-col': data?.item?.key === 'tie',
          'bg-[#0A0A0D] hover:bg-primary':
            !cart.pick_list.length || (cart.pick_list.length && cart.pick_list[0].selectedKeyItem !== data?.item?.key),
          'bg-primary is-active': cart.pick_list.length && cart.pick_list[0].selectedKeyItem === data?.item?.key
        }
      )}
      onClick={() => {
        addToCart({
          betMiniGameItem: {
            miniGameType: 'parsing-casino',
            selectedKeyItem: data?.item?.key || '',
            miniGameItem: {
              category: '',
              rate: data?.item?.rate || 0,
              text: textRender[data?.item?.key as keyof TextRender] || '',
              transId: data.transId
            }
          }
        })
      }}
    >
      <div className='flex flex-col items-center'>
        <p
          className={cn(
            'flex items-center w-[70px] h-[70px] justify-center rounded-full bg-gradient-to-b from-[#1d1d1d] to-[#2c2c2c] text-32 font-bold border-black-1 border',
            {
              'text-blue-3': data?.item?.color === 'blue',
              'text-green-700': data?.item?.color === 'green',
              'text-red-1': data?.item?.color === 'red',
              'lg:w-[140px] lg:h-[140px] text-[32px] lg:text-[72px] flex-col': data?.item?.key === 'tie'
            }
          )}
        >
          {data?.item?.text}
        </p>
        <p className='text-secondary-6'>{textRender[data?.item?.key as keyof TextRender]}</p>
      </div>
      <p className='text-28'>{formatRate(data.item.rate)}</p>
    </button>
  )
}
