import {
  CONDITION_SHOW_IN_BOARD,
  ESportsCode,
  LIST_HANDICAP_ID,
  LIST_ID_CHECK_RATE,
  LIST_SCORE_ID,
  LIST_UNDER_OVER_HOME_AWAY_OT_ID,
  LIST_UNDER_OVER_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  MAPPING_CATEGORY_BY_SPORTS_CODE,
  SportItemClient,
  SPORTS_IDX_BASEBALL
} from 'src/types/sports.type'
import {
  BetCartType,
  BetItemPusher,
  BoardBetSportsGroup,
  BoardBetSportsItemGroup,
  Domestic,
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  GroupSportListByKey,
  LiveSportItem,
  LiveSportItemClient
} from '../../types/live-sports.type'
import { BetItem } from '../stores/live-sport.reducer'

export const generateMapKeyLiveSportList = ({ liveSportItem }: { liveSportItem: LiveSportItem }) => {
  // const random = Math.random() > 0.5 ? 2 : 1
  // key map struct: league_idx|location_idx|sports_idx|start_date|status
  return `${liveSportItem.league_idx}|${liveSportItem.location_idx}|${liveSportItem.sports_idx}|${liveSportItem.status === 2 ? 'true' : 'false'}`
}

export const checkIsShowInBoardLiveSports = (item: Domestic) => {
  return item.status !== 1 ||
    (item.rate1 === item.rate3 && item.rate1 === 1) ||
    !checkIsCanBettingRate({
      rate: item.rate1,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    !checkIsCanBettingRate({
      rate: item.rate3,
      game_id: item.game_id,
      sports_idx: item.sports_idx
    }) ||
    checkIsLockAllBetting({
      rate1: item.rate1,
      rate2: item.betid2 && item.rate2 ? item.rate2 : undefined,
      rate3: item.rate3
    })
    ? false
    : true
}

export const calculatorStatus = (domestic: Domestic): number => {
  if (domestic.status2 === 0) {
    return domestic.status1 === 2 || domestic.status3 === 2 ? 2 : 1
  }

  return domestic.status1 === 2 || domestic.status2 === 2 || domestic.status3 === 2 ? 2 : 1
}

export const transformLiveSportDomesticData = (
  domestic: Domestic,
  liveSportsData: LiveSportItem
): DomesticWithKeyMatch => {
  // key map struct: league_idx|sports_idx|start_date|fixtures_idx
  domestic.status = calculatorStatus(domestic)
  domestic.status1 = domestic.status
  domestic.status2 = domestic.status
  domestic.status3 = domestic.status
  return {
    ...domestic,
    location_name: liveSportsData.location_name_kor || liveSportsData.location_name,
    updateType: {
      rate1: EUpdateDomesticType.DEFAULT,
      rate2: EUpdateDomesticType.DEFAULT,
      rate3: EUpdateDomesticType.DEFAULT
    },
    home_team: liveSportsData.home_team_kor || liveSportsData.home_team,
    away_team: liveSportsData.away_team_kor || liveSportsData.away_team,
    keyMatch: `${domestic.league_idx}|${domestic.sports_idx}|${liveSportsData.start_date}|${domestic.fixture_idx}`,
    leagueName: liveSportsData?.league_name_kor || liveSportsData.league_name || '',
    isShowInBoard: checkIsShowInBoardLiveSports(domestic)
  }
}

export const generateFakeDomestic = (liveSportItem: LiveSportItem, domestic?: DomesticWithKeyMatch): Domestic => {
  if (domestic) {
    return {
      ...domestic,
      rate1: 1,
      rate2: 0,
      rate3: 1,
      status: 2
    }
  }
  return {
    idx: liveSportItem.league_idx + liveSportItem.idx,
    game_id: '1',
    sports_name: liveSportItem.sports_name,
    fixture_idx: liveSportItem.idx,
    sports_idx: liveSportItem.sports_idx,
    league_idx: liveSportItem?.league?.idx || liveSportItem.league_idx,
    game_type: 1,
    game_kind: 1,
    game_time: '',
    play_time: '',
    home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
    away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
    location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
    home_team_sub: '',
    vs_team_sub: '',
    away_team_sub: '',

    score1: '',
    score2: '',
    score3: '',
    score4: '',
    result: '',
    isStop: '',
    state: '',
    money1: 0,
    money2: 0,
    money3: 0,
    auto_rate: 0,
    auto_result: 0,
    status: 2,
    add_rate1: '',
    add_rate2: '',
    add_rate3: '',
    rate1: 1,
    rate2: 0,
    rate3: 1,
    status1: 2,
    status2: 2,
    status3: 2,
    betid1: '',
    betid2: '',
    betid3: '',
    isMain: 0,
    main_line: '',
    market: {
      idx: 0,
      name: 'default',
      name_en: 'default',
      group: 0
    }
  }
}

export const transformDataLiveSport = (liveSportItem: LiveSportItem): LiveSportItemClient => {
  if (!liveSportItem.main || Object.keys(liveSportItem.main).length === 0 || liveSportItem.status !== 2) {
    const fakeDomestic: Domestic = generateFakeDomestic(liveSportItem)
    return {
      ...liveSportItem,
      league_name: liveSportItem.league_name_kor || liveSportItem.league_name,
      home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
      away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
      location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
      domestic: [fakeDomestic].map((domesticItem) => {
        return transformLiveSportDomesticData(domesticItem, liveSportItem)
      }),
      count: liveSportItem?.parents
        ? groupBoardBetSports(
            liveSportItem.parents
              .filter((item) => item.market)
              .map((item) => transformLiveSportDomesticData(item, liveSportItem))
          ).reduce(
            (count, curr) =>
              count + groupBoardBetItem(curr.data).reduce((countList, currList) => countList + currList.list.length, 0),
            0
          )
        : liveSportItem.count
    }
  }
  return {
    ...liveSportItem,
    league_name: liveSportItem.league_name_kor || liveSportItem.league_name,
    home_team: liveSportItem.home_team_kor || liveSportItem.home_team,
    away_team: liveSportItem.away_team_kor || liveSportItem.away_team,
    location_name: liveSportItem.location_name_kor || liveSportItem.location_name,
    main: liveSportItem.main,
    domestic: [liveSportItem.main].map((domesticItem) => {
      return transformLiveSportDomesticData(domesticItem, liveSportItem)
    })
  }
}

export const transformCartLiveSportToPickList = (betItem: BetItem): BetCartType => {
  return {
    parent_idx: betItem.domesticData.idx,
    bet_code: betItem.betId,
    fixture_id: betItem.domesticData.fixture_idx,
    key: `${[betItem.domesticData.fixture_idx, betItem.domesticData.rate1, betItem.domesticData.rate2, betItem.domesticData.rate3].join('-')}`,
    status: 0, // idk
    market_name: betItem.domesticData.market.name,
    pick_title: `${betItem.domesticData.home_team} vs ${betItem.domesticData.away_team}`,
    marketId: betItem.domesticData.market.idx,
    old_rate: '',
    pick_detail: '',
    select_idx: 0, // idk
    select_pick_desc: '',
    select_rate: Number(betItem.rate),
    sports_code: betItem.domesticData.sports_idx + '',
    sports_name: betItem.domesticData.sports_name
  }
}

export const groupDataSearchPure = (data: LiveSportItemClient[]): GroupSportListByKey[] => {
  const objGroupSportList: {
    [key: string]: LiveSportItemClient[]
  } = {}
  data.forEach((sportItem) => {
    const key = generateMapKeyLiveSportList({ liveSportItem: sportItem })
    if (key in objGroupSportList) {
      objGroupSportList[key].push(sportItem)
      return
    }

    objGroupSportList[key] = [sportItem]
  })

  return Object.entries(objGroupSportList).map(([key, listGroupSportByTime]) => {
    return {
      key: key,
      listGroupSport: listGroupSportByTime.filter((listGroupSportByKeyItem) => listGroupSportByKeyItem.domestic.length)
    }
  })
}

export const updateRate = ({
  domestic,
  rateKey,
  statusKey,
  betItemPusher
}: {
  domestic: DomesticWithKeyMatch
  rateKey: 'rate1' | 'rate2' | 'rate3'
  statusKey: 'status1' | 'status2' | 'status3'
  betItemPusher: BetItemPusher
}) => {
  if (betItemPusher.rate > domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.UP
  } else if (betItemPusher.rate < domestic[rateKey] && domestic.updateType) {
    domestic.updateType[rateKey] = EUpdateDomesticType.DOWN
  }
  domestic[rateKey] = betItemPusher.rate
  domestic[statusKey] = betItemPusher.status

  return domestic
}

export const checkIsCanBettingRate = ({
  rate,
  sports_idx,
  game_id
}: {
  rate?: number
  sports_idx: number
  game_id: string
}) => {
  if (LIST_SCORE_ID.includes(game_id) || !LIST_ID_CHECK_RATE.includes(game_id)) {
    return true
  }
  if (!rate || rate < 1.4 || rate > 2.6) {
    return false
  }
  if (sports_idx === SPORTS_IDX_BASEBALL) {
    // 1.6 => 2.0
    return rate >= 1.6 && rate <= 2.0
  }
  // 1.4 => 2.6
  return rate >= 1.4 && rate <= 2.6
}

export const validOptionScore = ({
  sportData,
  domesticData
}: {
  sportData?: LiveSportItemClient | SportItemClient
  domesticData: DomesticWithKeyMatch
}): boolean => {
  if (!sportData) {
    return false
  }
  const { home_team_sub } = domesticData
  const { home_score: currentHomeScore, away_score: currentAwayScore } = sportData
  const [checkHomeScore, checkAwayScore] = home_team_sub.split('-').map(Number)
  if (currentHomeScore > checkHomeScore) {
    return false
  }

  if (currentAwayScore > checkAwayScore) {
    return false
  }

  if (currentHomeScore === checkHomeScore && currentAwayScore >= checkAwayScore) {
    return false
  }

  if (currentAwayScore === checkAwayScore && currentHomeScore >= checkHomeScore) {
    return false
  }

  return true
}

export const checkIsLockAllBetting = ({ rate1, rate2, rate3 }: { rate1: number; rate2?: number; rate3: number }) => {
  let minRate = 2

  if (rate2 !== undefined) {
    minRate = 3
    if (rate2 >= minRate && rate1 >= minRate && rate3 >= minRate) {
      return true
    }
  }

  if (rate1 >= minRate && rate3 >= minRate) {
    return true
  }

  return false
}

export const groupBoardBetItem = (domesticList: DomesticWithKeyMatch[]): BoardBetSportsItemGroup[] => {
  const obj = new Map<string, BoardBetSportsItemGroup>()
  domesticList.forEach((item) => {
    let order = 99

    if (LIST_WIN_TIE_LOSE_ID.includes(item.game_id)) {
      order = 0
    } else if (LIST_WIN_LOSE_ID.includes(item.game_id)) {
      order = 1
    } else if (LIST_HANDICAP_ID.includes(item.game_id)) {
      order = 2
    } else if (LIST_UNDER_OVER_ID_ALL.includes(item.game_id)) {
      order = 3
    }
    const key = item.game_id + item.market.name

    if (!obj.has(key)) {
      obj.set(key, {
        order: order,
        marketName: item.market.name,
        gameId: item.game_id,
        sportsCode: item.sports_idx,
        list: [item]
      })
    } else {
      obj.get(key)!.list.push(item)
    }
  })

  const orderList = [...Array.from(obj.values())]
    .sort((a, b) => a.order - b.order)
    .map((item) => {
      return {
        ...item,
        list: LIST_SCORE_ID.includes(item.gameId)
          ? item.list.sort((a, b) => {
              const textA = a.home_team_sub.toUpperCase()
              const textB = b.home_team_sub.toUpperCase()
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
          : item.list.sort((a, b) => {
              return Number(a.main_line.split(' ')[0]) - Number(b.main_line.split(' ')[0])
            })
      }
    })

  const filteredConditionRateList = orderList
    .map((item) => {
      const indexTarget = getIndexTarget(item.list)
      return {
        ...item,
        list: item.list.filter((_, index) => {
          return (
            indexTarget === -999 ||
            (indexTarget !== -1 && [indexTarget - 1, indexTarget, indexTarget + 1].includes(index))
          )
        })
      }
    })
    .filter((item) => item.list.length)
  return filteredConditionRateList
}

export const groupBoardBetSports = (dataRateInfoList: DomesticWithKeyMatch[]): BoardBetSportsGroup[] => {
  const obj = new Map<number, BoardBetSportsGroup>()

  dataRateInfoList
    .filter((item) => item.isShowInBoard)
    .forEach((item) => {
      let order = 99

      if (LIST_WIN_TIE_LOSE_ID.includes(item.game_id)) {
        order = 0
      } else if (LIST_WIN_LOSE_ID.includes(item.game_id)) {
        order = 1
      } else if (LIST_HANDICAP_ID.includes(item.game_id)) {
        order = 2
      } else if (LIST_UNDER_OVER_ID_ALL.includes(item.game_id)) {
        order = 3
      }
      if (!obj.has(item.market.group || 0)) {
        obj.set(item.market.group || 0, {
          key: item.market.group || 0,
          group: item.market.group || 0,
          order: order,
          name: item.market?.group
            ? MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[item.market.group]
            : MAPPING_CATEGORY_BY_SPORTS_CODE[item.sports_idx]?.[0],
          sportsCode: item.sports_idx,
          data: [item]
        })
      } else {
        obj.get(item.market.group || 0)!.data.push(item)
      }
    })

  const orderList = [...Array.from(obj.values())].sort((a, b) => a.group - b.group)

  if (orderList.findIndex((item) => item.group === 0) === -1) {
    return orderList
  }

  const [firstGroup, ...restGroup] = orderList

  return [...restGroup, firstGroup]
}

export const getIndexTarget = (dataRateInfoList: DomesticWithKeyMatch[]) => {
  if (dataRateInfoList.length === 0) {
    return -999
  }

  if (
    !LIST_UNDER_OVER_ID_ALL.includes(dataRateInfoList[0].game_id) &&
    !LIST_HANDICAP_ID.includes(dataRateInfoList[0].game_id)
  ) {
    return -999
  }

  const conditionSports = CONDITION_SHOW_IN_BOARD[dataRateInfoList[0].sports_idx as ESportsCode]
  if (!conditionSports || !conditionSports.isNeedCheck) {
    return -999
  }

  const { condition } = conditionSports
  const itemMatchConditionList = dataRateInfoList.filter((option) => {
    return option.rate1 <= condition.max && option.rate1 >= condition.min
  })
  if (itemMatchConditionList.length) {
    if (LIST_UNDER_OVER_ID_ALL.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(b.main_line.split(' ')[0]) - Number(a.main_line.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
    if (LIST_HANDICAP_ID.includes(itemMatchConditionList[0].game_id)) {
      const sortDesc = itemMatchConditionList.sort((a, b) => {
        return Number(a.main_line.split(' ')[0]) - Number(b.main_line.split(' ')[0])
      })
      return dataRateInfoList.findIndex((item) => item.idx === sortDesc[0].idx)
    }
  }

  return -1
}
