import { gql } from '@apollo/client'

const getBetHistory = gql`
  query BetHistoriesQuery($limit: Int, $offset: Int, $category: String, $start_date: String) {
    BetHistoriesQuery(limit: $limit, offset: $offset, category: $category, start_date: $start_date) {
      data {
        uuid
        mID
        pCode
        gCode
        gName
        gNameEn
        gCategory
        tRoundId
        tType
        tAmount
        tReferenceUuid
        tRoundStarted
        tRoundFinished
        tDetails
        tRegDate
        tUpdateDate
        tAmountReturn
        tResult
      }
      total
      per_page
      current_page
      from
      to
      last_page
    }
  }
`

export { getBetHistory }
