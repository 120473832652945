import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import DataTable from 'src/components/Table/DataTable'
import { pathMiniGameType } from 'src/constants/path'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { DetailMiniGameBetting, ResultMiniGameHistory } from 'src/types/minigame.type'
import { useEventMiniGame } from '../useEventMiniGame'
import { BoardBetMiniGameBoscorePowerBall } from './BoardBetMiniGameBoscorePowerBall'
import { NotFoundState } from 'src/components/NotFoundState'
import { useBoscorePowerBallGame } from './useBoscorePowerBallGame'
import { GET_LOGO_PRIORITY_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'

/* eslint-disable jsx-a11y/iframe-has-title */
export default function BoscorePowerBall() {
  const { data: configMeta } = useQuery(GET_LOGO_PRIORITY_QUERY, { context: { apiName: 'auth' } })
  const siLogo2 = configMeta?.PageSiteQuery?.siLogo2
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const iframeParentRef = useRef<HTMLIFrameElement>(null)
  const { data, loading, error, handleRefetchGame } = useBoscorePowerBallGame()
  const { refetchHistoryBetting } = useCartMiniGameStore()

  const { width } = useViewport()
  useEventMiniGame({ callback: handleRefetchGame })
  const dispatch = useDispatch()
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [miniHistoryBet, setMiniHistoryBet] = useState<DetailMiniGameBetting[]>([])
  const {
    data: miniGameBetData,
    refetch: refetchMiniGameBetData,
    loading: loadingHistory
  } = useQuery<ResultMiniGameHistory>(GET_MINI_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: 1, category: pathMiniGameType[location.pathname] },
    context: { apiName: 'mini-game' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setMiniHistoryBet(data.MiniGameBettingList?.data)
      setQuery((prev) => ({
        ...prev,
        total: data?.MiniGameBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    }
  })

  const columns: TableColumnsType<DetailMiniGameBetting> = [
    {
      title: '시간',
      dataIndex: 'tmRegDate',
      key: 'tmRegDate',
      width: '180px',
      align: 'center',
      render: (_, record) => {
        return record.tmRegDate
      }
    },
    {
      title: '배팅정보',
      dataIndex: 'tmProviderGame',
      key: 'tmProviderGame',
      // width: '80px',
      align: 'center'
    },
    {
      title: '배팅액',
      dataIndex: 'tmBetAmount',
      key: 'tmBetAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        // return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
        return formatCurrency(value || 0)
      }
    },
    {
      title: '배팅접수',
      dataIndex: 'tmSelectedItem',
      key: 'tmSelectedItem',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmSelectedItem
          ? JSON.parse(record.tmSelectedItem)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmSelectedItem)?.color?.split(' ')
            : [record?.tmSelectedItem]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmSelectedItem && JSON.parse(record.tmSelectedItem)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || 'red'
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '배팅결과',
      dataIndex: 'tmResultBetting',
      key: 'tmResultBetting',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmResultBetting
          ? JSON.parse(record.tmResultBetting)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmResultBetting)?.color?.split(' ')
            : [record?.tmResultBetting]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmResultBetting && JSON.parse(record.tmResultBetting)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || ''
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '적중금액',
      dataIndex: 'tmWinAmount',
      key: 'tmWinAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
      }
    },
    {
      title: '상태결과',
      dataIndex: 'tmStatus',
      key: 'tmStatus',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result =
          record.tmStatus === 'bet'
            ? '배팅'
            : record.tmStatus === 'win'
              ? '당첨'
              : record.tmStatus === 'draw'
                ? '적특'
                : '낙첨'
        return result
      }
    }
  ]

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetchMiniGameBetData({ limit: query?.limit || 10, page: current_page || 1 })
  }

  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      const iframeParent = iframeParentRef.current

      if (iframe && iframeParent) {
        const screenWidth = iframeParent.offsetWidth
        const screenHeight = iframeParent.offsetHeight

        // Calculate the aspect ratio
        const aspectRatio = 800 / 700
        const parentAspectRatio = screenWidth / screenHeight

        let scaleX, scaleY

        if (parentAspectRatio > aspectRatio) {
          // Parent is wider than the aspect ratio, scale based on height
          scaleY = screenHeight / 700
          scaleX = scaleY
        } else {
          // Parent is taller than the aspect ratio, scale based on width
          scaleX = screenWidth / 800
          scaleY = scaleX
        }

        iframe.style.transform = `scale(${scaleX}, ${scaleY})`
        iframe.style.transformOrigin = '0 0'
        iframe.style.margin = 'auto'
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    if (iframeParentRef.current) {
      resizeObserver.observe(iframeParentRef.current)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    if (refetchHistoryBetting) {
      handleRefetchGame()
    }
  }, [refetchHistoryBetting])
  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 relative overflow-hidden items-center 3xl:items-stretch'>
        <div className='w-full aspect-[800/700]' ref={iframeParentRef}>
          <iframe
            scrolling='no'
            ref={iframeRef}
            src={'https://boscore.com/powerball/view.php?popup=1'}
            title='boscore powerball'
            width={800}
            height={700}
            className='absolute'
            // className='!scale-[0.5] -top-[170px] -left-[200px] xs:!scale-[0.55] xs:-left-[180px] xs:-top-[156px] md:!scale-[.95] md:left-[20px] md:-top-[20px] lg:left-[100px] 2xl:static 2xl:!scale-100 relative overflow-hidden'
          ></iframe>
        </div>
        <div className='w-full relative'>
          <div className='relative 2xl:static z-20 bg-mini-game-table-5 h-full'>
            {loading && !data?.PowerBallBoscoQuery ? (
              <div className={`h-[500px]`}>
                <div className='h-full flex items-center justify-center '>
                  <img src={siLogo2} className='animate-pulse w-1/2 max-w-[200px]' alt='logo loading' />
                </div>
              </div>
            ) : data?.PowerBallBoscoQuery ? (
              <BoardBetMiniGameBoscorePowerBall
                handleRefetchGame={handleRefetchGame}
                data={data.PowerBallBoscoQuery}
                loading={loading}
              />
            ) : (
              <NotFoundState message={error?.message} />
            )}
          </div>
        </div>
      </div>
      <div className='mx-2 mt-8 table-responsive -top-[330px] md:-top-[180px] 2xl:static'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={columns}
            loading={loadingHistory}
            dataSource={miniHistoryBet as any}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
