/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery } from '@apollo/client'
import { ConfigProvider, Select, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import path from 'src/constants/path.ts'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore.ts'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer.ts'
import { usePusher } from 'src/providers/PusherProvider'
import DataTable from '../../../components/Table/DataTable'
import {
  GET_MENU_MINI_GAME_CATEGORY,
  GET_MINI_GAME_BET_HISTORY
} from '../../../libs/apis/graphql/queries/game.query.ts'
import useQueryParams from '../../../libs/hooks/useQueryParams.ts'
import { addKey, formatCurrency } from '../../../libs/utils/utilFuncs.ts'
import {
  DetailMiniGameBetting,
  DetailParsingCasinoBetting,
  MenuMiniGameList,
  ParsingCasinoList,
  ResultMiniGameHistory,
  ResultParsingCasinoGameHistory,
  TabMiniGameInfo
} from '../../../types/minigame.type.ts'
import './minigameSelect.scss'
import {
  GET_PARSING_CASINO_GAME_BET_HISTORY,
  GET_PARSING_CASINO_MENU
} from 'src/libs/apis/graphql/queries/parsing-casino.query.ts'
import { useEventMiniGame } from 'src/pages/MiniGame/useEventMiniGame.ts'
import { useEventParsingCasino } from 'src/pages/MiniGame/useEvenParsingCasino.ts'
import RenderMinigameSelected from 'src/components/ui/RenderMiniGameSelected.tsx'
export interface MenuWithKey {
  no?: number
  name?: string
  nameEn?: string
  path?: string
  link?: string
  logo?: string
  subMenu: {
    no?: number
    name?: string
    nameEn?: string
    path?: string
    link?: string
    logo?: string
  }[]
  isParsingCasino: boolean
}

export default function Minigame() {
  const pusher = usePusher()
  const navigate = useNavigate()
  const [menu, setMenu] = useState<MenuWithKey[]>([])
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const dispatch = useDispatch()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [miniHistoryBet, setMiniHistoryBet] = useState<DetailMiniGameBetting[]>([])
  const { refetchHistoryBetting } = useCartMiniGameStore()
  const location = useLocation()
  const queryParams = useQueryParams()
  const [selectedValue, setSelectedValue] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [isShowParsingCasino, setIsShowParsingCasino] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (!isDragging || !sliderRef.current) return

    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const {
    data,
    refetch: refetchBattelMiniGameBetData,
    loading
  } = useQuery<ResultMiniGameHistory>(GET_MINI_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: 1, category: selectedValue },
    context: { apiName: 'mini-game' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMiniHistoryBet(data.MiniGameBettingList?.data?.map(addKey))
      setQuery((prev) => ({
        ...prev,
        total: data?.MiniGameBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    },
    skip: !selectedValue || isShowParsingCasino
  })

  const {
    data: dataParsingCasino,
    refetch: refetchBattelMiniGameBetDataParsingCasino,
    loading: loadingParsingCasino
  } = useQuery<ResultParsingCasinoGameHistory>(GET_PARSING_CASINO_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: 1, category: selectedValue },
    context: { apiName: 'parsing-casino' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMiniHistoryBet(
        data.ParsingCasinoBettingList?.data?.map(addKey).map<DetailMiniGameBetting>((item) => {
          return {
            tmBetAmount: item.tpcBetAmount,
            tmRegDate: item.tpcRegDate,
            tmProviderGame: item.tpcProviderGame,
            tmResultBetting: item.tpcResultBetting,
            tmSelectedItem: item.tpcSelectedItem,
            tmStatus: item.tpcStatus,
            tmTransID: item.tpcTransID,
            tmWinAmount: item.tpcWinAmount,
            tmRate: item.tpcRate,
            tmDetails: item.tpcDetails
          }
        })
      )
      setQuery((prev) => ({
        ...prev,
        total: data?.ParsingCasinoBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    },
    skip: !selectedValue || !isShowParsingCasino
  })

  const { data: categoryMiniGame = { MenuMiniGameQuery: [] } } = useQuery<MenuMiniGameList>(
    GET_MENU_MINI_GAME_CATEGORY,
    {
      context: { apiName: 'mini-game' },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const { data: categoryParsingCasino = { MenuParsingCasinoQuery: [] } } = useQuery<ParsingCasinoList>(
    GET_PARSING_CASINO_MENU,
    {
      context: { apiName: 'parsing-casino' },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  // const options =
  //   categoryMiniGame?.MenuMiniGameQuery.map((category) => ({
  //     label: category.mgName,
  //     value: category.gcReference
  //   })) || []

  // const options = useMemo(() => {
  //   if (categoryMiniGame?.MenuMiniGameQuery?.length) {
  //     const arr = categoryMiniGame?.MenuMiniGameQuery.map((item) => item.MiniGames)
  //     return {
  //       mini1: arr[0].map((item) => ({
  //         label: (item as TabMiniGameInfo)?.mgName,
  //         value: (item as TabMiniGameInfo)?.mgNameEn
  //       })),
  //       mini2: arr[1].map((item) => ({
  //         label: (item as TabMiniGameInfo)?.mgName,
  //         value: (item as TabMiniGameInfo)?.mgNameEn
  //       })),
  //       mini3: arr[2].map((item) => ({
  //         label: (item as TabMiniGameInfo)?.mgName,
  //         value: (item as TabMiniGameInfo)?.mgNameEn
  //       })),
  //       mini4: arr[3].map((item) => ({
  //         label: (item as TabMiniGameInfo)?.mgName,
  //         value: (item as TabMiniGameInfo)?.mgNameEn
  //       })),
  //       mini5: arr[4].map((item) => ({
  //         label: (item as TabMiniGameInfo)?.mgName,
  //         value: (item as TabMiniGameInfo)?.mgNameEn
  //       }))
  //     }
  //   }
  // }, [categoryMiniGame])

  const handleFilter = (filterBy: string, isParsingCasino: boolean = false) => {
    const queryParamsTemp = { ...queryParams, filter: filterBy }
    window.history.pushState(null, '', `${path.battle_minigame}?filter=${filterBy}`)
    setSelectedValue(filterBy)

    refetchBattelMiniGameBetData()
  }

  const handleChange = (event: string, isParsingCasino: boolean = false) => {
    const newValue = event
    handleFilter(newValue, isParsingCasino)
  }

  const columns: TableColumnsType<DetailMiniGameBetting> = [
    {
      title: '시간',
      dataIndex: 'tmRegDate',
      key: 'tmRegDate',
      width: '180px',
      align: 'center',
      render: (_, record) => {
        return record.tmRegDate
      }
    },
    {
      title: '배팅정보',
      dataIndex: 'tmProviderGame',
      key: 'tmProviderGame',
      // width: '80px',
      align: 'center'
    },
    {
      title: '배팅액',
      dataIndex: 'tmBetAmount',
      key: 'tmBetAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        // return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
        return formatCurrency(value || 0)
      }
    },
    {
      title: '배당',
      dataIndex: 'tmRate',
      key: 'tmRate',
      // width: '80px',
      align: 'center',
      render: (value: number) => {
        return value.toFixed(2)
      }
    },
    {
      title: '배팅접수',
      dataIndex: 'tmSelectedItem',
      key: 'tmSelectedItem',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmSelectedItem
          ? JSON.parse(record.tmSelectedItem)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmSelectedItem)?.color?.split(' ')
            : [record?.tmSelectedItem]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmSelectedItem && JSON.parse(record.tmSelectedItem)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || 'red'
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <RenderMinigameSelected betSelected={JSON.parse(result[0])?.text} />
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '배팅결과',
      dataIndex: 'tmResultBetting',
      key: 'tmResultBetting',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmResultBetting
          ? JSON.parse(record.tmResultBetting)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmResultBetting)?.color?.split(' ')
            : [record?.tmResultBetting]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmResultBetting && JSON.parse(record.tmResultBetting)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || ''
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <RenderMinigameSelected betSelected={JSON.parse(result[0])?.text} />
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '적중금액',
      dataIndex: 'tmWinAmount',
      key: 'tmWinAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
      }
    },
    {
      title: '상태결과',
      dataIndex: 'tmStatus',
      key: 'tmStatus',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result =
          record.tmStatus === 'bet'
            ? '배팅'
            : record.tmStatus === 'win'
              ? '당첨'
              : record.tmStatus === 'draw'
                ? '적특'
                : '낙첨'
        return result
      }
    }
  ]

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetchBattelMiniGameBetData({ limit: query?.limit || 10, page: current_page || 1 })
  }

  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      if (iframe) {
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const iframeWidth = iframe.offsetWidth
        const iframeHeight = iframe.offsetHeight
        const scaleX = screenWidth / iframeWidth
        const scaleY = screenHeight / iframeHeight
        const scale = Math.min(scaleX, scaleY)
        iframe.style.transform = `scale(${1})`
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // useEffect(() => {
  //   if (refetchHistoryBetting) {
  //     refetchBattelMiniGameBetData()
  //   }
  // }, [refetchHistoryBetting])
  //  min-h-[calc(100dvh-114px)]

  // useEffect(() => {
  //   const channel = pusher?.subscribe('minigame-result-channel')
  //   channel?.bind('minigame-result-event', async (data) => {
  //     if (data.game === selectedValue) {
  //       await refetchBattelMiniGameBetData()
  //     }
  //   })

  //   return () => {
  //     channel?.unbind('minigame-result-event')
  //     pusher?.unsubscribe('minigame-result-channel')
  //   }
  // }, [selectedValue, pusher])
  useEventMiniGame({
    callback: async () => {
      await refetchBattelMiniGameBetData()
    },
    gameType: selectedValue
  })
  useEventParsingCasino({
    callback: async () => {
      await refetchBattelMiniGameBetDataParsingCasino()
    },
    gameType: selectedValue
  })

  useEffect(() => {
    if (categoryMiniGame?.MenuMiniGameQuery.length || categoryParsingCasino?.MenuParsingCasinoQuery.length) {
      const parseGameMenu = categoryMiniGame?.MenuMiniGameQuery.map((item) => {
        if (!item.MiniGames.length) {
          return null
        }
        return {
          no: item.mgpNo,
          name: item.mgpName,
          nameEn: item.mgpNameEn,
          path: item.MiniGames[0].mgPath,
          link: item.MiniGames[0].mgFrameLink,
          logo: item.mgpLogo,
          subMenu: item.MiniGames.map((subItem) => ({
            no: subItem.mgNo,
            name: subItem.mgName,
            nameEn: subItem.mgNameEn,
            path: subItem.mgPath,
            link: subItem.mgFrameLink,
            logo: item.mgpLogo
          })),
          isParsingCasino: false
        }
      }).filter((i) => i !== null)
      const parseCasinoMenu = categoryParsingCasino?.MenuParsingCasinoQuery.map((item) => {
        if (!item.Providers.length) {
          return null
        }
        return {
          no: item.pcpNo,
          name: item.pcpName,
          nameEn: item.pcpNameEn,
          path: item.Providers[0]?.pcPath?.replace('/mini/parsing-casino/', '/mini/'),
          link: item.Providers[0]?.pcFrameLink,
          logo: item.pcpLogo,
          subMenu: item.Providers.map((subItem) => ({
            no: subItem.pcNo,
            name: subItem.pcName,
            nameEn: subItem.pcNameEn,
            path: (subItem.pcPath || '').replace('/mini/parsing-casino/', '/mini/'),
            link: subItem.pcFrameLink,
            logo: item?.pcpLogo || '/default-minigame.png'
          })),
          isParsingCasino: true
        }
      }).filter((i) => i !== null)
      setMenu([...parseGameMenu, ...parseCasinoMenu])
    }
  }, [categoryMiniGame, categoryParsingCasino])

  return (
    <>
      <div className='flex items-center gap-2 battle-category'>
        {/* <Button
          className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14  ${queryParams?.filter === 'bo_start_1' ? '!bg-primary !text-black' : ''}`}
          onClick={() => handleFilter('bo_start_1')}
          disabled={loading}
        >
          Mini Boscore Ladder1
        </Button>
        <Button
          className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14  ${queryParams?.filter === 'bo_start_2' ? '!bg-primary !text-black' : ''}`}
          onClick={() => handleFilter('bo_start_2')}
          disabled={loading}
        >
          Mini Boscore Ladder2
        </Button>
        <Button
          className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14  ${queryParams?.filter === 'bo_start_3' ? '!bg-primary !text-black' : ''}`}
          onClick={() => handleFilter('bo_start_3')}
          disabled={loading}
        >
          Mini Boscore Ladder3
        </Button> */}
        {/* <Select
          defaultValue='보스코어'
          onChange={(e) => handleChange(e)}
          className='flex-shrink-0 w-[150px] h-full select_minigame'
          options={options?.mini1}
        />
        <Select
          defaultValue='엔트리'
          onChange={(e) => handleChange(e)}
          className='flex-shrink-0 w-[150px] h-full select_minigame'
          options={options?.mini2}
        />
        <Select
          defaultValue='슈어맨'
          onChange={(e) => handleChange(e)}
          className='flex-shrink-0 w-[150px] h-full select_minigame'
          options={options?.mini3}
        />
        <Select
          defaultValue='베픽'
          onChange={(e) => handleChange(e)}
          className='flex-shrink-0 w-[150px] h-full select_minigame'
          options={options?.mini4}
        />
        <Select
          defaultValue='스코어탐정'
          onChange={(e) => handleChange(e)}
          className='flex-shrink-0 w-[150px] h-full select_minigame'
          options={options?.mini5}
        /> */}

        <div className='w-full overflow-auto scrollbar-hide'>
          <div className='relative flex items-center justify-between gap-4 group pb-4 pt-2'>
            <div className='flex items-center gap-4 overflow-hidden'>
              <nav
                ref={sliderRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                className='flex items-center space-x-2 lg:space-x-4 overflow-x-auto cursor-grab hide-scrollbar'
              >
                {/* {categoryMiniGame?.MenuMiniGameQuery.map((item) => {
                  const listOptionMiniGame = item.MiniGames.map((game) => {
                    return {
                      label: game.mgName,
                      value: game.mgNameEn
                    }
                  })
                  return (
                    <Select
                      key={item.mgpNo}
                      defaultValue={item.mgpName}
                      onChange={(e) => {
                        handleChange(e, false)
                      }}
                      className='flex-shrink-0 w-[150px] h-full select_minigame'
                      options={listOptionMiniGame}
                    />
                  )
                })} */}
                {menu.map((item) => {
                  const listOptionMiniGame = item.subMenu.map((game) => {
                    return {
                      label: game.name,
                      value: game.nameEn
                    }
                  })
                  return (
                    <Select
                      key={item.no}
                      defaultValue={item.name}
                      onChange={(e) => {
                        setIsShowParsingCasino(item.isParsingCasino || false)
                        handleChange(e, item.isParsingCasino || false)
                      }}
                      className='flex-shrink-0 w-[150px] h-full select_minigame'
                      options={listOptionMiniGame}
                    />
                  )
                })}
                {/* {categoryMiniGame?.MenuMiniGameQuery.map((item) => {
                  const listOptionMiniGame = item.MiniGames.map((game) => {
                    return {
                      label: game.mgName,
                      value: game.mgNameEn
                    }
                  })
                  return (
                    <Select
                      key={item.mgpNo}
                      defaultValue={item.mgpName}
                      onChange={(e) => {
                        handleChange(e, false)
                      }}
                      className='flex-shrink-0 w-[150px] h-full select_minigame'
                      options={listOptionMiniGame}
                    />
                  )
                })} */}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-2 mt-8 table-responsive'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={columns}
            loading={loading || loadingParsingCasino}
            dataSource={miniHistoryBet as any}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
