import { BetCartType, VirtualSportsGroupByIdx } from 'src/types/virtual-sport'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import { BetItem } from '../stores/virtual-sport.reducer'

export const groupVirtualSportsByIdx = (virtualSportsDataList: VirtualSportsDataItem[]): VirtualSportsGroupByIdx[] => {
  const obj = new Map<string, VirtualSportsGroupByIdx>()

  virtualSportsDataList.forEach((item) => {
    if (!obj.has(item.idx)) {
      obj.set(item.idx, {
        idx: item.idx,
        provider: 'BET365',
        title: item.title,
        titleEn: item.title,
        date: item.date_origin,
        data: [item]
      })
    } else {
      obj.get(item.idx)!.data.push(item)
    }
  })
  return Array.from(obj.values())
}

export const transformCartVirtualSportToPickList = (betItem: BetItem): BetCartType => {
  return {
    betid: betItem.betId,
    select_rate: Number(betItem.rate),
    idx: betItem.virtualData.idx,
    list: betItem.virtualDataForRacing.map((item) => ({
      name: item.home,
      position: Number(item.away)
    }))
  }
}

export const generateKeyMatchVirtualSport = (virtualData: VirtualSportsDataItem) => {
  return `${virtualData.idx}-${virtualData.date_origin}`
}
