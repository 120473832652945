import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

const EventZoneBanner = ({ Pagination, centerBanners }: { Pagination: any; centerBanners: any }) => {
  return (
    <>
      <div className='w-full h-auto shadow-inner'>
        <Swiper
          slidesPerView={1}
          className='h-full'
          modules={Pagination}
          pagination={{ bulletActiveClass: 'bg-white opacity-100', clickable: true }}
        >
          {centerBanners?.map((item: any, index: any) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={item?.image}
                  alt={item?.image}
                  title={item?.image}
                  className='w-full h-full object-cover shadow-inner'
                />
                <Link
                  to={item?.link}
                  target={item?.target ? '_blank' : '_self'}
                  className='absolute top-0 left-0 w-full h-full'
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default EventZoneBanner
