import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'
import { DomesticWithKeyMatch } from 'src/types/live-sports.type'

export const useCartLiveSportsStore = () => {
  const { cart, isBetting } = useSelector((state: RootState) => state.liveSport)
  function getCountItemInMatch(keyMatch: string) {
    let count = 0
    cart.pick_list.forEach((item) => {
      if (item.keyMatch === keyMatch) {
        count++
      }
    })
    return count
  }

  function getAllItemInMatch(domesticData: DomesticWithKeyMatch) {
    const allItemInMatch = cart.pick_list.filter((item) => item.keyMatch === domesticData.keyMatch)
    return allItemInMatch
  }

  return {
    cart,
    limitBetting: cart.bettingLimit,
    count: cart?.pick_list?.length,
    getCountItemInMatch,
    getAllItemInMatch,
    isBetting
  }
}
