import { LiveSportItemClient } from 'src/types/live-sports.type'
import { DomesticList } from './DomesticList'

type Props = {
  sportData: LiveSportItemClient
}

export const GroupLiveSportItem = ({ sportData }: Props) => {
  const { domestic: listDomestic } = sportData

  return <DomesticList sportData={sportData} listDomestic={listDomestic} />
}
