import { useEffect } from 'react'
type Props = {
  cbInactiveTab?: () => void
  cbActiveTab?: () => void
}
export function useDocumentVisibility({ cbInactiveTab = () => {}, cbActiveTab = () => {} }: Props) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        cbInactiveTab()
      } else {
        cbActiveTab()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
}
