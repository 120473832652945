import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'

export const useCartMiniGameStore = () => {
  const { cart, maximumBetOnceTime, refetchHistoryBetting } = useSelector((state: RootState) => state.miniGame)
  return {
    cart,
    refetchHistoryBetting,
    maximumBetOnceTime
  }
}
