import { gql } from '@apollo/client'

export const CALENDAR_ATTENDANCE = gql`
  query CalendarAttendanceQuery($month: String) {
    CalendarAttendanceQuery(month: $month) {
      date
      is_attended
      is_bonus
      is_today_attendance
    }
  }
`
