import { gql } from '@apollo/client'

const GET_NOTES = gql`
  query Note($page: Int, $limit: Int) {
    note(page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        id
        title
        content
        created_at
        is_read
      }
    }
  }
`

export const GET_ANNOUNCEMENTS = gql`
  query NewsBoard($page: Int, $limit: Int, $filter: String){
    newsBoard(page: $page, limit: $limit, filter: $filter) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        id
        category_id
        category_name
        title
        content
        created_date
      }
    }
    categoryNewsBoard {
      category_id
      category_name
    }
  }
`
const getCountNoteNoRead = gql`
  query CountNoteNoRead {
    countNoteNoRead {
      count
      count_no_read
    }
  }
`

export { GET_NOTES, getCountNoteNoRead }
