import { gql } from '@apollo/client'

export const WITHDRAW_CHECK_QUERY = gql`
  query WithdrawCheckQuery {
    WithdrawCheckQuery {
      wallet {
        key
        description
        rolling_percent
        note
        is_enable_withdraw
      }
      rolling {
        key
        text
        icon
        current
        withdraw
      }
    }
  }
`
