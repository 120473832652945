import { useDispatch } from 'react-redux'
import { logout, setOpenAuthModal } from 'src/libs/stores/auth.reducer'
import { clearLS } from 'src/libs/utils/auth'
import Button from '../ui/Button'
import { ButtonProps } from '../ui/Button/Button'
import { useNavigate } from 'react-router-dom'
import path from 'src/constants/path'
import { useMutation } from '@apollo/client'
import { LOGOUT_MUTATION } from 'src/libs/apis/graphql/mutations/auth.mutation'

type Props = ButtonProps & {
  variant?: 'normal' | 'iconOnly'
}

export default function SignOutButton(props: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { variant = 'normal', className, ...restProps } = props
  const [logoutMutation] = useMutation(LOGOUT_MUTATION, {
    context: {
      apiName: 'member'
    }
  })

  const handleClick = async () => {
    await logoutMutation()
    clearLS()
    dispatch(logout())
    dispatch(setOpenAuthModal('login'))
    navigate(path.home)
  }

  if (variant === 'iconOnly')
    return (
      <Button {...restProps} onClick={handleClick} className={className}>
        <img
          className={`w-[24px] h-[24px] max-w-none`}
          src='/assets/images/icons/logout.png'
          alt='Icon Logout'
          title='Icon Logout'
        />
      </Button>
    )

  return (
    <Button
      {...restProps}
      onClick={handleClick}
      className={`text-[13px] border-2 border-solid border-[#daa] text-shadow-[1px_1px_2px_rgba(0,0,0,0.7)] bg-gradient-to-b from-[#a41e1e] to-[#641f1f] h-[30px] leading-[30px] w-[120px] rounded-[30px] text-center p-0 ${className}`}
    >
      <i className='fas fa-sign-out-alt text-[13px] mr-1'></i>
      로그아웃
    </Button>
  )
}
