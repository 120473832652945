// /* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { Channel } from 'pusher-js'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import path, { showCartPath, sportsPrematchInplay, sportsRouters } from 'src/constants/path'
import { sportsApi } from 'src/libs/apis/axios/sports.api'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { GET_LIMIT_SPORTS } from 'src/libs/apis/graphql/queries/sport.query'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import {
  login,
  logout,
  setForceHiddenRegister,
  setMoneyAnim,
  setNotiMoney,
  setOpenAuthModal
} from 'src/libs/stores/auth.reducer'
import {
  clearCategoriesSports,
  clearMarketInfoList,
  setCategoriesLiveSports,
  setCategoriesSports,
  setMarketInfoList,
  setShowCart
} from 'src/libs/stores/common.reducer'
import { storeLimitLiveSportBetting } from 'src/libs/stores/live-sport.reducer'
import { storeLimitSportBetting } from 'src/libs/stores/sport.reducer'
import { client } from 'src/libs/utils/apollo'
import { clearLS, getAccessTokenFromLS } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'
import { usePusher } from 'src/providers/PusherProvider'
import {
  GetBalanceChannelPusher,
  MaintenanceChannelType,
  MoneyInfoChannelType,
  PusherChannelType
} from 'src/types/pusher.type'
import { LimitSport } from 'src/types/sports.type'
import { useCommonStore } from 'src/libs/hooks/store/useCommonStore'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { SiteInfoQueryType } from 'src/types/common.type'

const MONEY_INFO_CHANNEL = 'private-money-info-channel'
const MONEY_INFO_EVENT = 'money-info-event'

const GET_BALANCE_CHANNEL = 'private-get-balance-channel'
const GET_BALANCE_EVENT = 'get-balance-event'

const MAINTENANCE_CHANNEL = 'maintenance-channel'
const MAINTENANCE_EVENT = 'maintenance-event'

const MEMBER_LOGOUT_CHANNEL = 'private-member-logout-channel'
const MEMBER_LOGOUT_EVENT = 'member-logout-event'

const NOTICE_CHANEL = 'notice-channel'
const NOTICE_EVENT = 'notice-event'

const ROLLING_COMPLETED_EVENT = `rolling-completed`
const MONEY_ROUTE: string[] = [path.recharge, path.withdraw, path.move_money]

const BaseComponent = () => {
  const token = getAccessTokenFromLS()
  const user = useSelector((state: RootState) => state.auth.user)
  const { marketInfoList, isSpinning } = useCommonStore()
  const { width } = useViewport()
  console.log('isSpinning', isSpinning)

  const { cart: cartSport, limitBetting } = useCartSportStore()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { mNo } = user || {}
  const location = useLocation()
  const pusher = usePusher()

  // const { data: dataSiteInfo } = useQuery<SiteInfoQueryType>(SiteInfoQuery, {
  //   context: { apiName: 'siteInfo' },
  //   skip: !mNo,
  //   fetchPolicy: 'no-cache'
  // })

  // useEffect(() => {
  //   if (!dataSiteInfo) return
  //   // nếu siEnableGamesConfig là true thì kiểm tra nếu ở casino/slot thì maintain
  //   // nếu siEnableGamesConfig là false thì kiểm tra nếu config của riêng casino/slot là true thì maintain
  //   if (
  //     (dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfig &&
  //       (location.pathname === path.casino || location.pathname === path.slot)) ||
  //     (!dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfig &&
  //       ((dataSiteInfo?.SiteInfoQuery?.game_config?.casino?.enable && location.pathname === path.casino) ||
  //         (dataSiteInfo?.SiteInfoQuery?.game_config?.slot?.enable && location.pathname === path.slot)))
  //   ) {
  //     navigate(path.maintain, { state: dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfigNotice })
  //   }
  // }, [dataSiteInfo, location.pathname])

  const { refetch: refetchUser } = useQuery(getMember, {
    context: { apiName: 'member' },
    onCompleted: (data) => {
      dispatch(login(data?.MemberQuery))
    },
    skip: !token,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' },
    onCompleted: (data) => {
      const metaTag = document.querySelector('meta[name="description"]')
      document.title = data?.PageSiteQuery?.title || ''
      metaTag?.setAttribute('content', data?.PageSiteQuery?.description)
      metaTag?.setAttribute('content', data?.PageSiteQuery?.description)

      dispatch(setForceHiddenRegister(!data?.PageSiteQuery?.is_user_join))
    }
  })

  useEffect(() => {
    if (location.pathname === path.fame) return

    if (!token && !user) {
      dispatch(setOpenAuthModal('login'))
      navigate(path.home)
    }
  }, [dispatch, token, user, navigate])

  useEffect(() => {
    let channelMoney: PusherChannelType
    if (token && user && pusher) {
      channelMoney = pusher.subscribe(MONEY_INFO_CHANNEL + `.${user.mNo}`)
      channelMoney.bind(MONEY_INFO_EVENT, function (data: MoneyInfoChannelType) {
        if (data?.moneyInfo?.data?.miType === 'UW' || data?.moneyInfo?.data?.miType === 'UD') {
          client.refetchQueries({
            include: ['WithdrawCheckQuery']
          })
        }

        if (data?.moneyInfo?.type !== 'update') return
        if (
          data?.moneyInfo?.data?.member?.mMoney &&
          data?.moneyInfo?.data?.member?.mPoint &&
          user.mNo === data.moneyInfo.data.member.mNo
        ) {
          dispatch(login({ ...user, ...data.moneyInfo.data.member, notiMoney: true }))

          if (localStorage.getItem('speaker') === 'true') localStorage.setItem('soundEnabled', 'true')

          dispatch(setMoneyAnim(true))

          dispatch(setNotiMoney(false))
          localStorage.setItem('soundEnabled', 'false')

          setTimeout(() => {
            dispatch(setMoneyAnim(false))
          }, 500)
        }
      })

      channelMoney.bind(ROLLING_COMPLETED_EVENT, function () {
        client.refetchQueries({
          include: ['WithdrawCheckQuery', 'ExchangeMoneyCheckQuery']
        })
      })

      return () => {
        channelMoney.unbind(ROLLING_COMPLETED_EVENT)
        channelMoney.unbind(MONEY_INFO_EVENT)
        pusher.unsubscribe(MONEY_INFO_CHANNEL + `.${user.mNo}`)
      }
    }
  }, [dispatch, token, user?.mNo, pusher])

  useEffect(() => {
    let channelPoint: PusherChannelType

    if (mNo && pusher) {
      channelPoint = pusher.subscribe(GET_BALANCE_CHANNEL + `.${mNo}`)
      channelPoint.bind(GET_BALANCE_EVENT, async function (data: GetBalanceChannelPusher) {
        if (data?.balance) {
          if (!isSpinning) {
            dispatch(
              login({
                ...user,
                mMoney: data?.balance?.mMoney,
                notiMoney: true,
                mSportsMoney: data?.balance?.mSportsMoney,
                mPoint: data?.balance?.mPoint
              })
            )
          }

          if (localStorage.getItem('speaker') === 'true') localStorage.setItem('soundEnabled', 'true')

          dispatch(setNotiMoney(false))
          localStorage.setItem('soundEnabled', 'false')
        }
        if (MONEY_ROUTE.includes(location?.pathname)) {
          await client.refetchQueries({
            include: ['MoneyInfoListQuery', 'WithdrawCheckQuery', 'ExchangeMoneyCheckQuery', 'SiteInfoQuery']
          })
        }
      })

      return () => {
        channelPoint.unbind(GET_BALANCE_EVENT)
        pusher.unsubscribe(GET_BALANCE_CHANNEL + `.${mNo}`)
      }
    }
  }, [mNo, pusher, location.pathname, isSpinning])

  useEffect(() => {
    let channelLogout: Channel

    if (mNo && pusher) {
      channelLogout = pusher.subscribe(MEMBER_LOGOUT_CHANNEL + `.${mNo}`)
      channelLogout.bind(MEMBER_LOGOUT_EVENT, function () {
        if (localStorage.getItem('speaker') === 'true') localStorage.setItem('soundEnabled', 'true')

        clearLS()
        dispatch(logout())
        dispatch(setOpenAuthModal('login'))
        navigate(path.home)
      })
      return () => {
        channelLogout.unbind(MEMBER_LOGOUT_EVENT)
        pusher.unsubscribe(MEMBER_LOGOUT_CHANNEL + `.${mNo}`)
      }
    }
  }, [dispatch, mNo, navigate, pusher])

  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(NOTICE_CHANEL)
      channel.bind(NOTICE_EVENT, async () => {
        await client.refetchQueries({
          include: ['NoticeType', 'countNoteNoRead']
        })
      })

      return () => {
        channel.unbind(NOTICE_EVENT)
        pusher.unsubscribe(NOTICE_CHANEL)
      }
    }
  }, [pusher])

  useEffect(() => {
    if (sportsPrematchInplay.includes(location.pathname) && !marketInfoList.length) {
      sportsApi
        .getMarketInfoList({})
        .then((response) => {
          if (response.data) {
            dispatch(
              setMarketInfoList({
                marketList: response.data.data
              })
            )
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    return () => {
      if (sportsPrematchInplay.includes(location.pathname)) {
        dispatch(clearMarketInfoList())
      }
    }
  }, [location.pathname])

  const getDataSideBarSport = async () => {
    try {
      const res = await Promise.all([sportsApi.getSportCategories(), sportsApi.getLiveSportCategories()])
      const [resSports, resLiveSports] = res
      if (resSports.data) {
        dispatch(
          setCategoriesSports({
            // categories: [...res.data.data, ...res.data.data]
            categories: resSports.data.data
          })
        )
      }
      if (resLiveSports.data) {
        dispatch(
          setCategoriesLiveSports({
            // categories: [...res.data.data, ...res.data.data]
            categories: resLiveSports.data.data
          })
        )
      }
    } catch (e) {
      return e
    }
  }
  useEffect(() => {
    getDataSideBarSport()
    return () => {
      dispatch(clearCategoriesSports())
    }
  }, [])

  useEffect(() => {
    let intervalCategory: NodeJS.Timeout | null = null

    intervalCategory = setInterval(() => {
      getDataSideBarSport()
    }, 30000)

    return () => {
      if (intervalCategory) {
        clearInterval(intervalCategory)
      }
    }
  }, [])

  // useEffect(() => {
  //   if (pusher) {
  //     const channelConfig: PusherChannelType = pusher.subscribe(MAINTENANCE_CHANNEL)
  //     channelConfig.bind(MAINTENANCE_EVENT, function (data: MaintenanceChannelType) {
  //       if (data && data?.status) {
  //         window.postMessage('OPEN_MAINTENANCE')
  //         clearLS()
  //       }
  //     })

  //     return () => {
  //       if (channelConfig) {
  //         channelConfig.unbind(MAINTENANCE_EVENT)
  //         pusher.unsubscribe(MAINTENANCE_CHANNEL)
  //       }
  //     }
  //   }
  // }, [pusher])

  const [getSportsLimit] = useLazyQuery<LimitSport, { count_bet: number; type: string }>(GET_LIMIT_SPORTS, {
    context: { apiName: 'sports' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(storeLimitSportBetting({ limitSport: data }))
      dispatch(storeLimitLiveSportBetting({ limitSport: data }))
    }
  })

  useEffect(() => {
    if (sportsRouters.includes(location.pathname)) {
      getSportsLimit({
        variables: {
          count_bet: cartSport?.pick_list?.length || 1,
          type: location.pathname === path.sports ? 'cross' : 'realtime'
        }
      })
    }
  }, [location.pathname, cartSport])

  useEffect(() => {
    if (showCartPath.includes(location.pathname)) {
      if (width > 1280) {
        dispatch(setShowCart(true))
        return
      }
      dispatch(setShowCart(false))
    } else {
      dispatch(setShowCart(false))
    }
  }, [dispatch, width, location.pathname])

  useEffect(() => {
    if (location.pathname === path.slot) {
      navigate(`${path.casino}?tab=slot`)
    }
  }, [location.pathname])

  return <></>
}

export default React.memo(BaseComponent)
