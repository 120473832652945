export default function Maintenance() {
  return (
    <div className='w-[100vw] h-[100dvh] bg-[#bab3b3] flex items-center justify-center'>
      <div className='flex flex-col gap-5 text-center'>
        <h1 className='text-5xl font-bold text-content'>사이트</h1>
        <h1 className='text-5xl font-bold text-primary-2'>점검중</h1>
        <h1 className='text-5xl font-bold text-content'>입니다</h1>
        <p className='text-16 font-bold text-red-1'>서비스 이용에 불편을 드려 죄송합니다.</p>
        <p className='text-16 font-bold text-content'>보다 나온 서비스 제공을 위해 점검중입니다.</p>
        <p className='text-16 font-bold text-content'>불편하시더라도 조금만 기다려 주시길 바랍니다.</p>
      </div>
    </div>
  )
}
