import { createAction, createReducer } from '@reduxjs/toolkit'
import { User } from 'src/types/auth.type'
import { getAccessTokenFromSS } from '../utils/auth'

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any
  authModal: {
    isOpen: boolean
    type: string
    forceHiddenRegister: boolean
  }
  token: string
  refresh: boolean
  notiMoney: boolean
  moneyAnim: boolean
  first_time_login_modal: string
  readTokenFromLS: boolean
}

const initialState: AuthState = {
  user: null,
  authModal: {
    isOpen: false,
    type: 'login',
    forceHiddenRegister: false
  },
  token: getAccessTokenFromSS(),
  refresh: false,
  notiMoney: false,
  moneyAnim: false,
  first_time_login_modal: '',
  readTokenFromLS: true
}

export const login = createAction('login', function (user: User) {
  return {
    payload: {
      ...user
    }
  }
})

export const setOpenAuthModal = createAction(
  'setOpenAuthModal',
  function (typeModal: 'login' | 'register' | 'convertPoint') {
    return { payload: { typeModal } }
  }
)

export const setCloseAuthModal = createAction('setCloseAuthModal')

export const logout = createAction('logout')

export const setToken = createAction('setToken', function (token: string) {
  return { payload: { token } }
})

export const setRefresh = createAction('setRefresh', function (data: boolean) {
  return {
    payload: data
  }
})

export const setNotiMoney = createAction('setNotiMoney', function (data: boolean) {
  return {
    payload: data
  }
})

export const setForceHiddenRegister = createAction('setForceHiddenRegister', function (data: boolean) {
  return {
    payload: data
  }
})

export const setMoneyAnim = createAction('setMoneyAnim', function (data: boolean) {
  return {
    payload: data
  }
})

export const setSportsMoney = createAction('setSportsMoney', function (data: number) {
  return {
    payload: data
  }
})

export const setFirstTimeLoginModal = createAction('setFirstTimeLoginModal', function (first_time_login_modal: string) {
  return {
    payload: first_time_login_modal
  }
})

export const setReadTokenFromLS = createAction('setReadTokenFromLS', function (data: boolean) {
  return {
    payload: data
  }
})

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, action) => {
      state.user = {
        ...action.payload,
        mMoney: Math.round(action.payload?.mMoney || 0),
        mSportsMoney: Math.round(action.payload?.mSportsMoney || 0)
      }
    })
    .addCase(logout, (state) => {
      state.user = null
      state.token = ''
    })
    .addCase(setCloseAuthModal, (state) => {
      return {
        ...state,
        authModal: {
          ...state.authModal,
          isOpen: false,
          type: 'login'
        }
      }
    })
    .addCase(setOpenAuthModal, (state, action) => {
      return {
        ...state,
        authModal: {
          ...state.authModal,
          isOpen: true,
          type: state.authModal.forceHiddenRegister ? 'login' : action.payload.typeModal
        }
      }
    })
    .addCase(setToken, (state, action) => {
      state.token = action.payload.token
    })
    .addCase(setRefresh, (state, action) => {
      state.refresh = action.payload
    })
    .addCase(setNotiMoney, (state, action) => {
      state.refresh = action.payload
    })
    .addCase(setForceHiddenRegister, (state, action) => {
      state.authModal.forceHiddenRegister = action.payload
    })
    .addCase(setMoneyAnim, (state, action) => {
      state.moneyAnim = action.payload
    })
    .addCase(setSportsMoney, (state, action) => {
      state.user.mSportsMoney = Math.round(action.payload)
    })
    .addCase(setFirstTimeLoginModal, (state, action) => {
      state.first_time_login_modal = action.payload
    })
    .addCase(setReadTokenFromLS, (state, action) => {
      // state.readTokenFromLS = action.payload
    })
})

export default authReducer
