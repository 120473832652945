import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { GET_HISTORY_VIRTUAL_SPORTS } from 'src/libs/apis/graphql/queries/virtual-sport.query'
import { BetHistoryQuery, VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import { isGreyhoundsOrHorse, isSoccerOrBaseball, TabShow } from '../hooks/useVirtualSports'
import './index.scss'
import { TableHistoryBasketball } from './TableHistoryBasketball'
import { TableHistoryGreyhoundsOrHorse } from './TableHistoryGreyhoundsOrHorse'
import { TableHistorySoccerOrBaseball } from './TableHistorySoccerOrBaseball'
type Props = {
  tabShow: TabShow
  setTabShow: (tabShow: TabShow) => void
  dataShow: VirtualSportsDataItem[]
  categorySelected?: string
}

const classes = {
  0: {
    className: 'text-primary bg-black border-[1px] border-pink-300',
    label: '대기중' // waiting
  },
  1: {
    className: 'text-white bg-[#ff0000] border-[1px] border-[#ff0000]',
    label: '낙첨' //lose
  },
  2: {
    className: 'text-white bg-[#4986e8] border-[1px] border-[#4986e8]',
    label: '당첨' // win
  },
  3: {
    className: 'text-white bg-[#F79F2F] border-[1px] border-[#F79F2F]',
    label: '취소' // hoà
  },
  5: {
    className: 'text-white bg-[#F79F2F] border-[1px] border-[#F79F2F]',
    label: '취소' // hoà
  }
}

const statusClasses = (status: number) => {
  return classes[status]?.className
}

// const statusLabels = (status: number) => {
//   return classes[status]?.label
// }
export const MatchHistory = ({ dataShow, tabShow, setTabShow, categorySelected }: Props) => {
  const { data, loading: isLoadingBetData } = useQuery<BetHistoryQuery>(GET_HISTORY_VIRTUAL_SPORTS, {
    context: {
      apiName: 'virtual-sports'
    },
    variables: {
      cate: categorySelected,
      limit: 8
    },
    notifyOnNetworkStatusChange: true
  })

  const renderTable = () => {
    if (isSoccerOrBaseball.includes(dataShow?.[0]?.cate2)) {
      return <TableHistorySoccerOrBaseball dataShow={dataShow} />
    }
    if (isGreyhoundsOrHorse.includes(dataShow?.[0]?.cate2)) {
      return <TableHistoryGreyhoundsOrHorse dataShow={dataShow} categorySelected={categorySelected} />
    }

    return <TableHistoryBasketball dataShow={dataShow} />
  }

  const renderHistory = () => {
    return data?.BetHistoryQuery?.data?.map((item, index) => (
      <>
        <div
          className={cn('table-history-virtual mb-4 duration-150', {
            'animate-pulse': isLoadingBetData
          })}
          key={item._id}
        >
          <div className='flex w-full justify-between'>
            <h3 className='flex-auto'>회차</h3>
            <h3 className='flex-auto'>타입</h3>
            <h3 className='flex-auto'>배팅타입</h3>
            <h3 className='flex-auto'>결과</h3>
            <h3 className='hidden md:block'>상태</h3>
          </div>
          <div className='flex-col w-full justify-around'>
            {item?.bets.map((betItem, betIndex) => (
              <div key={betIndex} className='flex justify-between betList'>
                <p className='p-[1px] md:p-[5px] flex items-center justify-center'>{formatTime(betItem.startDate)}</p>
                <p className='p-[1px] md:p-[5px] flex items-center justify-center'>{betItem.leagueName}</p>
                <p className='p-[1px] md:p-[5px] flex flex-col items-center justify-center'>
                  <span>{betItem.betDescription}</span>
                  <span className='text-[#F9A732]'>{betItem.betDetail}</span>
                </p>
                <p className='p-[1px] md:p-[5px] flex items-center justify-center'>
                  {/* {betItem.awayScore === -1 || betItem.homeScore === -1
                    ? '---'
                    : `${betItem.awayScore} vs ${betItem.homeScore}`} */}
                  {betItem.betResult === '' ? '---' : betItem.betResult}
                </p>
                <p className='p-[1px] md:p-[5px] items-center justify-center hidden md:flex'>
                  <div className={cn(statusClasses(betItem.status), 'py-1 rounded-[4px] px-[30px]')}>
                    {betItem.statusKoName}
                  </div>
                </p>
              </div>
            ))}
          </div>
          <div className='my-4 px-2'>
            <p className='mt-2'>
              게임배팅금액: <span className='text-[#11b485]'>{item.betAmount} 원</span>
            </p>
            <p className='mt-2'>배팅접수시간: {item.regdate}</p>
            <p className='mt-2'>
              예상당첨금액: (배팅 <span className='text-[#11b485]'>{item.betAmount}</span> X 배당
              <span className='text-[#11b485]'>{item.result_rate.toFixed(2)}</span>) =
              <span className='text-primary-2'> {item.estimateAmount} 원</span>
            </p>
            <div className='flex mt-2 items-center'>
              <p>
                결과당첨금액: <span className='text-[#11b485]'>{item.winAmount} 원</span>
              </p>
              <div className={cn(statusClasses(item.result), 'py-1 rounded-[4px] ml-[10px] px-[30px]')}>
                {item.cartStatusKoName}
              </div>
            </div>
          </div>
        </div>
      </>
    ))
  }

  return (
    <div className='flex flex-col gap-2'>
      <div className='hidden items-center gap-1 laptop:flex'>
        <button
          onClick={() => {
            setTabShow(TabShow.RESULT)
          }}
          className={cn('flex-1 py-4 border duration-150 hover:border-primary bg-secondary-2', {
            'border-transparent': tabShow !== TabShow.RESULT,
            'border-primary': tabShow === TabShow.RESULT
          })}
        >
          지난회차 결과
        </button>
        <button
          onClick={() => {
            setTabShow(TabShow.HISTORY)
          }}
          className={cn('flex-1 py-4 border duration-150 hover:border-primary bg-secondary-2', {
            'border-transparent': tabShow !== TabShow.HISTORY,
            'border-primary': tabShow === TabShow.HISTORY
          })}
        >
          나의 배팅내역
        </button>
      </div>
      <div className='self-stretch text-12'>{tabShow === TabShow.RESULT ? renderTable() : renderHistory()}</div>
    </div>
  )
}

function formatTime(datetime) {
  const timePart = datetime.split(' ')[1] // Lấy phần thời gian
  const time = timePart.slice(0, 5) // Lấy giờ và phút
  return time
}

export default MatchHistory
