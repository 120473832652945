import { useEffect } from 'react'

const useIframeResize = (
  iframeRef: React.RefObject<HTMLIFrameElement>,
  iframeParentRef: React.RefObject<HTMLDivElement>,
  iframeLink2: string,
  iframeBaseWidth: number,
  iframeBaseHeight: number
) => {
  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      const iframeParent = iframeParentRef.current

      if (iframe && iframeParent) {
        const screenWidth = iframeParent.offsetWidth
        const screenHeight = iframeParent.offsetHeight

        const aspectRatio = iframeBaseWidth / iframeBaseHeight
        const parentAspectRatio = screenWidth / screenHeight

        let scaleX, scaleY

        if (parentAspectRatio > aspectRatio) {
          scaleY = screenHeight / iframeBaseHeight
          scaleX = scaleY
        } else {
          scaleX = screenWidth / iframeBaseWidth
          scaleY = scaleX
        }

        iframe.style.transform = `scale(${scaleX}, ${scaleY})`
        iframe.style.transformOrigin = '0 0'
        iframe.style.margin = 'auto'
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    if (iframeParentRef.current) {
      resizeObserver.observe(iframeParentRef.current)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      resizeObserver.disconnect()
    }
  }, [iframeLink2, iframeBaseWidth, iframeBaseHeight])
}

export default useIframeResize
