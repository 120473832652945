import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

// import { store } from 'stores';
// import { expiredSession } from 'stores/reducers/user';

// import { useToast } from '@/hooks/internals';

import { CustomAxiosResponse } from 'src/types/axios.type'
export const sleep = (second = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, second)
  })
}

const UNAUTHORIZED = 401
const NOT_PERMISSION = 403

// const toast = useToast();

class AxiosService {
  private instance: AxiosInstance
  private isExpired: boolean
  private location: object

  protected constructor(instance: AxiosInstance) {
    instance.interceptors.response.use(this.handleSuccess, this.handleError)
    this.instance = instance
    this.isExpired = false
    this.location = {}
  }

  setToken(token: string) {
    if (token) {
      this.isExpired = false
    }
    this.instance.defaults.headers.common['Authorization'] = token
  }
  deleteToken() {
    delete this.instance.defaults.headers.common['Authorization']
  }

  handleSuccess(response: AxiosResponse): AxiosResponse {
    const formatData: any = {
      originResponse: response as AxiosResponse,
      data: response.data,
      error: response.data?.error
    }
    // CHEATING TRANSFORM FORMAT DATA
    return formatData as AxiosResponse
  }

  handleError = async (error: AxiosError) => {
    if (error?.response?.status === UNAUTHORIZED) {
      return Promise.reject()
    }

    if (error?.response?.status === NOT_PERMISSION) {
      // toast.error('Not permission !');
      return Promise.reject()
    }

    return Promise.reject(error)
  }

  get<T = any>(url: string, params?: any, option?: AxiosRequestConfig): Promise<CustomAxiosResponse<T>> {
    return this.instance.get(url, {
      ...option,
      params: {
        ...params
      }
    })
  }

  put<T = any>(url: string, data?: any, option?: AxiosRequestConfig): Promise<CustomAxiosResponse<T>> {
    return this.instance.put(url, data, {
      ...option
    })
  }

  post<T = any>(url: string, data?: any, option?: AxiosRequestConfig): Promise<CustomAxiosResponse<T>> {
    return this.instance.post(url, data, {
      ...option
    })
  }

  patch<T = any>(url: string, data?: any, option?: AxiosRequestConfig): Promise<CustomAxiosResponse<T>> {
    return this.instance.patch(url, data, {
      ...option
    })
  }

  delete<T = any>(url: string, data?: any, option?: AxiosRequestConfig): Promise<CustomAxiosResponse<T>> {
    return this.instance.delete(url, {
      ...option,
      data
    })
  }
}

export default AxiosService
