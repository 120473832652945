import axios, { AxiosInstance } from 'axios'
import { config } from 'src/constants/config'
import AxiosService from 'src/libs/utils/axios.base'
import { convertAxiosEndpoint } from 'src/libs/utils/utilFuncs'

class AxiosSportsService extends AxiosService {
  private static asInstance: AxiosService

  private constructor() {
    const instance: AxiosInstance = axios.create({
      baseURL: convertAxiosEndpoint(config.sportsUrl) || '',
      timeout: 20000,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })

    super(instance)
  }

  public static getInstance(): AxiosSportsService {
    if (!AxiosSportsService.asInstance) {
      AxiosSportsService.asInstance = new AxiosSportsService()
    }

    return AxiosSportsService.asInstance
  }
}

export const sportsService = AxiosSportsService.getInstance()

export default AxiosSportsService.getInstance()
