import cn from 'classnames'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import { isGreyhoundsOrHorse } from '../hooks/useVirtualSports'
import { VirtualSportsOptionItem } from './VirtualSportsOptionItem'

type Props = {
  dataList: VirtualSportsDataItem[]
}

export const VirtualSportsOptionList = ({ dataList }: Props) => {
  return (
    <div
      className={cn('flex flex-col', {
        'gap-1': !isGreyhoundsOrHorse.includes(dataList[0].cate2)
      })}
    >
      {dataList.map((item) => {
        return (
          <VirtualSportsOptionItem
            data={item}
            list={isGreyhoundsOrHorse.includes(dataList[0].cate2) ? dataList : []}
            key={JSON.stringify(item)}
          />
        )
      })}
    </div>
  )
}
