import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { pathMiniGame, pathMiniGameType } from 'src/constants/path'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { useDocumentVisibility } from 'src/libs/hooks/useDocumentVisibility'
import { client } from 'src/libs/utils/apollo'
import { usePusher } from 'src/providers/PusherProvider'

type Props = {
  callback: () => Promise<void>
  gameType?: string
}

export const useEventMiniGame = ({ callback, gameType }: Props) => {
  const pusher = usePusher()
  const location = useLocation()
  const dispatch = useDispatch()
  useDocumentVisibility({ cbActiveTab: callback })
  useEffect(() => {
    const channel = pusher?.subscribe('minigame-result-channel')
    channel?.bind('minigame-result-event', async (data: { game: string }) => {
      // await handleRefetchGetMiniGame()

      // if (location.pathname === path.mini_boscore_ladder1) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder2) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder3) {
      //   refetchGame()
      // }
      const currentGameType = gameType || pathMiniGameType[location.pathname]
      if (currentGameType === data.game) {
        client.refetchQueries({
          include: [GET_MINI_GAME_BET_HISTORY]
        })
      }
    })

    return () => {
      if (!pathMiniGame.includes(location.pathname) || gameType) {
        channel?.unbind('minigame-result-event')
        pusher?.unsubscribe('minigame-result-channel')
      }
    }
  }, [location.pathname, pusher, gameType])
  useEffect(() => {
    const channel = pusher?.subscribe('minigame-new-round-channel')
    channel?.bind('minigame-new-round-event', async (data: { game: string }) => {
      // await handleRefetchGetMiniGame()

      // if (location.pathname === path.mini_boscore_ladder1) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder2) {
      //   refetchGame()
      // } else if (location.pathname === path.mini_boscore_ladder3) {
      //   refetchGame()
      // }
      const currentGameType = pathMiniGameType[location.pathname]
      if (currentGameType === data.game) {
        await callback()
      }
    })

    return () => {
      if (!pathMiniGame.includes(location.pathname)) {
        channel?.unbind('minigame-new-round-event')
        pusher?.unsubscribe('minigame-new-round-channel')
      }
    }
  }, [location.pathname, pusher])
}
