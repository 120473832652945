import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SideRightMiniGame from 'src/components/SideRightMiniGame'
import DataTable from 'src/components/Table/DataTable'
import path from 'src/constants/path'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { DetailMiniGameBetting, ResultMiniGameHistory } from 'src/types/minigame.type'

const tabs: { [key: string]: number } = {
  [path.mini_boscore_ladder1]: 1,
  [path.mini_boscore_ladder2]: 2,
  [path.mini_boscore_ladder3]: 3
}
/* eslint-disable jsx-a11y/iframe-has-title */
export default function BoscoreLadder() {
  const navigate = useNavigate()
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const dispatch = useDispatch()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [disabledRightSection, setDisabledRightSection] = useState(false)
  const [miniHistoryBet, setMiniHistoryBet] = useState<DetailMiniGameBetting[]>([])
  const { refetchHistoryBetting } = useCartMiniGameStore()
  const iframeParentRef = useRef<HTMLIFrameElement>(null)

  const location = useLocation()
  const nameMiniGame = () => {
    switch (location.pathname) {
      case path.mini_boscore_ladder1:
        return 'bo_start_1'
      case path.mini_boscore_ladder2:
        return 'bo_start_2'
      case path.mini_boscore_ladder3:
        return 'bo_start_3'
      default:
        return ''
    }
  }
  const {
    data: miniGameBetData,
    refetch: refetchMiniGameBetData,
    loading
  } = useQuery<ResultMiniGameHistory>(GET_MINI_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: 1, category: nameMiniGame() },
    context: { apiName: 'mini-game' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setMiniHistoryBet(data.MiniGameBettingList?.data)
      setQuery((prev) => ({
        ...prev,
        total: data?.MiniGameBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    }
  })

  const columns: TableColumnsType<DetailMiniGameBetting> = [
    {
      title: '시간',
      dataIndex: 'tmRegDate',
      key: 'tmRegDate',
      width: '180px',
      align: 'center',
      render: (_, record) => {
        return record.tmRegDate
      }
    },
    {
      title: '배팅정보',
      dataIndex: 'tmProviderGame',
      key: 'tmProviderGame',
      // width: '80px',
      align: 'center'
    },
    {
      title: '배팅액',
      dataIndex: 'tmBetAmount',
      key: 'tmBetAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        // return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
        return formatCurrency(value || 0)
      }
    },
    {
      title: '배팅접수',
      dataIndex: 'tmSelectedItem',
      key: 'tmSelectedItem',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmSelectedItem
          ? JSON.parse(record.tmSelectedItem)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmSelectedItem)?.color?.split(' ')
            : [record?.tmSelectedItem]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmSelectedItem && JSON.parse(record.tmSelectedItem)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || 'red'
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '배팅결과',
      dataIndex: 'tmResultBetting',
      key: 'tmResultBetting',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result = record?.tmResultBetting
          ? JSON.parse(record.tmResultBetting)?.color?.split(' ').length > 1
            ? JSON.parse(record.tmResultBetting)?.color?.split(' ')
            : [record?.tmResultBetting]
          : []
        // // eslint-disable-next-line no-constant-condition
        // const test = '{"color":"blue red red","text":"\ub099\ucca8 3 \ub099\ucca8"}'
        // const result = test
        //   ? JSON.parse(test)?.color?.split(' ').length > 1
        //     ? JSON.parse(test)?.color?.split(' ')
        //     : [test]
        //   : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => {
              return (
                <div
                  key={index}
                  className='w-10 h-10 rounded-full flex items-center justify-center'
                  style={{
                    background: item
                    // background: JSON.parse(test)?.color || 'red'
                  }}
                >
                  <span>
                    {(record?.tmResultBetting && JSON.parse(record.tmResultBetting)?.text?.split(' ')[index]) || ''}
                  </span>
                  {/* <span>{JSON.parse(test)?.text?.split(' ')[index] || ''}</span> */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{
                background: JSON.parse(result[0])?.color || ''
                // background: JSON.parse(test)?.color || 'red'
              }}
            >
              <span>{JSON.parse(result[0])?.text || ''}</span>
              {/* <span>{(record?.tmSelectedItem && JSON.parse(record?.tmSelectedItem)?.text?.split(' ')[0]) || ''}</span> */}
            </div>
          </div>
        )
      }
    },
    {
      title: '적중금액',
      dataIndex: 'tmWinAmount',
      key: 'tmWinAmount',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
      }
    },
    {
      title: '상태결과',
      dataIndex: 'tmStatus',
      key: 'tmStatus',
      // width: '80px',
      align: 'center',
      render: (_, record) => {
        const result =
          record.tmStatus === 'bet'
            ? '배팅'
            : record.tmStatus === 'win'
              ? '당첨'
              : record.tmStatus === 'draw'
                ? '적특'
                : '낙첨'
        return result
      }
    }
  ]

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetchMiniGameBetData({ limit: query?.limit || 10, page: current_page || 1 })
  }

  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      const iframeParent = iframeParentRef.current

      if (iframe && iframeParent) {
        const screenWidth = iframeParent.offsetWidth
        const screenHeight = iframeParent.offsetHeight

        // Calculate the aspect ratio
        const aspectRatio = 800 / 600
        const parentAspectRatio = screenWidth / screenHeight

        let scaleX, scaleY

        if (parentAspectRatio > aspectRatio) {
          // Parent is wider than the aspect ratio, scale based on height
          scaleY = screenHeight / 600
          scaleX = scaleY
        } else {
          // Parent is taller than the aspect ratio, scale based on width
          scaleX = screenWidth / 800
          scaleY = scaleX
        }

        iframe.style.transform = `scale(${scaleX}, ${scaleY})`
        iframe.style.transformOrigin = '0 0'
        iframe.style.margin = 'auto'
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    if (iframeParentRef.current) {
      resizeObserver.observe(iframeParentRef.current)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      resizeObserver.disconnect()
    }
  }, [miniGameBetData])

  const handleLockedPlay = (isLocked: boolean) => {
    setDisabledRightSection(isLocked)
  }

  useEffect(() => {
    if (refetchHistoryBetting) {
      refetchMiniGameBetData()
    }
  }, [refetchHistoryBetting])
  //  min-h-[calc(100dvh-114px)]
  return (
    <>
      {/* <BoscoreLadderTabs
        tabActive={tabs[location.pathname]}
        onTabChange={(tab) => {
          // TODO: update behavior
          setDisabledRightSection(tab !== 3)
          switch (tab) {
            case 1:
              navigate(path.mini_boscore_ladder1)
              break
            case 2:
              navigate(path.mini_boscore_ladder2)
              break
            case 3:
              navigate(path.mini_boscore_ladder3)
              break

            default:
              break
          }
        }}
      /> */}
      <div className='grid grid-cols-1 md:grid-cols-2 relative overflow-hidden items-center 3xl:items-stretch'>
        <div className='w-full aspect-[800/600]' ref={iframeParentRef}>
          <iframe
            ref={iframeRef}
            id='myframe'
            src={`https://boscore.com/ladder/live${tabs[location.pathname]}.php`}
            width='800'
            height='600'
            scrolling='no'
            frameBorder='0'
            className='absolute'
          ></iframe>
        </div>
        <div className='relative w-full'>
          {disabledRightSection && (
            <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
              <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
            </div>
          )}
          <SideRightMiniGame
            refreshHistory={async () => {
              await refetchMiniGameBetData()
            }}
            tabActive={tabs[location.pathname]}
            handleLockedPlay={() => {
              handleLockedPlay(true)
            }}
            handleUnlockedPlay={() => {
              handleLockedPlay(false)
            }}
          />
        </div>
      </div>

      <div className='mx-2 mt-8 table-responsive relative'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={columns}
            loading={loading}
            dataSource={miniHistoryBet as any}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            scroll={{ x: 900 }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
