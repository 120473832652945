import { DomesticWithKeyMatch, SportItemClient } from 'src/types/sports.type'
import { DomesticItem } from './DomesticItem'
import { BoardBetSport } from './BoardBetSport'

type Props = {
  listDomestic: DomesticWithKeyMatch[]
  sportData: Partial<SportItemClient>
}

export const DomesticList = ({ listDomestic, sportData }: Props) => {
  return listDomestic.map((domestic, index) => {
    return (
      <DomesticItem
        key={domestic.idx}
        domesticData={domestic}
        sportData={sportData}
        isLastItem={index === listDomestic.length - 1}
        render={({ isActiveSideRight, isOpenBoardBetSport }) => {
          if (isActiveSideRight && index === listDomestic.length - 1) {
            return (
              <div className='px-1 xl:hidden'>
                <BoardBetSport isOpen={isOpenBoardBetSport} />
              </div>
            )
          }
        }}
      />
    )
  })
}
