import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  BetItemPusher,
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  ExchangeRateInfoItem,
  GroupSportListByKey,
  LIST_PERIOD_LIVE_MATCH_NOT_ALLOW_WHEN_VALID_STATUS,
  LiveSportItemClient,
  rateKeyList,
  statusKeyList,
  UpdateScoreRecord
} from 'src/types/live-sports.type'
import {
  calculatorStatus,
  checkIsShowInBoardLiveSports,
  groupDataSearchPure,
  updateRate
} from '../utils/live-sports.func'

interface LiveSportSearchState {
  dataLiveSportSearchPure: LiveSportItemClient[]
  dataLiveSportSearch: GroupSportListByKey[]
  dataRateInfoList: DomesticWithKeyMatch[]
  isLoadingLiveSports: boolean
  isLoadingRateInfo: boolean
  isOpenBoardBetSport: boolean
  isFirstTimeFetch: boolean
  eventIdLiveSport: string
  exchangeRateInfoList: ExchangeRateInfoItem[]
  isOpenModalCartChange: boolean
}

const initialState: LiveSportSearchState = {
  dataLiveSportSearchPure: [],
  dataLiveSportSearch: [],
  dataRateInfoList: [],
  isLoadingLiveSports: false,
  isLoadingRateInfo: false,
  isOpenBoardBetSport: false,
  isFirstTimeFetch: true,
  eventIdLiveSport: '',
  exchangeRateInfoList: [],
  isOpenModalCartChange: false
}

export const setIsOpenModalCartChangeRate = createAction('setIsOpenModalCartChangeRate', (isOpen: boolean) => {
  return {
    payload: isOpen
  }
})

export const addDataToLiveSearchListPure = createAction(
  'addDataToLiveSearchListPure',
  function ({ liveSportList }: { liveSportList: LiveSportItemClient[] }) {
    if (!liveSportList.length) {
      return {
        payload: []
      }
    }

    return {
      payload: liveSportList
    }
  }
)

export const updateDataToLiveSearchListPure = createAction(
  'updateDataToLiveSearchListPure',
  function ({ liveSportItem }: { liveSportItem: LiveSportItemClient }) {
    return { payload: liveSportItem }
  }
)

export const lockDataLiveSearchPure = createAction(
  'lockDataLiveSearchPure',
  ({ isLock, index }: { isLock: boolean; index: number }) => {
    return {
      payload: {
        isLock,
        index
      }
    }
  }
)

export const addDataRateInfoLiveList = createAction(
  'addDataRateInfoLiveList',
  function ({ rateInfoList }: { rateInfoList: DomesticWithKeyMatch[] }) {
    return {
      payload: rateInfoList
    }
  }
)

export const updateDataFromSearchLiveList = createAction(
  'updateDataFromSearchLiveList',
  function ({
    betItemPusher,
    fixture_idx,
    index
  }: {
    betItemPusher: BetItemPusher
    fixture_idx: number
    index: number
  }) {
    return {
      payload: {
        betItemPusher,
        fixture_idx,
        index
      }
    }
  }
)

export const updateDataScoreFromSearchLiveList = createAction(
  'updateDataScoreFromSearchLiveList',
  function ({ dataUpdate, index }: { dataUpdate: UpdateScoreRecord; index: number }) {
    return {
      payload: {
        dataUpdate,
        index
      }
    }
  }
)

export const setFetchAllApiLive = createAction('setFetchAllApiLive', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setFetchApiGetAllLiveSports = createAction('setFetchApiGetAllLiveSports', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setFetchApiGetAllRateInfoLive = createAction('setFetchApiGetAllRateInfoLive', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setOpenBoardBetLiveSport = createAction('setOpenBoardBetLiveSport', (isOpen: boolean) => {
  return {
    payload: isOpen
  }
})

export const setEventIdLiveSport = createAction('setEventIdLiveSport', (id: string) => {
  return {
    payload: id
  }
})

export const setIsFirstTimeFetchLiveSport = createAction('setIsFirstTimeFetchLiveSport', (isFirstTime: boolean) => {
  return {
    payload: isFirstTime
  }
})

export const resetDataLiveSportSearch = createAction('resetDataLiveSportSearch')

export const resetDataBoardBetLive = createAction('resetDataBoardBetLive')

export const setExchangeRateLiveSport = createAction(
  'setExchangeRateLiveSport',
  (exchangeRateInfoList: ExchangeRateInfoItem[]) => {
    return {
      payload: exchangeRateInfoList
    }
  }
)

export const updateDataRateInfoListLive = createAction(
  'updateDataRateInfoListLive',
  function ({
    betItemPusher,
    fixture_idx,
    index
  }: {
    betItemPusher: BetItemPusher
    fixture_idx: number
    index: number
  }) {
    return {
      payload: {
        betItemPusher,
        fixture_idx,
        index
      }
    }
  }
)

export const addItemDataRateInfo = createAction('addItemDataRateInfo', (item: DomesticWithKeyMatch) => {
  return {
    payload: item
  }
})

export const updateSingleDataLiveSearchPure = createAction(
  'updateSingleDataLiveSearchPure',
  ({ sportData }: { sportData: LiveSportItemClient }) => {
    return {
      payload: sportData
    }
  }
)

export const updateAllStatusDefaultRateInfoLive = createAction('updateAllStatusDefaultRateInfoLive')
export const updateAllStatusDefaultLive = createAction('updateAllStatusDefaultLive')

const liveSportSearchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addDataToLiveSearchListPure, (state, action) => {
      state.dataLiveSportSearchPure = action.payload
      state.dataLiveSportSearch = groupDataSearchPure(action.payload)
    })
    .addCase(setIsOpenModalCartChangeRate, (state, action) => {
      state.isOpenModalCartChange = action.payload
    })
    .addCase(updateDataToLiveSearchListPure, (state, action) => {
      let updateDataPure = [...state.dataLiveSportSearchPure]
      const fixtureIndex = updateDataPure.findIndex((i) => i.idx === action.payload.idx)
      let isDelete = false
      if (fixtureIndex !== -1) {
        if (action.payload.status === 3) {
          isDelete = true
          updateDataPure = updateDataPure.filter((item) => item.idx !== action.payload.idx)
        } else if (action.payload.status === 9) {
          updateDataPure[fixtureIndex] = action.payload
        } else if (action.payload.status === 2) {
          if (
            !action.payload.main ||
            LIST_PERIOD_LIVE_MATCH_NOT_ALLOW_WHEN_VALID_STATUS.includes(action.payload.period)
          ) {
            isDelete = true
            updateDataPure = updateDataPure.filter((item) => item.idx !== action.payload.idx)
          } else {
            updateDataPure[fixtureIndex] = action.payload
          }
        }
      } else {
        if (action.payload.status !== 3) {
          if (action.payload.status === 9) {
            updateDataPure.push(action.payload)
          } else {
            updateDataPure.unshift(action.payload)
          }
        }
      }
      state.dataLiveSportSearchPure = [...updateDataPure]
      state.dataLiveSportSearch = groupDataSearchPure(updateDataPure)
      if (state.isOpenBoardBetSport) {
        if (state.dataRateInfoList.length && state.dataRateInfoList[0].fixture_idx === action.payload.idx && isDelete) {
          state.isOpenBoardBetSport = false
        }
      }
    })
    .addCase(updateDataFromSearchLiveList, (state, action) => {
      const updatedDataSportSearchPure = [...state.dataLiveSportSearchPure]
      const dataSportPureTarget = updatedDataSportSearchPure[action.payload.index]
      let listDomesticDataSportPureTarget = [...dataSportPureTarget.domestic]
      for (let index = 0; index < listDomesticDataSportPureTarget.length; index++) {
        if (action.payload.betItemPusher.status === 3) {
          listDomesticDataSportPureTarget = listDomesticDataSportPureTarget.filter(
            (_, indexDomestic) => index !== indexDomestic
          )
          dataSportPureTarget.domestic = listDomesticDataSportPureTarget
          break
        }

        const domestic = { ...listDomesticDataSportPureTarget[index] }
        const existingBetIdIndex = [domestic.betid1, domestic.betid2, domestic.betid3].indexOf(
          action.payload.betItemPusher.betid
        )
        if (existingBetIdIndex !== -1) {
          if (
            domestic[rateKeyList[existingBetIdIndex]] !== action.payload.betItemPusher.rate ||
            domestic[statusKeyList[existingBetIdIndex]] !== action.payload.betItemPusher.status
          ) {
            const domesticUpdate = updateRate({
              domestic,
              rateKey: rateKeyList[existingBetIdIndex],
              betItemPusher: action.payload.betItemPusher,
              statusKey: statusKeyList[existingBetIdIndex]
            })
            domesticUpdate.status = calculatorStatus(domesticUpdate)
            listDomesticDataSportPureTarget[index] = domesticUpdate
            break
          }
        }
      }
      dataSportPureTarget.domestic = listDomesticDataSportPureTarget
      updatedDataSportSearchPure[action.payload.index] = dataSportPureTarget
      if (
        dataSportPureTarget.domestic[0].status === 2 &&
        state.dataRateInfoList.length &&
        state.dataRateInfoList[0].fixture_idx === dataSportPureTarget.idx &&
        state.isOpenBoardBetSport
      ) {
        state.isOpenBoardBetSport = false
      }
      state.dataLiveSportSearchPure = [...updatedDataSportSearchPure]
      state.dataLiveSportSearch = groupDataSearchPure(updatedDataSportSearchPure)
    })
    .addCase(updateSingleDataLiveSearchPure, (state, action) => {
      const existingIndex = state.dataLiveSportSearchPure.findIndex((item) => item.idx === action.payload.idx)
      if (existingIndex !== -1) {
        const updateLiveSportSearchPure = [...state.dataLiveSportSearchPure]
        updateLiveSportSearchPure[existingIndex] = action.payload
        state.dataLiveSportSearchPure = [...updateLiveSportSearchPure]
        state.dataLiveSportSearch = groupDataSearchPure(state.dataLiveSportSearchPure)
      }
    })
    .addCase(updateDataScoreFromSearchLiveList, (state, action) => {
      const updatedDataSportSearchPure = [...state.dataLiveSportSearchPure]
      const updatedDataSportItem = { ...updatedDataSportSearchPure[action.payload.index], ...action.payload.dataUpdate }
      updatedDataSportSearchPure[action.payload.index] = updatedDataSportItem

      state.dataLiveSportSearchPure = [...updatedDataSportSearchPure]
      state.dataLiveSportSearch = groupDataSearchPure(updatedDataSportSearchPure)
    })
    .addCase(addDataRateInfoLiveList, (state, action) => {
      state.dataRateInfoList = action.payload
    })
    .addCase(updateDataRateInfoListLive, (state, action) => {
      let updatedDataRateInfoList = [...state.dataRateInfoList]
      const domestic: DomesticWithKeyMatch = { ...updatedDataRateInfoList[action.payload.index] }
      if (action.payload.betItemPusher.status === 3) {
        updatedDataRateInfoList = updatedDataRateInfoList.filter((item) => {
          return ![item.betid1, item.betid2, item.betid3].includes(action.payload.betItemPusher.betid)
        })
        state.dataRateInfoList = [...updatedDataRateInfoList]
        return
      }

      if (action.payload.betItemPusher.status === 2) {
        updatedDataRateInfoList = updatedDataRateInfoList.map((item) => {
          if (![item.betid1, item.betid2, item.betid3].includes(action.payload.betItemPusher.betid)) {
            return { ...item, isShowInBoard: checkIsShowInBoardLiveSports(item) }
          }
          return {
            ...item,
            status: action.payload.betItemPusher.status,
            isShowInBoard: false
          }
        })
        state.dataRateInfoList = [...updatedDataRateInfoList]
        return
      }

      const betIdList = [domestic.betid1, domestic.betid2, domestic.betid3]
      const existingBetIdIndex = betIdList.findIndex((item) => item === action.payload.betItemPusher.betid)

      if (existingBetIdIndex !== -1) {
        const domesticUpdate = updateRate({
          domestic,
          rateKey: rateKeyList[existingBetIdIndex],
          betItemPusher: action.payload.betItemPusher,
          statusKey: statusKeyList[existingBetIdIndex]
        })
        domesticUpdate.status = calculatorStatus(domesticUpdate)
        updatedDataRateInfoList[action.payload.index] = domesticUpdate
      }
      state.dataRateInfoList = [
        ...updatedDataRateInfoList.map((item) => ({
          ...item,
          isShowInBoard: checkIsShowInBoardLiveSports(item)
        }))
      ]
    })
    .addCase(addItemDataRateInfo, (state, action) => {
      const updatedDataRateInfoList = [...state.dataRateInfoList]
      updatedDataRateInfoList.push({
        ...action.payload,
        updateType: {
          rate1: EUpdateDomesticType.DEFAULT,
          rate2: EUpdateDomesticType.DEFAULT,
          rate3: EUpdateDomesticType.DEFAULT
        },
        isShowInBoard: checkIsShowInBoardLiveSports(action.payload)
      })
      state.dataRateInfoList = [...updatedDataRateInfoList]
    })
    .addCase(setFetchApiGetAllLiveSports, (state, action) => {
      state.isLoadingRateInfo = action.payload
      state.isLoadingLiveSports = action.payload
    })
    .addCase(setFetchAllApiLive, (state, action) => {
      state.isLoadingLiveSports = action.payload
    })
    .addCase(setFetchApiGetAllRateInfoLive, (state, action) => {
      state.isLoadingRateInfo = action.payload
    })
    .addCase(setOpenBoardBetLiveSport, (state, action) => {
      state.isOpenBoardBetSport = action.payload
    })
    .addCase(setEventIdLiveSport, (state, action) => {
      state.eventIdLiveSport = action.payload
    })
    .addCase(setIsFirstTimeFetchLiveSport, (state, action) => {
      state.isFirstTimeFetch = action.payload
    })
    .addCase(resetDataBoardBetLive, (state) => {
      state.dataRateInfoList = []
    })
    .addCase(setExchangeRateLiveSport, (state, action) => {
      state.exchangeRateInfoList = action.payload
    })
    .addCase(resetDataLiveSportSearch, () => {
      return { ...initialState }
    })

    .addCase(updateAllStatusDefaultLive, (state) => {
      const updatedDataSportSearchPure = [...state.dataLiveSportSearchPure].map((sportItem) => {
        sportItem.domestic.forEach((domestic) => {
          domestic.updateType = {
            rate1: EUpdateDomesticType.DEFAULT,
            rate2: EUpdateDomesticType.DEFAULT,
            rate3: EUpdateDomesticType.DEFAULT
          }
        })
        return sportItem
      })
      state.dataLiveSportSearchPure = updatedDataSportSearchPure
      state.dataLiveSportSearch = groupDataSearchPure(updatedDataSportSearchPure)
    })
    .addCase(updateAllStatusDefaultRateInfoLive, (state) => {
      const updatedDataRateInfoList = [...state.dataRateInfoList].map((domestic) => {
        return {
          ...domestic,
          updateType: {
            rate1: EUpdateDomesticType.DEFAULT,
            rate2: EUpdateDomesticType.DEFAULT,
            rate3: EUpdateDomesticType.DEFAULT
          }
        }
      })
      state.dataRateInfoList = updatedDataRateInfoList
    })
    .addCase(lockDataLiveSearchPure, (state, action) => {
      const updateDataPure = [...state.dataLiveSportSearchPure]
      updateDataPure[action.payload.index].block_all = action.payload.isLock
      if (state.isOpenBoardBetSport) {
        if (
          state.dataRateInfoList.length &&
          state.dataRateInfoList[0].fixture_idx === updateDataPure[action.payload.index].idx
        ) {
          state.isOpenBoardBetSport = false
        }
      }
    })
})

export default liveSportSearchReducer
