import { gql } from '@apollo/client'

export const GET_NOTICES = gql`
  query NoticeType($ntType: String!, $filter: String, $page: Int, $limit: Int) {
    categoryNotice(ntType: $ntType) {
      category_id
      category_name
    }
    noticeType(ntType: $ntType, filter: $filter, page: $page, limit: $limit) {
      data {
        ntNo
        ntTitle
        ntContent
        ntStatus
        ntLogo
        ntImage
        ntType
        ntCategory
        ntPartnerType
        ntRegDate
        ntUpdateDate
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`
