import { useLocation } from 'react-router-dom'

export default function MaintainGame() {
  const location = useLocation()
  return (
    <div className='w-full h-[calc(100dvh-146px)] lg:h-[calc(100dvh-124px)] flex items-center justify-center'>
      <div className='flex flex-col gap-5 text-center'>
        <h1 className='text-5xl font-bold text-white'>{location.state ? location.state : '보수 작업중'}</h1>
        <h1 className='text-5xl font-bold text-primary-2'>곧 돌아오겠습니다.</h1>
        <p className='text-16 font-bold text-white'>
          우리는 새로운 기술로 업그레이드하느라 바쁩니다. 불편을 끼쳐드려 죄송합니다.
        </p>
      </div>
    </div>
  )
}
