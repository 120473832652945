import { gql } from '@apollo/client'

export const GET_HALL_OF_FAME = gql`
  query HallOfFameCategoryQuery {
    HallOfFameCategoryQuery {
      key
      title
      description
    }
    HallOfFameQuery {
      rank
      mID
      group
      amount
      bonus_amount
    }
  }
`
