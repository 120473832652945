import { gql } from '@apollo/client'

export const GET_MENU_MINI_GAME = gql`
  query MenuMiniGameQuery {
    MenuMiniGameQuery {
      mgpName
      mgpNameEn
      MiniGames {
        mgNo
        mgName
        mgNameEn
        mgPath
        mgFrameLink
        mgFrameWidth
        mgFrameHeight
      }
    }
  }
`

export const GET_POWER_LADDER_GAME = gql`
  query PowerLadderQuery($game: String) {
    PowerLadderQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`
// for sure powerball 123
export const GET_SURE_POWER_LADDER_GAME = gql`
  query SurePowerLadderQuery($game: String) {
    SurePowerLadderQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`
// for score power 123
export const GET_SCORE_POWER_LADDER_GAME = gql`
  query ScoreQuery($game: String) {
    ScoreQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        color_position
        sub_text
        link
      }
    }
  }
`

export const GET_SURE_LADDER_GAME = gql`
  query SureLadderQuery($game: String) {
    SureLadderQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`

export const GET_SPEED_KENO_GAME = gql`
  query SpeedKenoQuery($game: String) {
    SpeedKenoQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`

export const GET_KENO_LADDER_GAME = gql`
  query KenoLadderQuery($game: String) {
    KenoLadderQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`
export const GET_BO_SCORE_POWER_GAME = gql`
  query PowerBallBoscoQuery($game: String) {
    PowerBallBoscoQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`

export const GET_SURE_RACING_GAME = gql`
  query SureRacingQuery($game: String) {
    SureRacingQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`
export const GET_LOTTERIA_POWER_BALL_GAME = gql`
  query LotteryPowerballQuery($game: String) {
    LotteryPowerballQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`

export const GET_BEPICK_GAME = gql`
  query BepickQuery($game: String) {
    BepickQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      data {
        tab
        tab_text
        category
        category_text
        key
        text
        rate
        color
        link
      }
    }
  }
`
