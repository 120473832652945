/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'
import { GET_COUPON_LIST } from 'src/libs/apis/graphql/queries/coupon.query'
import { Coupon, CouponType } from '../CouponList'

export default function UsageHistory() {
  const couponMap = {
    [CouponType.MONEY_BONUS]: '보너스폰',
    [CouponType.ROULETTE_BONUS]: '룰렛',
    [CouponType.RECHARGE_BONUS]: '입플'
  }

  const BattleColumns: TableColumnsType<Coupon> = [
    {
      title: '지급받은 날짜',
      key: 'startDate',
      width: '20%',
      align: 'center',
      dataIndex: 'startDate'
    },
    { title: '쿠폰 제목', key: 'name', width: '30%', align: 'center', dataIndex: 'name' },
    {
      title: '쿠폰 금액',
      key: 'amount',
      width: '10%',
      align: 'center',
      render(_, record) {
        return <div>{record.type === CouponType.ROULETTE_BONUS ? '-' : record.amount ? record.amount : '-'}</div>
      }
    },
    {
      title: '수량',
      key: 'amount',
      width: '10%',
      align: 'center',
      render(_, record) {
        return <div>{record.type !== CouponType.ROULETTE_BONUS ? '1' : record.amount ? record.amount : '-'}</div>
      }
    },
    {
      title: '쿠폰 종류',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      align: 'center',
      render(_, record) {
        return <div>{couponMap[record.type]}</div>
      }
    },
    {
      title: '사용/만료 날짜',
      key: 'endDate',
      width: '20%',
      align: 'center',
      dataIndex: 'endDate'
    }
  ]
  const { data, loading } = useQuery(GET_COUPON_LIST, {
    context: {
      apiName: 'member'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      // 1: used
      status: 1
    }
  })

  return (
    <div className='battle__type mt-5 md:mt-0'>
      <div className='flex flex-wrap '>
        <ConfigProvider
          renderEmpty={() => <span className='text-center font-medium text-white'>등록된 내용이 없습니다.</span>}
        >
          <DataTable
            rowKey={(record) => record.id}
            className='w-full'
            columns={BattleColumns}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            locale={{
              emptyText: '쿠폰내역이 존재하지 않습니다.'
            }}
            loading={loading}
            dataSource={data?.CouponQuery || []}
            // expandable={{
            //   expandedRowRender: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>,
            //   expandRowByClick: true,
            //   expandIconColumnIndex: -1
            // }}
            pagination={{
              position: ['bottomCenter'],
              total: data?.CouponQuery?.length,
              pageSize: 10
            }}
            // dataSource={data}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
