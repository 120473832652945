import { useSelector } from 'react-redux'
import { BetItem } from 'src/libs/stores/sport.reducer'
import { RootState } from 'src/libs/utils/store'
import { DomesticWithKeyMatch } from 'src/types/sports.type'

export const useCartSportStore = () => {
  const { cart, isBetting } = useSelector((state: RootState) => state.sport)
  function getCountItemInMatch(keyMatch: string) {
    let count = 0
    cart.pick_list.forEach((item) => {
      if (item.keyMatch === keyMatch) {
        count++
      }
    })

    return count
  }

  function getAllItemInMatch(domesticData: DomesticWithKeyMatch) {
    const allItemInMatch = cart.pick_list.filter((item) => item.keyMatch === domesticData.keyMatch)
    return allItemInMatch
  }

  function handleSwapData(domesticDataNew: DomesticWithKeyMatch) {
    if (cart.pick_list.length === 0) {
      return null
    }
    // check same game id
    const indexItemSameGameId = cart.pick_list.findIndex(
      (item) => item.domesticData.game_id === domesticDataNew.game_id
    )
    if (indexItemSameGameId === -1) {
      return null
    }

    return {
      idxNeedSwap: cart.pick_list[indexItemSameGameId].domesticData.idx
    }
  }

  function checkPickedOptionDraw(cartInMatch: BetItem[]) {
    const indexOptionDraw = cartInMatch.findIndex((item) => {
      return item.domesticData.isWinTieLose && item.betId === item.domesticData.betid2
    })
    return indexOptionDraw !== -1
  }
  return {
    cart,
    limitBetting: cart.bettingLimit,
    count: cart?.pick_list?.length,
    getCountItemInMatch,
    handleSwapData,
    getAllItemInMatch,
    checkPickedOptionDraw,
    isBetting
  }
}
