import { useEffect } from 'react'
const MAPPING_TITLE_TO_URL: Record<string, string> = {
  // Horse
  'Britannia Way': 'https://uvavasau.com/b3/b3hrbw.html',
  'Festival Downs': 'https://uvavasau.com/b3/b3hrfd.html',
  'Victoria Park': 'https://uvavasau.com/b3/b3hrvp.html',

  // Soccer
  Premiership: 'https://uvavasau.com/b3/b3sp.html',
  Superleague: 'https://uvavasau.com/b3/b3ss.html',
  'World Cup': 'https://uvavasau2.com/b3/b3sw.html',
  'Euro Cup': 'https://uvavasau2.com/b3/b3se.html',

  // Dog
  'Goldenhill Park': 'https://uvavasau2.com/b3/b3gdgp.html',
  'Hillside Park': 'https://uvavasau2.com/b3/b3gdhp.html',

  // Basketball
  'Baker Gardens': 'https://uvavasau.com/b3/b3bkbg.html',
  'Waterport Arena': 'https://uvavasau.com/b3/b3bkwa.html'
}

export const IframeLiveStream = ({ title }: { title: string }) => {
  useEffect(() => {
    const iframe = document.getElementById('vframe1') as HTMLIFrameElement
    if (iframe) {
      iframe.height = `${(iframe.offsetWidth * 9) / 16}px`
    }
  }, [])

  return (
    <div>
      <iframe
        width='648'
        height='365'
        id='vframe1'
        src={MAPPING_TITLE_TO_URL[title]}
        // src='https://vet3.com/video?q=high&player=1&autostart=true&title=Euro+Cup'
        frameBorder='0'
        scrolling='no'
        allowFullScreen
        title='live stream'
      ></iframe>
    </div>
  )
}
