import { ApolloError, useMutation, useQuery } from '@apollo/client'

import { GET_NOTICES } from '../apis/graphql/queries/notice.query'
import { UPDATE_VIEW_NOTICE_MUTATION } from '../apis/graphql/mutations/notice.mutation'

type NoticePaginateType = {
  noticeType: NoticePagination & {
    data: Partial<NoticeType[]>
  }
  categoryNotice: CategoryNotice[]
}

export const defaultNoticeData: NoticePaginateType = {
  noticeType: {
    total: 1,
    per_page: 1,
    current_page: 1,
    from: 1,
    to: 1,
    last_page: 1,
    has_more_pages: false,
    data: []
  },
  categoryNotice: []
}

export enum NtType {
  // note
  RULE = 'rule',
  // event
  EVENT = 'event',
  // TODO: BE not handle
  PARTNER = 'partner',
  VOTE = 'vote'
}
export interface CategoryNotice {
  category_id: string
  category_name: string
}

type NoticePagination = {
  total?: number
  per_page?: number
  current_page?: number
  from?: number
  to?: number
  last_page?: number
  has_more_pages?: boolean
}

export type NoticeType = {
  ntNo: number
  ntTitle: string
  ntContent: string
  ntStatus: number
  ntLogo?: string
  ntImage?: string
  ntType: string
  ntCategory?: string
  ntPartnerType: string
  ntRegDate: string
  __typename: string
}

type Option = {
  page?: number
  limit?: number
  filter?: string
}

type UpdateViewCountResponse = {
  updateViewCountMutation: {
    status: boolean
  }
}

type EventOption = Option & {
  viewEventSuccess?: (data: UpdateViewCountResponse) => void
  viewEventFailed?: (error?: ApolloError) => void
}

type NoticeOption = Option & {
  viewNoticeSuccess?: (data: UpdateViewCountResponse) => void
  viewNoticeFailed?: (error?: ApolloError) => void
}

export const useEventQuery = (option?: EventOption) => {
  const [viewEvent] = useMutation<UpdateViewCountResponse>(UPDATE_VIEW_NOTICE_MUTATION, {
    context: {
      apiName: 'event'
    },
    onCompleted: (data) => {
      if (!data.updateViewCountMutation.status && option?.viewEventFailed) {
        option?.viewEventFailed()
      }

      if (option?.viewEventSuccess && data?.updateViewCountMutation?.status) {
        option?.viewEventSuccess(data)
      }
    },
    onError: (error: ApolloError) => {
      if (option?.viewEventFailed) {
        option?.viewEventFailed(error)
      }
    }
  })

  const queryResult = useQuery<NoticePaginateType>(GET_NOTICES, {
    variables: {
      page: option?.page || 1,
      limit: option?.limit || 10,
      ntType: NtType.EVENT,
      filter: option?.filter
    },
    context: { apiName: 'notice' },
    notifyOnNetworkStatusChange: true
  })

  return {
    ...queryResult,
    viewEvent
  }
}

export const useNoticeQuery = (option?: NoticeOption) => {
  const [viewNewBoard] = useMutation(UPDATE_VIEW_NOTICE_MUTATION, {
    context: { apiName: 'announcement' },
    onCompleted: (data) => {
      if (!data.updateViewCountMutation.status && option?.viewNoticeFailed) {
        option?.viewNoticeFailed()
      }

      if (option?.viewNoticeSuccess && data?.updateViewCountMutation?.status) {
        option?.viewNoticeSuccess(data)
      }
    },
    onError: (error: ApolloError) => {
      if (option?.viewNoticeFailed) {
        option?.viewNoticeFailed(error)
      }
    }
  })

  const queryResult = useQuery<NoticePaginateType>(GET_NOTICES, {
    variables: {
      page: option?.page || 1,
      limit: option?.limit || 10,
      ntType: NtType.RULE,
      filter: option?.filter
    },
    context: { apiName: 'notice' },
    notifyOnNetworkStatusChange: true
  })

  return {
    ...queryResult,
    viewNewBoard
  }
}
