import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import path from 'src/constants/path'

// 'https://flexsport-tv.com/streaming.php?user_id=sample'
const TVStreamSports = () => {
  const searchParams = useSearchParams()
  const link = searchParams[0].get('link')
  useEffect(() => {
    window.history.pushState(null, '', `${path.tv_stream}`)
  }, [])

  return (
    <div className='relative w-screen h-screen'>
      <iframe
        title='tv-stream'
        src={link || 'https://flexsport-tv.com/streaming.php?user_id=plus'}
        className='w-full h-full'
      />
    </div>
  )
}

export default TVStreamSports
