import { useQuery } from '@apollo/client'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { getBetHistory } from 'src/libs/apis/graphql/queries/battle.query'
import { GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { GET_PARSING_CASINO_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { GET_BATTLE_HISTORY_SPORTS, GET_VIRTUAL_SPORTS_CATEGORY } from 'src/libs/apis/graphql/queries/sport.query'
import './battle.scss'
import { RootState } from 'src/libs/utils/store'
import { useSelector } from 'react-redux'
export default function Battle() {
  const location = useLocation()
  const user = useSelector((state: RootState) => state.auth.user)
  const battleFilter = [
    {
      title: '스포츠',
      type: 'sports'
    },
    {
      title: '미니게임',
      type: 'minigame'
    },
    // {
    //   title: '파싱카지노',
    //   type: 'parsing-casino'
    // },
    {
      title: '카지노',
      type: 'casino'
    },
    {
      title: '가상스포츠',
      type: 'virtual-sports'
    },
    {
      title: '벳게임 카지노',
      type: 'bet-games'
    }
  ]

  const checkPathName = (type: string) => {
    const pathName = location.pathname.split('/')[2]
    return pathName === type
  }
  const { refetch: refetchBattelMiniGameBetData } = useQuery(GET_MINI_GAME_BET_HISTORY, {
    variables: { limit: 10, page: 1, category: '' },
    context: { apiName: 'mini-game' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const { refetch: refetchSportsBattle } = useQuery(GET_BATTLE_HISTORY_SPORTS, {
    variables: { game_type: '', limit: 10, offset: 0 },
    context: { apiName: 'sports' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !user?.mNo
  })
  const { refetch: refetchBetHistory } = useQuery(getBetHistory, {
    variables: {
      limit: 10,
      offset: 1,
      category: 'casino, slot',
      start_date: null
    },
    context: { apiName: 'bet-histories' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const { refetch: refetchVirtualSports } = useQuery(GET_VIRTUAL_SPORTS_CATEGORY, {
    context: {
      apiName: 'virtual-sports'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const { refetch: refetchParsingBetData } = useQuery(GET_PARSING_CASINO_GAME_BET_HISTORY, {
    variables: { limit: 10, page: 1, category: '' },
    context: { apiName: 'parsing-casino' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const { refetch: refetchBetHistories } = useQuery(getBetHistory, {
    variables: {
      limit: 10,
      offset: 1,
      category: 'casino, slot',
      start_date: null
    },
    context: { apiName: 'bet-histories' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const fetchApi = (type: string) => {
    switch (type) {
      case 'bet-games':
        refetchBetHistory()
        break
      case 'sports':
        refetchSportsBattle()
        break
      case 'virtual-sports':
        refetchVirtualSports()
        break
      case 'minigame':
        refetchBattelMiniGameBetData()
        break
      // case 'parsing-casino':
      //   refetchParsingBetData()
      //   break
      case 'casino':
        refetchBetHistories()
        break

      default:
        break
    }
  }

  return (
    <div className='battle__container mt-2' data-aos='fade-up'>
      <div className='battle__container-tab grid grid-cols-3 gap-4 md:flex px-2 text-[13px] overflow-y-auto sidebar__overflow'>
        {battleFilter.map((item) => {
          return (
            <Link
              to={`${item.type}`}
              className={`w-full min-w-[120px] text-center text-14 h-8 lg:h-[50px] flex justify-center items-center hover:border-b hover:border-primary hover:text-primary ${checkPathName(item.type) ? 'border-b border-primary text-primary' : ''}`}
              key={item.type}
              onClick={() => fetchApi(item.type)}
            >
              {item.title}
            </Link>
          )
        })}
      </div>
      <div className='px-2'>
        <Outlet />
      </div>
    </div>
  )
}
