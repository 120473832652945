/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Navigate } from 'react-router-dom'
import { config } from 'src/constants/config'
import { HttpStatusCode } from 'src/constants/httpStatusCode.enum'
import path, { unAuthRouters } from 'src/constants/path'
import { logout } from '../stores/auth.reducer'
import { setShowMaintenance } from '../stores/common.reducer'
import { clearLS, getAccessTokenFromLS } from './auth'
import { store } from './store'

const httpLink = createHttpLink({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  uri: ({ getContext }) => {
    const { apiName } = getContext()

    return config.baseUrl + '/' + apiName
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { statusCode }: any = networkError || {}
  const isUnauthenticated = statusCode === HttpStatusCode.Unauthorized

  // token expired && api return 401 && current page is protected pages
  if (isUnauthenticated) {
    store.dispatch(logout())
    clearLS()
    if (!unAuthRouters.includes(window.location.pathname)) {
      Navigate({ to: path.home })
    }
  }

  if (statusCode === HttpStatusCode.Forbidden) {
    store.dispatch(setShowMaintenance(true))
    clearLS()
    store.dispatch(logout())
    return
  }

  // for dev debug, global request error will show in console
  if ((process.env.REACT_APP_NODE_ENV as string) === 'development') {
    console.log('[GraphQL error]', graphQLErrors)
    console.log('[Network error]', networkError)
  }
})

const authLink = setContext(({ headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessTokenFromLS()
  if (!token) {
    return
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      PageSiteType: {
        fields: {
          siLogo1: {
            merge(existing, incoming) {
              if (process.env.REACT_APP_DEMO_MODE == 'true') {
                return '/sample.png'
              }
              return incoming
            }
          }
        }
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: (process.env.REACT_APP_NODE_ENV as string) === 'development' ? true : false
})
