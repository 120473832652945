/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { ConfigProvider, Select, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useState } from 'react'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import { getBetHistory } from 'src/libs/apis/graphql/queries/battle.query'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { useNavigate } from 'react-router-dom'
import { formatQueryParams } from 'src/libs/utils/utilFuncs'
import { useDispatch } from 'react-redux'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { refetchHistoryBetting } from 'src/libs/stores/sport-backup.reducer'
import path from 'src/constants/path'

interface IBattleBetGames {
  gName: string
  tRegDate: string
  tAmount: string
  tType: string
}

enum TypeResult {
  FAILED = '패배',
  WIN = '승리',
  PENDING = '결과 대기중'
}

export default function BattleCasino() {
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const [params, setParams] = useState({ filter: 'casino, slot', date: null })
  const queryParams = useQueryParams()
  const dispatch = useDispatch()
  const {
    data: dataBetHistory,
    refetch,
    loading
  } = useQuery(getBetHistory, {
    variables: {
      limit: query?.limit || 10,
      offset: query?.page || 1,
      category: params?.filter ? params?.filter : 'casino, slot',
      start_date: params?.date ? params?.date : null
    },
    context: { apiName: 'bet-histories' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setQuery((prev) => ({
        ...prev,
        total: data?.BetHistoriesQuery?.total
      }))
    }
  })

  console.log('dataBetHistory', dataBetHistory?.BetHistoriesQuery?.data)

  const BattleColumns: TableColumnsType<IBattleBetGames> = [
    {
      title: '배팅시간',
      key: 'tRegDate',
      width: '15%',
      align: 'center',
      dataIndex: 'tRegDate'
    },
    { title: '게임', key: 'gName', width: '40%', align: 'center', dataIndex: 'gName' },
    {
      title: '배팅액',
      dataIndex: 'tAmount',
      key: 'tAmount',
      width: '15%',
      align: 'center',
      render: (record) => <span className={`${record.includes('-') ? 'text-red-1' : 'text-white'}`}>{record} 원</span>
    },
    {
      title: '당첨금액',
      key: 'tAmountReturn',
      width: '15%',
      align: 'center',
      dataIndex: 'tAmountReturn',
      render: (record) => {
        return <span className=''>{record}</span>
      }
    },
    {
      title: '배팅결과',
      key: 'tAmountReturn',
      width: '15%',
      align: 'center',
      dataIndex: 'tAmountReturn',
      render: (record) => {
        const amount = Number(record.replace(',', ''))
        return <span className=''>{amount > 0 ? '당첨' : '낙첨'}</span>
      }
    }
  ]

  const navigate = useNavigate()
  const handleFilter = (filterBy: any, dateFilter: any) => {
    // console.log(queryParams)
    setParams((prev) => ({
      ...prev,
      filter: filterBy || prev.filter,
      date: dateFilter ? dateFilter : null
    }))
    window.history.pushState(
      null,
      '',
      `${path.battle_casino}?filter=${filterBy || params.filter}${dateFilter || params.date ? '&date=' + (dateFilter || params.date || null) : ''}`
    )

    // const queryParamsTemp = { ...queryParams, filter: filterBy, date: dateFilter ? dateFilter : null }
    // refetch()
  }

  const getPastDate = (days: any) => {
    const date = new Date()
    date.setDate(date.getDate() - days)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0 based
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetch({ limit: query?.limit || 10, page: current_page || 1 })
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  useEffect(() => {
    refetch()
  }, [location.pathname, params])
  return (
    <div className='flex flex-col gap-5 mt-5 md:mt-0'>
      <div className='flex items-center justify-between gap-2 flex-wrap'>
        <div className='flex items-center gap-2'>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${params?.filter === 'casino' ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter('casino', null)}
            disabled={loading}
          >
            카지노
          </Button>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${params?.filter === 'slot' ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter('slot', null)}
            disabled={loading}
          >
            슬롯
          </Button>
        </div>
        <div className='flex items-center gap-2'>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(0))}
            disabled={loading}
          >
            오늘
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(7))}
            disabled={loading}
          >
            1주
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(15))}
            disabled={loading}
          >
            15일
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(30))}
            disabled={loading}
          >
            1달
          </Button>
        </div>
      </div>

      <div className='battle__table table-responsive'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={BattleColumns}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            dataSource={dataBetHistory?.BetHistoriesQuery?.data}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
