import { gql } from '@apollo/client'

const SiteInfoQuery = gql`
  query SiteInfoQuery {
    SiteInfoQuery {
      min_deposit
      deposit_text
      min_withraw
      withraw_text
      captcha
      enable_consultation
      sports_config_text
      casino_config_text
      validation_deposit {
        required
        min
      }
      validation_withraw {
        required
        min
        bank_pw_required
      }
      game_config {
        siEnableGamesConfig
        siEnableGamesConfigNotice
        casino {
          enable
        }
        slot {
          enable
        }
      }
      roulette_rules {
        member_roulette
      }
    }
  }
`

const GET_COUNT_ROULETE = gql`
  query GET_COUNT_ROULETE {
    SiteInfoQuery {
      roulette_rules {
        member_roulette
      }
    }
  }
`

const InfoQuery = gql`
  query InfoQuery {
    InfoQuery {
      recharge_config {
        validation_deposit {
          required
        }
        rc_rules
        rc_max_bonus_first_time_sports_recharge
        rc_max_bonus_sports_recharge
        rc_max_bonus_first_time_casino_recharge
        rc_max_bonus_casino_recharge
        rc_enable_bonus
        rc_max_bonus_first_time_poker_recharge
        rc_max_bonus_poker_recharge
        rc_amount_no_bonus
        rc_auto_bonus
        rc_manual_recharge
        rc_enable_recharge
        rc_enable_config_bonus
        rc_enable_thousand_money
        rc_exchange_rules
        rc_bonus {
          sports {
            key
            title
            color
            description
            couponId
          }
          casino_slot {
            key
            title
            color
            description
          }
        }
      }
      withdraw_config {
        wc_rules
        wc_manual_withdraw
        wc_exchange_rules
      }
    }
  }
`

export { SiteInfoQuery, InfoQuery, GET_COUNT_ROULETE }
