import { gql } from '@apollo/client'

export const GET_VIRTUAL_SPORTS = gql`
  query ActiveBetQuery($cate: String) {
    ActiveBetQuery(cate: $cate) {
      id
      title
      seq
      cate1
      cate2
      date
      date_origin
      time_left
      home
      away
      ratio1
      ratio2
      ratio3
      result1
      result2
      result3
      flag
      country1
      country2
      betid1
      betid2
      betid3
      idx
      cate1KoName
      cate2KoName
    }
  }
`
export const GET_RESULT_VIRTUAL_SPORTS = gql`
  query FinishedBetQuery($cate: String) {
    FinishedBetQuery(cate: $cate) {
      id
      idx
      title
      seq
      cate1
      cate2
      date
      date_origin
      home
      away
      ratio1
      ratio2
      ratio3
      result1
      result2
      result3
      flag
      country1
      country2
      betid1
      betid2
      betid3
    }
  }
`
export const GET_HISTORY_VIRTUAL_SPORTS = gql`
  query BetHistoryQueryVirtualSports($cate: String, $limit: Int, $offset: Int) {
    BetHistoryQuery(cate: $cate, limit: $limit, offset: $offset) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        _id
        betAmount
        regdate
        result_rate
        estimateAmount
        winAmount
        isCancelable
        result
        now
        bets {
          status
          selectRate
          startDate
          homeTeamName
          awayTeamName
          homeScore
          awayScore
          selectedLine
          leagueName
          betDetail
          betResult
          betDescription
          statusKoName
        }
        settlementStatusKoName
        cartStatusKoName
      }
    }
  }
`

export const GET_LIMIT_VIRTUAL_SPORTS = gql`
  query BettingLimitsVirtualSportQuery($count_bet: Int, $cate: String) {
    BettingLimitsVirtualSportQuery(count_bet: $count_bet, cate: $cate) {
      min_bet_amount
      max_bet_amount
      max_bet_payout
      max_payout
      percent_distribution
    }
  }
`

export const GET_QUERY_RULES_VIRTUAL_SPORTS = gql`
  query ValidSelectOptionVirtualSportsQuery {
    ValidSelectOptionQuery {
      category
      is_can_bet_draw
      list_limit {
        type
        list_allow
      }
    }
  }
`

export const GET_ENABLE_MULTI_MATCH_VIRTUAL_SPORTS = gql`
  query ValidOptionAxisBetQueryVirtualSports($cate: String) {
    ValidOptionAxisBetQuery(cate: $cate) {
      enable
    }
  }
`
