import { ReactNode } from 'react'
import { ImClubs, ImDiamonds, ImHeart, ImSpades } from 'react-icons/im'

export const textRender: Record<string, ReactNode> = {
  even: '짝',
  odd: '홀',
  over: '오버',
  under: '언더',
  heart: <ImHeart size={26} />,
  spade: <ImSpades size={26} />,
  club: <ImClubs size={26} />,
  diamond: <ImDiamonds size={26} />,
  tie: 'TIE',
  banker: 'B',
  player: 'P',
  banker_pair: 'BB',
  player_pair: 'PP',
  go: 'Go',
  stop: 'Stop',
  red: '레드',
  black: '블랙'
}

const RenderMinigameSelected = ({ betSelected }: { betSelected: string }) => {
  const renderContent = () => {
    if (!betSelected) {
      return <span>---</span>
    }
    if (betSelected in textRender) {
      return textRender[betSelected]
    } else {
      return betSelected
    }
  }

  return <div>{renderContent()}</div>
}

export default RenderMinigameSelected
