import { createAction, createReducer } from '@reduxjs/toolkit'
import { MiniGameOption } from 'src/types/minigame.type'

export type BetMiniGameItem = {
  selectedKeyItem: string
  miniGameItem: MiniGameOption
}

// {
//         selectedItem: Object.keys(cartSport?.mini_game)[0],
//         betAmount: crashBet,
//         rate: cartSport?.mini_game[Object.keys(cartSport?.mini_game)[0]].rate,
//         transId: cartSport?.mini_game[Object.keys(cartSport?.mini_game)[0]].transId,
//         details: cartSport?.mini_game[Object.keys(cartSport?.mini_game)[0]].details,
//         game: minigameName
//       }

export const MAXIMUM_BET_ONE_TIME = 1
interface MiniGameState {
  cart: {
    pick_list: BetMiniGameItem[]
  }
  refetchHistoryBetting: boolean
  maximumBetOnceTime: number
}

const initialState: MiniGameState = {
  cart: {
    pick_list: []
  },
  refetchHistoryBetting: false,
  maximumBetOnceTime: MAXIMUM_BET_ONE_TIME
}

export const removeAllCartMiniGame = createAction('removeAllCartMiniGame')

export const addItemToCartMiniGame = createAction(
  'addItemToCartMiniGame',
  function ({ miniGameItem, selectedKeyItem }: BetMiniGameItem) {
    const payload: BetMiniGameItem = { miniGameItem, selectedKeyItem }
    return {
      payload
    }
  }
)

export const removeItemFromCartMiniGame = createAction(
  'removeItemFromCartMiniGame',
  function ({ selectedKeyItem }: { selectedKeyItem: string }) {
    const payload = { selectedKeyItem }
    return {
      payload
    }
  }
)

export const addMiniGameToCart = createAction(
  'addMiniGameToCart',
  function ({ selectedKeyItem, miniGameItem }: BetMiniGameItem) {
    const payload: BetMiniGameItem = {
      miniGameItem,
      selectedKeyItem
    }
    return {
      payload
    }
  }
)

export const setRefetchHistoryBetting = createAction('setRefetchHistoryBetting', function (isRefetch: boolean) {
  return {
    payload: isRefetch
  }
})

const miniGameReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addItemToCartMiniGame, (state, action) => {
      // implement when BE implement multi choose in 1 request
      // if (state.cart.pick_list.length === MAXIMUM_BET_ONE_TIME) {
      //   return
      // }
      state.cart.pick_list[0] = action.payload
    })
    .addCase(setRefetchHistoryBetting, (state, action) => {
      state.refetchHistoryBetting = action.payload
    })
    .addCase(removeItemFromCartMiniGame, (state, action) => {
      let updatedCartMiniGame = [...state.cart.pick_list]
      updatedCartMiniGame = updatedCartMiniGame.filter(
        (cartItem) => cartItem.selectedKeyItem !== action.payload.selectedKeyItem
      )
      state.cart.pick_list = updatedCartMiniGame
    })
    .addCase(removeAllCartMiniGame, (state) => {
      state.cart.pick_list = []
    })
})

export default miniGameReducer
