/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import path, { pathMiniGameType } from 'src/constants/path'
import { BET_MINI_GAME_MUTATION } from 'src/libs/apis/graphql/mutations/mini_game.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_LIMIT_MINI_GAME, GET_MINI_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/game.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { setSportsMoney } from 'src/libs/stores/auth.reducer'
import { removeAllCartMiniGame, removeItemFromCartMiniGame } from 'src/libs/stores/minigame.reducer'
import { removeAllCartSport } from 'src/libs/stores/sport.reducer'
import { client } from 'src/libs/utils/apollo'
import { getAccessTokenFromLS } from 'src/libs/utils/auth'
import { transformCartMiniGameToPickList } from 'src/libs/utils/minigame.func'
import { usePusher } from 'src/providers/PusherProvider'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { PaginationType } from 'src/types/common.type'
import { BetMiniGameRequest, ResultMiniGameHistory } from 'src/types/minigame.type'
import cn from 'classnames'
const MINI_GAME_CART_BET = 1
const MINI_GAME_CART_HISTORY = 2

export type LimitMiniGame = {
  BettingLimitsMiniGame: {
    max_bet_amount?: number
    min_bet_amount?: number
    max_bet_payout?: number
    max_payout?: number
    percent_distribution?: number
  }
}

type LimitMiniGameVar = {
  game?: string
}

export default function MiniGameCart() {
  const pusher = usePusher()
  const dispatch = useDispatch()

  const { cart } = useCartMiniGameStore()
  const user = useSelector((state: RootState) => state.auth.user)

  const [crashBet, setCrashBet] = useState(0)
  const [tab, setTab] = useState(MINI_GAME_CART_BET)
  const [query, setQuery] = useState<PaginationType>({ offset: 0, limit: 10, total: 0 })
  const token = getAccessTokenFromLS()

  const [refreshMemberInfo] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch(setSportsMoney(data?.MemberQuery?.mSportsMoney))
    }
  })

  const [getLimitMiniGame, { data: limitData }] = useLazyQuery<LimitMiniGame, LimitMiniGameVar>(GET_LIMIT_MINI_GAME, {
    context: { apiName: 'mini-game' },
    fetchPolicy: 'network-only'
  })

  const limit = limitData?.BettingLimitsMiniGame || {
    max_bet_amount: 0,
    min_bet_amount: 0,
    max_bet_payout: 0,
    max_payout: 0,
    percent_distribution: 0
  }

  const { data: miniGameBetData, refetch: refetchMiniGameBetData } = useQuery<ResultMiniGameHistory>(
    GET_MINI_GAME_BET_HISTORY,
    {
      variables: {
        limit: 1,
        page: 1,
        // @ts-ignore
        category: pathMiniGameType[location.pathname] || ''
      },
      context: { apiName: 'mini-game' },
      notifyOnNetworkStatusChange: true
    }
  )

  const totalBetRate = useMemo(() => {
    return cart.pick_list.reduce((sum, item) => {
      return sum + Number(item.miniGameItem.rate)
    }, 0)
  }, [JSON.stringify(cart.pick_list)])

  const totalBetLimit = totalBetRate + (totalBetRate * (limit.percent_distribution || 0)) / 100
  const formattedTotalBetRate = totalBetLimit?.toFixed(2)
  const totalMoney = (crashBet * Number(formattedTotalBetRate)).toFixed(0)

  const [betMiniGameMutation, { loading }] = useMutation<any, BetMiniGameRequest>(BET_MINI_GAME_MUTATION)

  const handleChangeTotalBet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxBetAmount = limit?.max_bet_amount || 0
    const availableMoney = user?.mSportsMoney || 0
    const value = Number(e.target.value.replace(/\D/g, ''))

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (value > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(value))
    }
  }

  const addExtraBet = (value: number) => {
    const maxBetAmount = limit?.max_bet_amount || 0
    const minBetAmount = limit?.min_bet_amount || 0

    if (Math.min(value, user?.mSportsMoney) < minBetAmount) {
      return
    }

    const availableMoney = user?.mSportsMoney || 0
    const newBetAmount = crashBet + value

    const maxAllowedBet = Math.min(maxBetAmount, availableMoney)

    if (newBetAmount > maxAllowedBet) {
      setCrashBet(handleAddMaxBet(maxAllowedBet))
    } else {
      setCrashBet(handleAddMaxBet(newBetAmount))
    }
  }
  const handleAddMaxBet = (value: number) => {
    if (totalBetLimit * value > (limit?.max_bet_payout ?? 0)) {
      return Math.floor(Number((limit?.max_bet_payout ?? 0) / totalBetLimit))
    } else {
      return Number(value)
    }
  }

  const [minigameName, setMiniGameName] = useState('')

  const nameMiniGame = () => {
    return pathMiniGameType[location.pathname] || ''
  }

  useEffect(() => {
    setMiniGameName(nameMiniGame())
    getLimitMiniGame({
      variables: {
        game: nameMiniGame()
      }
    })
  }, [getLimitMiniGame, location.pathname])

  const handleBetting = async () => {
    await betMiniGameMutation({
      context: { apiName: 'mini-game' },
      variables: cart.pick_list.map((item) => {
        return transformCartMiniGameToPickList({ betItem: item, betAmount: crashBet, gameType: minigameName })
      })[0],
      onCompleted: async () => {
        toast.success('배팅 성공')
        dispatch(removeAllCartMiniGame())
        setCrashBet(0)
        await refreshMemberInfo()
        // dispatch(setRefetchHistoryBetting(true))
        client.refetchQueries({
          include: [GET_MINI_GAME_BET_HISTORY]
        })
        await refetchMiniGameBetData()
      },
      onError: (error) => {
        toast.error(error?.message ?? '배팅 실패')
      }
    })
  }

  useEffect(() => {
    return () => {
      setCrashBet(0)
    }
  }, [window.location.pathname])

  useEffect(() => {
    const channel = pusher?.subscribe('minigame-result-channel')
    channel?.bind('minigame-result-event', async () => {
      await refetchMiniGameBetData()
    })

    return () => {
      channel?.unbind('minigame-result-event')
      pusher?.unsubscribe('minigame-result-channel')
    }
  }, [pusher])

  // useEffect(() => {
  //   if (user) {
  //     refetchMiniGameBetData()
  //   }
  // }, [user])

  const renderMiniGameCart = () => {
    return (
      cart.pick_list.length > 0 &&
      cart.pick_list.map((item) => {
        const { miniGameItem, selectedKeyItem } = item
        return (
          <div key={selectedKeyItem} className='bg-[#14161b] mb-2 rounded relative'>
            <div className='p-2 pt-4 pr-6 flex flex-col relative'>
              <button
                type='button'
                onClick={() => {
                  dispatch(
                    removeItemFromCartMiniGame({
                      selectedKeyItem
                    })
                  )
                }}
                className='absolute top-2 right-2 font-bold'
              >
                X
              </button>
              <div className='flex'>
                <div className='flex flex-auto'>
                  <p className='text-white  text-12 font-bold'>{`${miniGameItem.text} (${miniGameItem.category})`}</p>
                </div>
                <p className='text-white  text-12 font-bold flex justify-between'>
                  <span className='text-primary'>{miniGameItem.rate}</span>
                </p>
              </div>
              <div className='flex flex-auto'>
                <p className='text-white  text-12 font-bold'>{`분 별다리-${miniGameItem.transId.split('_').length > 1 ? miniGameItem.transId[1] : miniGameItem.transId} 회차`}</p>
              </div>
              <p className=' text-[#d65708] text-12 font-bold'>분 별다리</p>
            </div>
          </div>
        )
      })
    )
  }
  return (
    <>
      <div className='w-full mt-4'>
        <div className='flex justify-between'>
          <button
            className={`w-1/2 h-10 mb-2 font-bold hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === MINI_GAME_CART_BET ? 'text-primary border-b border-b-primary' : 'text-white'}`}
            onClick={() => setTab(MINI_GAME_CART_BET)}
          >
            {/* 배팅카트 {cart?.pick_list?.length || Object.keys(cart?.mini_game)?.length} */}
            배팅카트 {cart?.pick_list?.length}
          </button>
          <button
            className={`w-1/2 h-10 mb-2 font-bold hover:text-primary-2 hover:border-b hover:border-b-primary-2 ${tab === MINI_GAME_CART_HISTORY ? 'text-primary border-b border-b-primary' : 'text-white'}`}
            onClick={() => {
              if (tab === MINI_GAME_CART_HISTORY) return

              refetchMiniGameBetData()
              setTab(MINI_GAME_CART_HISTORY)
            }}
          >
            배팅 기록
          </button>
        </div>
        {tab === MINI_GAME_CART_BET && (
          <>
            <div>
              <div className='rounded'>
                <div className='flex font-bold justify-between text-primary-2 border-b border-[#3C3C3C] p-2'>
                  <div className='right number bet-cart-cnt'>
                    {/* {cart?.pick_list?.length || Object.keys(cart?.mini_game)?.length} */}
                    {cart?.pick_list?.length}
                    폴더
                  </div>
                  <div className='left'>
                    <button className='text-[#707478] font-bold' onClick={() => dispatch(removeAllCartSport())}>
                      전체지우기
                    </button>
                  </div>
                </div>
                <div className='py-2 max-h-[406px] overflow-y-auto sidebar__overflow'>{renderMiniGameCart()}</div>
                <div className='px-1'>
                  <div className='border border-[#3C3C3C] pr-1 pl-3 flex justify-between items-center gap-10 py-2'>
                    <p className='text-12 font-bold flex-shrink-0'>배팅</p>
                    <div className='flex items-center gap-2'>
                      <button onClick={() => setCrashBet(0)}>
                        <img
                          src='/assets/images/icons/icon-rotate-white.svg'
                          alt='Icon Rotate'
                          title='Icon Rotate'
                          width='16'
                          height='19'
                        />
                      </button>
                      <input
                        className='input-primary auto-fill-input w-full placeholder:text-gray-500 focus:outline-none text-white text-14 h-8 p-3 rounded border-2 border-primary bg-black  '
                        placeholder='금액을 입력해주세요.'
                        value={formatCurrency(crashBet, ',')}
                        onChange={handleChangeTotalBet}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex justify-between items-center gap-1 text-12 my-3'>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(5000)}
                  >
                    오천
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(10000)}
                  >
                    일만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(50000)}
                  >
                    오만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(100000)}
                  >
                    십만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(1000000)}
                  >
                    백만
                  </button>
                  <button
                    className='bg-[#0b0d10] h-8 px-1 text-white rounded hover:bg-primary duration-150'
                    onClick={() => addExtraBet(user.mSportsMoney)}
                  >
                    맥스
                  </button>
                </div>
                <div className='p-2'>
                  <div>
                    <ul className='flex flex-col gap-1'>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>총 배당:</p>
                        <p>
                          {/* {location.pathname === path.sports ? formattedTotalBetRate : formattedTotalBetRateMnGame} 배 */}
                          {formattedTotalBetRate} 배
                        </p>
                      </li>
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>당첨 예상금액</p>
                        <p>{formatCurrency(totalMoney, ',')} 원</p>
                      </li>
                      {/* MIN */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최소 배팅금액</p>
                        <p>{formatCurrency(limit?.min_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 배팅금액</p>
                        <p>{formatCurrency(limit?.max_bet_amount || 0, ',')} 원</p>
                      </li>

                      {/* MAX REWARD */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대 당첨금액</p>
                        <p>{formatCurrency(limit?.max_bet_payout || 0, ',')} 원</p>
                      </li>

                      {/* COUNT ... */}
                      <li className='flex justify-between items-center py-2 px-3 gap-2 text-12 bg-gray-1'>
                        <p>최대배당</p>
                        <p>{formatCurrency(limit?.max_payout || 0, ',')} 배</p>
                      </li>
                    </ul>
                  </div>
                  <button
                    className={cn(
                      'rounded-md bg-[#14161b] block w-full text-white py-2 my-2 font-bold hover:bg-primary hover:text-white',
                      {
                        '!bg-gray-5 pointer-events-none hover:!bg-gray-5 ': loading
                      }
                    )}
                    disabled={!token || loading}
                    onClick={cart.pick_list.length ? handleBetting : () => {}}
                  >
                    베팅하기
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {tab === MINI_GAME_CART_HISTORY && location.pathname && user && (
          <div className='flex flex-col gap-3 my-4 px-2'>
            {miniGameBetData?.MiniGameBettingList.data.map((historyBet, index) => {
              const result =
                historyBet.tmStatus === 'bet'
                  ? '배팅'
                  : historyBet.tmStatus === 'win'
                    ? '당첨'
                    : historyBet.tmStatus === 'draw'
                      ? '적특'
                      : '낙첨'
              return (
                <div key={index} className='p-4 bg-[#000] rounded-lg'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>배팅타입</span>
                        <span className='text-12 text-white'>{historyBet.tmProviderGame}</span>
                      </div>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>배팅결과</span>
                        <span className='text-12 text-white'>{historyBet.tmDetails?.split('|')[1]}</span>
                      </div>
                    </div>
                    <div className='w-full h-px bg-[#1f7aff]'></div>
                    <div className='flex flex-col gap-2'>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>배팅시간</span>
                        <span className='text-12 text-white'>{historyBet.tmRegDate}</span>
                      </div>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>예상당첨금액</span>
                        <span className='text-12 text-white'>
                          <span className='text-[#00acac]'>(</span>배팅{' '}
                          <span className='text-[#00acac]'>{historyBet.tmBetAmount} X</span> 배당{' '}
                          <span className='text-[#00acac]'>{historyBet.tmRate}</span>
                          <span className='text-[#00acac]'>)</span> ={' '}
                          <span className='text-[#f59c1a]'>{historyBet.tmBetAmount * historyBet.tmRate}</span>
                          <span className='text-[#00acac]'>원</span>
                        </span>
                      </div>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>결과당첨금액</span>
                        <span className='text-12 text-[#a94442]'>
                          {historyBet.tmBetAmount * historyBet.tmRate} <span className='text-white'>원</span>
                        </span>
                      </div>
                      <div className='flex gap-3'>
                        <span className='text-12 text-[#707478]'>배팅결과</span>
                        <span className='text-12 text-white'>{result}</span>
                      </div>
                    </div>
                    <span className='text-12 text-[#707478] max-w-[70%]'>
                      *자세한 내역 및 취소요청은 배팅내역 페이지에서 확인 가능합니다.
                    </span>
                  </div>
                </div>
              )
            })}
            <button
              className='block mx-auto text-blue-1'
              onClick={() => setQuery({ ...query, limit: (query?.limit || 0) + 10 })}
            >
              더보기
            </button>
          </div>
        )}
      </div>
    </>
  )
}
