import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { Input, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useLocation, useNavigate } from 'react-router-dom'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { formatQueryParams } from 'src/libs/utils/utilFuncs'
import { Provider } from 'src/types/game.type'
import styles from './SearchGameInput.module.scss'

const SearchGameInput = ({
  providers,
  defaultValue,
  refetch,
  typeParams,
  defaultValueFilter
}: {
  providers: Provider[]
  defaultValue: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  typeParams?: string
  defaultValueFilter?: string
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useQueryParams()

  const handleChange = (e: string) => {
    delete queryParams.offset
    delete queryParams.limit
    delete queryParams.limitSlot
    delete queryParams.offsetSlot
    navigate(
      `${location.pathname}${formatQueryParams(queryParams) ? formatQueryParams({ ...queryParams, [`filter${typeParams ? typeParams : ''}`]: e }) : ''}`
    )
    refetch()
  }

  const handleClick = (data: string) => {
    delete queryParams.search
    delete queryParams.offset
    delete queryParams.limit
    delete queryParams.limitSlot
    delete queryParams.offsetSlot
    navigate(
      `${location.pathname}${formatQueryParams(queryParams) ? formatQueryParams({ ...queryParams, [`search${typeParams ? typeParams : ''}`]: data }) : ''}`
    )
    refetch()
  }

  const optionSelect: DefaultOptionType[] =
    providers?.map((item) => ({
      value: item?.gpCode,
      label: item?.gpName
    })) || []

  return (
    <div className='flex gap-4 items-center flex-wrap md:flex-nowrap'>
      <Input.Search
        className={styles['searchInput']}
        classNames={{
          input: ''
        }}
        defaultValue={defaultValue}
        onSearch={(data) => {
          handleClick(data)
        }}
        placeholder='게임명 검색'
      />
      <Select
        showSearch
        placeholder='필터'
        optionFilterProp='children'
        onChange={(e) => handleChange(e)}
        options={[{ label: '모두', value: '' }, ...optionSelect]}
        defaultValue={defaultValueFilter}
        className={`flex-shrink-0 w-[200px] h-full bg-secondary ${styles['selectFilter']}`}
      />
    </div>
  )
}

export default SearchGameInput
