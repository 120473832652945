import { AxiosRequestConfig } from 'axios'
import { sportsService } from 'src/libs/utils/axios.sports'
import { CustomAxiosResponse } from 'src/types/axios.type'
import { ResponseGetAllLiveSports } from 'src/types/live-sports.type'
import {
  ResponseGetAllSports,
  ResponseGetMarketInfoList,
  ResponseGetRateInfoList,
  ResponseGetSportCategories
} from 'src/types/sports.type'
type GetAllSportsReq = {
  limit: number
  page: number
  sportsCode: string
  nationCode: string
  leagueCode: string
  isFavorite?: boolean
  isSortBy24h?: boolean
  isSortByTomorrow?: boolean
  isSortByLeague?: boolean
}

type GetRateInfoReq = {
  sportsCode: number
  leagueCode: number
  fixtureCode: number
}
export const sportsApi = {
  getAllSports({
    params,
    options
  }: {
    params: GetAllSportsReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetAllSports>> {
    return sportsService.get(
      `/sports/prematch`,
      {
        ...params,
        sportsCode: params.sportsCode === '0' ? '' : params.sportsCode,
        nationCode: params.nationCode === '0' ? '' : params.nationCode,
        leagueCode: params.leagueCode === '0' ? '' : params.leagueCode,
        isFavorite: params.isFavorite ? 1 : 0,
        isSortBy24h: params.isSortBy24h ? 1 : 0,
        isSortByTomorrow: params.isSortByTomorrow ? 1 : 0,
        isSortByLeague: params.isSortByLeague ? 1 : 0
      },
      {
        ...options
      }
    )
  },

  getAllLiveSports({
    params,
    options
  }: {
    params: GetAllSportsReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetAllLiveSports>> {
    return sportsService.get(
      `/sports/inplay`,
      {
        ...params
      },
      {
        ...options
      }
    )
  },

  getSportCategories(): Promise<CustomAxiosResponse<ResponseGetSportCategories>> {
    return sportsService.get(`/sports/menu?type=cross`)
  },
  getLiveSportCategories(): Promise<CustomAxiosResponse<ResponseGetSportCategories>> {
    return sportsService.get(`/sports/menu?type=realtime`)
  },
  getSportRateInfoList({
    params,
    options
  }: {
    params: GetRateInfoReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetRateInfoList>> {
    return sportsService.get(
      `/sports/markets`,
      {
        ...params,
        type: 'cross'
      },
      {
        ...options
      }
    )
  },
  getSportRateInfoLiveList({
    params,
    options
  }: {
    params: GetRateInfoReq
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetRateInfoList>> {
    return sportsService.get(
      `/sports/markets`,
      {
        ...params,
        type: 'realtime'
      },
      {
        ...options
      }
    )
  },

  getMarketInfoList({
    options = {}
  }: {
    options?: AxiosRequestConfig
  }): Promise<CustomAxiosResponse<ResponseGetMarketInfoList>> {
    return sportsService.get(
      `/sports/market-list`,
      {},
      {
        ...options
      }
    )
  }
}
