import s from './landing_2.module.scss'
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Landing_2() {
  return (
    <div>
      <div className='container mx-auto max-w-[700px] relative'>
        <img src='/assets/images/landing_2/section1.png' alt='' className='w-full max-w-[700px] block mx-auto' />
        <a
          href='https://t.me/sinoent777'
          title='고객센터 텔레그램 바로가기'
          target='_blank'
          className={`!absolute top-[80%] left-1/2 -translate-x-1/2 w-[60%] max-w-[398px] ${s.shadow}`}
          rel='noreferrer'
        >
          <img
            src='/assets/images/landing_2/telegram-button.png'
            alt='고객센터 텔레그램 바로가기'
            title='고객센터 텔레그램 바로가기'
            width='1135'
            height='232'
            className='w-full object-cover'
          />
        </a>
      </div>
      <div className='relative container mx-auto max-w-[700px] py-[80px]'>
        <img src='/assets/images/landing_2/section2.png' alt='' className='absolute top-0 left-0 w-full h-full' />
        <div className={`relative !mx-auto rounded-lg !w-[90%] ${s.rotated_box}`}>
          <img
            src='/assets/images/landing_2/tv-section.png'
            alt=''
            className={`relative  mx-auto z-20 rounded-lg ${s.tvSection}`}
          />
          <a
            href='https://www.youtube.com/@casinokino'
            title='지노엔터 키노 YOUTUBE 바로가기'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='/assets/images/landing_2/youtube-button.png'
              className='relative z-40 mt-2 mb-2 block mx-auto'
              alt=''
            />
          </a>
        </div>
      </div>
      <div className='container mx-auto max-w-[700px]'>
        <img src='/assets/images/landing_2/section3.png' alt='' className='w-full max-w-[700px] block mx-auto' />
      </div>
    </div>
  )
}
