import { gql } from '@apollo/client'

export const GET_PROVIDERS = gql`
  query GameProviders($offset: Int, $limit: Int, $categories: String, $game_providers: String, $search: String) {
    GameProviders(
      offset: $offset
      limit: $limit
      categories: $categories
      game_providers: $game_providers
      search: $search
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        gpNo
        gpCode
        gpName
        gpNameEn
        gpCategory
        gpImage
        gpLogo
        gpImgBackground
        gpMaintenanceNotice
        gpMaintenance
      }
    }
  }
`

export const GET_PROVIDER = gql`
  query GameProvider($limit: Int, $offset: Int, $provider_code: String, $category: String, $search: String) {
    GameProvider(limit: $limit, offset: $offset, provider_code: $provider_code, category: $category, search: $search) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        gCode
        gName
        gNameEn
        gCategory
        gIconUrl
        gpCode
      }
    }
  }
`

export const GET_DATA_GAME = gql`
  query GameQuery($provider_code: String!) {
    GameQuery(provider_code: $provider_code) {
      url
    }
  }
`

export const GET_DATA_GAME_SLOT = gql`
  query GameQuery($provider_code: String!, $game_code: String!) {
    GameQuery(provider_code: $provider_code, game_code: $game_code) {
      url
    }
  }
`

export const GET_DATA_LOOBY = gql`
  query GameQuery($provider_code: String!) {
    GameQuery(provider_code: $provider_code) {
      url
    }
  }
`

export const GET_GP_CODE_QUERY = gql`
  query GameProvider {
    GpCodeQuery {
      gpName
      gpCode
    }
  }
`

export const GET_MINI_GAME = gql`
  query MiniGame {
    BoStart3Query {
      round_id
      start_time
      end_time
      now
      odd_even {
        odd {
          text
          rate
        }
        even {
          text
          rate
        }
      }
      left_right {
        left {
          text
          rate
        }
        right {
          text
          rate
        }
      }
      line {
        line3 {
          text
          rate
        }
        line4 {
          text
          rate
        }
      }
      complex {
        left_3_even {
          text
          rate
        }
        right_3_odd {
          text
          rate
        }
        left_4_odd {
          text
          rate
        }
        right_4_even {
          text
          rate
        }
      }
    }
  }
`

export const GET_MINI_GAME_BET_HISTORY = gql`
  query MiniGameBettingList($page: Int, $limit: Int, $category: String) {
    MiniGameBettingList(page: $page, limit: $limit, category: $category) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        tmTransID
        tmProviderGame
        tmSelectedItem
        tmResultBetting
        tmBetAmount
        tmWinAmount
        tmRate
        tmStatus
        tmRegDate
        tmDetails
      }
    }
  }
`
// TODO: following BE!! it should be refactored. should use 1 query
export const GET_MINI_GAME_NEW = gql`
  query MiniGame($game: String!) {
    BoStartQuery(game: $game) {
      round_id
      start_time
      now
      end_time
      blocking_time
      odd_even {
        odd {
          text
          rate
          color
        }
        even {
          text
          rate
          color
        }
      }
      left_right {
        left {
          text
          rate
          color
        }
        right {
          text
          rate
          color
        }
      }
      line {
        line3 {
          text
          rate
          color
        }
        line4 {
          text
          rate
          color
        }
      }
      complex {
        left_3_even {
          text
          rate
          color
        }
        right_3_odd {
          text
          rate
          color
        }
        left_4_odd {
          text
          rate
          color
        }
        right_4_even {
          text
          rate
          color
        }
      }
    }
  }
`
export const GET_LIMIT_MINI_GAME = gql`
  query BettingLimitsMiniGame($game: String) {
    BettingLimitsMiniGame(game: $game) {
      min_bet_amount
      max_bet_amount
      max_bet_payout
      max_payout
    }
  }
`

export const GET_LIMIT_PARSING_CASINO = gql`
  query BettingLimitsParsingCasino($game: String) {
    BettingLimitsParsingCasino(game: $game) {
      min_bet_amount
      max_bet_amount
      max_bet_payout
      max_payout
    }
  }
`

export const GET_MINI_GAME_CATEGORY = gql`
  query CategoryMiniGameQuery {
    CategoryMiniGameQuery {
      mgName
      gcReference
    }
  }
`

export const GET_MENU_MINI_GAME_CATEGORY = gql`
  query MenuMiniGameQuery {
    MenuMiniGameQuery {
      mgpName
      mgpNameEn
      mgpLogo
      MiniGames {
        mgNo
        mgName
        mgNameEn
        mgPath
        mgFrameLink
        mgFrameWidth
        mgFrameHeight
      }
    }
  }
`
