import { gql } from '@apollo/client'

export const REGISTER_MEMBER = gql`
  mutation registerMemberMutation(
    $member_id: String
    $password: String
    $re_password: String
    $bank_owner: String
    $bank_name: String
    $bank_number: String
    $phone: String
    $key: String
    $captcha: String
    $member_invite: String
    $nick: String
  ) {
    registerMember(
      member_id: $member_id
      password: $password
      re_password: $re_password
      bank_owner: $bank_owner
      bank_name: $bank_name
      bank_number: $bank_number
      phone: $phone
      key: $key
      captcha: $captcha
      member_invite: $member_invite
      nick: $nick
    ) {
      message
      status
    }
  }
`

export const LOGIN_MEMBER = gql`
  mutation loginMember($member_id: String, $password: String, $key: String, $captcha: String) {
    loginMember(member_id: $member_id, password: $password, key: $key, captcha: $captcha) {
      message
      access_token
      status
      member {
        mNo
        mID
        mNick
        mRealName
        mLevel
        mPhone
        mStatus
        mPoint
        mMoney
        mSportsMoney
        mBankName
        mBankNumber
        mBankOwner
        mMemberID
      }
      first_time_login_modal
    }
  }
`
export const CONSULATION_USER = gql`
  mutation GuestCreateMutation(
    $title: String
    $name: String
    $bank_number: String
    $bank_name: String
    $phone_number: String
    $content: String
  ) {
    guestCreateMutation(
      title: $title
      name: $name
      bank_number: $bank_number
      bank_name: $bank_name
      phone_number: $phone_number
      content: $content
    ) {
      status
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    LogoutMutation
  }
`
