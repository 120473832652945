export const localStorageEventTarget = new EventTarget()

export const setAccessTokenToSS = (access_token: string) => {
  sessionStorage.setItem('token', access_token)
}

export const clearSS: () => void = () => {
  sessionStorage.clear()
}

export const getAccessTokenFromSS = () => sessionStorage.getItem('token') || ''

export const setAccessTokenToLS = (access_token: string) => {
  localStorage.setItem('token', access_token)
}

export const clearLS: () => void = () => {
  localStorage.removeItem('token')
  const clearLSEvent = new Event('clearLS')
  localStorageEventTarget.dispatchEvent(clearLSEvent)
}

export const getAccessTokenFromLS = () => localStorage.getItem('token') || ''

export const getTokenDynamic = (readFromLS: boolean) => {
  // const token = readFromLS ? getAccessTokenFromLS() : getAccessTokenFromSS()
  // not apply readFrom session storage
  return getAccessTokenFromLS()
}
