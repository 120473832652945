import { createAction, createReducer } from '@reduxjs/toolkit'
import { ValidSelectOptionVirtualSportRuleItem, VirtualSportsDataItem } from 'src/types/virtual-sport.api'

interface VirtualSportSearchState {
  dataVirtualSportSearch: VirtualSportsDataItem[]
  rules: ValidSelectOptionVirtualSportRuleItem[]
  enableMultiMatch: boolean
}

const initialState: VirtualSportSearchState = {
  dataVirtualSportSearch: [],
  rules: [],
  enableMultiMatch: false
}

export const addDataToVirtualSearchList = createAction(
  'addDataToVirtualSearchList',
  function ({ virtualSportList }: { virtualSportList: VirtualSportsDataItem[] }) {
    if (!virtualSportList.length) {
      return {
        payload: []
      }
    }

    return {
      payload: virtualSportList
    }
  }
)

export const resetDataVirtualSportSearch = createAction('resetDataVirtualSportSearch')

export const setRulesVirtualSports = createAction(
  'setRulesVirtualSports',
  (rules: ValidSelectOptionVirtualSportRuleItem[]) => {
    return {
      payload: rules
    }
  }
)
export const setEnableMultiMatchVirtualSports = createAction(
  'setEnableMultiMatchVirtualSports',
  (isEnable: boolean) => {
    return {
      payload: isEnable
    }
  }
)

const virtualSportSearchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addDataToVirtualSearchList, (state, action) => {
      state.dataVirtualSportSearch = action.payload
    })
    .addCase(resetDataVirtualSportSearch, () => {
      return { ...initialState }
    })
    .addCase(setRulesVirtualSports, (state, action) => {
      state.rules = action.payload
    })
    .addCase(setEnableMultiMatchVirtualSports, (state, action) => {
      state.enableMultiMatch = action.payload
    })
})

export default virtualSportSearchReducer
