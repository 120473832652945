import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useOutletContext } from 'react-router-dom'
import DataTable from 'src/components/Table/DataTable'
import RenderMinigameSelected from 'src/components/ui/RenderMiniGameSelected'
import path from 'src/constants/path'
import { GET_PARSING_CASINO_GAME_BET_HISTORY } from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { useCartMiniGameStore } from 'src/libs/hooks/store/useCartMiniGameStore'
import { setRefetchHistoryBetting } from 'src/libs/stores/minigame.reducer'
import { addKey, formatCurrency } from 'src/libs/utils/utilFuncs'
import { DetailParsingCasinoBetting, ResultParsingCasinoGameHistory } from 'src/types/minigame.type'
import { Menu } from '../MiniGame'
import EvenOddBet from './EvenOddBet'
import useIframeResize from 'src/libs/hooks/useIframeResize'

const tabs: { [key: string]: number } = {
  [path.lotus_odd_even]: 1,
  [path.mgm_odd_even]: 2
}

const useMiniGameBetData = (
  query: { limit: number; page: number },
  nameMiniGame: () => string,
  dispatch: any,
  setMiniHistoryBet: React.Dispatch<React.SetStateAction<DetailParsingCasinoBetting[]>>,
  setQuery: React.Dispatch<React.SetStateAction<{ limit: number; page: number; total: number }>>
) => {
  return useQuery<ResultParsingCasinoGameHistory>(GET_PARSING_CASINO_GAME_BET_HISTORY, {
    variables: { limit: query.limit ?? 10, page: query.page ?? 1, category: nameMiniGame() },
    context: { apiName: 'parsing-casino' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setMiniHistoryBet(data.ParsingCasinoBettingList?.data?.map(addKey))
      setQuery((prev) => ({
        ...prev,
        total: data?.ParsingCasinoBettingList?.total
      }))
      dispatch(setRefetchHistoryBetting(false))
    }
  })
}

const useRefetchHistoryBetting = (refetchHistoryBetting: boolean, refetchMiniGameBetData: () => void) => {
  useEffect(() => {
    if (refetchHistoryBetting) {
      refetchMiniGameBetData()
    }
  }, [refetchHistoryBetting])
}

const EvenOdd = () => {
  const { menu } = useOutletContext<{ menu: Menu[] }>()
  const subMenus = menu.map((i) => [...i.subMenu]).flat()
  const location = useLocation()
  const iframeLink2 = subMenus.find((i) => i.path === location.pathname)?.link || ''
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const dispatch = useDispatch()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [disabledRightSection, setDisabledRightSection] = useState(false)
  const [miniHistoryBet, setMiniHistoryBet] = useState<DetailParsingCasinoBetting[]>([])
  const { refetchHistoryBetting } = useCartMiniGameStore()

  const nameMiniGame = useCallback(() => {
    switch (location.pathname) {
      case path.lotus_odd_even:
        return 'lotus_oe'
      case path.mgm_odd_even:
        return 'mgm_oe'
      default:
        return ''
    }
  }, [location.pathname])

  const {
    data: miniGameBetData,
    refetch: refetchMiniGameBetData,
    loading
  } = useMiniGameBetData(query, nameMiniGame, dispatch, setMiniHistoryBet, setQuery)
  const iframeParentRef = useRef<HTMLDivElement>(null)

  useIframeResize(iframeRef, iframeParentRef, iframeLink2, 840, 756)
  useRefetchHistoryBetting(refetchHistoryBetting, refetchMiniGameBetData)

  const handleLockedPlay = (isLocked: boolean) => {
    setDisabledRightSection(isLocked)
  }

  const columns: TableColumnsType<DetailParsingCasinoBetting> = [
    {
      title: '시간',
      dataIndex: 'tpcRegDate',
      key: 'tpcRegDate',
      width: '180px',
      align: 'center',
      render: (_, record) => record.tpcRegDate
    },
    {
      title: '배팅정보',
      dataIndex: 'tpcProviderGame',
      key: 'tpcProviderGame',
      align: 'center'
    },
    {
      title: '배팅액',
      dataIndex: 'tpcBetAmount',
      key: 'tpcBetAmount',
      align: 'center',
      render: (value) => formatCurrency(value || 0)
    },
    {
      title: '배팅접수',
      dataIndex: 'tpcSelectedItem',
      key: 'tpcSelectedItem',
      align: 'center',
      render: (_, record) => {
        const result = record?.tpcSelectedItem
          ? JSON.parse(record.tpcSelectedItem)?.color?.split(' ').length > 1
            ? JSON.parse(record.tpcSelectedItem)?.color?.split(' ')
            : [record?.tpcSelectedItem]
          : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => (
              <div
                key={index}
                className='w-10 h-10 rounded-full flex items-center justify-center'
                style={{ background: item }}
              >
                <span>
                  {(record?.tpcSelectedItem && JSON.parse(record.tpcSelectedItem)?.text?.split(' ')[index]) || ''}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{ background: JSON.parse(result[0])?.color || 'red' }}
            >
              {<RenderMinigameSelected betSelected={JSON.parse(result[0])?.text} />}
            </div>
          </div>
        )
      }
    },
    {
      title: '배팅결과',
      dataIndex: 'tpcResultBetting',
      key: 'tpcResultBetting',
      align: 'center',
      render: (_, record) => {
        const result = record?.tpcResultBetting
          ? JSON.parse(record.tpcResultBetting)?.color?.split(' ').length > 1
            ? JSON.parse(record.tpcResultBetting)?.color?.split(' ')
            : [record?.tpcResultBetting]
          : []
        return result.length > 1 ? (
          <div className='w-full gap-1 flex items-center justify-center'>
            {result.map((item, index) => (
              <div
                key={index}
                className='w-10 h-10 rounded-full flex items-center justify-center'
                style={{ background: item }}
              >
                <span>
                  {(record?.tpcSelectedItem && JSON.parse(record.tpcResultBetting)?.text?.split(' ')[index]) || ''}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className='w-full flex items-center justify-center'>
            <div
              className='w-10 h-10 rounded-full flex items-center justify-center'
              style={{ background: JSON.parse(result[0])?.color || '' }}
            >
              {<RenderMinigameSelected betSelected={JSON.parse(result[0])?.text} />}
            </div>
          </div>
        )
      }
    },
    {
      title: '적중금액',
      dataIndex: 'tpcWinAmount',
      key: 'tpcWinAmount',
      align: 'center',
      render: (value) => formatCurrency(Number(value.toFixed(0)) || 0) + ' 원'
    },
    {
      title: '상태결과',
      dataIndex: 'tpcStatus',
      key: 'tpcStatus',
      align: 'center',
      render: (_, record) => {
        const statusMap: { [key: string]: string } = {
          bet: '배팅',
          win: '당첨',
          draw: '적특',
          lose: '낙첨'
        }
        return statusMap[record.tpcStatus] || '낙첨'
      }
    }
  ]

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetchMiniGameBetData({ limit: query?.limit || 10, page: current_page || 1 })
  }

  return (
    <>
      <div className='grid grid-cols-1 relative overflow-hidden'>
        <div className='w-full flex justify-center'>
          <div className='w-full aspect-[840/756]' ref={iframeParentRef}>
            <iframe
              className='absolute'
              ref={iframeRef}
              src={iframeLink2}
              title='myframe'
              width={840}
              height={756}
            ></iframe>
          </div>
        </div>
        <div className='relative w-full'>
          {disabledRightSection && (
            <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
              <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
            </div>
          )}
          <EvenOddBet
            refreshHistory={async () => {
              await refetchMiniGameBetData()
            }}
            tabActive={tabs[location.pathname]}
            handleLockedPlay={() => handleLockedPlay(true)}
            handleUnlockedPlay={() => handleLockedPlay(false)}
          />
        </div>
      </div>
      <div className='mx-2 mt-8 table-responsive relative w-full'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={columns}
            loading={loading}
            dataSource={miniHistoryBet as any}
            rowClassName={(index) => (index % 2 === 0 ? 'even' : 'odd')}
            scroll={{ x: 600 }}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            onChange={handleChangePagination}
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default EvenOdd
