import { gql } from '@apollo/client'

export const BET_MUTATION = gql`
  mutation VirtualSportBetMutation($bet_cash: Int, $bet_category: String, $pick_list: [PickInputType]!) {
    VirtualSportBetMutation(bet_cash: $bet_cash, bet_category: $bet_category, pick_list: $pick_list) {
      status
      message
    }
  }
`
