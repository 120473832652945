import { ApolloProvider } from '@apollo/client'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import './index.css'
import { client } from './libs/utils/apollo.ts'
import { store } from './libs/utils/store.ts'
import React from 'react'
import { PusherProvider } from './providers/PusherProvider.tsx'
const liveChatUrl = process.env.REACT_APP_LIVECHAT;
window.liveChatUrl = liveChatUrl;

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PusherProvider>
          <App />
        </PusherProvider>
      </Provider>
    </ApolloProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
