import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import { FolderBonusQueryRes } from 'src/types/sports.type'
import { useSelectOptBettingSport } from '../hooks/useSelectOptBettingSport'
import cn from 'classnames'
import { StarIcon } from 'src/components/Icons'

type Props = {
  folderBonusQuery?: FolderBonusQueryRes
  isLoadingFolderBonus: boolean
}

export const FoldersBonus = ({ folderBonusQuery, isLoadingFolderBonus }: Props) => {
  const { cart } = useCartSportStore()
  const { handleSelectOptionFolderBonus } = useSelectOptBettingSport()
  return isLoadingFolderBonus ? null : folderBonusQuery && folderBonusQuery.is_use_bonus_folders ? (
    <div className='flex flex-col gap-3 p-2 rounded-lg bg-black-4 w-full'>
      <div className='flex items-center gap-2'>
        <img src='/assets/images/sports/multibonus.png' alt='multibonus img' className='size-7' />
        <span className='text-12 font-thin'>{folderBonusQuery.text_folders_bonus}</span>
      </div>
      <div className='flex items-center gap-2'>
        {folderBonusQuery.folders_bonus.map((folderBonus) => {
          return (
            <button
              className={cn('flex-1 flex justify-between p-2 md:p-4 rounded bg-black-3 border  duration-150', {
                'border-transparent hover:border-primary cursor-pointer':
                  cart.folder_bonus?.key !== folderBonus.bonus_folder_key,
                'border-primary cursor-pointer': cart.folder_bonus?.key === folderBonus.bonus_folder_key
              })}
              key={folderBonus.bonus_folder_key}
              type='button'
              onClick={() => {
                handleSelectOptionFolderBonus(folderBonus, folderBonusQuery.min_rate)
              }}
            >
              <div className='flex items-center gap-1.5'>
                <StarIcon className='text-red-1 size-4' />
                <span className='text-14'>{folderBonus.bonus_folder_text}</span>
              </div>
              <span className='text-primary text-13 md:text-16'>{folderBonus.bonus_folder_rate}</span>
            </button>
          )
        })}
      </div>
    </div>
  ) : null
}
