import React from 'react'

type Props = {
  size: number
  color?: string
  className?: string
}

export const CloseIcon = (props: Props) => {
  const { size, color, className } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size + 'px'}
      height={size + 'px'}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      className={className}
    >
      <path
        d='M13.3 0.710703C12.91 0.320703 12.28 0.320703 11.89 0.710703L6.99997 5.5907L2.10997 0.700703C1.71997 0.310703 1.08997 0.310703 0.699971 0.700703C0.309971 1.0907 0.309971 1.7207 0.699971 2.1107L5.58997 7.0007L0.699971 11.8907C0.309971 12.2807 0.309971 12.9107 0.699971 13.3007C1.08997 13.6907 1.71997 13.6907 2.10997 13.3007L6.99997 8.4107L11.89 13.3007C12.28 13.6907 12.91 13.6907 13.3 13.3007C13.69 12.9107 13.69 12.2807 13.3 11.8907L8.40997 7.0007L13.3 2.1107C13.68 1.7307 13.68 1.0907 13.3 0.710703Z'
        fill='white'
      />
    </svg>
  )
}
