import { Outlet, useRoutes } from 'react-router-dom'

// eslint-disable-next-line import/default, import/namespace, import/no-named-as-default, import/no-named-as-default-member
import path from 'src/constants/path'
import { Landing_1, Landing_2 } from 'src/layouts/LandingLayout'
import AttendancePage from 'src/pages/AttendancePage/AttendancePage'
import Battle from 'src/pages/Battle'
import BattleCasino from 'src/pages/Battle/BattleCasino'
import BetGames from 'src/pages/Battle/BetGames'
import Minigame from 'src/pages/Battle/Minigame'
import Sports from 'src/pages/Battle/Sports'
import Event from 'src/pages/Event'
import ListPoint from 'src/pages/ListPoint'
import MiniGame from 'src/pages/MiniGame'
import BoscoreLadder from 'src/pages/MiniGame/BoscoreLadder'
import SurePowerBall from 'src/pages/MiniGame/SurePowerBall'
import TokenPowerBall from 'src/pages/MiniGame/TokenPowerBall'
import MoveMoney from 'src/pages/MoveMoney'
import NewBoard from 'src/pages/NewBoard'
import Note from 'src/pages/Note'
import Slot from 'src/pages/Slot'
import SpinWheel from 'src/pages/SpinWheel'
import WithDraw from 'src/pages/WithDraw'
import MainLayout from '../layouts/MainLayout'
import Casino from '../pages/Casino'
import Chat11 from '../pages/Chat11'
import HomePage from '../pages/HomePage'
import ReCharge from '../pages/ReCharge'
// import { default as SportsPage } from '../pages/Sports/index'
import { default as SportsPage } from '../pages/Sports'
import ProtectedRoute from './ProtectedRoute'

import Developing from 'src/components/Developing/Developing'
import MoneyLayout from 'src/layouts/MoneyLayout'
import Coupon from 'src/pages/Coupon'
import AvailableCoupon from 'src/pages/Coupon/AvailableCoupon'
import UsageHistory from 'src/pages/Coupon/UsageHistory'
import Fame from 'src/pages/Fame'
import Friend from 'src/pages/Friend/Friend'
import LiveSport from 'src/pages/LiveSport'
import BoscorePowerBall from 'src/pages/MiniGame/BoscorePowerBall'
import LotteriaPowerBall from 'src/pages/MiniGame/LotteriaPowerBall'
import SureLadder from 'src/pages/MiniGame/SureLadder'
import SureRacing from 'src/pages/MiniGame/SureRacing'
import CashHistoryPage from '../pages/ CashHistoryPage'
import BePick from 'src/pages/MiniGame/BePick/BePick'
import ScorePower from 'src/pages/MiniGame/ScorePower'
import { BetHistory } from 'src/components/BetHistory'
import VirtualSportsCategory from 'src/pages/VirtualSportsCategory'
import BattleVirtualSports from 'src/pages/Battle/BattleVirtualSports'
import VirtualSports from 'src/pages/VirtualSports'
import { Suspense } from 'react'
import MaintainGame from 'src/pages/MaintainGame/'
import EvenOdd from 'src/pages/MiniGame/EvenOdd'
import PowerLadder from 'src/pages/MiniGame/PowerLadder'
import KenoLadder from 'src/pages/MiniGame/KenoLadder'
import SpeedKeno from 'src/pages/MiniGame/SpeedKeno/SpeedKeno'
import Baccarat from 'src/pages/MiniGame/Bacarat'
import TVStreamSports from 'src/pages/TVStreamSports/TVStreamSports'
export default function Router() {
  const routeElements = useRoutes([
    {
      path: '',
      element: <ProtectedRoute />,
      children: [
        {
          element: (
            <MainLayout>
              <div data-aos='fade-up' className='h-full lg:h-auto'>
                <Outlet />
              </div>
            </MainLayout>
          ),
          children: [
            {
              path: path.home,
              element: <HomePage />
            },
            {
              path: path.casino,
              index: true,
              element: (
                <>
                  <Casino isHome />
                  <Slot isHome />
                </>
              )
            },
            {
              path: path.chat11,
              index: true,
              element: <Chat11 />
            },
            {
              path: path.event,
              index: true,
              element: <Event />
            },
            {
              path: path.note,
              index: true,
              element: <Note />
            },
            {
              path: '/',
              element: <MoneyLayout />,
              children: [
                {
                  path: path.recharge,
                  index: true,
                  element: <ReCharge />
                },
                {
                  path: path.withdraw,
                  index: true,
                  element: <WithDraw />
                },
                {
                  path: path.move_money,
                  index: true,
                  element: <MoveMoney />
                },
                {
                  path: path.cashhistory,
                  index: true,
                  element: <CashHistoryPage />
                }
              ]
            },
            {
              path: path.new_board,
              index: true,
              element: <NewBoard />
            },
            {
              path: path.maintain,
              index: true,
              element: <MaintainGame />
            },
            {
              path: path.list_point,
              index: true,
              element: <ListPoint />
            },
            {
              path: path.battle,
              element: <Battle />,
              children: [
                {
                  path: path.battle_sports,
                  element: <Sports />
                },
                {
                  path: path.battle_minigame,
                  element: <Minigame />
                },
                {
                  path: path.battle_virtual_sport,
                  element: <BattleVirtualSports />
                },
                {
                  path: path.battle_bet_games,
                  element: <BetGames />
                },
                {
                  path: path.battle_casino,
                  element: <BattleCasino />
                }
              ]
            },
            {
              path: path.sports,
              element: <SportsPage />
            },
            {
              path: path.live_sport,
              element: <LiveSport />
            },
            {
              path: path.virtual_sports,
              element: <VirtualSports />,
              children: [
                {
                  path: path.virtual_sports_football,
                  element: <VirtualSportsCategory />
                },
                {
                  path: path.virtual_sports_basketball,
                  element: <VirtualSportsCategory />
                },
                {
                  path: path.virtual_sports_baseball,
                  element: <VirtualSportsCategory />
                },
                {
                  path: path.virtual_sports_greyhounds,
                  element: <VirtualSportsCategory />
                },
                {
                  path: path.virtual_sports_horse,
                  element: <VirtualSportsCategory />
                }
              ]
            },
            {
              path: path.spin,
              element: <SpinWheel />
            },
            {
              path: path.slot,
              index: true,
              element: <Slot />
            },
            {
              path: path.attendance,
              element: <AttendancePage />
            },
            {
              path: path.friend,
              index: true,
              element: <Friend />
            },

            {
              path: path.minigame,
              element: <MiniGame />,
              children: [
                // TAB1
                {
                  path: path.mini_power_ladder,
                  element: <PowerLadder />
                },
                {
                  path: path.mini_keno_ladder,
                  element: <KenoLadder />
                },
                {
                  path: path.mini_speed_keno,
                  element: <SpeedKeno />
                },

                // TAB2
                {
                  path: path.mini_boscore_ladder1,
                  element: <BoscoreLadder />
                },
                {
                  path: path.mini_boscore_ladder2,
                  element: <BoscoreLadder />
                },
                {
                  path: path.mini_boscore_ladder3,
                  element: <BoscoreLadder />
                },
                {
                  path: path.mini_boscore_power_ball,
                  element: <BoscorePowerBall />
                },

                //TAB3
                {
                  path: path.mini_sure_power_ball1,
                  element: <SurePowerBall />
                },
                {
                  path: path.mini_sure_power_ball2,
                  element: <SurePowerBall />
                },
                {
                  path: path.mini_sure_power_ball3,
                  element: <SurePowerBall />
                },
                {
                  path: path.mini_sure_ladder1,
                  element: <SureLadder />
                },
                {
                  path: path.mini_sure_ladder2,
                  element: <SureLadder />
                },
                {
                  path: path.mini_sure_ladder3,
                  element: <SureLadder />
                },
                {
                  path: path.mini_sure_racing1,
                  element: <SureRacing />
                },
                {
                  path: path.mini_sure_racing2,
                  element: <SureRacing />
                },
                {
                  path: path.mini_sure_racing3,
                  element: <SureRacing />
                },

                //TAB4
                {
                  path: path.mini_token_power_ball,
                  element: <TokenPowerBall />
                },
                // {
                //   path: path.mini_token_hilo,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mini_token_hilo5,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mini_token_baccarat,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mini_token_horse,
                //   element: <PowerBall />
                // },

                // //TAB5
                // {
                //   path: path.mgm_baccarat1,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mgm_baccarat2,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mgm_baccarat3,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mgm_even_odd,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.mgm_go_stop,
                //   element: <PowerBall />
                // },

                //TAB6
                // {
                //   path: path.lotus_baccarat1,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.lotus_even_odd,
                //   element: <PowerBall />
                // },

                //TAB7
                // {
                //   path: path.bet_games_lucky7,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_lucky6,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_lucky5,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_wheel,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_poker,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_baccarat,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_bet,
                //   element: <PowerBall />
                // },
                // {
                //   path: path.bet_games_dice_duel,
                //   element: <PowerBall />
                // },
                {
                  path: path.mini_lottery_powerball,
                  element: <LotteriaPowerBall />
                },
                {
                  path: path.be_pick_bogle2,
                  element: <BePick />
                },
                {
                  path: path.be_pick_power5,
                  element: <BePick />
                },
                {
                  path: path.score_power_1,
                  element: <ScorePower />
                },
                {
                  path: path.score_power_2,
                  element: <ScorePower />
                },
                {
                  path: path.score_power_3,
                  element: <ScorePower />
                },

                {
                  path: path.mgm_bacarat,
                  element: <Baccarat />
                },
                {
                  path: path.lotus_bacarat,
                  element: <Baccarat />
                },
                {
                  path: path.mgm_odd_even,
                  element: <EvenOdd />
                },
                {
                  path: path.lotus_odd_even,
                  element: <EvenOdd />
                }
              ]
            },
            {
              path: path.coupon,
              element: <Coupon />,
              children: [
                {
                  path: path.available_coupon,
                  element: <AvailableCoupon />
                },
                {
                  path: path.used_coupon,
                  element: <UsageHistory />
                }
              ]
            },
            {
              path: path.bet_history,
              index: true,
              element: <BetHistory />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: (
        <MainLayout>
          <div data-aos='fade-up' className='h-full'>
            <Developing />
          </div>
        </MainLayout>
      )
    },
    {
      path: '/landing1',
      element: <Landing_1 />
    },
    {
      path: '/landing2',
      element: <Landing_2 />
    },
    {
      path: path.tv_stream,
      element: <TVStreamSports />
    },
    {
      path: '',
      element: (
        <MainLayout>
          <div data-aos='fade-up' className='h-full lg:h-auto'>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </div>
        </MainLayout>
      ),
      children: [
        {
          path: path.fame,
          index: true,
          element: <Fame />
        }
      ]
    }
  ])

  return routeElements
}
