import React from 'react'

type Props = {
  message?: string
}

export const NotFoundState = ({ message }: Props) => {
  if (message) {
    return (
      <div className='h-full min-h-[200px] lg:min-h-[400px] w-full flex flex-col gap-4 items-center justify-center text-white font-bold bg-mini-game-table-5'>
        <img src='/assets/images/sports/Lock1.png' alt='Lock game' className='size-[52px]' />
        <span>{message}</span>
      </div>
    )
  }
  return (
    <div className='h-full w-full flex items-center justify-center text-white font-bold bg-mini-game-table-5'>
      실패. 나중에 다시 시도 해주십시오.
    </div>
  )
}
