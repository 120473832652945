import { createAction, createReducer } from '@reduxjs/toolkit'
import { DomesticWithKeyMatch, LimitSport } from 'src/types/live-sports.type'

export type BetItem = {
  // domesticData: Omit<Domestic, 'betid1' | 'betid2' | 'betid3' | 'rate1' | 'rate2' | 'rate3'>
  domesticData: DomesticWithKeyMatch
  betId: string
  keyMatch: string
  rate: number
  newRate?: number
  isChanged?: boolean
  isLocked?: boolean
  betTeam: string
  status?: number
}
interface LiveSportState {
  cart: {
    pick_list: BetItem[]
    bettingLimit?: LimitSport
  }
  isBetting: boolean
}

const initialState: LiveSportState = {
  cart: {
    pick_list: [],
    bettingLimit: {
      BettingLimitsSportQuery: {
        max_bet_amount: 0,
        max_bet_payout: 0,
        max_payout: 0,
        min_bet_amount: 0,
        percent_distribution: 0
      }
    }
  },
  isBetting: false
}

export const removeAllCartLiveSport = createAction('removeAllCartLiveSport')

export const addItemToCartLiveSport = createAction(
  'addItemToCartLiveSport',
  function ({ domesticData, betId, keyMatch, rate, isChanged = false, newRate, betTeam }: BetItem) {
    const payload: BetItem = { domesticData, betId, keyMatch, rate, isChanged, newRate, betTeam }
    return {
      payload
    }
  }
)

export const updateItemFromCartLiveSport = createAction(
  'updateItemFromCartLiveSport',
  function ({
    domesticIdx,
    betId,
    rate,
    newRate,
    betTeam
  }: {
    domesticIdx: number
    betId: string
    rate: number
    newRate?: number
    betTeam: string
  }) {
    const payload = { domesticIdx, betId, rate, newRate, betTeam }
    return {
      payload
    }
  }
)

export const removeItemFromCartLiveSport = createAction(
  'removeItemFromCartLiveSport',
  function ({ domesticIdx, betId }: { domesticIdx: number; betId: string }) {
    const payload = { domesticIdx, betId }
    return {
      payload
    }
  }
)

export const removeAllItemInMatchAndAddNewItemLive = createAction(
  'removeAllItemInMatchAndAddNewItemLive',
  function ({ keyMatch, dateBet }: { keyMatch: string; dateBet: BetItem }) {
    return {
      payload: { keyMatch, dateBet }
    }
  }
)

export const storeLimitLiveSportBetting = createAction(
  'storeLimitLiveSportBetting',
  function ({ limitSport }: { limitSport: LimitSport }) {
    return {
      payload: limitSport
    }
  }
)

export const updateAndLockItemLive = createAction(
  'updateAndLockItemLive',
  function ({
    domesticIdx,
    betId,
    isChanged = false,
    newRate,
    isLocked,
    status
  }: {
    domesticIdx: number
    betId: string
    isChanged?: boolean
    newRate: number
    isLocked?: boolean
    status?: number
  }) {
    return {
      payload: { domesticIdx, betId, isChanged, newRate, isLocked, status }
    }
  }
)

export const setIsBetting = createAction('setIsBettingLiveSports', (isBetting: boolean) => {
  return {
    payload: isBetting
  }
})

const liveSportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addItemToCartLiveSport, (state, action) => {
      const status = action.payload.status || 1
      state.cart.pick_list.push({ ...action.payload, status })
    })
    .addCase(updateItemFromCartLiveSport, (state, action) => {
      const existingCartItemIndex = state.cart.pick_list.findIndex(
        (item) => item.domesticData.idx === action.payload.domesticIdx
      )
      if (existingCartItemIndex !== -1) {
        const updatedCartLiveSport = [...state.cart.pick_list]
        updatedCartLiveSport[existingCartItemIndex].betId = action.payload.betId
        updatedCartLiveSport[existingCartItemIndex].rate = action.payload.rate
        updatedCartLiveSport[existingCartItemIndex].betTeam = action.payload.betTeam
        state.cart.pick_list = updatedCartLiveSport
      }
    })
    .addCase(removeItemFromCartLiveSport, (state, action) => {
      let updatedCartLiveSport = [...state.cart.pick_list]
      updatedCartLiveSport = updatedCartLiveSport.filter(
        (cartItem) =>
          cartItem.betId !== action.payload.betId && cartItem.domesticData.idx !== action.payload.domesticIdx
      )
      state.cart.pick_list = updatedCartLiveSport
    })
    .addCase(removeAllCartLiveSport, (state) => {
      state.cart.pick_list = []
    })
    .addCase(storeLimitLiveSportBetting, (state, action) => {
      state.cart.bettingLimit = action.payload
    })
    .addCase(removeAllItemInMatchAndAddNewItemLive, (state, action) => {
      const updatedCartSport = [...state.cart.pick_list].filter((item) => item.keyMatch !== action.payload.keyMatch)
      updatedCartSport.push(action.payload.dateBet)
      state.cart.pick_list = updatedCartSport
    })
    .addCase(updateAndLockItemLive, (state, action) => {
      const existingCartItemIndex = state.cart.pick_list.findIndex(
        (item) => item.domesticData.idx === action.payload.domesticIdx
      )
      if (existingCartItemIndex !== -1) {
        const updatedCartSport = [...state.cart.pick_list]
        updatedCartSport[existingCartItemIndex].isChanged = action.payload.isChanged
        updatedCartSport[existingCartItemIndex].isLocked = action.payload.isLocked
        updatedCartSport[existingCartItemIndex].newRate = action.payload.newRate
        const updateDomesticData = { ...updatedCartSport[existingCartItemIndex].domesticData }
        updatedCartSport[existingCartItemIndex].status = action.payload.status || 1
        updatedCartSport[existingCartItemIndex].domesticData = updateDomesticData
        if (!action.payload.isChanged) {
          updatedCartSport[existingCartItemIndex].rate = action.payload.newRate
        }

        state.cart.pick_list = updatedCartSport
      }
    })
    .addCase(setIsBetting, (state, action) => {
      state.isBetting = action.payload
    })
})

export default liveSportReducer
