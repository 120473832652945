import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useCartVirtualSportsStore } from 'src/libs/hooks/store/useCartVirtualSportsStore'
import { useVirtualSportSearchStore } from 'src/libs/hooks/store/useVirtualSportSearchStore'
import {
  addItemToCartVirtualSport,
  removeAllItemInMatchAndAddNewItemVirtual,
  removeItemFromCartVirtualSport,
  updateItemVirtualSport
} from 'src/libs/stores/virtual-sport.reducer'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
export const useSelectOptBettingVirtualSport = () => {
  const dispatch = useDispatch()
  const {
    indexItemExistInCart,
    isAlreadyPicked,
    limitBetting,
    cart,
    getAllItemInMatch,
    count: cartCount
  } = useCartVirtualSportsStore()
  const { getRulesByVirtualSportCategory, dataVirtualSportSearch, enableMultiMatch } = useVirtualSportSearchStore()

  const handleSelectBetting = async ({
    virtualData,
    betId,
    rate,
    betTeam,
    virtualDataForRacing
  }: {
    virtualData: VirtualSportsDataItem
    betId: string
    rate: number
    betTeam: string
    virtualDataForRacing: VirtualSportsDataItem[]
  }) => {
    // const cartInMatch = getAllItemInMatch(virtualData)
    // if (!enableMultiMatch && cartCount !== cartInMatch.length) {
    //   toast.error('최대 1경기만 배팅할 수 있습니다.')
    //   return
    // }
    if (virtualData.cate2 === 'Basketball') {
      handleSelectBettingSpecialCase({ virtualData, betId, rate, betTeam, virtualDataForRacing })
      return
    }
    // check in match
    const indexExistingItem = indexItemExistInCart(virtualData.idx)
    if (indexExistingItem !== -1) {
      if (isAlreadyPicked(betId)) {
        dispatch(removeItemFromCartVirtualSport({ betId: betId }))
      } else {
        const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
        const incomingTotalRate = (totalRate / Number(cart.pick_list[indexExistingItem].rate)) * Number(rate)

        if (incomingTotalRate > (limitBetting?.BettingLimitsVirtualSportQuery.max_payout || 0)) {
          toast.error('최대배당을 초과하였습니다.')
          return
        }
        dispatch(
          removeAllItemInMatchAndAddNewItemVirtual({
            idx: virtualData.idx,
            dateBet: { virtualData, betId, rate, betTeam, virtualDataForRacing }
          })
        )
      }
      return
    }
    const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
    const incomingTotalRate = totalRate * Number(rate)

    if (incomingTotalRate > (limitBetting?.BettingLimitsVirtualSportQuery.max_payout || 0)) {
      toast.error('최대배당을 초과하였습니다.')
      return
    }
    dispatch(addItemToCartVirtualSport({ virtualData, betId, rate, betTeam, virtualDataForRacing }))
  }

  const handleSelectBettingSpecialCase = async ({
    virtualData,
    betId,
    rate,
    betTeam,
    virtualDataForRacing
    // isDrawOption = false
  }: {
    virtualData: VirtualSportsDataItem
    betId: string
    rate: number
    betTeam: string
    virtualDataForRacing: VirtualSportsDataItem[]
    // isDrawOption?: boolean
  }) => {
    const rules = getRulesByVirtualSportCategory(virtualData.cate2)
    const typeRuleFlag = rules?.list_limit[0].type
    const listAllow = rules?.list_limit[0].list_allow || []
    const cartInMatch = getAllItemInMatch(virtualData)
    const categoryUserPick = virtualData.cate1
    if (cartInMatch.length === 0) {
      const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
      const incomingTotalRate = totalRate * Number(rate)

      if (incomingTotalRate > (limitBetting?.BettingLimitsVirtualSportQuery.max_payout || 0)) {
        toast.error('최대배당을 초과하였습니다.')
        return
      }
      dispatch(addItemToCartVirtualSport({ virtualData, betId, rate, betTeam, virtualDataForRacing }))
      return
    }

    // console.log('cartInMatch', cartInMatch)
    // console.log('pick', virtualData.id)
    const existingItemRowIndex = cartInMatch.findIndex((item) => item.virtualData.id === virtualData.id)
    // console.log('existingItemRowIndex', existingItemRowIndex?.virtualData.id)
    if (existingItemRowIndex !== -1) {
      const isRePick = cartInMatch[existingItemRowIndex].betId === betId
      if (isRePick) {
        // console.log('repick')
        // If it's the same bet option, remove it
        dispatch(
          removeItemFromCartVirtualSport({
            betId
          })
        )
        return
      } else {
        // console.log('update')
        const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
        const incomingTotalRate = (totalRate / Number(cart.pick_list[existingItemRowIndex].rate)) * Number(rate)
        if (incomingTotalRate > (limitBetting?.BettingLimitsVirtualSportQuery.max_payout || 0)) {
          toast.error('최대배당을 초과하였습니다.')
          return
        }
        dispatch(
          updateItemVirtualSport({
            id: virtualData.id,
            betId,
            rate,
            betTeam
          })
        )
        return
      }
    } else {
      if (listAllow.length === 0) {
        const totalRate = cart.pick_list.reduce((rateTotal, curr) => rateTotal * Number(curr.rate), 1)
        const incomingTotalRate = (totalRate / Number(cartInMatch[0].rate)) * Number(rate)

        if (incomingTotalRate > (limitBetting?.BettingLimitsVirtualSportQuery.max_payout || 0)) {
          toast.error('최대배당을 초과하였습니다.')
          return
        }
        dispatch(
          removeAllItemInMatchAndAddNewItemVirtual({
            idx: cartInMatch[0].virtualData.idx,
            dateBet: { virtualData, betId, rate, betTeam, virtualDataForRacing }
          })
        )
        return
      }

      // Check if typeRuleFlag is already picked in the cart
      const typeRuleFlagAlreadyPicked = cartInMatch.some((item) => item.virtualData.cate1 === typeRuleFlag)
      if (typeRuleFlagAlreadyPicked) {
        const categoriesInCartNotIncludeTypeFlag = cartInMatch
          .map((item) => item.virtualData.cate1)
          .filter((cate) => cate !== typeRuleFlag)
        if (categoriesInCartNotIncludeTypeFlag.some((i) => listAllow.includes(i))) {
          return
        }

        if (!listAllow.includes(categoryUserPick)) {
          return
        }
      } else {
        if (categoryUserPick !== typeRuleFlag) {
          return
        } else {
          const categoriesInCartNotIncludeTypeFlag = cartInMatch
            .map((item) => item.virtualData.cate1)
            .filter((cate) => cate !== typeRuleFlag)
          if (categoriesInCartNotIncludeTypeFlag.some((i) => !listAllow.includes(i))) {
            return
          }
        }
      }

      dispatch(addItemToCartVirtualSport({ virtualData, betId, rate, betTeam, virtualDataForRacing }))
      return
    }
  }

  return {
    handleSelectBetting
  }
}
