import Pusher from 'pusher-js'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAccessTokenFromLS } from 'src/libs/utils/auth'
import { RootState } from 'src/libs/utils/store'

const PusherContext = createContext<Pusher | null>(null)

export default PusherContext

interface PusherProviderProps {
  children: React.ReactNode
}

export const PusherProvider: React.FC<PusherProviderProps> = ({ children }) => {
  const [pusherClient, setPusherClient] = useState<Pusher | null>(null)
  const user = useSelector((state: RootState) => state.auth.user)
  const token = getAccessTokenFromLS()
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: 80,
      wssPort: 443,
      enabledTransports: ['ws', 'wss'],
      cluster: '',
      authEndpoint: `${process.env.REACT_APP_ADMIN_API}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      }
    })

    setPusherClient(pusher)

    if (!user?.mNo) {
      pusher.disconnect()
    }

    return () => {
      pusher.disconnect()
    }
  }, [token, user?.mNo])

  return <PusherContext.Provider value={pusherClient}>{children}</PusherContext.Provider>
}

export const usePusher = () => {
  const pusher = useContext(PusherContext)
  if (!pusher) {
    return null
  }
  return pusher
}
