/* eslint-disable @typescript-eslint/no-explicit-any */
import { UnknownAction } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import { ArrFamilyType } from 'src/pages/Sports/SportSideRight/SportSideRight'
import { BetCartType, DetailInfoType, Domestic, DomesticWithKeyMatch, SportItem } from 'src/types/sports.type'
import { addItemToCartSport } from '../stores/sport.reducer'
/**
 * Formats a number as a currency string 1000 to 1,000.
 *
 * @param {number} number - The number to format as currency.
 * @return {string} The formatted currency string.
 */
export const formatCurrency = (number: number | string, character = ',') => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, character)
}
/**
 * Formats a number as a number to .00
 *
 * @param {number} number - The number to format as currency.
 * @return {string} The formatted currency string.
 */
export const formatRate = (number: number | string, character = '.') => {
  let num = parseFloat(number.toString())

  if (num % 1 === 0) {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, character)
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, character)
}

/**
 * Formats a number to two decimal places.
 *
 * @param {number | string} number - The number to format.
 * @return {string} The formatted number with two decimal places.
 */
export const formatToTwoDecimalPlaces = (number: number | string): string => {
  // Chuyển đổi giá trị đầu vào thành kiểu số thực
  const num = parseFloat(number.toString())

  // Đảm bảo rằng số luôn có hai chữ số thập phân
  return num.toFixed(2)
}

/**
 * Removes all decimal points from a number or string representation of a number.
 *
 * @param {number | string} number - The number or string to remove decimal points from.
 * @return {string} The string representation of the number with all decimal points removed.
 */
export const reFormatCurrency = (number: number | string) => {
  return Number(number.toString().replace(/\,/g, ''))
}

/**
 * Formats the given query parameters into a URL query string.
 *
 * @param {Object} params - The query parameters to format.
 * @return {string} The formatted URL query string.
 */
export const formatQueryParams = (params: Record<string, string | number>) => {
  const queryString = Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')

  return `?${queryString}`
}

export const omitBy = <T>(obj: T, predicate: (value: unknown) => boolean): Partial<T> => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (!predicate(value)) {
        result[key] = value
      }
    }
  }

  return result
}

export const getTimeDifference = (timeStartSpin: Dayjs, timeStopSpin: Dayjs) => {
  return timeStopSpin.diff(timeStartSpin, 'seconds')
}

export const getPickPosInBetCart = (cartSport: BetCartType[], betCode?: string) => {
  let pos = -1

  for (let i = 0; i < cartSport.length; i++) {
    if (cartSport[i].bet_code == betCode) {
      pos = i

      break
    }
  }

  return pos
}

export const removePickFromBetCart = (
  cartSport: BetCartType[],
  dispatch: Dispatch<UnknownAction>,
  betCode?: string
) => {
  const pos = getPickPosInBetCart(cartSport, betCode)

  // if (pos >= 0) dispatch(removeItemCart(pos))
}

export const deleteSameLevelPick = (
  cartSport: BetCartType[],
  fixtureId: number,
  marketId: number,
  dispatch: Dispatch<UnknownAction>
) => {
  if (cartSport?.length > 0) {
    for (let i = 0; i < cartSport?.length; i++) {
      if (fixtureId == cartSport[i].fixture_id && marketId == cartSport[i].marketId) {
        removePickFromBetCart(cartSport, dispatch, cartSport[i].bet_code)
      }
    }
  }
}

export const setBetPickStatusBySpBetCode = (
  cartSport: BetCartType[],
  dispatch: Dispatch<UnknownAction>,
  betCode?: string,
  status?: number
) => {
  if (cartSport.length > 0) {
    for (let i = 0; i < cartSport.length; i++) {
      if (betCode == cartSport[i].bet_code) {
        const updatedCartSportItem = { ...cartSport[i], status: status }
        console.log('updatedCartSportItem')
        // cartSport[i] = updatedCartSportItem
        // dispatch(updateDataCart(updatedCartSportItem, i))
      }
    }
  }
}

export const getBetKeyStr = (fixtureId?: number, rateDetailInfo?: ArrFamilyType) => {
  const str =
    fixtureId +
    '-' +
    (rateDetailInfo?.data?.abetCode || '') +
    '-' +
    (rateDetailInfo?.data?.dbetCode || '') +
    '-' +
    (rateDetailInfo?.data?.hbetCode || '')

  return str
}

export const onAddToCart = (
  cartSport: BetCartType[],
  arrDetailInfo: ArrFamilyType[],
  mapAllowMarketLimitIds: any,
  mapMarketLimitUseYn: any,
  mapNotAllowMarketLimitIds: any,
  mapStrAllowMarketLimitIds: any,
  dispatch: Dispatch<UnknownAction>,
  rateDetailInfo: ArrFamilyType,
  detailInfoData: DetailInfoType,
  dataTemp: BetCartType[],
  setDataTemp: Dispatch<SetStateAction<BetCartType[]>>,
  i: number,
  game_type?: string | number,
  fixture_id?: number,
  bet_code?: string,
  marketId?: number | undefined,
  select_idx?: number | undefined,
  marketName?: string,
  pick_detail?: string,
  select_pick_desc?: string,
  select_rate?: string,
  leftSide?: boolean
) => {
  const pos = getPickPosInBetCart(cartSport, bet_code)

  const betKey = getBetKeyStr(arrDetailInfo[i]?.data?.fixtureId, rateDetailInfo)
  const pickTitle = detailInfoData?.homeTeamName + ' vs ' + detailInfoData?.awayTeamName

  if (marketId != 0 && fixture_id && marketId && !leftSide) {
    deleteSameLevelPick(cartSport, fixture_id, marketId, dispatch)

    if (dataTemp?.length > 0) {
      for (let i = 0; i < dataTemp?.length; i++) {
        if (fixture_id == dataTemp[i].fixture_id && marketId == dataTemp[i].marketId) {
          let posTemp = -1

          for (let i = 0; i < dataTemp.length; i++) {
            if (dataTemp[i].bet_code == dataTemp[i].bet_code) {
              posTemp = i

              break
            }
          }

          if (posTemp >= 0)
            setDataTemp((prevDataTemp) => {
              const indexToRemove = prevDataTemp.findIndex(
                (item) => item.fixture_id === fixture_id && item.marketId === marketId
              )

              if (indexToRemove !== -1) {
                return [...prevDataTemp.slice(0, indexToRemove), ...prevDataTemp.slice(indexToRemove + 1)]
              }

              return prevDataTemp
            })
        }
      }
    }
  }

  const formatData: BetCartType = {
    fixture_id: fixture_id,
    bet_code: bet_code,
    select_rate: select_rate,
    select_pick_desc: select_pick_desc,
    pick_detail: pick_detail,
    market_name: marketName,
    pick_title: pickTitle,
    key: betKey,
    status: 0,
    marketId: marketId,
    select_idx: select_idx,
    sports_code: detailInfoData?.sportsCode,
    sports_name: detailInfoData?.sportsName,
    old_rate: ''
  }

  if (pos === -1) {
    setDataTemp([...dataTemp, formatData])

    let bonusPickCnt = 0
    const mapCheckBonus = new Map()
    const mapCheckRateByBetCode = new Map()
    const mapCheckRateByMarketId = new Map()
    const mapCheckMarketIds = new Map()
    const mapStrCheckMarketIds = new Map()
    const mapSportsCodeByFixtureId = new Map()
    const mapSportsNameBySportsCode = new Map()

    for (let i = 0; i < dataTemp.length; i++) {
      const strPickDetail = dataTemp[i]?.pick_detail
      const strBetCode = dataTemp[i]?.bet_code
      const pickFixtureId = dataTemp[i]?.fixture_id
      const pickMarketId = dataTemp[i]?.marketId
      const pickSportsCode = dataTemp[i]?.sports_code
      const pickSportsName = dataTemp[i]?.sports_name

      if (pickSportsCode != '') mapSportsCodeByFixtureId.set(pickFixtureId, pickSportsCode)

      if (pickSportsCode != '' && pickSportsName != '') mapSportsNameBySportsCode.set(pickSportsCode, pickSportsName)

      if (strPickDetail && strPickDetail.includes('Bonus') == true) {
        bonusPickCnt++

        if (mapCheckBonus.has(strPickDetail) == true) {
          toast.error('보너스배당이 중복 선택되었습니다.')
          setBetPickStatusBySpBetCode(dataTemp, dispatch, strBetCode, 1)
          return
        } else {
          mapCheckBonus.set(strPickDetail, 1)
        }
      } else {
        if (mapCheckRateByBetCode.has(strBetCode) == true) {
          toast.error('같은 배당이 2번이상 선택되었습니다.')
          setBetPickStatusBySpBetCode(dataTemp, dispatch, strBetCode, 1)
          return
        } else {
          mapCheckRateByBetCode.set(strBetCode, 1)
        }

        const marketKey = pickFixtureId + '_' + pickMarketId

        if (mapCheckRateByMarketId.has(marketKey) == true) {
          toast.error('같은 마켓 배당이 2개 이상 선택되었습니다.')
          setBetPickStatusBySpBetCode(dataTemp, dispatch, strBetCode, 1)
          return
        } else {
          mapCheckRateByMarketId.set(marketKey, 1)
        }

        if (mapCheckMarketIds.has(pickFixtureId) == true) {
          mapCheckMarketIds.get(pickFixtureId).push(pickMarketId)
        } else {
          const tmpList = []
          tmpList.push(pickMarketId)
          mapCheckMarketIds.set(pickFixtureId, tmpList)
        }
        let strPushMarketId = pickMarketId?.toString()

        if (dataTemp[i].select_idx == 2) strPushMarketId = 'x'

        if (mapStrCheckMarketIds.has(pickFixtureId) == true) {
          mapStrCheckMarketIds.get(pickFixtureId).push(strPushMarketId)
        } else {
          const tmpList = []
          tmpList.push(strPushMarketId)
          mapStrCheckMarketIds.set(pickFixtureId, tmpList)
        }
      }
    }

    if (bonusPickCnt > 1) {
      toast.error('보너스배당이 중복 선택되었습니다.')
      return
    }

    let strErrorMsg = ''

    switch (game_type) {
      case '1':
        strErrorMsg += ' - 국내형'
        break
      case '2':
        strErrorMsg += ' - 해외형'
        break
      case '3':
        strErrorMsg += ' - 라이브'
        break
      case '4':
        strErrorMsg += ' - 실시간'
        break
      case '5':
        strErrorMsg += ' - 스페셜'
        break
      default:
        break
    }

    strErrorMsg += ' 에서 승인되지 않은 배팅조합입니다.'

    let checkSportsName = ''
    let marketLimitNotMatchYn = 0

    mapCheckMarketIds.forEach((value, key) => {
      if (value.length > 1) {
        console.log('in loop 1')
        let agreeInOneGamePossibleYn = 0

        const tmpSportsCode = mapSportsCodeByFixtureId.get(key)
        checkSportsName = mapSportsNameBySportsCode.get(tmpSportsCode)
        const allowMarketLimitIdsList = mapAllowMarketLimitIds[tmpSportsCode]
        const strAllowMarketLimitIdsList = mapStrAllowMarketLimitIds[tmpSportsCode]
        const limitUseYn = mapMarketLimitUseYn[tmpSportsCode]

        if (limitUseYn == 1) {
          console.log('in loop 2')
          if (allowMarketLimitIdsList && allowMarketLimitIdsList.length > 0) {
            for (let i = 0; i < allowMarketLimitIdsList.length; i++) {
              let findYn = 0
              const allowMarketLimitIds = allowMarketLimitIdsList[i]
              if (allowMarketLimitIds && allowMarketLimitIds.length > 0) {
                if (value.length > allowMarketLimitIds.length) continue

                for (let j = 0; j < value.length; j++) {
                  if (allowMarketLimitIds.includes(value[j]) == false) {
                    findYn = 1
                    break
                  }
                }
                if (findYn == 0) {
                  agreeInOneGamePossibleYn = 1
                  break
                }
              }
            }
          }

          const strCheckMarketIds = mapStrCheckMarketIds.get(key)

          if (strAllowMarketLimitIdsList && strAllowMarketLimitIdsList.length > 0) {
            for (let i = 0; i < strAllowMarketLimitIdsList.length; i++) {
              let findYn = 0
              const strAllowMarketLimitIds = strAllowMarketLimitIdsList[i]

              if (strAllowMarketLimitIds && strAllowMarketLimitIds.length > 0) {
                if (strCheckMarketIds.length > strAllowMarketLimitIds.length) continue

                let inputXCnt = 0
                let limitXCnt = 0

                for (let k = 0; k < strAllowMarketLimitIds.length; k++) {
                  if (strAllowMarketLimitIds[k] == 'x') limitXCnt++
                }

                for (let k = 0; k < strCheckMarketIds.length; k++) {
                  if (strCheckMarketIds[k] == 'x') inputXCnt++
                }

                if (inputXCnt > limitXCnt) continue

                for (let j = 0; j < strCheckMarketIds.length; j++) {
                  if (strAllowMarketLimitIds.includes(strCheckMarketIds[j]) == false) {
                    findYn = 1

                    break
                  }
                }

                if (findYn == 0) {
                  agreeInOneGamePossibleYn = 1

                  break
                }
              }
            }
          }

          if (agreeInOneGamePossibleYn == 0) {
            marketLimitNotMatchYn = 1

            return
          }
        }
      }
    })

    if (marketLimitNotMatchYn == 1) {
      strErrorMsg = checkSportsName + strErrorMsg
      toast.warn(strErrorMsg)

      return
    }

    const mapNotAllowPossibleList = new Map()

    mapCheckMarketIds.forEach((value, key) => {
      const tmpSportsCode = mapSportsCodeByFixtureId.get(key)
      checkSportsName = mapSportsNameBySportsCode.get(tmpSportsCode)
      let tmpCheckList = mapNotAllowPossibleList.get(tmpSportsCode)

      if (tmpCheckList && tmpCheckList.length > 0) {
        const newCheckList = []

        for (let i = 0; i < value.length; i++) {
          for (let j = 0; j < tmpCheckList.length; j++) {
            const oldList = tmpCheckList[j]
            const copyList = []

            for (let k = 0; k < oldList.length; k++) {
              copyList.push(oldList[k])
            }

            copyList.push(value[i])
            newCheckList.push(copyList)
          }
        }
        tmpCheckList = newCheckList
      } else {
        tmpCheckList = []

        for (let i = 0; i < value.length; i++) {
          const tmpList = []
          tmpList.push(value[i])
          tmpCheckList.push(tmpList)
        }
      }
      mapNotAllowPossibleList.set(tmpSportsCode, tmpCheckList)
    })

    mapNotAllowPossibleList.forEach((value, key) => {
      if (!mapNotAllowMarketLimitIds || Object.keys(mapNotAllowMarketLimitIds).length == 0) return

      let notAgreeInOtherGameFindYn = 0
      checkSportsName = mapSportsNameBySportsCode.get(key)
      const notAllowMarketLimitIdsList = mapNotAllowMarketLimitIds[key]

      if (notAllowMarketLimitIdsList && notAllowMarketLimitIdsList.length > 0) {
        for (let i = 0; i < notAllowMarketLimitIdsList.length; i++) {
          const notAllowMarketLimitIds = notAllowMarketLimitIdsList[i]

          for (let k = 0; k < value.length; k++) {
            let findYn = 0
            const inputMarketIds = value[k]

            if (notAllowMarketLimitIds.length <= inputMarketIds.length) {
              for (let j = 0; j < notAllowMarketLimitIds.length; j++) {
                if (inputMarketIds.includes(notAllowMarketLimitIds[j]) == false) {
                  findYn = 1

                  break
                }
              }

              if (findYn == 0) {
                notAgreeInOtherGameFindYn = 1

                break
              }
            }
          }

          if (notAgreeInOtherGameFindYn == 1) break
        }

        if (notAgreeInOtherGameFindYn == 1) {
          marketLimitNotMatchYn = 1

          return
        }
      }
    })

    if (marketLimitNotMatchYn == 1) {
      strErrorMsg = checkSportsName + strErrorMsg
      toast.warn(strErrorMsg)

      return
    }

    dispatch(addItemToCartSport(formatData))
  } else {
    removePickFromBetCart(cartSport, dispatch, bet_code)
  }
}

export const formatDateString = (dateString: string): Date | null => {
  const dateFormat = /^(\d{4})-(\d{2})-(\d{2})(?: (\d{2}):(\d{2}):(\d{2}))?$/
  const match = dateString.match(dateFormat)

  if (!match) {
    return null
  }

  const year = parseInt(match[1], 10)
  const month = parseInt(match[2], 10) - 1
  const day = parseInt(match[3], 10)
  const hours = match[4] ? parseInt(match[4], 10) : 0
  const minutes = match[5] ? parseInt(match[5], 10) : 0
  const seconds = match[6] ? parseInt(match[6], 10) : 0

  const date = new Date(year, month, day, hours, minutes, seconds)

  return date
}

export const isFutureDate = (date: string): boolean => {
  const currentDate = new Date()
  const tempDate = formatDateString(date)

  if (!tempDate) return false

  const inputDate = new Date(tempDate)

  return inputDate > currentDate
}

export const formatComma = (num: number) => {
  num = num || 0
  let strNum = num.toString()

  let precishn = ''
  if (strNum.indexOf('.') >= 0) {
    const tokens = strNum.split('.')
    precishn = tokens[1] || ''
    strNum = tokens[0] || '0'
  }

  strNum = strNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  if (precishn.length > 0) {
    precishn = '.' + precishn
  }

  return strNum + precishn
}

export const convertAxiosEndpoint = (url: string) => {
  // baseUrl/graphql/auth -> baseUrl
  const urlArr = url?.split('/') || []
  urlArr.pop()
  return urlArr.join('/')
}

export const delay = (time: number) => new Promise((res) => setTimeout(res, time))

export const objToQueryParams = (obj: any) => {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
}

export function guidGenerator(): string {
  const S4 = (): string => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

export const addKey = <T extends Record<string, any>>(item: T, index: number, array: T[]): T & { key: string } => {
  return {
    ...item,
    key: guidGenerator()
  }
}

export const renderRating = (rate: number): string => {
  return rate < 1 ? `1.00` : rate.toFixed(2)
}

export const isWithinMaintenancePeriod = (casino: { time: string; enable: boolean }): boolean => {
  const [start, end] = casino.time.split(' - ')
  const startTime = new Date(start.replace(' ', 'T')).getTime()
  const endTime = new Date(end.replace(' ', 'T')).getTime()
  const currentTime = new Date().getTime()

  return (currentTime >= startTime && currentTime <= endTime) || casino.enable
}

