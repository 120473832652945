import { gql } from '@apollo/client'

export const GET_CASH_POINT_HISTORY = gql`
  query CashPointHistoryQuery($filter: String, $page: Int, $limit: Int) {
    cashPointHistoryQuery(filter: $filter, page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        created_at
        amount
        description
        pre_amount
        wallet
      }
    }
  }
`

export const GET_CATEGORY_CASH_POINT_HISTORY = gql`
  query CashPointHistoryCategoryQuery {
    cashPointHistoryCategoryQuery {
      filter
      text
    }
  }
`
