import { gql } from '@apollo/client'

const GET_EVENTS = gql`
  query event($page: Int, $limit: Int) {
    event(page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        id
        title
        content
        banner
        created_date
        start_date
        end_date
      }
    }
  }
`

export { GET_EVENTS }
