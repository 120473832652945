export interface VirtualSportsDataItem {
  idx: string
  title: string
  seq: string
  cate1: string
  cate2: string
  date: string
  date_origin: string
  home: string
  away: string
  ratio1: number
  ratio2: number
  ratio3: number
  result1: string
  result2: string
  result3: string
  flag: string
  country1: string
  country2: string
  betid1: string
  betid2: string
  betid3: string
  cate1KoName: string
  cate2KoName: string
  time_left: number
  id: number
}

export interface ActiveBetQuery {
  ActiveBetQuery: VirtualSportsDataItem[]
}

export interface FinishedBetQuery {
  FinishedBetQuery: VirtualSportsDataItem[]
}

export interface BetItemHistory {
  status: number
  selectRate: number
  startDate: string
  homeTeamName: string
  awayTeamName: string
  homeScore: number
  awayScore: number
  selectedLine: number
  leagueName: string
  betDetail: string
  betResult: string
  betDescription: string
  statusKoName: string
}

export enum EStatusBetting {
  WAIT,
  LOSE,
  WIN,
  CANCEL,
  SPECIAL = 5
}

export interface BetHistoryGroup {
  _id: string
  betAmount: string
  regdate: string
  result_rate: number
  estimateAmount: string
  winAmount: string
  isCancelable: boolean
  result: EStatusBetting
  cartStatusKoName: string
  now: number
  bets: BetItemHistory[]
}

export interface BetHistoryData {
  total: number
  per_page: number
  current_page: number
  from: number
  to: number
  last_page: number
  has_more_pages: boolean
  data: BetHistoryGroup[]
  __typename: string
}

export interface BetHistoryQuery {
  BetHistoryQuery: BetHistoryData
}

export interface RuleVirtualSportItem {
  type: string
  list_allow: string[]
}

export interface ValidSelectOptionVirtualSportRuleItem {
  category: string
  list_limit: RuleVirtualSportItem[]
  is_can_bet_draw: boolean
}

export interface ValidSelectOptionVirtualSportsQuery {
  ValidSelectOptionQuery: ValidSelectOptionVirtualSportRuleItem[]
}
export interface ValidOptionAxisBetQuery {
  ValidOptionAxisBetQuery: { enable: boolean }
}
