import Button from 'src/components/ui/Button'
import deleteLarge from '../../../public/assets/images/icons/deleteLarge.png'
import { useQuery } from '@apollo/client'
import { getCountNoteNoRead } from 'src/libs/apis/graphql/queries/note.query'

interface INoteModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const NoteModal = ({ setOpen }: INoteModalProps) => {
  const { data } = useQuery(getCountNoteNoRead)

  return (
    <div className='note__modal'>
      <img src={deleteLarge} alt='' />
      <h1 className='note__modal-heading'>{`쪽지에 ${data?.countNoteNoRead?.count_no_read || 1} 메시지 있습니다`}</h1>
      <p className='note__modal-desc'>메시지를 바로 확인해 주세요</p>
      <hr className='note__modal-line' />
      <Button className='note__modal-action' onClick={() => setOpen(false)}>
        확인
      </Button>
    </div>
  )
}

export default NoteModal
