import cn from 'classnames'
import { VirtualSportsCategory } from 'src/types/virtual-sport'

type Props = {
  categoriesVirtualSports: VirtualSportsCategory[]
  isLoading: boolean
  classNames?: string
  categorySelected: string
  handleChangeCategory: (category: string) => void
}

export const CategoriesVirtualSports = ({
  categorySelected,
  classNames,
  categoriesVirtualSports,
  handleChangeCategory,
  isLoading
}: Props) => {
  return (
    <div className='max-w-full overflow-x-auto scrollbar-hide bg-secondary-2'>
      <div className={cn('flex items-center justify-between', classNames)}>
        {!isLoading &&
          categoriesVirtualSports.map((category) => {
            return (
              <button
                key={category.name}
                className={cn(
                  'flex-1 flex items-center justify-center gap-1 min-w-max py-4 px-2 border-b-[5px] font-thin duration-150',
                  {
                    'text-primary-2': category.name === categorySelected,
                    'text-white': category.name !== categorySelected
                  }
                )}
                style={{
                  borderColor: category.color
                }}
                onClick={(e) => {
                  handleChangeCategory(category.name)
                  e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
              >
                <img src='/assets/images/virtual/Bet365.png' alt='logo bet' className='size-[25px]' />
                <span>{category.name_ko}</span>
              </button>
            )
          })}
      </div>
    </div>
  )
}
