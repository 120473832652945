import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, ConfigProvider, Radio } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { MOVE_MONEY_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { login, setCloseAuthModal } from 'src/libs/stores/auth.reducer'
import { client } from 'src/libs/utils/apollo'
const options = [
  { label: '스포츠캐쉬', value: 'sports' },
  { label: '카지노캐쉬', value: 'casino_slot' }
]
const ModalConvertPoint = () => {
  const [wallet, setWallet] = useState<'casino_slot' | 'sports'>('sports')
  const dispatch = useDispatch()
  const [reloadMember] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })
  const [moveMoney] = useMutation(MOVE_MONEY_MUTATION, {
    onCompleted() {
      client.refetchQueries({
        include: ['MoneyInfoListQuery']
      })
      toast.success('포인트가 이전되었습니다')
      dispatch(setCloseAuthModal())
      reloadMember()
    },
    onError: (error) => {
      dispatch(setCloseAuthModal())
      toast.error(error.message)
    }
  })
  const handleConvertPoint = async () => {
    await moveMoney({
      context: { apiName: 'member' },
      variables: {
        from: 'point',
        to: wallet
      }
    })
  }
  const handleCloseConvertPoint = () => {
    dispatch(setCloseAuthModal())
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorText: '#ffffff'
          }
        }
      }}
    >
      <div className='flex flex-col items-center justify-center gap-4 w-[400px] '>
        <p className='text-16 text-white'>포인트를 캐쉬로 전환하시겠습니까?</p>
        <div className='flex items-center gap-2'>
          <Radio.Group options={options} defaultValue={wallet} onChange={(e) => setWallet(e.target.value)} />
        </div>
        <div className='flex items-center gap-2'>
          <Button className='text-white bg-primary border-primary w-[100px]' onClick={() => handleConvertPoint()}>
            승인
          </Button>
          <Button className='text-black bg-gray-5 border-gray-5 w-[100px]' onClick={() => handleCloseConvertPoint()}>
            취소
          </Button>
        </div>
      </div>
    </ConfigProvider>
  )
}
export default ModalConvertPoint
