import { useLazyQuery, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { MOVE_MONEY_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { login } from 'src/libs/stores/auth.reducer'
import { client } from 'src/libs/utils/apollo'
import { showErrorsResponse, showNetworkError } from 'src/libs/utils/error'
import { moveMoneySchema } from 'src/libs/utils/rules'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency, reFormatCurrency } from 'src/libs/utils/utilFuncs'
import { CASINO_SLOT_WALLET, SPORT_WALLET } from 'src/pages/WithDraw/WithDraw'
import { SiteInfoType } from 'src/types/common.type'
import Input from '../Input'
import Button from '../ui/Button'

type MoveMoneyForm = {
  amount: string
}

type DataExchangeMoneyCheckQuery = {
  ExchangeMoneyCheckQuery: {
    sports_current_money: number
    sports_withdraw_money: number
    casino_current_money: number
    casino_withdraw_money: number
    is_enable_exchange_money_sports_to_casino: boolean
    is_enable_exchange_money_casino_to_sports: boolean
  }
}
type MoveMoneyFormProps = {
  dataInfo: SiteInfoType
  mMoney?: string
  moveMoneyData: {
    from: string
    to: string
  }
  moneyUser?: {
    value: string
    render: boolean
    wallet: string
  }
  wallet?: string
  mSportsMoney?: string
  dataExchangeMoneyCheckQuery?: DataExchangeMoneyCheckQuery
}

export const MIN_MOVE_MONEY_DEFAULT_VALUE = 10_000
export const MAX_MOVE_MONEY_VALUE = 2_000_000_000

export default function MoveMoneyForm({
  dataInfo,
  mMoney,
  moveMoneyData,
  moneyUser,
  mSportsMoney,
  wallet,
  dataExchangeMoneyCheckQuery
}: MoveMoneyFormProps) {
  const {
    register,
    setValue,
    setError,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<MoveMoneyForm>({
    resolver: yupResolver(moveMoneySchema(dataInfo))
  })
  const user = useSelector((state: RootState) => state.auth.user)
  const casinoBalance = user?.mMoney || 0
  const sportsBalance = user?.mSportsMoney || 0

  const dispatch = useDispatch()

  const [reloadMember] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })

  const [moveMoney, { loading }] = useMutation(MOVE_MONEY_MUTATION, {
    // TODO: use data from mutation to show toast message. currently BE not provide any message
    onCompleted() {
      client.refetchQueries({
        include: ['MoneyInfoListQuery']
      })
      toast.success('머니이동되었습니다')

      reloadMember()
    },
    onError: (error) => {
      showErrorsResponse(error)
      showNetworkError(error)
    }
  })

  const onSubmit = handleSubmit(async (formData: MoveMoneyForm) => {
    const dataSubmit = { ...formData, moveMoneyData }

    const result = await moveMoney({
      variables: {
        from: dataSubmit?.moveMoneyData?.from ?? '',
        to: dataSubmit?.moveMoneyData?.to ?? '',
        amount: reFormatCurrency(dataSubmit.amount)
      },
      context: { apiName: 'member' }
    })

    if (result.data && result.data.ExchangeMoneyMutation) {
      const isSuccess = result.data.ExchangeMoneyMutation?.miType
      setValue('amount', '')

      if (isSuccess === 'UD') toast.success('초기화 성공')
    }
  })

  const dataButton = [
    {
      name: '1만원',
      value: 10000
    },
    {
      name: '3만원',
      value: 30000
    },
    {
      name: '5만원',
      value: 50000
    },
    {
      name: '10만원',
      value: 100000
    },
    {
      name: '50만원',
      value: 500000
    },
    {
      name: '100만원',
      value: 1000000
    }
  ]

  // useEffect(() => {
  //   if (moneyUser) {
  //     setValue('amount', formatCurrency(moneyUser.value))
  //   }
  // }, [moneyUser, mMoney])

  const handleButtonClick = (value: number) => {
    const currentValue = watch('amount')
    const newValue = parseInt(currentValue?.replace(/[^\d]/g, '')) || 0
    setValue('amount', formatCurrency((newValue + value).toString()))
  }

  const {
    sports_current_money,
    sports_withdraw_money,
    casino_current_money,
    casino_withdraw_money,
    is_enable_exchange_money_casino_to_sports,
    is_enable_exchange_money_sports_to_casino
  } = dataExchangeMoneyCheckQuery?.ExchangeMoneyCheckQuery ?? {}

  const is_enable_sports =
    Number(sports_current_money) >= Number(sports_withdraw_money) || is_enable_exchange_money_sports_to_casino

  const is_enable_casino =
    Number(casino_current_money) >= Number(casino_withdraw_money) || is_enable_exchange_money_casino_to_sports

  // TODO: remove on release helful for debugging
  console.log({ is_enable_casino, is_enable_sports })

  useEffect(() => {
    setValue('amount', '')
    setError('amount', {})
  }, [wallet])

  return (
    <form noValidate onSubmit={onSubmit} className='relative'>
      {wallet === SPORT_WALLET && !is_enable_sports && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          <p>롤링 완료 후 머니 이동이 가능합니다</p>
        </div>
      )}
      {wallet === CASINO_SLOT_WALLET && !is_enable_casino && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          <p>롤링 완료 후 머니 이동이 가능합니다</p>
        </div>
      )}
      <Input
        name='amount'
        type='text'
        register={register}
        placeholder='이동금액 입력'
        errorMessage={errors?.amount?.message}
        required
        classNameInput='placeholder:!text-[#CE6462] text-[#CE6462] text-14 h-10 p-3 rounded border border-none bg-white'
        className='w-full'
        classNameError='text-red-1 mt-1 text-12'
        disabled
        // onChange={(e) => onInputChange(e)}
      />
      <div className='grid grid-cols-3 gap-4 mt-4'>
        {dataButton?.map((item, index) => (
          <Button
            className='transition-all h-8 w-full rounded bg-secondary-6 text-content hover:bg-[#ccc] hover:text-secondary-2 px-4 min-w-20 font-medium text-14'
            type='button'
            key={index}
            onClick={() => handleButtonClick(item.value)}
          >
            {item?.name}
          </Button>
        ))}
        <Button
          className='transition-all h-8 w-full rounded bg-secondary-6 text-content hover:bg-[#ccc] hover:text-secondary-2 px-4 min-w-20 font-medium text-14'
          type='button'
          onClick={() => {
            if (user)
              if (moneyUser?.wallet === 'casino_slot' && casinoBalance < MIN_MOVE_MONEY_DEFAULT_VALUE) {
                setValue('amount', '0')
                setError('amount', {
                  type: 'validate',
                  message: `최소 이동금액은 ${formatCurrency(MIN_MOVE_MONEY_DEFAULT_VALUE)}원 입니다.`
                })

                return
              }

            if (moneyUser?.wallet === 'sports' && sportsBalance < MIN_MOVE_MONEY_DEFAULT_VALUE) {
              setValue('amount', '0')
              setError('amount', {
                type: 'validate',
                message: `최소 이동금액은 ${formatCurrency(MIN_MOVE_MONEY_DEFAULT_VALUE)}원 입니다.`
              })
              return
            }

            setValue(
              'amount',
              formatCurrency((moneyUser?.wallet === 'casino_slot' ? mMoney : mSportsMoney)?.toString() ?? '')
            )
          }}
        >
          Max
        </Button>
        <Button
          className='h-8 rounded w-full bg-secondary-2 hover:bg-primary text-primary hover:text-secondary-2 transition-all px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
          type='button'
          onClick={() => setValue('amount', '')}
        >
          <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
        </Button>
      </div>
      <div className='flex justify-center items-center'>
        <Button
          className='h-10 rounded bg-linearRed border-[2px] border-primary text-content w-fit px-4 min-w-[120px] font-medium text-14 my-5 mb-0'
          type='submit'
          disabled={loading}
          isLoading={loading}
        >
          머니이동 신청
        </Button>
      </div>
    </form>
  )
}
