import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'
import { generateKeyMatchVirtualSport } from 'src/libs/utils/virtual-sports.func'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'

export const useCartVirtualSportsStore = () => {
  const { cart } = useSelector((state: RootState) => state.virtual)
  function indexItemExistInCart(idx: string) {
    let indexExistingItem = -1
    for (let index = 0; index < cart.pick_list.length; index++) {
      const item = cart.pick_list[index]
      if (item.virtualData.idx === idx) {
        indexExistingItem = index
        break
      }
    }

    return indexExistingItem
  }

  function isAlreadyPicked(betId: string) {
    let isPicked = false
    for (let index = 0; index < cart.pick_list.length; index++) {
      const item = cart.pick_list[index]
      if (item.betId === betId) {
        isPicked = true
        break
      }
    }

    return isPicked
  }

  function getAllItemInMatch(virtualData: VirtualSportsDataItem) {
    const allItemInMatch = cart.pick_list.filter((item) => item.keyMatch === generateKeyMatchVirtualSport(virtualData))
    return allItemInMatch
  }

  return {
    cart,
    limitBetting: cart.bettingLimit,
    count: cart?.pick_list?.length,
    indexItemExistInCart,
    isAlreadyPicked,
    getAllItemInMatch
  }
}
