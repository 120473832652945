// import { Link, createSearchParams } from 'react-router-dom'
// import path from 'src/constants/path'
// import useQueryConfig from 'src/libs/hooks/useQueryConfig'

import { useMutation, useQuery } from '@apollo/client'
import { Select, TableColumnsType } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MoveMoneyForm from 'src/components/MoveMoneyForm'
import ReChargeWithDrawInfo from 'src/components/ReChargeWithDrawInfo'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import { DELETE_MONEY_INFO_BY_PERIOD_MUTATION } from 'src/libs/apis/graphql/mutations/money.mutation'
import { EXCHANGE_MONEY_CHECK_QUERY, MoneyInfoListQuery } from 'src/libs/apis/graphql/queries/money.query'
import { InfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { Wallet } from 'src/types/wallet.type'
import { CASINO_SLOT_WALLET, SPORT_WALLET } from '../WithDraw/WithDraw'

type DataMoneyType = {
  mProcessDate?: string
  miBankMoney?: number
  miNo?: number
  miRegDate?: string
  miStatus?: string
  miType?: string
  from?: string
  to?: string
  miTotalMoney?: number
  mMoney?: number
  mSportsMoney?: number
  __typename?: string
}

export interface GraphQLErrorWithValidations {
  message: string
  validations: Record<string, string[]>
}

export default function MoveMoney() {
  const [dataRecharge, setDataRecharge] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 })
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const [moneyUser, setMoneyUser] = useState({ value: '', render: false, wallet: '' })
  const [moveMoneyData, setMoveMoneyData] = useState({
    from: 'sports',
    to: 'casino_slot'
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [wallet, setWallet] = useState<Wallet>(SPORT_WALLET)
  console.log({ moneyUser })
  const user = useSelector((state: RootState) => state.auth.user)
  const { mBankOwner = '', mBankNumber = 0 } = user || {}
  const dispatch = useDispatch()

  const dataInfo = useQuery(InfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const { data, refetch, loading } = useQuery(MoneyInfoListQuery, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, type: 'exchange' },
    context: { apiName: 'money-info' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const { data: dataExchangeMoneyCheckQuery } = useQuery(EXCHANGE_MONEY_CHECK_QUERY, {
    context: {
      apiName: 'member'
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const [deleteMoney, DeleteMoneyInfoMutations] = useMutation(DELETE_MONEY_INFO_BY_PERIOD_MUTATION)

  const columns: TableColumnsType<DataMoneyType> = [
    { title: '이동시간', dataIndex: 'miRegDate', key: 'miRegDate', align: 'center' },
    // { title: '타입', dataIndex: 'field2', key: 'field2', width: '70px', align: 'center' },
    {
      title: '타입',
      dataIndex: 'miType',
      key: 'miType',
      width: '180px',
      align: 'center',
      render: (value, record) => {
        return record.from + ' > ' + record.to
      }
    },
    {
      title: '이동금액',
      dataIndex: 'miBankMoney',
      key: 'miBankMoney',
      // width: '80px',
      align: 'center',
      render: (value) => formatCurrency(value || 0) + ' 원'
    },
    {
      title: '이동 후 보유 스포츠캐쉬',
      dataIndex: 'mSportsMoney',
      key: 'mSportsMoney',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(value || 0) + ' 원'
      }
    },
    {
      title: '이동후 보유 카지노 캐쉬',
      dataIndex: 'mMoney',
      key: 'mMoney',
      // width: '80px',
      align: 'center',
      render: (value) => {
        return formatCurrency(value || 0) + ' 원'
      }
    }
    // { title: '처리시간', dataIndex: 'field6', key: 'field6', width: '150px', align: 'center' },
    // {
    //   title: '상태',
    //   dataIndex: 'miNo',
    //   key: 'miNo',
    //   width: '100px',
    //   align: 'center',
    //   render: (text, record) => (
    //     <Button
    //       className={` w-[70px] h-8 flex items-center justify-center rounded text-14 mx-auto ${record?.status === '대기' ? 'bg-primary-2' : 'bg-[#367FDE]'}`}
    //     >
    //       {record?.status}
    //     </Button>
    //   )
    // }
  ]

  // useEffect(() => {

  // }, [user])

  useEffect(() => {
    if (data && data.MoneyInfoListQuery && data.MoneyInfoListQuery?.data) {
      setDataRecharge(
        data.MoneyInfoListQuery.data.map((item: DataMoneyType, index: number) => ({
          ...item,
          // status: item.miStatus,
          key: index
        }))
      )
    }
  }, [data, mBankNumber, mBankOwner])

  useEffect(() => {
    if (data && data?.MoneyInfoListQuery && data?.MoneyInfoListQuery?.total) {
      setPagination({
        ...pagination,
        total: data.MoneyInfoListQuery.total
      })
    }
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleDeleteMoney = (id: Array<any>) => {
    deleteMoney({
      variables: {
        type: 'exchange',
        days: 3
      },
      context: { apiName: 'money-info' }
    })
  }

  const handleSelectMoney = (): DataMoneyType[] => {
    const selected = selectedRowKeys.map((index: number) => dataRecharge[index])

    return selected
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteChoose = () => {
    const data = handleSelectMoney()
    handleDeleteMoney(data)
    setSelectedRowKeys([])
  }

  const handleSelectChange = (e: string) => {
    console.log('e', e)
    setWallet(e as Wallet)
    setMoveMoneyData({
      from: e,
      to: e === 'sports' ? 'casino_slot' : 'sports'
    })
    setMoneyUser((prev) => ({
      ...prev,
      value: e === 'sports' ? user?.mSportsMoney?.toString() : user?.mMoney?.toString(),
      render: !prev.render,
      wallet: e
    }))
  }

  useEffect(() => {
    const errorText = DeleteMoneyInfoMutations?.error?.graphQLErrors[0]?.message
    if (errorText) {
      toast.error(errorText)
    }
  }, [DeleteMoneyInfoMutations?.error])

  useEffect(() => {
    const isSuccess = DeleteMoneyInfoMutations?.data
    if (isSuccess) {
      toast.success('초기화 성공')
    }
  }, [DeleteMoneyInfoMutations?.data])

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user?.mMoney, user?.mSportsMoney])

  return (
    <section className='mt-2 px-2' data-aos='fade-up'>
      <div className='px-2 flex flex-col xl:flex-row justify-between gap-8'>
        <div className='w-full'>
          <div className='py-5 w-full mt-6 bg-[#101216] rounded-md px-4'>
            <h3 className='text-white text-14 font-bold'>입금 지갑선택</h3>
            <div className='px-2 flex w-full gap-2 mt-5'>
              <Button
                className={`flex-1 flex flex-auto justify-between text-primary rounded-md p-3 items-center text-12 border border-[#616161] hover:border-primary ${moveMoneyData.from === SPORT_WALLET ? 'border-primary' : ''}`}
                onClick={() => {
                  setWallet(SPORT_WALLET)
                  setMoneyUser((prev) => ({
                    ...prev,
                    value: user?.mSportsMoney?.toString(),
                    render: !prev.render,
                    wallet: 'sports'
                  }))

                  setMoveMoneyData({
                    from: 'sports',
                    to: 'casino_slot'
                  })
                }}
              >
                <div className='flex items-center gap-1'>
                  <img
                    src='/assets/images/money/sportscash.png'
                    alt='스포츠캐쉬'
                    title='스포츠캐쉬'
                    width='50'
                    height='50'
                    className='w-6 h-auto'
                  />
                  <p>스포츠캐쉬</p>
                </div>
                <span>{formatCurrency(user?.mSportsMoney)} 원</span>
              </Button>
              <Button
                className={`flex-1 flex flex-auto justify-between text-primary rounded-md p-3 items-center text-12 border border-[#616161] hover:border-primary ${moveMoneyData.from === CASINO_SLOT_WALLET ? 'border-primary' : ''}`}
                onClick={() => {
                  setWallet(CASINO_SLOT_WALLET)
                  setMoneyUser((prev) => ({
                    ...prev,
                    value: user?.mMoney?.toString(),
                    render: !prev.render,
                    wallet: 'casino_slot'
                  }))
                  setMoveMoneyData({
                    from: 'casino_slot',
                    to: 'sports'
                  })
                }}
              >
                <div className='flex items-center gap-1'>
                  <img
                    src='/assets/images/money/casinocash.png'
                    alt='카지노캐쉬'
                    title='카지노캐쉬'
                    width='50'
                    height='50'
                    className='w-6 h-auto'
                  />
                  <p>카지노캐쉬</p>
                </div>
                <span>{formatCurrency(user?.mMoney)} 원</span>
              </Button>

              {/* <Button
                className={`flex flex-auto w-max justify-between text-black p-3 items-center text-[14px] border border-none ${moveMoneyData.from === SPORT_WALLET ? 'bg-linearRed' : 'bg-linearBlack hover:bg-linearBlackHover text-white'}`}
                onClick={() => {
                  setWallet(SPORT_WALLET)
                  setMoneyUser((prev) => ({
                    ...prev,
                    value: user?.mSportsMoney?.toString(),
                    render: !prev.render,
                    wallet: 'sports'
                  }))

                  setMoveMoneyData({
                    from: 'sports',
                    to: 'casino_slot'
                  })
                }}
              >
                <div className='flex items-center gap-1'>
                  <p>스포츠캐쉬</p>
                </div>
              </Button>
              <Button
                className={`flex flex-auto w-max justify-between text-content p-3 items-center text-[14px] border border-none ${moveMoneyData.from === CASINO_SLOT_WALLET ? 'bg-linearRed' : 'bg-linearBlack hover:bg-linearBlackHover text-white'}`}
                onClick={() => {
                  setWallet(CASINO_SLOT_WALLET)
                  setMoneyUser((prev) => ({
                    ...prev,
                    value: user?.mMoney?.toString(),
                    render: !prev.render,
                    wallet: 'casino_slot'
                  }))
                  setMoveMoneyData({
                    from: 'casino_slot',
                    to: 'sports'
                  })
                }}
              >
                <div className='flex items-center gap-1'>
                  <p>카지노캐쉬</p>
                </div>
              </Button> */}
            </div>
          </div>
          <div className='py-5 w-full mt-6 bg-[#101216] rounded-md px-4'>
            <h3 className='text-white text-14 font-bold'>캐쉬이동선택</h3>
            <div className='flex justify-between items-center gap-2 mt-5'>
              <Select
                defaultValue='sports'
                className='w-full md:w-[45%] h-11 rounded select-move-money'
                value={moveMoneyData?.from}
                onChange={(e) => handleSelectChange(e)}
                dropdownRender={(menu) => <div className='w-full'>{menu}</div>}
                options={[
                  {
                    value: 'sports',
                    label: (
                      <div className='flex items-center gap-2'>
                        <img
                          src='/assets/images/money/sports.png'
                          alt='스포츠캐쉬'
                          title='스포츠캐쉬'
                          width='50'
                          height='50'
                          className='w-8 h-auto'
                        />
                        <p className='font-bold'>스포츠캐쉬</p>
                      </div>
                    )
                  },
                  {
                    value: 'casino_slot',
                    label: (
                      <div className='flex items-center gap-2'>
                        <img
                          src='/assets/images/money/casino.png'
                          alt='카지노캐쉬'
                          title='카지노캐쉬'
                          width='50'
                          height='50'
                          className='w-8 h-auto'
                        />
                        <p className='font-bold'>카지노캐쉬</p>
                      </div>
                    )
                  }
                ]}
              />
              <img
                src='/assets/images/money/icon-arrow-right-yellow.png'
                alt='Change Money'
                title='Change Money'
                width='50'
                height='50'
                className='w-[18px] h-auto rotate-90 md:rotate-0'
              />
              <div className='w-full md:w-[45%] bg-linearRed text-content h-11 rounded flex flex-col justify-center items-end px-2'>
                <div className='flex items-center gap-2 w-full'>
                  <img
                    src={
                      moveMoneyData?.to === 'sports'
                        ? '/assets/images/money/sports-on.png'
                        : '/assets/images/money/casino-on.png'
                    }
                    alt={moveMoneyData?.to}
                    title={moveMoneyData?.to}
                    width='50'
                    height='50'
                    className='w-8 h-auto'
                  />
                  <p className='font-bold'>{moveMoneyData?.to === 'sports' ? '스포츠캐쉬' : '카지노캐쉬'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='py-5 w-full mt-6 bg-[#101216] rounded-md px-4'>
            <h3 className='text-white text-14 font-bold'>이동금액</h3>
            <div className='mt-5'>
              <MoveMoneyForm
                dataInfo={dataInfo?.data}
                moveMoneyData={moveMoneyData}
                mMoney={user?.mMoney}
                moneyUser={moneyUser}
                mSportsMoney={user?.mSportsMoney}
                wallet={wallet}
                dataExchangeMoneyCheckQuery={dataExchangeMoneyCheckQuery}
              />
            </div>
          </div>
        </div>
        <ReChargeWithDrawInfo
          title={dataInfo?.data?.InfoQuery?.recharge_config?.rc_exchange_rules}
          titleButton='송금규정을 확인하세요'
        />
      </div>
      <div className='flex justify-center items-center mt-[30px] mb-3'>
        <h2 className='text-white text-14 text-center font-bold pl-4 md:pl-[50px]'>머니이동내역</h2>
        {/* <Button
          className='h-8 rounded bg-[#FF3F40] w-fit px-4 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
          type='button'
          onClick={handleDeleteChoose}
        >
          <img src='/assets/images/icons/icon-delete.svg' alt='Delete' title='Delete' width='20' height='25' />
          3일전 내역 삭제
        </Button>  */}
      </div>
      <DataTable
        columns={columns}
        loading={loading}
        dataSource={dataRecharge}
        className='px-2'
        rowClassName={(record, index) => {
          return index % 2 === 0 ? 'even' : 'odd'
        }}
        pagination={{
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page, pageSize) => {
            if (loading) return
            setPagination({ ...pagination, current: page, pageSize })
            setSelectedRowKeys([])
            refetch()
          }
        }}
      />
    </section>
  )
}
