import cn from 'classnames'
import Loading from 'src/components/Loading'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { CategoriesVirtualSports } from './components/CategoriesVirtualSports'
import { EmptyDataSports } from './components/EmptyDataSports'
import { GroupVirtualSport } from './components/GroupVirtualSport'
import { IframeLiveStream } from './components/IframeLiveStream'
import { MatchHistory } from './components/MatchHistory'
import { TabViewMobile } from './components/TabViewMobile'
import { TabViewMobile as ETabViewMobile, TabShow, useVirtualSports } from './hooks/useVirtualSports'

export default function VirtualSports() {
  const {
    leaguesMenu,
    categoriesVirtualSports,
    activeLeague,
    isLoading,
    isLoadingCates,
    tabShow,
    tabViewInMobile,
    categorySelected,
    setTabShow,
    setActiveLeague,
    handleChangeTabViewInMobile,
    handleChangeCategory,
    resultVirtualSport,
    dataVirtualSportSearch
  } = useVirtualSports()

  const { isIP } = useViewport()

  const renderContent = () => {
    return dataVirtualSportSearch.length > 0 ? (
      <div className='grid grid-cols-1 laptop:grid-cols-[50%_50%] max-w-full gap-2'>
        {dataVirtualSportSearch.length === 0 ? (
          <EmptyDataSports />
        ) : (
          <GroupVirtualSport leaguesMenu={leaguesMenu} virtualSportsDataList={dataVirtualSportSearch} />
        )}
        {resultVirtualSport.length > 0 && (
          <MatchHistory
            categorySelected={categorySelected}
            dataShow={resultVirtualSport}
            tabShow={tabShow}
            setTabShow={(tabShow: TabShow) => {
              setTabShow(tabShow)
            }}
          />
        )}
      </div>
    ) : null
  }

  const renderContentMobile = () => {
    if (tabViewInMobile === ETabViewMobile.BOARD_BET) {
      return (
        <div className='grid grid-cols-1 laptop:grid-cols-[50%_50%] max-w-full gap-2'>
          {dataVirtualSportSearch.length === 0 ? (
            <EmptyDataSports />
          ) : (
            <GroupVirtualSport leaguesMenu={leaguesMenu} virtualSportsDataList={dataVirtualSportSearch} />
          )}
        </div>
      )
    }

    if (tabViewInMobile === ETabViewMobile.HISTORY) {
      return (
        resultVirtualSport.length > 0 && (
          <MatchHistory
            categorySelected={categorySelected}
            dataShow={resultVirtualSport}
            tabShow={TabShow.RESULT}
            setTabShow={() => {}}
          />
        )
      )
    }
    if (tabViewInMobile === ETabViewMobile.HISTORY_BET) {
      return (
        resultVirtualSport.length > 0 && (
          <MatchHistory
            categorySelected={categorySelected}
            dataShow={resultVirtualSport}
            tabShow={TabShow.HISTORY}
            setTabShow={() => {}}
          />
        )
      )
    }
  }

  return (
    <div className='px-2 lg:px-4 laptop:px-8 space-y-4 pb-8 overflow-hidden'>
      {isLoading || isLoadingCates ? (
        <div className='flex items-center justify-center h-screen w-screen overflow-hidden'>
          <Loading />
        </div>
      ) : dataVirtualSportSearch.length === 0 ? (
        <>
          <CategoriesVirtualSports
            categorySelected={categorySelected}
            handleChangeCategory={handleChangeCategory}
            isLoading={isLoadingCates}
            categoriesVirtualSports={categoriesVirtualSports}
          />
          <div className='w-full flex items-center justify-center'>
            <EmptyDataSports />
          </div>
        </>
      ) : (
        <>
          <CategoriesVirtualSports
            categorySelected={categorySelected}
            handleChangeCategory={handleChangeCategory}
            isLoading={isLoadingCates}
            categoriesVirtualSports={categoriesVirtualSports}
          />
          {/* begin live stream view */}
          <div className='grid grid-cols-[280px_1fr] gap-0 laptop:gap-4 2xl:gap-0 items-center'>
            <div className='hidden 2xl:block max-h-[360px] overflow-y-auto scrollbar-hide'>
              <div className='flex flex-col gap-2'>
                {leaguesMenu.slice(1).map((league) => {
                  return (
                    <button
                      onClick={(e) => {
                        setActiveLeague(league)
                        e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                      }}
                      key={league}
                      className={cn(
                        'h-[120px] flex flex-col items-center justify-center hover:bg-primary hover:text-black duration-150',
                        {
                          'bg-primary text-black': activeLeague === league,
                          'bg-black text-white': activeLeague !== league
                        }
                      )}
                    >
                      <span className='text-16 font-bold'>{league}</span>
                    </button>
                  )
                })}
              </div>
            </div>
            <div className='max-w-full col-span-2 2xl:col-span-1'>
              <div className='flex items-center justify-center w-full h-[250px] scale-[.65] md:scale-100 md:h-full'>
                <IframeLiveStream title={activeLeague} />
              </div>
            </div>
            <div className='col-span-2 2xl:hidden'>
              <div className='max-w-full overflow-x-auto scrollbar-hide'>
                <div className='flex items-center gap-2'>
                  {leaguesMenu.slice(1).map((league) => {
                    return (
                      <button
                        onClick={(e) => {
                          setActiveLeague(league)
                          e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }}
                        key={league}
                        className={cn('flex flex-col items-center justify-center duration-150 relative min-w-max', {
                          'text-primary': activeLeague === league,
                          'text-white': activeLeague !== league
                        })}
                      >
                        <span className='text-16 font-bold'>{league}</span>
                        {activeLeague === league && <div className='absolute top-full w-full h-px bg-primary'></div>}
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* end live stream view */}
          <TabViewMobile isShow={isIP} handleChangeTabShow={handleChangeTabViewInMobile} tabActive={tabViewInMobile} />
          {!isIP ? renderContent() : renderContentMobile()}
        </>
      )}
    </div>
  )
}
