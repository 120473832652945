import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

type Props = {
  children: React.ReactNode
  open: boolean
  onCancel: () => void
}

const ModalRoulette = ({ open, onCancel, children }: Props) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      destroyOnClose
      rootClassName='spin-wheel'
      footer={null}
      centered
      closeIcon={null}
    >
      {children}
    </Modal>
  )
}

export default ModalRoulette
