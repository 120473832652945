import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { GET_VIRTUAL_SPORTS_CATEGORY } from 'src/libs/apis/graphql/queries/sport.query'
import { GET_HISTORY_VIRTUAL_SPORTS } from 'src/libs/apis/graphql/queries/virtual-sport.query'
import { client } from 'src/libs/utils/apollo'
import { VirtualSportsGroupByIdx } from 'src/types/virtual-sport'
import { isGreyhoundsOrHorse } from '../hooks/useVirtualSports'
import { VirtualSportsOptionList } from './VirtualSportsOptionList'

type Props = {
  virtualSportsGroupData: VirtualSportsGroupByIdx
  isFirstItem: boolean
}

export const VirtualSportsMatch = ({ isFirstItem, virtualSportsGroupData }: Props) => {
  const { data: categoryData } = useQuery(GET_VIRTUAL_SPORTS_CATEGORY, {
    context: {
      apiName: 'virtual-sports'
    },
    notifyOnNetworkStatusChange: true
  })

  const limitTime = useMemo(() => {
    return (
      categoryData?.VirtualSportsCategory?.find((c) => c.name === window.location.pathname.split('/VirtualSports/')[1])
        ?.time_block_bet || 0
    )
  }, [categoryData])

  const [isCollapse, setIsCollapse] = useState(
    isFirstItem ? false : virtualSportsGroupData.data.length <= 3 ? false : true
  )

  const [currentTime, setCurrentTime] = useState(0)

  useEffect(() => {
    if (virtualSportsGroupData.data[0]?.time_left >= 0) {
      setCurrentTime(virtualSportsGroupData.data[0]?.time_left - Math.floor(Date.now() / 1000))
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setCurrentTime(virtualSportsGroupData.data[0]?.time_left - Math.floor(Date.now() / 1000))
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [virtualSportsGroupData.data])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((prev) => {
        if (prev - 1 < 0) return prev
        if (prev - 1 === 0) {
          client.refetchQueries({
            include: ['ActiveBetQuery', 'FinishedBetQuery', GET_HISTORY_VIRTUAL_SPORTS]
          })
          return prev - 1
        }
        return prev - 1
      })
    }, 1000)

    // if (currentTime === 0) {
    //   client.refetchQueries({
    //     include: ['ActiveBetQuery', 'FinishedBetQuery', GET_HISTORY_VIRTUAL_SPORTS]
    //   })
    // }

    return () => clearInterval(interval) // Cleanup the interval on component unmount
  }, [currentTime])

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
  }

  const formatedTime = useMemo(() => formatTime(currentTime), [currentTime])
  const isLocked = currentTime <= limitTime

  return (
    <div
      className={cn(
        'flex flex-col gap-2 bg-secondary-2 rounded-ss-md rounded-se-md border-t-2 border-primary py-2 group relative',
        {
          'is-collapse': isCollapse,
          hidden: currentTime <= 0
        }
      )}
    >
      <button
        onClick={() => {
          if (isLocked) return

          setIsCollapse((isCollapse) => !isCollapse)
        }}
        className='flex items-center justify-between px-3'
      >
        <div className='flex items-center gap-1'>
          <img src='/assets/images/virtual/Bet365.png' alt='logo 365' className='size-[25px]' />
          <span className='text-primary text-12'>{`${virtualSportsGroupData.provider} ${virtualSportsGroupData.title} - ${virtualSportsGroupData.titleEn}`}</span>
        </div>
        <img
          src='/assets/images/icons/icon-arrow-bottom-yellow.svg'
          alt='arrow icon'
          className='group-[.is-collapse]:rotate-180 duration-300'
        />
      </button>
      <div className='flex flex-col gap-2 duration-300'>
        <div
          className={cn('flex items-center justify-between pr-3', {
            'bg-black': isGreyhoundsOrHorse.includes(
              location.pathname.split('/')[location.pathname.split('/').length - 1]
            )
          })}
        >
          <span
            className={cn('py-1 px-3 text-12 rounded', {
              'bg-black-4': !isGreyhoundsOrHorse.includes(
                location.pathname.split('/')[location.pathname.split('/').length - 1]
              ),
              'bg-transparent': isGreyhoundsOrHorse.includes(
                location.pathname.split('/')[location.pathname.split('/').length - 1]
              )
            })}
          >
            경기 시간: {virtualSportsGroupData.date}
          </span>
          <span className='py-1 px-3 text-10 rounded border border-primary text-primary'>배팅마감: {formatedTime}</span>
        </div>
        <div className='max-h-[2000px] group-[.is-collapse]:max-h-[0] duration-300 overflow-hidden'>
          <VirtualSportsOptionList dataList={virtualSportsGroupData.data} />
        </div>
      </div>

      {isLocked && (
        <div className='absolute w-full h-full z-[1] bg-[rgba(0,0,0,0.6)] flex justify-center items-center flex-col gap-2 rounded-md'>
          <img src='/assets/images/recharge/block-icon.png' alt='block' title='block' width='50' height='50' />
          {/* <p className='text-white'>롤링 완료 후 출금가능합니다.</p> */}
        </div>
      )}
    </div>
  )
}
