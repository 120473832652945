import { TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'

type Props = {
  dataShow: VirtualSportsDataItem[]
}

export const TableHistoryGreyhoundsOrHorse = ({ dataShow, categorySelected }: Props) => {
  const columns: TableColumnsType<VirtualSportsDataItem> = [
    {
      title: '회차',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      className: 'w-[5%]',
      render: (_, record) => {
        return record.date.split(' ')[1].split(':').slice(0, -1).join(':')
      }
    },
    {
      title: '경기장',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      className: 'w-[35%]',
      render: (_, record) => {
        return record.title
      }
    },
    {
      title(props) {
        return (
          <div className='w-full text-center'>
            <span>{categorySelected === 'Horse' ? '우승마' : '우승견'}</span>
          </div>
        )
      },
      dataIndex: 'home',
      key: 'home',
      className: 'w-[60%]',
      align: 'center',
      render: (_, record) => {
        return `${record.away}. ${record.home}`
      }
    }
  ]

  return (
    <DataTable
      columns={columns}
      dataSource={dataShow.slice(0, 15)}
      rowClassName={(record, index) => {
        return index % 2 === 0 ? 'even' : 'odd'
      }}
      className='pointer-events-none'
    />
  )
}
