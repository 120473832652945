import { gql } from '@apollo/client'

const getMember = gql`
  query MemberQuery {
    MemberQuery {
      mID
      mNo
      mNick
      mRealName
      mLevel
      mPhone
      mStatus
      mPoint
      mMoney
      mSportsMoney
      mBankName
      mBankNumber
      mBankOwner
      mMemberID
    }
  }
`
const HAS_MEMBER = gql`
  query HasMember($member_id: String, $member_nick: String, $member_invite: String) {
    hasMember(member_id: $member_id, member_nick: $member_nick, member_invite: $member_invite)
  }
`

export { HAS_MEMBER, getMember }
