import { useEffect, useRef } from 'react'
import SideRightMiniGame from 'src/components/SideRightMiniGame'

/* eslint-disable jsx-a11y/iframe-has-title */
export default function TokenPowerBall() {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleResize = () => {
      const iframe = iframeRef.current
      if (iframe) {
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const iframeWidth = iframe.offsetWidth
        const iframeHeight = iframe.offsetHeight
        const scaleX = screenWidth / iframeWidth
        const scaleY = screenHeight / iframeHeight
        const scale = Math.min(scaleX, scaleY)
        iframe.style.transform = `scale(${scale})`
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className='flex flex-col relative'>
      <iframe
        ref={iframeRef}
        src='https://tgame365.nutv365.com/powerball/'
        width='830'
        height='415'
        scrolling='no'
        frameBorder='0'
        id='myframe'
        className='md:!scale-100 md:mx-auto origin-top-left'
      ></iframe>
      <div className='absolute top-[calc(100%-200px)] w-full md:relative md:top-[unset]'>
        <SideRightMiniGame />
      </div>
    </div>
  )
}
