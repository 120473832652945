/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@apollo/client'
import CouponList from '../CouponList'
import cn from 'classnames'
import { GET_WEEKLY_BONUS } from 'src/libs/apis/graphql/queries/coupon.query'
import { toast } from 'react-toastify'
import { USE_BONUS_MUTATION } from 'src/libs/apis/graphql/mutations/coupon'

const PointSection = ({
  title,
  currentPoints,
  previousPoints,
  buttonText,
  updateText,
  currentPointValue,
  previousPointValue,
  isRedeemable,
  handleRedeemWeeklyBonus
}: {
  title: string
  currentPoints: string
  previousPoints: string
  buttonText: string
  updateText: string
  currentPointValue: number
  previousPointValue: number
  isRedeemable: boolean
  handleRedeemWeeklyBonus: () => void
}) => (
  <div className='px-2'>
    <div className='mb-[16px]'>{title}</div>
    <div className='text-center p-[16px] px-6 rounded-md bg-gray-900'>
      <div>
        <div>{currentPoints}</div>
        <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-primary bg-gray-800'>
          {Math.floor(currentPointValue).toLocaleString()} 원
        </div>
        <div>{previousPoints}</div>
        <div className='rounded-md p-[16px] mt-[16px] mb-[16px] text-primary bg-gray-800'>
          {Math.floor(previousPointValue).toLocaleString()} 원
        </div>
        <button
          onClick={handleRedeemWeeklyBonus}
          className={cn('rounded-md w-full p-[16px] text-white bg-blue-500 cursor-pointer', {
            'bg-gray-500 !cursor-not-allowed opacity-50': !isRedeemable
          })}
          disabled={!isRedeemable}
        >
          {buttonText}
        </button>
        <div className='text-xs mt-8 mb-[16px]'>{updateText}</div>
      </div>
    </div>
  </div>
)

export default function AvailableCoupon() {
  const { data: weeklyBonusData } = useQuery(GET_WEEKLY_BONUS, {
    context: {
      apiName: 'member'
    },
    fetchPolicy: 'network-only'
  })

  const { losing_bonus, payback_bonus } = weeklyBonusData?.WeeklyBonusQuery || {}
  const isLosingBonusRedeemable = losing_bonus?.is_redeemable
  const isPaybackBonusRedeemable = payback_bonus?.is_redeemable

  const [handleRedeemWeeklyBonusMutation] = useMutation(USE_BONUS_MUTATION, {
    context: {
      apiName: 'member'
    },
    onCompleted: (data) => {
      if (data.RedeemBonusMutation.success) {
        toast.success(data.RedeemBonusMutation.message)
      } else {
        toast.error(data.RedeemBonusMutation.message)
      }
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const handleRedeemWeeklyBonus = (weeklyBonusId: string) => {
    if (weeklyBonusId === 'payback_bonus' && !isPaybackBonusRedeemable) return
    if (weeklyBonusId === 'losing_bonus' && !isLosingBonusRedeemable) return

    handleRedeemWeeklyBonusMutation({
      variables: {
        type: weeklyBonusId
      },
      refetchQueries: [GET_WEEKLY_BONUS]
    })
  }

  return (
    <div>
      <CouponList />
      <div className='battle__type mt-5 md:mt-0'>
        <div className='flex'>
          <PointSection
            title='낙첨포인트'
            currentPoints='금주 예상 낙첨 포인트'
            previousPoints='지난주 낙첨 포인트'
            buttonText='낙첨포인트 받기'
            updateText='*누적금액 및 누적 포인트는 1시간 마다 업데이트됩니다.'
            currentPointValue={losing_bonus?.bonus_this_week || 0}
            previousPointValue={losing_bonus?.bonus_last_week || 0}
            isRedeemable={isLosingBonusRedeemable}
            handleRedeemWeeklyBonus={() => handleRedeemWeeklyBonus('losing_bonus')}
          />
          <PointSection
            title='페이백포인트'
            currentPoints='금주 예상 페이백 포인트'
            previousPoints='지난주 페이백 포인트'
            buttonText='페이백포인트 받기'
            updateText='*누적금액 및 페이백 포인트는 24시간 마다 업데이트됩니다.'
            currentPointValue={payback_bonus?.bonus_this_week || 0}
            previousPointValue={payback_bonus?.bonus_last_week || 0}
            isRedeemable={isPaybackBonusRedeemable}
            handleRedeemWeeklyBonus={() => handleRedeemWeeklyBonus('payback_bonus')}
          />
        </div>
      </div>
    </div>
  )
}
