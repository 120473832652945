import { ConfigProvider, Pagination, PaginationProps } from 'antd'
import React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.ts'

export const PaginationCustom = (props: PaginationProps) => {
  const fullConfig = resolveConfig(tailwindConfig)
  return (
    <ConfigProvider
      theme={{
        components: {
          Pagination: {
            itemActiveBg: fullConfig.theme.colors.primary.DEFAULT,
            colorPrimary: fullConfig.theme.colors.content,
            colorPrimaryBorder: fullConfig.theme.colors.primary.DEFAULT,
            colorPrimaryHover: fullConfig.theme.colors.content,
            colorBgTextHover: fullConfig.theme.colors.primary.DEFAULT,
            colorText: fullConfig.theme.colors.content
          }
        }
      }}
    >
      <Pagination {...props} />
    </ConfigProvider>
  )
}
