import { gql } from '@apollo/client'

export const BET_QUERY = gql`
  query BetQuery($game_type: String, $limit: Int, $offset: Int) {
    BetQuery(game_type: $game_type, limit: $limit, offset: $offset) {
      length
      total
      list {
        id
        betDate
        status
        cashBet
        rateBet
        rateBonus
      }
      detailList {
        betId
        status
        fixtureId
        selectRate
        baseLine
        startDate
        selectIdx
        selPickName
        homeTeamName
        awayTeamName
        marketName
        gameType
      }
      bettingSlips {
        mem_idx
        game_no
        reg_date
        result
        betting_cnt
        confirmBetting
        detailInfos {
          betId
          status
          fixtureId
          selectRate
          baseLine
          startDate
          selectIdx
          selPickName
          homeTeamName
          awayTeamName
          marketName
          gameType
        }
      }
    }
  }
`

export const GET_FOLDER_BONUS_QUERY = gql`
  query FolderBonusQuery {
    FolderBonusQuery {
      folders_bonus {
        bonus_folder_text
        bonus_folder_key
        bonus_folder_rate
        bet_list_length
      }
      text_folders_bonus
      min_rate
      is_use_bonus_folders
    }
  }
`

export const GET_LIMIT_SPORTS = gql`
  query BettingLimitsSportQuery($count_bet: Int, $type: String) {
    BettingLimitsSportQuery(count_bet: $count_bet, type: $type) {
      min_bet_amount
      max_bet_amount
      max_bet_payout
      max_payout
      percent_distribution
    }
  }
`

export const GET_QUERY_RULES = gql`
  query ValidSelectOptionQuery {
    ValidSelectOptionQuery {
      sports_code
      list_limit {
        game_id
        list_allow
      }
      is_can_bet_draw
    }
  }
`

export const GET_BATTLE_HISTORY_SPORTS = gql`
  query BetHistoryQuery($game_type: String, $limit: Int, $offset: Int) {
    BetHistoryQuery(game_type: $game_type, limit: $limit, offset: $offset) {
      total
      per_page
      current_page
      to
      last_page
      has_more_pages
      from
      data {
        betAmount
        date
        rate
        estimateAmount
        winAmount
        isCancelable
        settlementStatus
        betList {
          status
          selectRate
          startDate
          homeTeamName
          awayTeamName
          marketName
          homeScore
          awayScore
          leagueName
          teamAndRuleBet
          selectedLine
          selectBetTeam
          labelBetStatus
          fixtureStatus
        }
        now
        timeLeftCancelable
        timeLeftDisable
        _id
        bonusFolder
        bonusFolderRate
        isCancelledBonusFolder
        enableAutoCancel
      }
    }
  }
`

export const GET_TIME_WAITING_BET_LIVE_SPORTS = gql`
  query TimeWaitBettingLiveSportsQuery {
    TimeWaitBettingLiveSportsQuery {
      sports_idx
      time
    }
  }
`

export const BEFORE_BET_LIVE_SPORTS = gql`
  mutation TimeWaitBettingLiveSportsMutation($bet_cash: Int, $game_type: Int, $pick_list: [PickInputType]!) {
    TimeWaitBettingLiveSportsMutation(bet_cash: $bet_cash, game_type: $game_type, pick_list: $pick_list) {
      sports_idx
      time
    }
  }
`

export const GET_VIRTUAL_SPORTS_CATEGORY = gql`
  query VirtualSportsCategory {
    VirtualSportsCategory {
      name
      name_ko
      color
      time_block_bet
    }
  }
`

export const GET_EXCHANGE_SPORTS_QUERY = gql`
  query ExchangeRateInfoQuery($type: String) {
    ExchangeRateInfoQuery(type: $type) {
      sports_code
      rate_deduct
    }
  }
`
