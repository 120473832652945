import { useQuery } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import EventCard from 'src/components/Event/EventCard'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { NoticeType, useEventQuery } from 'src/libs/hooks/useNotice'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import './Event.scss'

type FILTER_SIDE_TYPE = 'client' | 'server'
const FILTER_SIDE: FILTER_SIDE_TYPE = 'server'
const DEFAULT_LIMIT = 9
const DEFAULT_OFFSET = 1
export default function Event() {
  const navigate = useNavigate()
  const [queryEvent, setQueryEvent] = useState({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, total: 0 })
  const [tab, setTab] = useState<string>('notice')
  const [eventDetail, setEventDetail] = useState<NoticeType | undefined>(undefined)
  const [listProvider, setListProvider] = useState<EventDetailType[]>()
  const dispatch = useDispatch()
  const buttonRefEvent = useRef<HTMLButtonElement>(null)
  const {
    data: dataEvent,
    loading,
    refetch
  } = useEventQuery({
    filter: FILTER_SIDE === 'server' ? tab : 'all',
    page: queryEvent.offset ?? DEFAULT_OFFSET,
    limit: queryEvent.limit ?? DEFAULT_LIMIT
  })
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })

  const searchParams = new URLSearchParams(window.location.search)

  const { categoryNotice, noticeType } = dataEvent || {}
  const { data: eventList } = noticeType || {}

  const handleOnClick = async (eventId: number) => {
    searchParams.set('event', eventId.toString())
    navigate({ search: searchParams.toString() })
  }

  const changeTab = (newCategoryId: string) => () => {
    searchParams.set('category', newCategoryId)
    searchParams.delete('event')
    navigate({ search: searchParams.toString() })
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  useEffect(() => {
    const category = searchParams.get('category')
    const event = searchParams.get('event')

    if (category) {
      setTab(category)
    }

    if (event) {
      setEventDetail(eventList?.find((item) => item?.ntNo === Number(event)))
    }

    if (!event && eventDetail) {
      setEventDetail(undefined)
    }
  }, [eventDetail, eventList, searchParams])

  useEffect(() => {
    if (searchParams.get('event')) {
      const eventId = Number(searchParams.get('event'))

      if (eventId) {
        const [event] = eventList?.filter((item) => item?.ntNo === eventId) ?? []
        if (event) {
          setEventDetail(event)
        }
      }
    }
  }, [eventList, searchParams])

  const loadMoreDataEvent = useCallback(() => {
    setQueryEvent({ ...queryEvent, limit: queryEvent.limit + 12 })

    refetch()
  }, [queryEvent])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) loadMoreDataEvent()
      })
    })

    if (buttonRefEvent.current) observer.observe(buttonRefEvent.current)

    return () => {
      if (buttonRefEvent.current) observer.unobserve(buttonRefEvent.current)
    }
  }, [loadMoreDataEvent, eventList])

  useEffect(() => {
    if (eventList) {
      const filteredList = eventList.filter((event): event is EventDetailType => event !== undefined)
      setListProvider(filteredList)
    }
  }, [eventList])

  return (
    <section className='mt-2 md:p-4' data-aos='fade-up'>
      <div className='p-2.5 border border-[#2f2f2f] pb-0'>
        <div className='w-full flex flex-col p-1' data-aos='fade-up'>
          <div className='gap-4 md:gap-0 flex-wrap md:flex-nowrap flex justify-center md:justify-between mt-4'>
            {categoryNotice?.map((item) => {
              const { category_id, category_name } = item || { category_id: '', category_name: '' }
              return (
                <button
                  key={category_id}
                  className={`md:w-full text-center md:border-b md:border-primary h-8 lg:h-[50px] flex justify-center items-center font-bold ${tab === category_id ? 'md:border-b-transparent md:border-2 md:border-primary text-primary' : ''}`}
                  onClick={changeTab(category_id)}
                >
                  {category_name}
                </button>
              )
            })}
          </div>
          <div className='flex flex-col-reverse gap-3 mt-4'>
            <div className='w-full grid grid-cols-1 md:grid-cols-3 md-up:grid-cols-3 gap-4'>
              {listProvider?.map((item, index: number) => (
                <EventCard
                  key={index}
                  index={index + 1}
                  id={item?.ntNo ?? undefined}
                  banner={item?.ntLogo ?? ''}
                  title={Number(item?.ntTitle) ?? ''}
                  titleEvent={item?.ntTitle}
                  handleOnClick={(e) => handleOnClick(e)}
                />
              ))}
              <ModalEventDetail open={!!eventDetail} eventDetail={eventDetail} />
              {Math.ceil(Number(noticeType?.total) / Number(noticeType?.per_page)) > 1 ||
              Math.ceil(Number(noticeType?.total) / Number(noticeType?.per_page)) > 1 ? (
                <button onClick={loadMoreDataEvent} ref={buttonRefEvent}></button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import { Modal, ModalProps } from 'antd'
import { EventDetailType } from 'src/types/event.type'
import { IoClose } from 'react-icons/io5'

type ModalEventDetailProps = ModalProps & {
  eventDetail?: NoticeType
}

export const ModalEventDetail = (props: ModalEventDetailProps) => {
  const { eventDetail, ...restProps } = props
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const handleCloseModal = () => {
    setShowModal(false)
    navigate({ search: '' })
  }

  useEffect(() => {
    setShowModal(!!eventDetail)
  }, [eventDetail])

  return (
    <Modal
      footer={null}
      centered
      className='modal-event-detail'
      classNames={{
        body: 'max-h-[90dvh] mt-[24px] sidebar__overflow overflow-y-auto',
        content: '!bg-black text-white'
      }}
      closeIcon={
        <button className='text-white' onClick={() => setShowModal(false)}>
          <IoClose />
        </button>
      }
      open={showModal}
      onCancel={handleCloseModal}
      {...restProps}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: eventDetail?.ntContent ?? ''
        }}
      />
    </Modal>
  )
}
