import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { GET_VIRTUAL_SPORTS_CATEGORY } from 'src/libs/apis/graphql/queries/sport.query'
import {
  GET_QUERY_RULES_VIRTUAL_SPORTS,
  GET_RESULT_VIRTUAL_SPORTS,
  GET_VIRTUAL_SPORTS
} from 'src/libs/apis/graphql/queries/virtual-sport.query'
import { useCartVirtualSportsStore } from 'src/libs/hooks/store/useCartVirtualSportsStore'
import { useVirtualSportSearchStore } from 'src/libs/hooks/store/useVirtualSportSearchStore'
import {
  addDataToVirtualSearchList,
  resetDataVirtualSportSearch,
  setRulesVirtualSports
} from 'src/libs/stores/virtual-sport-search.reducer'
import { removeAllCartVirtualSport, removeItemFromCartVirtualSport } from 'src/libs/stores/virtual-sport.reducer'
import {
  ActiveBetQuery,
  FinishedBetQuery,
  ValidSelectOptionVirtualSportsQuery,
  VirtualSportsDataItem
} from 'src/types/virtual-sport.api'

export enum TabShow {
  RESULT,
  HISTORY
}

export enum TabViewMobile {
  BOARD_BET,
  HISTORY,
  HISTORY_BET
}
export const isSoccerOrBaseball = ['Soccer', 'Baseball']
export const isBasketball = ['Basketball']
export const isGreyhoundsOrHorse = ['Greyhounds', 'Horse']

export const useVirtualSports = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cart } = useCartVirtualSportsStore()
  const { dataVirtualSportSearch } = useVirtualSportSearchStore()
  const [tabViewInMobile, setTabViewInMobile] = useState<TabViewMobile>(TabViewMobile.BOARD_BET)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeLeague, setActiveLeague] = useState<string>()
  const [tabShow, setTabShow] = useState<TabShow>(TabShow.RESULT)
  const [resultVirtualSport, setResultVirtualSport] = useState<VirtualSportsDataItem[]>([])
  const pathNameArr = location.pathname.split('/')
  const category = pathNameArr[pathNameArr.length - 1]
  const [getVirtualSports] = useLazyQuery<ActiveBetQuery>(GET_VIRTUAL_SPORTS, {
    context: {
      apiName: 'virtual-sports'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.ActiveBetQuery.length) {
        dispatch(
          addDataToVirtualSearchList({
            virtualSportList: data.ActiveBetQuery
          })
        )
        if (cart.pick_list.length) {
          const listBetId = data.ActiveBetQuery.map((item) => {
            return `${item.betid1}|${item.betid2}|${item.betid3}`.split('|')
          }).flat()
          cart.pick_list.forEach((cartItem) => {
            if (!listBetId.includes(cartItem.betId)) {
              dispatch(removeItemFromCartVirtualSport({ betId: cartItem.betId }))
            }
          })
        }
      }
    }
  })

  const [getResultVirtualSports] = useLazyQuery<FinishedBetQuery>(GET_RESULT_VIRTUAL_SPORTS, {
    context: {
      apiName: 'virtual-sports'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const [getRulesSports] = useLazyQuery<ValidSelectOptionVirtualSportsQuery>(GET_QUERY_RULES_VIRTUAL_SPORTS, {
    context: { apiName: 'virtual-sports' },
    onCompleted: (data) => {
      if (data && data.ValidSelectOptionQuery.length) {
        dispatch(setRulesVirtualSports(data.ValidSelectOptionQuery))
      }
    },
    notifyOnNetworkStatusChange: true
  })

  // const [getEnableMultiMatch] = useLazyQuery<ValidOptionAxisBetQuery>(GET_ENABLE_MULTI_MATCH_VIRTUAL_SPORTS, {
  //   context: { apiName: 'virtual-sports' },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data) => {
  //     dispatch(setEnableMultiMatchVirtualSports(data.ValidOptionAxisBetQuery.enable))
  //   }
  // })

  const { data, loading: isLoadingCates } = useQuery(GET_VIRTUAL_SPORTS_CATEGORY, {
    context: {
      apiName: 'virtual-sports'
    },
    notifyOnNetworkStatusChange: true
  })

  const categoriesVirtualSports = data?.VirtualSportsCategory || []

  const leaguesMenu = ['모든 리그', ...new Set(dataVirtualSportSearch.map((item) => item.title))]

  const handleChangeCategory = (category: string) => {
    navigate(`${path.virtual_sports}/${category}`)
    dispatch(removeAllCartVirtualSport())
  }

  const handleChangeTabViewInMobile = (view: TabViewMobile) => {
    setTabViewInMobile(view)
  }

  useEffect(() => {
    const fetchVirtualSports = async (category?: string) => {
      setIsLoading(true)
      try {
        // const res = await sportsApi.getVirtualSports({ category: category })
        // will move to store
        const { data } = await getVirtualSports({
          variables: {
            cate: category
          }
        })

        await getResultVirtualSports({
          variables: {
            cate: category
          },
          onCompleted(data) {
            setResultVirtualSport(data?.FinishedBetQuery || [])
          }
        })

        const res = data?.ActiveBetQuery || []

        dispatch(
          addDataToVirtualSearchList({
            virtualSportList: res
          })
        )
        res.length > 1 && setActiveLeague(res[1].title || '')
        setTabShow(TabShow.RESULT)
        setTabViewInMobile(TabViewMobile.BOARD_BET)
      } catch (error) {
        console.log('eror', error)
        toast.error('실패. 나중에 다시 시도 해주십시오.')
        dispatch(
          addDataToVirtualSearchList({
            virtualSportList: []
          })
        )
      } finally {
        setIsLoading(false)
      }
    }
    // getEnableMultiMatch({
    //   variables: {
    //     cate: category
    //   }
    // })

    fetchVirtualSports(category)
  }, [location.pathname])

  // useEffect(() => {
  //   // fetch api history betting or api history match depend on tabShow
  // }, [tabShow])
  useEffect(() => {
    getRulesSports()
    return () => {
      dispatch(resetDataVirtualSportSearch())
    }
  }, [])
  return {
    categoriesVirtualSports,
    dataVirtualSportSearch,
    leaguesMenu: leaguesMenu,
    isLoading,
    isLoadingCates,
    tabShow,
    categorySelected: category,
    activeLeague: activeLeague || leaguesMenu[1],
    tabViewInMobile,
    handleChangeCategory,
    handleChangeTabViewInMobile,
    setActiveLeague,
    setTabShow,
    setTabViewInMobile,
    resultVirtualSport
  }
}
