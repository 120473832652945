import { Table, TableColumnsType } from 'antd'
import { useState } from 'react'
import Button from 'src/components/ui/Button'
import './friend.scss'
const useColumnDefault = () => {
  const columnStatusMoney: TableColumnsType<any> = [
    {
      title: '지인 아이디',
      dataIndex: 'field1',
      key: 'field1',
      width: '160px',
      align: 'center'
    },
    {
      title: '닉네임',
      dataIndex: 'field2',
      key: 'field2',
      width: '160px',
      align: 'center'
    },
    {
      title: '가입일',
      dataIndex: 'field3',
      width: '160px',
      align: 'center'
    },
    {
      title: '입금수',
      dataIndex: 'field4',
      key: 'field4',
      width: '160px',
      align: 'center'
    },
    {
      title: '입금액',
      dataIndex: 'field5',
      key: 'field5',
      width: '355px',
      align: 'center'
    },

    {
      title: '1번 보너스지급 현황',
      dataIndex: 'field5',
      key: 'field5',
      width: '160px',
      align: 'center'
    },

    {
      title: '2번보너스지급현황',
      dataIndex: 'field6',
      key: 'field6',
      width: '160px',
      align: 'center'
    }
  ]

  const columns2: TableColumnsType<any> = [
    {
      title: '기간',
      dataIndex: 'field1',
      key: 'field1',
      align: 'center',
      className: 'flex-shrink-0'
    },
    {
      title: '입금총액',
      dataIndex: 'field2',
      key: 'field2',
      width: '160px',
      align: 'center'
    },
    {
      title: '승률(입금-출금)',
      dataIndex: 'field3',
      width: '160px',
      align: 'center'
    },
    {
      title: '지급액',
      dataIndex: 'field4',
      key: 'field4',
      width: '160px',
      align: 'center'
    },
    {
      title: '상태',
      dataIndex: 'field5',
      key: 'field5',
      width: '160px',
      align: 'center'
    }
  ]
  return {
    columnStatusMoney,
    columns2
  }
}
const Friend = () => {
  const { columnStatusMoney, columns2 } = useColumnDefault()
  const [tab, setTab] = useState<string>('tab1')
  return (
    <div className='flex flex-col gap-5 min-h-[90dvh]'>
      <div className='w-full lg:min-h-[300px] relative bg-red'>
        <img src='/assets/images/friend/banner-friend.webp?v=222' alt='' className='w-full  object-cover' />
      </div>
      <div className='w-full lg:px-10 max-sm:px-3 flex flex-col gap-5'>
        <h3 className='text-14 text-center'>
          지인 가입 코드는 1:1 문의로 발급 가능하며 추천아이디로 회원님의 아이디를 기재하여 가입을 성사시키면 업계 최고
          혜택을 지급하는 이벤트입니다
        </h3>
        <div className='w-full h-[50px] bg-black text-primary-2 flex items-center justify-center font-semibold text-14 p-5 text-center'>
          자세한 혜택은 프리미엄 멤버쉽 지인 추천 이벤트 게시판 참고 부탁드립니다.
        </div>
        <h3 className='text-18 font-bold text-primary-2 text-center pt-10'>지인 입금 현황</h3>

        <Table columns={columnStatusMoney} dataSource={[]} scroll={{ x: 1200 }} className='sidebar__overflow' />
        <div className='grid grid-cols-2 gap-10'>
          <Button
            className={`h-[60px] flex items-center justify-center bg-[#101216] rounded-md ${tab === 'tab1' ? 'text-primary-2' : 'text-white'}`}
            onClick={() => setTab('tab1')}
          >
            금주 정산예정
          </Button>
          <Button
            className={`h-[60px] flex items-center justify-center bg-[#101216] rounded-md ${tab === 'tab2' ? 'text-primary-2' : 'text-white'}`}
            onClick={() => setTab('tab2')}
          >
            정산완료내역
          </Button>
        </div>
        <div className='w-full min-h-[100px] border-t-2 border-primary-2 bg-[#101216] py-10 px-5'>
          <div className='flex items-center justify-between max-sm:flex-col max-sm:text-center max-sm:gap-5'>
            <div className='flex flex-col gap-2'>
              <h3 className='text-16 font-semibold text-primary-2'>지인 주정산 현황표</h3>
              <p className='text-14'>
                2024년 7월 3주차 예상 수익금액: <span className='text-primary-2 font-semibold'>0 원</span>
              </p>
              <p className='text-14'>07월 15일(월) ~ 07월 21일(일)</p>
            </div>
            <div className='grid grid-cols-4 gap-2 lg:w-[60%] max-sm:grid-cols-2 w-full'>
              <div className='flex flex-col gap-3'>
                <h3 className='text-16 font-semibold text-primary-2 text-center'>입금 합계</h3>
                <Button className='w-full h-[50px] bg-[#2f343a]'>
                  <strong className='text-blue-1 font-semibold'>0 원</strong>(0)
                </Button>
              </div>
              <div className='flex flex-col gap-3'>
                <h3 className='text-16 font-semibold text-primary-2 text-center'>입금 합계</h3>
                <Button className='w-full h-[50px] bg-[#2f343a]'>
                  <strong className='text-red-1 font-semibold'>0 원</strong>(0)
                </Button>
              </div>
              <div className='flex flex-col gap-3'>
                <h3 className='text-16 font-semibold text-primary-2 text-center'>입금 합계</h3>
                <Button className='w-full h-[50px] bg-[#2f343a]'>
                  <strong className='text-blue-1 font-semibold'>0 원</strong>(0)
                </Button>
              </div>
              <div className='flex flex-col gap-3'>
                <h3 className='text-16 font-semibold text-primary-2 text-center'>입금 합계</h3>
                <Button className='w-full h-[50px] bg-[#2f343a]'>
                  <strong className='text-red-1 font-semibold'>0 원</strong>(0)
                </Button>
              </div>
            </div>
          </div>
          {tab === 'tab2' && (
            <div className='mt-10'>
              <Table columns={columns2} dataSource={[]} scroll={{ x: 1200 }} className='sidebar__overflow' />
            </div>
          )}
          <p className='text-12 pt-5 ml-auto mr-0 flex items-center justify-end text-primary-2'>
            월요일부터 일요일까지의 입금 - 출금액을 자동계산하여 월요일 자정에 자동지급되며 정산받을 금액이
            마이너스일경우 매주 월요일 자정에 0으로 초기화 됩니다.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Friend
