const path = {
  home: '/',
  register: '/register',
  casino: '/casino',
  chat11: '/customer',
  recharge: '/recharge',
  withdraw: '/withdraw',
  event: '/event',
  note: '/list-note',
  new_board: '/notice',
  fame: '/hall-of-fame',
  slot: '/slot',
  list_point: '/list-point',
  attendance: '/attendance',
  tv_stream: '/TVStream',
  /*---Battle----*/
  battle: '/battle',
  battle_sports: '/battle/sports',
  battle_minigame: '/battle/minigame',
  battle_virtual_sport: '/battle/virtual-sports',
  battle_bet_games: '/battle/bet-games',
  battle_casino: '/battle/casino',
  landing1: '/landing-1',
  move_money: '/move-money',
  coupon: '/coupon',
  available_coupon: '/coupon/available',
  used_coupon: '/coupon/usage-history',
  sports: '/sports',
  virtual_sports: '/VirtualSports',
  virtual_sports_football: '/VirtualSports/Soccer',
  virtual_sports_basketball: '/VirtualSports/Basketball',
  virtual_sports_baseball: '/VirtualSports/Baseball',
  virtual_sports_greyhounds: '/VirtualSports/Greyhounds',
  virtual_sports_horse: '/VirtualSports/Horse',
  domestic: '/domestic',
  live_sport: '/LiveSports',
  spin: '/spin',
  minigame: 'mini',
  mini_power_ball: `/mini/PowerBall`,

  // TAB1
  mini_power_ladder: '/mini/PowerLadder',
  mini_keno_ladder: '/mini/KenoLadder',
  mini_speed_keno: '/mini/SpeedKeno',

  // TAB2
  mini_boscore_ladder1: '/mini/BoscoreLadder1',
  mini_boscore_ladder2: '/mini/BoscoreLadder2',
  mini_boscore_ladder3: '/mini/BoscoreLadder3',
  mini_boscore_power_ball: '/mini/BoscorePowerBall',

  // TAB3
  mini_sure_power_ball1: '/mini/SurePowerBall1',
  mini_sure_power_ball2: '/mini/SurePowerBall2',
  mini_sure_power_ball3: '/mini/SurePowerBall3',
  mini_sure_ladder1: '/mini/SureLadder1',
  mini_sure_ladder2: '/mini/SureLadder2',
  mini_sure_ladder3: '/mini/SureLadder3',
  mini_sure_racing1: '/mini/SureRacing1',
  mini_sure_racing2: '/mini/SureRacing2',
  mini_sure_racing3: '/mini/SureRacing3',

  // TAB4
  mini_token_power_ball: '/mini/TokenPowerBall',
  mini_token_hilo: '/mini/TokenHilo',
  mini_token_hilo5: '/mini/TokenHilo5',
  mini_token_baccarat: '/mini/TokenBaccarat',
  mini_token_horse: '/mini/TokenHorse',

  // TAB5
  mgm_baccarat1: '/mgm/MGMBaccarat1',
  mgm_baccarat2: '/mgm/MGMBaccarat2',
  mgm_baccarat3: '/mgm/MGMBaccarat3',
  mgm_even_odd: '/mgm/MGMEvenOdd',
  mgm_go_stop: '/mgm/MGMGoStop',

  // TAB6
  lotus_baccarat1: '/lotus/Baccarat1',
  lotus_even_odd: '/lotus/EvenOdd',

  // TAB7
  bet_games_lucky7: '/betgames/Lucky7',
  bet_games_lucky6: '/betgames/Lucky6',
  bet_games_lucky5: '/betgames/Lucky5',
  bet_games_wheel: '/betgames/Wheel',
  bet_games_poker: '/betgames/Poker',
  bet_games_baccarat: '/betgames/Baccarat',
  bet_games_bet: '/betgames/Bet',
  bet_games_dice_duel: '/betgames/DiceDuel',

  mini_lottery_powerball: '/mini/LotteryPowerball',
  be_pick_bogle2: '/mini/BepickBogle2',
  be_pick_power5: '/mini/BepickPower5',

  score_power_1: '/mini/ScorePower1',
  score_power_2: '/mini/ScorePower2',
  score_power_3: '/mini/ScorePower3',

  maintain: '/maintain',
  cashhistory: '/cashhistory',
  friend: '/friend',
  bet_history: '/bet-history',

    //TABMGM
    mgm_bacarat: '/mini/MGMBaccarat',
    lotus_bacarat: '/mini/LotusBaccarat',
    lotus_odd_even: '/mini/LotusOddEven',
    mgm_odd_even: '/mini/MGMOddEven',
} as const

export const pathMiniGame: string[] = [
  // TAB1
  path.mini_power_ladder,
  path.mini_keno_ladder,
  path.mini_speed_keno,

  // TAB2
  path.mini_boscore_ladder1,
  path.mini_boscore_ladder2,
  path.mini_boscore_ladder3,
  path.mini_boscore_power_ball,

  // TAB3
  path.mini_sure_power_ball1,
  path.mini_sure_power_ball2,
  path.mini_sure_power_ball3,
  path.mini_sure_ladder1,
  path.mini_sure_ladder2,
  path.mini_sure_ladder3,
  path.mini_sure_racing1,
  path.mini_sure_racing2,
  path.mini_sure_racing3,

  // TAB4
  path.mini_token_power_ball,
  path.mini_token_hilo,
  path.mini_token_hilo5,
  path.mini_token_baccarat,
  path.mini_token_horse,

  // TAB5
  path.mgm_baccarat1,
  path.mgm_baccarat2,
  path.mgm_baccarat3,
  path.mgm_even_odd,
  path.mgm_go_stop,

  // TAB6
  path.lotus_baccarat1,
  path.lotus_even_odd,
  path.score_power_1,
  path.score_power_2,
  path.score_power_3,

  // TAB7
  path.bet_games_lucky7,
  path.bet_games_lucky6,
  path.bet_games_lucky5,
  path.bet_games_wheel,
  path.bet_games_poker,
  path.bet_games_baccarat,
  path.bet_games_bet,
  path.bet_games_dice_duel,
  path.mini_lottery_powerball,
  path.be_pick_bogle2,
  path.be_pick_power5,

    // TABMGM
    path.mgm_bacarat,
    path.lotus_bacarat,
    path.lotus_odd_even,
    path.mgm_odd_even,
]

export const pathMiniGameType: { [key: string]: string } = {
  // TAB1
  [path.mini_power_ladder]: 'power_ladder',
  [path.mini_keno_ladder]: 'kino_ladder',
  [path.mini_speed_keno]: 'speed_kino',

  // TAB2
  [path.mini_boscore_ladder1]: 'bo_start_1',
  [path.mini_boscore_ladder2]: 'bo_start_2',
  [path.mini_boscore_ladder3]: 'bo_start_3',
  [path.mini_boscore_power_ball]: 'one_minute_powerball_bosco',

  // TAB3
  [path.mini_sure_power_ball1]: 'sure_powerball_1',
  [path.mini_sure_power_ball2]: 'sure_powerball_2',
  [path.mini_sure_power_ball3]: 'sure_powerball_3',
  [path.mini_sure_ladder1]: 'sure_ladder_1',
  [path.mini_sure_ladder2]: 'sure_ladder_2',
  [path.mini_sure_ladder3]: 'sure_ladder_3',
  [path.mini_sure_racing1]: 'sure_racing_1',
  [path.mini_sure_racing2]: 'sure_racing_2',
  [path.mini_sure_racing3]: 'sure_racing_3',

  // TAB4
  [path.mini_token_power_ball]: '',
  [path.mini_token_hilo]: '',
  [path.mini_token_hilo5]: '',
  [path.mini_token_baccarat]: '',
  [path.mini_token_horse]: '',

  // TAB5
  [path.mgm_baccarat1]: '',
  [path.mgm_baccarat2]: '',
  [path.mgm_baccarat3]: '',
  [path.mgm_even_odd]: '',
  [path.mgm_go_stop]: '',

  // TAB6
  [path.lotus_baccarat1]: '',
  [path.lotus_even_odd]: '',
  [path.score_power_1]: 'score_power1',
  [path.score_power_2]: 'score_power2',
  [path.score_power_3]: 'score_power3',

  // TAB7
  [path.bet_games_lucky7]: '',
  [path.bet_games_lucky6]: '',
  [path.bet_games_lucky5]: '',
  [path.bet_games_wheel]: '',
  [path.bet_games_poker]: '',
  [path.bet_games_baccarat]: '',
  [path.bet_games_bet]: '',
  [path.bet_games_dice_duel]: '',
  [path.mini_lottery_powerball]: 'lottery_powerball',
  [path.be_pick_bogle2]: 'bepick_bogle2',
  [path.be_pick_power5]: 'bepick_power5',


  // TABMGM
  [path.mgm_bacarat]: 'mgm_bacc',
  [path.lotus_bacarat]: 'lotus_bacc',
  [path.lotus_odd_even]: 'lotus_oe',
  [path.mgm_odd_even]: 'mgm_oe',
}

export const unAuthRouters = ['/', '/home', '/login', '/register']
export const sportsRouters = [path.sports, '/domestic', path.live_sport]
export const sportsPrematchInplay: string[] = [path.sports, path.live_sport]
export const showCartPath = [path.sports, path.live_sport, ...pathMiniGame]

export default path
