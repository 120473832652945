/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import path from 'src/constants/path'
import { GET_MINI_GAME_BACARAT, GET_MINI_GAME_MGM_BACARAT } from 'src/libs/apis/graphql/queries/parsing-casino.query'
import { useDocumentVisibility } from 'src/libs/hooks/useDocumentVisibility'
import { removeAllCartMiniGame } from 'src/libs/stores/minigame.reducer'
import { removeAllCartSport } from 'src/libs/stores/sport.reducer'
import { useEventParsingCasino } from '../useEvenParsingCasino'
import { BaccaratBetItem } from './BaccaratItem'
import { NotFoundState } from 'src/components/NotFoundState'
import { GET_LOGO_PRIORITY_QUERY } from 'src/libs/apis/graphql/queries/page.query'

export interface GET_MINI_GAME {
  // TOOD: refactor
  BaccaratQuery: BaccaratQuery
}

export interface BaccaratQuery {
  data: BaccaratBetItem[]
  round_id: string
  now: number
  blocking_time?: number
}

export interface OddEven {
  odd: Option
  even: Option
}

export interface BaccaratBetItem {
  key: string
  text: string
  rate: number
  color: string
  category_name: string
  category_id: string
}

export interface LeftRight {
  left: Option
  right: Option
}

export interface Line {
  line3: Option
  line4: Option
}

export interface Complex {
  left_3_even: Option
  right_3_odd: Option
  left_4_odd: Option
  right_4_even: Option
}

export interface Option {
  text: string
  rate: number
  transId: string
  color: string
}

type Props = {
  tabActive?: number
  handleLockedPlay?: () => void
  handleUnlockedPlay?: () => void
  refreshHistory?: () => Promise<void>
}

function formatTimeFromSeconds(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export default function BaccaratBet({
  tabActive,
  handleLockedPlay = () => {},
  handleUnlockedPlay = () => {},
  refreshHistory = async () => {}
}: Props) {
  const dispatch = useDispatch()
  const [countdownTimer, setCountdownTimer] = useState<number>(0)
  const [isRefetchApi, setIsRefetchApi] = useState(false)
  const [timerServer, setTimerServer] = useState({
    startTime: 0,
    endTime: 0,
    now: 0
  })
  const [dataMiniGame, setDataMiniGame] = useState<GET_MINI_GAME | null>(null)
  const location = useLocation()
  const [minigameName, setMiniGameName] = useState('mgm_baccarat1')
  const [minigameNameTitle, setMiniGameNameTitle] = useState('')

  const { data: configMeta } = useQuery(GET_LOGO_PRIORITY_QUERY, { context: { apiName: 'auth' } })
  const siLogo2 = configMeta?.PageSiteQuery?.siLogo2
  const handleRefetchGame = async () => {
    await refetchGame()
  }
  useEventParsingCasino({ callback: handleRefetchGame })

  const nameMiniGame = () => {
    switch (location.pathname) {
      case path.mgm_bacarat:
        return 'mgm_bacc'
      case path.lotus_bacarat:
        return 'lotus_bacc'
      default:
        return 'mgm_bacc'
    }
  }

  //After hanlder again
  const nameMiniGameTitle = () => {
    switch (location.pathname) {
      case path.mgm_bacarat:
        return '바카라1'
      case path.lotus_bacarat:
        return '바카라2'
      default:
        return '바카라1'
    }
  }

  const {
    data: dataMini,
    refetch: refetchGame,
    loading: loading1,
    error
  } = useQuery<GET_MINI_GAME>(GET_MINI_GAME_BACARAT, {
    variables: { game: minigameName },
    context: {
      apiName: 'parsing-casino'
    },
    onCompleted: (data) => {
      setDataMiniGame(data)
      const now = data.BaccaratQuery.now
      setCountdownTimer(now)
      setIsRefetchApi(true)
      if (!data.BaccaratQuery.round_id) {
        toast.error('Game is not available')
      }
      dispatch(removeAllCartMiniGame())
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: location.pathname !== path.mgm_bacarat && location.pathname !== path.lotus_bacarat && minigameName !== ''
  })

  useEffect(() => {
    setMiniGameName(nameMiniGame())

    //After hanlder again
    setMiniGameNameTitle(nameMiniGameTitle())

    refetchGame()
  }, [location.pathname, refetchGame])

  useDocumentVisibility({
    cbActiveTab: refetchGame
  })

  const loading = loading1
  const data = dataMini?.BaccaratQuery

  // const leftRight = data?.left_right
  // const line = data?.line
  // const odd_even = data?.odd_even
  // const complex = data?.complex
  const round_id = data?.round_id?.split('_').at(-1)
  const blocking_time = data?.blocking_time || 30

  /**\
   * structure of data
   * leftRight: line 1
   * line: line 2
   * odd_even: line 3
   * complex: line 4
   */
  // async function handleRefetchGetMiniGame() {
  //   await refetchGetMiniGame2()
  // }

  // async function handleGetMiniGame() {
  //   await refetchGetMiniGame2()
  // }

  useEffect(() => {
    if (countdownTimer !== null && countdownTimer <= 0) {
      handleLockedPlay()
      dispatch(removeAllCartSport())
      setCountdownTimer(0)
      refetchGame()
      handleRefetchHistory()
      setIsRefetchApi(false)
      return
    }

    if (!isRefetchApi) {
      return
    }
    if (dataMiniGame) {
      if (countdownTimer && countdownTimer <= blocking_time) {
        handleLockedPlay()
        dispatch(removeAllCartMiniGame())
      } else {
        handleUnlockedPlay()
      }
    }
  }, [countdownTimer, timerServer])

  useEffect(() => {
    // handleGetMiniGame()
    const interval = setInterval(() => {
      setCountdownTimer((prev) => {
        if (prev !== 0) {
          return prev - 1
        }

        return prev
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [location.pathname])

  const handleRefetchHistory = async () => {
    setTimeout(() => {
      refreshHistory()
    }, 15000)
  }

  // useEffect(() => {
  //   const channel = pusher.subscribe('parsing-casino-result-channel')
  //   channel.bind('parsing-casino-result-event', async (data: { game: string }) => {
  //     console.log(data)
  //     const currentGameType = 'mgm_baccarat' + tabActive || 0
  //     if (currentGameType === data.game) {
  //       await refetchGame()
  //       await refreshHistory()
  //     }
  //   })

  //   pusher.connection.bind('connected', () => {
  //     console.log('Pusher connected successfully')
  //   })

  //   pusher.connection.bind('error', () => {
  //     console.error('Pusher connection error:')
  //   })

  //   return () => {
  //     channel.unbind('parsing-casino-result-event')
  //     pusher.unsubscribe('parsing-casino-result-channel')
  //   }
  // }, [location.pathname, refetchGame])

  const groupedItems = dataMiniGame?.BaccaratQuery?.data?.reduce(
    (acc, item) => {
      if (item.key.includes('player')) {
        acc.player.push(item)
      } else if (item.key.includes('banker')) {
        acc.banker.push(item)
      } else if (item.key === 'tie') {
        acc.tie.push(item)
      }
      return acc
    },
    { player: [], banker: [], tie: [] } as {
      player: BaccaratBetItem[]
      banker: BaccaratBetItem[]
      tie: BaccaratBetItem[]
    }
  )

  return (
    <div className='w-full h-full justify-center items-center flex flex-col'>
      {loading && !data ? (
        <div className={`h-[200px]`}>
          <div className='h-full flex items-center justify-center '>
            <img src={siLogo2} className='animate-pulse w-1/2 max-w-[200px]' alt='logo loading' />
          </div>
        </div>
      ) : !data ? (
        <NotFoundState message={error?.message} />
      ) : (
        <div className='bg-[#101216] w-11/12 p-2 grid grid-rows-[1fr_3fr] rounded-md'>
          <div className='bg-[#030303] rounded-md p-4 h-[85px] flex justify-between'>
            <div className='flex gap-3 items-start'>
              <div className='flex items-start pt-1'>
                <img src='/assets/icons/star_1.svg' />
              </div>
              <div className='flex flex-col'>
                <p className='text-[25px] flex'>
                  <span className='hidden lg:block'>
                    MGM({minigameNameTitle})<span className='mx-2'>-</span>
                  </span>
                  <span>
                    <span className='text-primary'> {round_id}</span>회차
                  </span>
                </p>
                <p className='text-12 hidden lg:block'>1분단위로 추첨이 이루어지며 하루 1440회차 진행</p>
              </div>
            </div>
            <div className=''>
              <Countdown seconds={countdownTimer} />
            </div>
          </div>
          {data && (
            <div className='rounded-md p-4 bg-[#030303]'>
              <div className='grid grid-cols-1 lg:grid-cols-[1fr_2fr_1fr] gap-4 items-stretch h-full'>
                <div className='flex flex-row lg:flex-col gap-4'>
                  {groupedItems?.player.map((item) => (
                    <BaccaratBetItem key={item.key} title={''} item={item} transId={data?.round_id} isLock={false} />
                  ))}
                </div>
                {groupedItems?.tie.map((item) => (
                  <BaccaratBetItem key={item.key} title={''} item={item} transId={data?.round_id} isLock={false} />
                ))}
                <div className='flex flex-row lg:flex-col gap-4'>
                  {groupedItems?.banker.map((item) => (
                    <BaccaratBetItem key={item.key} title={''} item={item} transId={data?.round_id} isLock={false} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const Countdown = ({ seconds }: { seconds: number }) => {
  return (
    <div className='flex gap-1 h-full'>
      <div className='hidden lg:flex bg-[#333333] text-30 font-bold p-3 rounded-md items-center'>0</div>
      <div className='hidden lg:flex bg-[#333333] text-30 font-bold p-3 rounded-md items-center'>0</div>
      <div className='hidden lg:flex items-center text-32'>:</div>
      <div className='bg-[#333333] text-30 font-bold p-3 rounded-md flex items-center'>{Math.floor(seconds / 10)}</div>
      <div className='bg-[#333333] text-30 font-bold p-3 rounded-md flex items-center'>{seconds % 10}</div>
    </div>
  )
}
