import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { config } from 'src/constants/config'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import { useCommonStore } from 'src/libs/hooks/store/useCommonStore'
import { useLiveSportSearchStore } from 'src/libs/hooks/store/useLiveSportSearchStore'
import {
  addItemDataRateInfo,
  lockDataLiveSearchPure,
  setIsOpenModalCartChangeRate,
  updateAllStatusDefaultLive as updateAllStatusDefault,
  updateAllStatusDefaultRateInfoLive as updateAllStatusDefaultRateInfo,
  updateDataFromSearchLiveList as updateDataFromSearchList,
  updateDataRateInfoListLive as updateDataRateInfoList,
  updateDataScoreFromSearchLiveList,
  updateDataToLiveSearchListPure
} from 'src/libs/stores/live-sport-search.reducer'
import { updateAndLockItemLive as updateAndLockItem } from 'src/libs/stores/live-sport.reducer'
import { transformDataLiveSport, transformLiveSportDomesticData } from 'src/libs/utils/live-sports.func'
import { checkIsCanBettingRate } from 'src/libs/utils/sports.func'
import { usePusher } from 'src/providers/PusherProvider'
import {
  BetItemPusher,
  LIST_PERIOD_LIVE_MATCH_NOT_ALLOW_WHEN_VALID_STATUS,
  LIST_STATUS_LIVE_MATCH_ALLOW,
  LiveSportItem,
  UpdateRecordParent,
  UpdateScoreRecord
} from 'src/types/live-sports.type'
import { Domestic, LIST_SCORE_ID } from 'src/types/sports.type'

const SPORTS_CHANNEL = 'sports-channel'
const SPORTS_UPDATE_EVENT = 'inplay-parent-event'
const SPORTS_UPDATE_SCORE_EVENT = 'update-score-event'
const SPORTS_UPDATE_FIXTURE_EVENT = 'update-realtime-fixture-event'
const SPORTS_CREATE_MARKET_EVENT = 'sports-create-market-event'
const SPORTS_FIXTURE_BLOCK_EVENT = 'fixture-block-betting-event'

const randomElement = (list: string[] | number[]): string | number => {
  const randomIndex = Math.floor(Math.random() * list.length)
  return list[randomIndex]
}

const calculateBetRate = ({
  rate,
  percent_distribution = 0,
  rate_deduct = 0
}: {
  rate: number
  percent_distribution?: number
  rate_deduct?: number
}) => {
  const newRate = rate + rate * ((percent_distribution + rate_deduct) / 100)
  return Number(newRate.toFixed(2))
}

const getSportsCodeFromLocation = (): number | null => {
  const searchParams = new URLSearchParams(window.location.search)
  return Number(searchParams.get('sportsCode'))
}
export const useEventLiveSports = () => {
  const pusher = usePusher()
  const { marketInfoList } = useCommonStore()
  const dispatch = useDispatch()
  const [isOpenModalCartChange, setIsOpenModalCartChange] = useState(false)
  const { dataLiveSportSearchPure, dataRateInfoList, isOpenBoardBetSport, objExchangeRateInfo } =
    useLiveSportSearchStore()
  const fixtureIdxList = useMemo(() => {
    return dataLiveSportSearchPure.map((item) => item.idx)
  }, [dataLiveSportSearchPure])
  const { cart, limitBetting } = useCartLiveSportsStore()
  // const sportsCode = getSportsCodeFromLocation()

  useEffect(() => {
    const channel = pusher?.subscribe(SPORTS_CHANNEL)
    // let timeoutData: NodeJS.Timeout
    // let timeoutRareInfo: NodeJS.Timeout
    // const clearTimeouts = () => {
    //   if (timeoutData) clearTimeout(timeoutData)
    //   if (timeoutRareInfo) clearTimeout(timeoutRareInfo)
    // }
    channel?.bind(SPORTS_UPDATE_EVENT, async ({ updateRecordParent }: { updateRecordParent: UpdateRecordParent }) => {
      if (!dataLiveSportSearchPure.length) {
        return
      }
      if (!fixtureIdxList.includes(updateRecordParent.fixture_idx)) {
        return
      }
      // if (updateRecordParent.type !== 'inplay') {
      //   return
      // }
      // if (updateRecordParent.sports_idx !== sportsCode && sportsCode !== 0) {
      //   return
      // }
      // if (isOpenBoardBetSport) {
      //   console.log(betIdListGameId9)
      // }
      // const isLock = Math.random() > 0.4 ? 2 : 1
      // const fakeUpdate: UpdateRecordParent = {
      //   ...updateRecordParent,
      //   fixture_idx: 13785691,
      //   bets: [
      //     {
      //       ...updateRecordParent.bets[0],
      //       betid: randomElement([
      //         '59112826413785691',
      //         '125653481713785691',
      //         '127673779313785691',
      //         '97420981513785691',
      //         '57092528813785691',
      //         '57167115013785691',
      //         '71208778913785691',
      //         '213775509113785691',
      //         '85399615213785691',
      //         '59187412613785691',
      //         '11393837913785691',
      //         '213700922913785691',
      //         '147234853813785691',
      //         '28934614813785691',
      //         '45071162513785691',
      //         '99441279113785691',
      //         '9373540313785691',
      //         '30954912413785691',
      //         '153885981913785691',
      //         '2722412213785691',
      //         '128754949613785691',
      //         '88426496913785691',
      //         '54065647113785691',
      //         '39949397013785691',
      //         '25833146913785691',
      //         '184461838613785691',
      //         '116658997113785691',
      //         '130775247213785691',
      //         '102542747013785691',
      //         '156229338413785691',
      //         '90446794513785691',
      //         '109432389313785691',
      //         '163455946213785691',
      //         '111452686913785691',
      //         '161435648613785691'
      //       ])!,
      //       status: 2
      //     }
      //   ]
      // }
      // updateRecordParent = { ...fakeUpdate }
      // const existingIndex = dataLiveSportSearchPure.findIndex((item) => item.idx === 13785691)
      const existingIndex = dataLiveSportSearchPure.findIndex((item) => item.idx === updateRecordParent.fixture_idx)
      if (existingIndex !== -1) {
        const updateRecordParentCalculateBetRate = { ...updateRecordParent }
        updateRecordParentCalculateBetRate.bets.forEach((bet) => {
          const existingIndexInDataListPure = dataLiveSportSearchPure.findIndex((item) =>
            [
              item?.domestic?.[0]?.betid1 || '',
              item?.domestic?.[0]?.betid2 || '',
              item?.domestic?.[0]?.betid3 || ''
            ].includes(bet.betid)
          )
          if (existingIndexInDataListPure !== -1) {
            const updateBetItem: BetItemPusher = {
              ...bet,
              rate: calculateBetRate({
                rate: bet.rate,
                percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
                rate_deduct: objExchangeRateInfo[dataLiveSportSearchPure[existingIndexInDataListPure].sports_idx]
              })
            }
            dispatch(
              updateDataFromSearchList({
                betItemPusher: updateBetItem,
                fixture_idx: updateRecordParentCalculateBetRate.fixture_idx,
                index: existingIndex
              })
            )
          }

          // clearTimeouts()
          // timeoutData = setTimeout(() => {
          //   dispatch(updateAllStatusDefault())
          // }, 3000)
        })
      }
      if (
        isOpenBoardBetSport &&
        dataRateInfoList.length &&
        updateRecordParent.fixture_idx === dataRateInfoList[0].fixture_idx
      ) {
        const updateRecordParentCalculateBetRate = { ...updateRecordParent }
        updateRecordParentCalculateBetRate.bets.forEach((bet) => {
          const existingIndexInDataRateInfoList = dataRateInfoList.findIndex((item) =>
            [item.betid1, item.betid2, item.betid3].includes(bet.betid)
          )
          if (existingIndexInDataRateInfoList !== -1) {
            const updateBetItem: BetItemPusher = {
              ...bet,
              rate: calculateBetRate({
                rate: bet.rate,
                percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
                rate_deduct: objExchangeRateInfo[dataRateInfoList[existingIndexInDataRateInfoList].sports_idx]
              })
            }
            dispatch(
              updateDataRateInfoList({
                betItemPusher: updateBetItem,
                fixture_idx: updateRecordParentCalculateBetRate.fixture_idx,
                index: existingIndexInDataRateInfoList
              })
            )
            // clearTimeouts()
            // timeoutRareInfo = setTimeout(() => {
            //   dispatch(updateAllStatusDefaultRateInfo())
            // }, 3000)
          }
        })
      }

      const updateRecordParentCalculateBetRate = { ...updateRecordParent }
      updateRecordParentCalculateBetRate.bets.forEach((bet) => {
        const existingCartItemIndex = cart.pick_list.findIndex((item) => item.betId === bet.betid)
        if (existingCartItemIndex !== -1) {
          const updateBetItem: BetItemPusher = {
            ...bet,
            rate: calculateBetRate({
              rate: bet.rate,
              percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
              rate_deduct: objExchangeRateInfo[cart.pick_list[existingCartItemIndex].domesticData.sports_idx]
            })
          }
          if (updateBetItem.rate !== cart.pick_list[existingCartItemIndex].rate) {
            // toast.warn(`선택된 경기중 변경된 사항이 있습니다.`)
            setIsOpenModalCartChange(true)
            dispatch(setIsOpenModalCartChangeRate(true))
            dispatch(
              updateAndLockItem({
                domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
                betId: updateBetItem.betid,
                isChanged:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
                    ? false
                    : true,
                newRate:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
                    ? cart.pick_list[existingCartItemIndex].rate
                    : updateBetItem.rate,
                status: updateBetItem.status,
                isLocked:
                  updateBetItem.status !== 1 ||
                  !checkIsCanBettingRate({
                    game_id: cart.pick_list[existingCartItemIndex].domesticData.game_id,
                    sports_idx: cart.pick_list[existingCartItemIndex].domesticData.sports_idx,
                    rate: updateBetItem.rate
                  })
              })
            )
          }
        }
      })
    })

    channel?.bind(SPORTS_UPDATE_SCORE_EVENT, async ({ updateScore }: { updateScore: UpdateScoreRecord }) => {
      // if (updateScore.sports_idx !== sportsCode && sportsCode !== 0) {
      //   return
      // }
      if (!dataLiveSportSearchPure.length) {
        return
      }
      // console.log('first', updateScore)

      // const fakeUpdateScore: UpdateScoreRecord = {
      //   ...updateScore,
      //   play_time: randomElement(['10', '20', '30', '40', '50']),
      //   period_name: randomElement(['1st Half', '2nd Half', 'Overtime'])
      // }
      const existingIndex = dataLiveSportSearchPure.findIndex((item) => item.idx === updateScore.fixture_idx)
      if (existingIndex !== -1) {
        dispatch(
          updateDataScoreFromSearchLiveList({
            dataUpdate: updateScore,
            index: existingIndex
          })
        )
      }
    })
    // handle event add option
    channel?.bind(SPORTS_CREATE_MARKET_EVENT, async ({ updateMarket }: { updateMarket: Domestic }) => {
      // updateMarket.fixture_idx = 13904029
      updateMarket.main_line = String(updateMarket.main_line)
      if (!isOpenBoardBetSport) {
        return
      }
      if (!dataRateInfoList.length) {
        return
      }
      if (dataRateInfoList[0].fixture_idx !== updateMarket.fixture_idx) {
        return
      }
      const listBetId = dataRateInfoList.map((i) => `${i.betid1}|${i.betid2}|${i.betid3}`.split('|')).flat()
      if (
        listBetId.includes(updateMarket?.betid1) ||
        listBetId.includes(updateMarket?.betid2) ||
        listBetId.includes(updateMarket?.betid3)
      ) {
        return
      }
      const dataLiveSportSearchPureTarget = dataLiveSportSearchPure.find(
        (item) => item.idx === updateMarket.fixture_idx
      )
      if (!dataLiveSportSearchPureTarget) {
        return
      }
      const gameId = Number(updateMarket.game_id)
      const marketTarget = marketInfoList.find((i) => i.idx === gameId)
      if (!marketTarget) {
        return
      }
      const marketTransferTarget = marketTarget.market_transfers.find(
        (i) => i.sports_idx === dataRateInfoList[0].sports_idx
      )
      if (!marketTransferTarget) {
        updateMarket.market = {
          idx: gameId,
          group: marketTarget.group,
          name: marketTarget.name
        }
      } else {
        updateMarket.market = {
          idx: gameId,
          group: marketTransferTarget.group,
          name: marketTransferTarget.market_name
        }
      }
      if (updateMarket.rate1) {
        updateMarket.rate1 = calculateBetRate({
          rate: updateMarket.rate1,
          percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
          rate_deduct: objExchangeRateInfo[dataRateInfoList[0].sports_idx]
        })
      }
      if (updateMarket.rate2) {
        updateMarket.rate2 = calculateBetRate({
          rate: updateMarket.rate2,
          percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
          rate_deduct: objExchangeRateInfo[dataRateInfoList[0].sports_idx]
        })
      }
      if (updateMarket.rate3) {
        updateMarket.rate3 = calculateBetRate({
          rate: updateMarket.rate3,
          percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
          rate_deduct: objExchangeRateInfo[dataRateInfoList[0].sports_idx]
        })
      }
      dispatch(addItemDataRateInfo(transformLiveSportDomesticData(updateMarket, dataLiveSportSearchPureTarget)))
    })

    channel?.bind(SPORTS_UPDATE_FIXTURE_EVENT, async ({ realtime_fixture }: { realtime_fixture: LiveSportItem }) => {
      // if (realtime_fixture.status !== 2 && !fixtureIdxList.includes(realtime_fixture.idx)) {
      //   return
      // }
      if (config.demoMode) {
        console.log('update-realtime-fixture-event')
        console.log('-----START-----')
        console.log('realtime_fixture: ', realtime_fixture)
        console.log('status: ', realtime_fixture.status)
        console.log('period: ', realtime_fixture.period)
        console.log('main is null?', realtime_fixture.main === null, realtime_fixture.main)
        console.log('idx: ', realtime_fixture.idx)
        console.log('-----END-----')
      }
      if (!LIST_STATUS_LIVE_MATCH_ALLOW.includes(realtime_fixture.status)) {
        return
      }
      if (
        (!realtime_fixture.main ||
          LIST_PERIOD_LIVE_MATCH_NOT_ALLOW_WHEN_VALID_STATUS.includes(realtime_fixture.period)) &&
        realtime_fixture.status === 2 &&
        !fixtureIdxList.includes(realtime_fixture.idx)
      ) {
        return
      }
      if (getSportsCodeFromLocation() !== realtime_fixture.sports_idx && getSportsCodeFromLocation() !== 0) {
        return
      }

      const liveSportItem = transformDataLiveSport(realtime_fixture)
      dispatch(
        updateDataToLiveSearchListPure({
          liveSportItem
        })
      )
      const existingCartItemIndex = cart.pick_list.findIndex((item) =>
        [liveSportItem.domestic[0].betid1, liveSportItem.domestic[0].betid2, liveSportItem.domestic[0].betid3].includes(
          item.betId
        )
      )
      if (fixtureIdxList.includes(realtime_fixture.idx) && existingCartItemIndex !== -1) {
        let rate = calculateBetRate({
          rate: liveSportItem.domestic[0].rate1,
          percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
          rate_deduct: objExchangeRateInfo[liveSportItem.sports_idx]
        })
        if (liveSportItem.domestic[0].betid2 === cart.pick_list[existingCartItemIndex].betId) {
          rate = calculateBetRate({
            rate: liveSportItem.domestic[0].rate2,
            percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
            rate_deduct: objExchangeRateInfo[liveSportItem.sports_idx]
          })
        } else if (liveSportItem.domestic[0].betid3 === cart.pick_list[existingCartItemIndex].betId) {
          rate = calculateBetRate({
            rate: liveSportItem.domestic[0].rate3,
            percent_distribution: limitBetting?.BettingLimitsSportQuery.percent_distribution,
            rate_deduct: objExchangeRateInfo[liveSportItem.sports_idx]
          })
        }
        setIsOpenModalCartChange(true)
        dispatch(setIsOpenModalCartChangeRate(true))
        dispatch(
          updateAndLockItem({
            domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
            betId: cart.pick_list[existingCartItemIndex].betId,
            isChanged:
              liveSportItem.domestic[0].status !== 1 ||
              !checkIsCanBettingRate({
                game_id: liveSportItem.domestic[0].game_id,
                sports_idx: liveSportItem.sports_idx,
                rate
              })
                ? false
                : true,
            newRate:
              liveSportItem.domestic[0].status !== 1 ||
              !checkIsCanBettingRate({
                game_id: liveSportItem.domestic[0].game_id,
                sports_idx: liveSportItem.sports_idx,
                rate
              })
                ? cart.pick_list[existingCartItemIndex].rate
                : rate,
            status: liveSportItem.domestic[0].status,
            isLocked:
              liveSportItem.domestic[0].status !== 1 ||
              !checkIsCanBettingRate({
                game_id: liveSportItem.domestic[0].game_id,
                sports_idx: liveSportItem.sports_idx,
                rate
              })
          })
        )
      }
    })

    channel?.bind(SPORTS_FIXTURE_BLOCK_EVENT, async ({ data }: { data: { idx: number; block: boolean } }) => {
      if (!fixtureIdxList.includes(data.idx)) {
        return
      }
      dispatch(
        lockDataLiveSearchPure({
          isLock: data.block,
          index: fixtureIdxList.indexOf(data.idx)
        })
      )
      const existingCartItemIndex = cart.pick_list.findIndex((item) => item.domesticData.fixture_idx === data.idx)
      if (existingCartItemIndex !== -1) {
        if (data.block) {
          setIsOpenModalCartChange(true)
          dispatch(setIsOpenModalCartChangeRate(true))
          dispatch(
            updateAndLockItem({
              domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
              betId: cart.pick_list[existingCartItemIndex].betId,
              isChanged: false,
              newRate: cart.pick_list[existingCartItemIndex].rate,
              status: cart.pick_list[existingCartItemIndex].domesticData.status,
              isLocked: true
            })
          )
        } else {
          setIsOpenModalCartChange(true)
          dispatch(setIsOpenModalCartChangeRate(true))
          dispatch(
            updateAndLockItem({
              domesticIdx: cart.pick_list[existingCartItemIndex].domesticData.idx,
              betId: cart.pick_list[existingCartItemIndex].betId,
              isChanged: true,
              newRate: cart.pick_list[existingCartItemIndex].rate,
              status: cart.pick_list[existingCartItemIndex].domesticData.status,
              isLocked: false
            })
          )
        }
      }
    })

    return () => {
      channel?.unbind(SPORTS_UPDATE_EVENT)
      channel?.unbind(SPORTS_UPDATE_SCORE_EVENT)
      channel?.unbind(SPORTS_UPDATE_FIXTURE_EVENT)
      channel?.unbind(SPORTS_FIXTURE_BLOCK_EVENT)
      pusher?.unsubscribe(SPORTS_CHANNEL)
      // clearTimeout(timeoutData)
      // clearTimeout(timeoutRareInfo)
    }
  }, [dataLiveSportSearchPure, cart, isOpenBoardBetSport, pusher])

  useEffect(() => {
    if (config.demoMode) {
      return
    }
    const interval = setInterval(() => {
      dispatch(updateAllStatusDefault())
      dispatch(updateAllStatusDefaultRateInfo())
    }, 12000)

    return () => clearInterval(interval)
  }, [])

  const handleCloseModalCartChange = () => {
    setIsOpenModalCartChange(false)
    dispatch(setIsOpenModalCartChangeRate(false))
  }

  return {
    isOpenModalCartChange,
    handleCloseModalCartChange
  }
}
