import { GroupSportListByKey } from 'src/types/live-sports.type'
import { GroupLiveSportItem } from './GroupLiveSportItem'

type Props = {
  listGroupSportByKey: GroupSportListByKey
}

export const GroupLiveSportList = ({ listGroupSportByKey }: Props) => {
  return (
    <div className='relative grid grid-cols-1 gap-4'>
      {listGroupSportByKey.listGroupSport.map((groupSportItem) => {
        return <GroupLiveSportItem sportData={groupSportItem} key={groupSportItem.idx} />
      })}
    </div>
  )
}
