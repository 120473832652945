// THIS MUTATIONS USE FOR NOTICES(RULES on BE)/EVENTS

import { gql } from '@apollo/client'

/**
 * Update: 2024-06-19
 * ! FE USE THIS MUTATION in the last, to update view for notice and event.
 * ! But this time BE grouped notice event to one type, so currently it don't have any field detect updateView for notice/event
 * TODO: remove unused, or refactor if needed
 */

export const UPDATE_VIEW_NOTICE_MUTATION = gql`
  mutation UpdateViewCountMutation($id: Int) {
    updateViewCountMutation(id: $id) {
      status
    }
  }
`
