import cn from 'classnames'
import { useViewport } from 'src/libs/hooks/useWindowDimension'
import { formatToTwoDecimalPlaces } from 'src/libs/utils/utilFuncs'
import { ListBetBattleQueryType } from 'src/types/sports.type'

const GAME_TYPES = {
  cross: '2',
  live: '3'
}

const renderStatus = (value: number | undefined, className?: string) => {
  switch (value) {
    case 0:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24',
            className
          )}
        >
          대기중
        </button>
      )
    case 1:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24',
            className
          )}
        >
          낙첨
        </button>
      )
    case 2:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24',
            className
          )}
        >
          당첨
        </button>
      )
    case 3:
      return (
        <button
          disabled
          className='w-max bg-[#F79F2F] rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24'
        >
          적특
        </button>
      )
    case 4:
      return (
        <button
          disabled
          className='w-max bg-[#be311e] rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24'
        >
          취소
        </button>
      )
    case 5:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#be311e] rounded-[5px] py-[3px] md:px-[1rem] px-0 self-center justify-center place-self-center min-w-12 md:min-w-24',
            className
          )}
        >
          취소
        </button>
      )
    default:
      return <></>
  }
}

const renderSettleStatus = (value: number | undefined, className?: string) => {
  switch (value) {
    case 0:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
            className
          )}
        >
          대기중
        </button>
      )
    case 1:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
            className
          )}
        >
          낙첨
        </button>
      )
    case 2:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
            className
          )}
        >
          당첨
        </button>
      )
    case 3:
      return (
        <button
          disabled
          className='w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
        >
          취소
        </button>
      )
    case 5:
      return (
        <button
          disabled
          className={cn(
            'w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24',
            className
          )}
        >
          취소
        </button>
      )
    default:
      return <></>
  }
}

const renderResults = (value: number | undefined) => {
  switch (value) {
    case 0:
      return (
        <button disabled className='bg-[#773f05] text-12 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'>
          정산대기중
        </button>
      )
    default:
      return (
        <button disabled className='bg-[#4986e8] text-12 rounded-[5px] px-4 py-1 min-w-[100px] md:min-w-24 max-h-8'>
          정산완료
        </button>
      )
  }
}

const renderScore = ({
  statusBet,
  fixtureStatus,
  homeScore,
  awayScore
}: {
  statusBet?: number
  fixtureStatus?: number
  homeScore?: number
  awayScore?: number
}) => {
  if (statusBet === 0 && fixtureStatus !== 3) {
    return '-'
  }

  if (statusBet !== 0 && fixtureStatus !== 3) {
    return '스코어 집계중'
  }
  return homeScore + ' - ' + awayScore
}
const SportRowItem = ({
  item,
  index,
  query,
  showModal,
  checkTimeOutAdminBet
}: {
  item: ListBetBattleQueryType
  index: number
  query: { limit: number; page: number; total: number; offset: number; game_type: string }
  showModal: (id: string) => void
  checkTimeOutAdminBet: (timeLeftDisable: number, timeLeftCancelable: number) => boolean
}) => {
  const { isSP } = useViewport()

  return isSP ? (
    <div className='flex mb-12 mt-1 flex-col md:hidden text-11 p-2 pt-0 border-[1.5px] border-primary' key={index}>
      <div className='flex items-center justify-between py-3'>
        <span className='flex-1 text-12 !font-bold font-sans text-primary'>{item.date}</span>
        <span>{renderResults(item.settlementStatus)}</span>
      </div>
      <div className='flex flex-col gap-3'>
        {item.betList?.map((dataBetList, idx) => (
          <div key={idx} className='flex flex-col border border-secondary-3'>
            <div className='flex items-center justify-between p-4'>
              <span className='flex-1 flex flex-col'>
                <span>{dataBetList.startDate}</span>
                <span className='text-12'>{dataBetList.leagueName}</span>
              </span>
              <span>{renderStatus(dataBetList.status)}</span>
            </div>
            <div className='flex flex-col'>
              <div className='grid grid-cols-12 items-center border-t border-secondary-3'>
                <span className='col-span-5 text-start p-3'>{dataBetList.homeTeamName}</span>
                <div className='self-stretch col-span-2 flex items-center justify-center border-l border-r border-secondary-3'>
                  {renderScore({
                    fixtureStatus: dataBetList.fixtureStatus,
                    awayScore: dataBetList.awayScore,
                    homeScore: dataBetList.homeScore,
                    statusBet: dataBetList.status
                  })}
                </div>
                <span className='col-span-5 text-end p-3'>{dataBetList.awayTeamName}</span>
              </div>
              <div className='p-3 flex gap-2 items-center  border-t border-secondary-3'>
                <span className='bg-primary p-0.5'>{dataBetList.marketName}</span>
                {dataBetList.labelBetStatus} <span className='text-red-1'>{dataBetList.selectRate?.toFixed(2)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex flex-col items-start justify-between py-3'>
        <div className='flex flex-col'>
          <div>
            예상당첨금액 : (배팅금 <span className='text-green-1'>{item.betAmount?.toLocaleString()}</span> X 배당률{' '}
            <span className='text-green-1'>{item.rate?.toFixed(2)}</span>) =
            <span className='text-primary-6'> {item.estimateAmount?.toLocaleString()}</span> 원
          
          </div>
          <span>
            당첨금액 : <span className='text-green-1'>{item.winAmount?.toLocaleString()} </span>원
          </span>
        </div>
        {item.settlementStatus !== 0 ? (
          <div className='md:mr-[22px] min-w-24 md:min-w-24 max-h-8 ml-auto'>
            {renderSettleStatus(item.settlementStatus, '!h-full')}
          </div>
        ) : item.enableAutoCancel ? (
          query.game_type === GAME_TYPES.live ? (
            <button
              disabled
              className='bg-[#999999] text-12 order-last cursor-not-allowed rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
            >
              배팅취소요청
            </button>
          ) : !item.isCancelable ? (
            <button
              className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
              onClick={() => showModal(item._id)}
            >
              배팅취소요청
            </button>
          ) : checkTimeOutAdminBet(item.timeLeftDisable || 0, item.timeLeftCancelable || 0) ? (
            <button
              className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
              onClick={() => showModal(item._id)}
            >
              배팅취소요청
            </button>
          ) : (
            <button
              disabled
              className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
            >
              배팅취소요청
            </button>
          )
        ) : (
          <button
            disabled
            className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
          >
            배팅취소요청
          </button>
        )}
      </div>
    </div>
  ) : (
    <div
      key={index}
      className={`hidden md:block mt-4 py-2 px-2 md:min-w-[800px] min-w-0 mb-[30px] rounded-[5px] ${index % 2 == 1 ? 'bg-primary-8' : 'bg-primary-9'}`}
    >
      {item?.betList?.map((dataBetList, idx, array) => (
        <div
          className={`grid md:grid-cols-8 grid-cols-5 py-2 text-12 md:text-[13px] ${idx === array.length - 1 ? '' : 'line-battle'}`}
          key={idx}
        >
          <h3 className='w-full text-left self-center justify-center place-self-center'>{dataBetList.startDate}</h3>
          <h3 className='w-full text-center self-center justify-center place-self-center hidden md:block'>
            {dataBetList.leagueName}
          </h3>
          <h3 className='w-full text-center self-center justify-center place-self-center hidden md:block'>
            {dataBetList.homeTeamName} <span className='text-primary'>vs</span> {dataBetList.awayTeamName}
          </h3>
          <h3 className='w-full text-center self-center justify-center place-self-center hidden md:block'>
            {dataBetList.marketName}
          </h3>
          <h3 className='w-full text-center self-center justify-center place-self-center'>
            {`${dataBetList?.labelBetStatus}`}
          </h3>
          <h3 className='w-full text-center self-center justify-center place-self-center'>
            {formatToTwoDecimalPlaces(dataBetList.selectRate || 0)}
          </h3>
          <h3 className='w-full text-center self-center justify-center place-self-center'>
            {renderScore({
              fixtureStatus: dataBetList.fixtureStatus,
              awayScore: dataBetList.awayScore,
              homeScore: dataBetList.homeScore,
              statusBet: dataBetList.status
            })}
          </h3>
          <h3 className='w-full text-right md:pr-[22px] self-center justify-center place-self-center'>
            {renderStatus(dataBetList.status)}
          </h3>
        </div>
      ))}
      <div className='mt-4 pt-4 border-t border-primary'>
        <span className='block md:flex w-full text-12 md:text-[13px] gap-[16px]'>
          <div className='w-full md:w-[22.3%] self-center place-self-center break-words mb-1'>
            배팅접수시간: {item.date}
          </div>
          <span
            className={`flex md:justify-end w-full items-center ${item.bonusFolder > 0 ? 'flex-col md:flex-row gap-1' : 'justify-start'}`}
          >
            {item.bonusFolder > 0 && (
              <span className='mr-2 block md:flex self-start md:self-center place-self-start md:place-self-center justify-center items-center'>
                {item.bonusFolder} 폴더 보너스 :{' '}
                <span
                  style={{
                    textDecoration: item.isCancelledBonusFolder ? 'line-through' : 'none',
                    color: item.isCancelledBonusFolder ? 'grey' : 'inherit'
                  }}
                >
                  보너스배당률({item.bonusFolderRate})
                </span>
              </span>
            )}

            <span className='block md:flex self-start md:self-center place-self-start md:place-self-center justify-center items-center'>
              예상당첨금액 : (배팅금 <span className='text-green-1'>{item.betAmount?.toLocaleString()}</span> X 배당률{' '}
              <span className='text-green-1'>{item.rate?.toFixed(2)}</span>) =
              <span className='text-primary-6'> {item.estimateAmount?.toLocaleString()}</span> 원 /
              <span className='flex items-center mt-1'>
                <span>
                  {' '}
                  당첨금액 : <span className='text-green-1'>{item.winAmount?.toLocaleString()} </span>원
                </span>
                <span className='flex items-center'>
                  <div className='px-4'>{renderResults(item.settlementStatus)}</div>{' '}
                  {item.settlementStatus !== 0 ? (
                    <div className='md:mr-[22px] min-w-24 md:min-w-24 max-h-8'>
                      {renderSettleStatus(item.settlementStatus, '!h-full')}
                    </div>
                  ) : item.enableAutoCancel ? (
                    query.game_type === GAME_TYPES.live ? (
                      <button
                        disabled
                        className='bg-[#999999] text-12 order-last cursor-not-allowed rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                      >
                        배팅취소요청
                      </button>
                    ) : !item.isCancelable ? (
                      <button
                        className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                        onClick={() => showModal(item._id)}
                      >
                        배팅취소요청
                      </button>
                    ) : checkTimeOutAdminBet(item.timeLeftDisable || 0, item.timeLeftCancelable || 0) ? (
                      <button
                        className='bg-[#6aa74f] order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                        onClick={() => showModal(item._id)}
                      >
                        배팅취소요청
                      </button>
                    ) : (
                      <button
                        disabled
                        className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                      >
                        배팅취소요청
                      </button>
                    )
                  ) : (
                    <button
                      disabled
                      className='bg-[#999999] text-12 order-last rounded-[5px] md:mr-[22px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                    >
                      배팅취소요청
                    </button>
                  )}
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
  )
}

export default SportRowItem
