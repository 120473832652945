/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Modal } from 'antd'
import Button from 'src/components/ui/Button'
import { GET_VIRTUAL_SPORTS_CATEGORY } from 'src/libs/apis/graphql/queries/sport.query'
import { GET_HISTORY_VIRTUAL_SPORTS } from 'src/libs/apis/graphql/queries/virtual-sport.query'
import './battleVitualSports.scss'
import cn from 'classnames'

const GAME_CATEGORY = {
  Soccer: 'Soccer',
  Basketball: 'Basketball',
  Baseball: 'Baseball',
  Greyhounds: 'Greyhounds',
  Horse: 'Horse'
}

const classesStatus = {
  0: {
    className:
      'w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
  },
  1: {
    className:
      'w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
  },
  2: {
    className:
      'w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
  },
  3: {
    className:
      'w-max bg-[#F79F2F] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
  },
  5: {
    className:
      'w-max bg-[#F79F2F] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
  }
}

const classesResult = {
  0: {
    className: 'bg-[#773f05] ml-5 text-12 rounded-[5px] px-4 mr-4 py-1 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
  },
  1: {
    className: 'bg-[#ff0000] ml-5 text-12 rounded-[5px] px-4 py-1 mr-4 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
  },
  2: {
    className: 'bg-[#4986e8] ml-5 text-12 rounded-[5px] px-4 py-1 mr-4 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
  },
  3: {
    className: 'bg-[#F79F2F] ml-5 rounded-[5px] px-4 mr-4 py-1 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
  },
  5: {
    className: 'bg-[#be311e] ml-5 rounded-[5px] px-4 mr-4 py-1 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
  }
}

const statusClasses = (status: number) => {
  return classesStatus[status]?.className
}

const resultClasses = (status: number) => {
  return classesResult[status]?.className
}

export default function BattleVirtualSports() {
  const [query, setQuery] = useState({ limit: 5, page: 1, total: 0, offset: 1, game_category: 'Soccer' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cancelId, setCancelId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  //Get Categories
  const { data: dataCategory } = useQuery(GET_VIRTUAL_SPORTS_CATEGORY, {
    context: {
      apiName: 'virtual-sports'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const categoriesVirtualSports = dataCategory?.VirtualSportsCategory || []

  const handleFilter = (category: string) => {
    setCurrentPage(1)
    setQuery({ ...query, game_category: category, offset: 1 })
  }

  //Get HistoryVirtualSports follow Categories
  const { data: dataHistoryVirtualSports, loading: loadingHistoryVirtualSports } = useQuery(
    GET_HISTORY_VIRTUAL_SPORTS,
    {
      context: {
        apiName: 'virtual-sports'
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        cate: query.game_category || 'Soccer',
        limit: query.limit ?? 10,
        offset: query.offset ?? 0
      }
    }
  )
  const historyVirtualSports = (!loadingHistoryVirtualSports && dataHistoryVirtualSports?.BetHistoryQuery?.data) || []
  const itemsPerPage = query.limit
  const total = (!loadingHistoryVirtualSports && dataHistoryVirtualSports?.BetHistoryQuery?.total) || 0
  const totalPages = Math.ceil(total / itemsPerPage)
  console.log('historyVirtualSports', historyVirtualSports)

  const handlePageChange = (newPage: any | undefined) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setQuery({ ...query, offset: newPage })
      setCurrentPage(newPage)
    }
  }

  //Reder Status
  const renderStatus = (value: number | undefined) => {
    switch (value) {
      case 0:
        return (
          <button
            disabled
            className='w-max bg-[#000] text-12 text-primary-7 border-[#e06666] border rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
            onClick={() => {}}
          >
            대기중
          </button>
        )
      case 1:
        return (
          <button
            disabled
            className='w-max bg-[#ff0000] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
            onClick={() => {}}
          >
            낙첨
          </button>
        )
      case 2:
        return (
          <button
            disabled
            className='w-max bg-[#4986e8] text-12 rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
          >
            당첨
          </button>
        )
      case 3:
        return (
          <button
            disabled
            className='w-max bg-[#F79F2F] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
          >
            적특
          </button>
        )
      case 5:
        return (
          <button
            disabled
            className='w-max bg-[#be311e] rounded-[5px] py-[3px] px-[1rem] self-center justify-center place-self-center min-w-24 md:min-w-24'
          >
            취소
          </button>
        )
      default:
        return <></>
    }
  }

  //render Results
  const renderResults = (value: number | undefined) => {
    switch (value) {
      case 0:
        return (
          <button
            disabled
            className='bg-[#773f05] ml-5 text-12 rounded-[5px] px-4 mr-4 py-1 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
          >
            정산대기중
          </button>
        )
      case 1:
        return (
          <button
            disabled
            className='bg-[#be311e] ml-5 text-12 rounded-[5px] px-4 py-1 mr-4 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
          >
            정산완료
          </button>
        )
      case 2:
        return (
          <button
            disabled
            className='bg-[#4986e8] ml-5 text-12 rounded-[5px] px-4 py-1 mr-4 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
          >
            정산완료
          </button>
        )
      case 3:
        return (
          <button
            disabled
            className='bg-[#be311e] ml-5 rounded-[5px] px-4 mr-4 py-1 md:mr-8 min-w-[100px] md:min-w-24 max-h-8'
          >
            취소
          </button>
        )
    }
  }

  //Check Button Cancel
  const checkTimeOutAdminBet = (cancelT: any, disableT: any) => {
    if (cancelT > 0 && disableT < 0) {
      return true
    } else {
      return false
    }
  }

  //Show Modal
  const showModal = (id: string) => {
    setIsModalOpen(true)
    setCancelId(id)
  }

  const handleOk = () => {
    // cancelBetMutation({
    //   context: { apiName: 'sports' },
    //   variables: {
    //     cart_idx: cancelId
    //   },
    //   onCompleted: (data) => {
    //     toast.success(data?.CancelBetMutation?.message)
    //     refetchSportsBattle()
    //   }
    // })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const modalExampleCancel = () => {
    return (
      <Modal
        title=''
        onCancel={handleCancel}
        open={isModalOpen}
        centered
        footer={null}
        closeIcon={false}
        rootClassName='sports-modal'
      >
        <div className='flex flex-col justify-between gap-4 p-6 relative items-center bg-black-1 border-black-2 border text-white rounded'>
          <span className='text-20 font-medium'>배팅을 취소합니다.</span>
          <div className='self-stretch flex items-center justify-center gap-4'>
            <button
              className='bg-black-4 text-white px-10 py-2 rounded hover:bg-black-2 duration-200'
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              className='bg-primary text-white px-10 py-2 rounded hover:bg-primary-2 duration-200'
              onClick={handleOk}
            >
              확인
            </button>
          </div>
          <button className='absolute top-4 right-4' onClick={handleCancel}>
            <img src='/Close.png' alt='close' className='size-4' />
          </button>
        </div>
      </Modal>
    )
  }
  const handleCategoryBattleHomeAway = (betItem: any) => {
    if (query.game_category === GAME_CATEGORY.Greyhounds) {
      return
    } else if (query.game_category === GAME_CATEGORY.Horse) {
      return
    } else {
      return <p className='p-[1px] md:p-[5px]'>{betItem.betDescription}</p>
    }
  }
  const handleTitleCategoryBattleHomeAway = () => {
    if (query.game_category === GAME_CATEGORY.Greyhounds) {
      return
    } else if (query.game_category === GAME_CATEGORY.Horse) {
      return
    } else {
      return <h3 className=''>홈팀 vs 원징팀</h3>
    }
  }
  return (
    <div className='mt-5 md:mt-0 w-full'>
      {modalExampleCancel()}
      <div className='flex items-center justify-between gap-2 flex-wrap overflow-auto scrollbar-hide'>
        <div className='flex items-center gap-2'>
          {categoriesVirtualSports?.map((category: any, index: any) => {
            return (
              <Button
                key={index}
                className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${query?.game_category === category.name ? '!bg-primary !text-black' : ''}`}
                onClick={() => handleFilter(category.name)}
                disabled={loadingHistoryVirtualSports}
              >
                {category.name_ko}
              </Button>
            )
          })}
        </div>
      </div>

      {/* Render HistoryVirtualSports */}

      {historyVirtualSports?.length > 0 ? (
        <div className='table-battle-virtual w-full mb-4 mt-4 overflow-auto scrollbar-hide'>
          <div className='table-battle-virtual_title flex w-full justify-between min-w-[900px] mb-[16px]'>
            <h3 className=''>회차</h3>
            <h3 className=''>리그명</h3>
            {/* <h3 className=''>홈팀 vs 원징팀</h3> */}
            {handleTitleCategoryBattleHomeAway()}
            <h3 className=''>배팅타입</h3>
            <h3 className=''>배당</h3>
            <h3 className=''>경기결과</h3>
            <h3 className=''>배팅결과</h3>
          </div>
          {historyVirtualSports?.map((historyVirtualSports: any, index: any) => {
            return (
              <>
                <div
                  className={`rounded-[5px] px-2 py-2 mb-[30px] min-w-[900px] ${index % 2 == 1 ? 'bg-primary-8' : 'bg-primary-9'}`}
                >
                  <div key={index} className='flex-col w-full justify-around min-w-[900px]'>
                    {historyVirtualSports?.bets?.map((betItem: any, betIndex: any, array: any) => (
                      <div
                        key={betIndex}
                        className={`flex justify-between table-battle-virtual_betlist ${betIndex === array.length - 1 ? '' : 'line-battle rounded-none'}`}
                      >
                        <p className='p-[1px] md:p-[5px]'>
                          {betItem?.startDate?.split(' ')[1]?.split(':')?.slice(0, -1)?.join(':')}
                        </p>
                        <p className='p-[1px] md:p-[5px]'>{betItem?.leagueName}</p>
                        {handleCategoryBattleHomeAway(betItem)}
                        <p className='p-[1px] md:p-[5px]'>{betItem?.betDetail}</p>
                        <p className='p-[1px] md:p-[5px]'>{betItem?.selectRate?.toFixed(2)}</p>
                        <p className='p-[1px] md:p-[5px]'>{betItem?.betResult}</p>
                        <p className='w-full'>
                          <span className='p-[1px] md:p-[0px] mr-[32px]'>
                            <button className={cn(statusClasses(betItem?.status))}>{betItem?.statusKoName}</button>
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className='my-4 w-full min-w-[900px] table-sum'>
                    <p className=''>
                      예상당첨금액 : (배팅금
                      <span className='text-green-1'> {historyVirtualSports?.betAmount?.toLocaleString()}</span> X 배당률
                      <span className='text-green-1'> {historyVirtualSports?.result_rate?.toFixed(2)}</span>) =
                      <span className='text-primary-6 mr-5'>
                        {historyVirtualSports?.estimateAmount?.toLocaleString()} 원
                      </span>
                      결과당심금액
                      <span className='text-green-1'> {historyVirtualSports?.winAmount?.toLocaleString()} </span>원
                      <button className={cn(resultClasses(historyVirtualSports?.result))}>
                        {historyVirtualSports?.settlementStatusKoName}
                      </button>
                      {/* {checkTimeOutAdminBet(
                        historyVirtualSports.timeLeftCancelable,
                        historyVirtualSports.timeLeftDisable
                      ) && historyVirtualSports.isCancelable ? (
                        <button
                          className='bg-[#6aa74f] order-last rounded-[5px] mr-[32px] min-w-24 md:min-w-24 py-[3px] max-h-8'
                          onClick={() => showModal(historyVirtualSports._id)}
                        >
                          배팅취소요청
                        </button>
                      ) : (
                        <button
                          disabled
                          className='bg-[#999999] text-12 order-last rounded-[5px] min-w-24 md:min-w-24 py-[3px] mr-[32px] max-h-8'
                        >
                          배팅취소
                        </button>
                      )} */}
                    </p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      ) : (
        <>
          <div className='table-battle-virtual mb-4 mt-4'>
            <div className='table-battle-virtual_title flex w-full justify-between'>
              <h3 className=''>회차</h3>
              <h3 className=''>리그명</h3>
              {/* <h3 className=''>홈팀 vs 원징팀</h3> */}
              <h3 className=''>배팅타입</h3>
              <h3 className=''>배당</h3>
              <h3 className=''>경기결과</h3>
              <h3 className=''>배팅결과</h3>
            </div>
            <div className='bg-[#25292c] p-5 flex justify-center place-items-center'>
              <div className='text-center'>
                <p className='text-14'>해당 게임에 배팅내역이 존재하지 않습니다.</p>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Pagination Controls */}
      {!loadingHistoryVirtualSports && historyVirtualSports.length > 0 && (
        <div className='mt-2 mb-4 flex justify-center'>
          <button
            className={`px-4 py-2 bg-primary-10  text-white rounded ${currentPage === 1 ? 'opacity-15' : 'opacity-1'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src='/arrow-left.svg' alt='' />
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`w-[32px] font-dotum font-[600] text-[14px] h-[32px] rounded mx-2 ${currentPage === index + 1 ? 'bg-primary text-black' : 'bg-primary-10 text-white hover:text-primary'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={`px-4 py-2 bg-primary-10 text-white rounded ${currentPage === totalPages ? 'opacity-15' : 'opacity-1'}`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src='/arrow-right.svg' alt='' />
          </button>
        </div>
      )}
    </div>
  )
}
