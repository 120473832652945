/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { ConfigProvider, TableColumnsType, TablePaginationConfig } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DataTable from 'src/components/Table/DataTable'
import { getBetHistory } from 'src/libs/apis/graphql/queries/battle.query'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { formatQueryParams } from 'src/libs/utils/utilFuncs'

interface IBattleBetGames {
  gName: string
  tRegDate: string
  tAmount: string
  tType: string
}
enum TypeResult {
  FAILED = '패배',
  WIN = '승리',
  PENDING = '결과 대기중'
}
export default function BetGames() {
  const [query, setQuery] = useState({ limit: 10, page: 1, total: 0 })
  const queryParams = useQueryParams()
  const dispatch = useDispatch()
  const {
    data: dataBetHistory,
    refetch,
    loading
  } = useQuery(getBetHistory, {
    variables: {
      limit: query?.limit || 10,
      offset: query?.page || 1,
      category: queryParams?.filter ? queryParams?.filter : 'casino, slot',
      start_date: queryParams?.date ? queryParams?.date : null
    },
    context: { apiName: 'bet-histories' },
    onCompleted(data) {
      setQuery((prev) => ({
        ...prev,
        total: data?.BetHistoriesQuery?.total
      }))
    }
  })

  const BattleColumns: TableColumnsType<IBattleBetGames> = [
    {
      title: '배팅시간',
      key: 'tRegDate',
      width: '15%',
      align: 'center',
      dataIndex: 'tRegDate'
    },
    { title: '게임', key: 'gName', width: '40%', align: 'center', dataIndex: 'gName' },
    {
      title: '배팅액',
      dataIndex: 'tAmount',
      key: 'tAmount',
      width: '15%',
      align: 'center',
      render: (record) => <span className={`${record.includes('-') ? 'text-red-1' : 'text-white'}`}>{record} 원</span>
    },
    {
      title: '당첨금액',
      key: 'tAmountReturn',
      width: '15%',
      align: 'center',
      dataIndex: 'tAmountReturn',
      render: (record) => {
        return <span className=''>{record}</span>
      }
    },
    {
      title: '배팅결과',
      key: 'tAmountReturn',
      width: '15%',
      align: 'center',
      dataIndex: 'tAmountReturn',
      render: (record) => {
        const amount = Number(record.replace(',', ''))
        return <span className=''>{amount > 0 ? '당첨' : '낙첨'}</span>
      }
    }
  ]

  const navigate = useNavigate()
  const handleFilter = (filterBy: any, dateFilter: any) => {
    const queryParamsTemp = { ...queryParams, filter: filterBy, date: dateFilter ? dateFilter : null }
    navigate(`${location.pathname}${formatQueryParams(queryParamsTemp)}`)
    refetch()
  }

  const getPastDate = (days: any) => {
    const date = new Date()
    date.setDate(date.getDate() - days)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0 based
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const { current: current_page } = pagination

    setQuery((prev) => ({
      ...prev,
      page: current_page as number
    }))
    refetch({ limit: query?.limit || 10, page: current_page || 1 })
  }

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  // don't need for now
  // useEffect(() => {
  //   refetch()
  // }, [location.pathname])

  return (
    <div className='flex flex-col gap-5 mt-5 md:mt-0'>
      {/* <div className='flex items-center justify-between gap-2 flex-wrap'>
        <div className='flex items-center gap-2'>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${queryParams?.filter === 'casino' ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter('casino', queryParams?.date ?? getPastDate(0))}
            disabled={loading}
          >
            Casino
          </Button>
          <Button
            className={`transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14 ${queryParams?.filter === 'slot' ? '!bg-primary !text-black' : ''}`}
            onClick={() => handleFilter('slot', queryParams?.date ?? getPastDate(0))}
            disabled={loading}
          >
            Slot
          </Button>
        </div>
        <div className='flex items-center gap-2'>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(0))}
            disabled={loading}
          >
            오늘
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(7))}
            disabled={loading}
          >
            1주
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(15))}
            disabled={loading}
          >
            15일
          </Button>
          <Button
            className='transition-all h-10 rounded bg-secondary-2 text-primary hover:bg-primary hover:text-secondary-2 w-fit px-4 min-w-20 font-medium text-14'
            onClick={() => handleFilter('', getPastDate(30))}
            disabled={loading}
          >
            1달
          </Button>
        </div>
      </div> */}

      <div className='battle__table table-responsive'>
        <ConfigProvider
          renderEmpty={() => (
            <span className='text-center font-medium text-white'>해당 게임에 배팅내역이 존재하지 않습니다.</span>
          )}
        >
          <DataTable
            columns={BattleColumns}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            rowKey={'uuid'}
            pagination={{
              position: ['bottomCenter'],
              total: query.total,
              pageSize: query.limit
            }}
            dataSource={dataBetHistory?.BetHistoriesQuery?.data}
            onChange={handleChangePagination}
            loading={loading}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
