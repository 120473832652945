import { TableColumnsType } from 'antd'
import DataTable from 'src/components/Table/DataTable'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'

type Props = {
  dataShow: VirtualSportsDataItem[]
}

export const TableHistoryBasketball = ({ dataShow }: Props) => {
  const columns: TableColumnsType<VirtualSportsDataItem> = [
    {
      title: '회차',
      dataIndex: 'date',
      key: 'date',
      width: '75px',
      align: 'center',
      render: (_, record) => {
        return record.date.split(' ')[1].split(':').slice(0, -1).join(':')
      }
    },
    {
      title: '경기장',
      dataIndex: 'title',
      key: 'title',
      width: '160px',
      align: 'center',
      render: (_, record) => {
        return record.title
      }
    },
    {
      title: '홈팀',
      dataIndex: 'home',
      key: 'home',
      width: '120px',
      align: 'center',
      render: (_, record) => {
        return record.home
      }
    },
    {
      title: '원정팀',
      dataIndex: 'away',
      key: 'away',
      width: '120px',
      align: 'center',
      render: (_, record) => {
        return record.away
      }
    },
    {
      title: '승무패',
      dataIndex: 'result1',
      key: 'result1',
      width: '120px',
      align: 'center',
      render: (_, record) => {
        return record.result1
      }
    },
    {
      title: '언더 오버',
      dataIndex: 'result2',
      key: 'result2',
      width: '120px',
      align: 'center',
      render: (_, record) => {
        return record.result2
      }
    },
    {
      title: '핸디캡',
      dataIndex: 'result3',
      key: 'result3',
      width: '120px',
      align: 'center',
      render: (_, record) => {
        return record.result3
      }
    }
  ]
  return (
    <DataTable
      columns={columns}
      dataSource={dataShow.slice(0, 15)}
      rowClassName={(record, index) => {
        return index % 2 === 0 ? 'even' : 'odd'
      }}
      className='!scrollbar-hide'
    />
  )
}
