import { useSelector } from 'react-redux'
import { RootState } from 'src/libs/utils/store'

export const useCommonStore = () => {
  const { categoriesSports, categoriesLiveSports, marketInfoList, isSpinning } = useSelector(
    (state: RootState) => state.common
  )
  return {
    categoriesSports,
    categoriesLiveSports,
    marketInfoList,
    isSpinning
  }
}
