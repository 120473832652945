import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import LuckyWheel from 'src/components/Wheel/LuckyWheel/LuckyWheel'
import { ROULETTE_MUTATION } from 'src/libs/apis/graphql/mutations/spin.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { ROULETTE } from 'src/libs/apis/graphql/queries/spin.query'
import { login, setReadTokenFromLS } from 'src/libs/stores/auth.reducer'
import { getTimeDifference } from 'src/libs/utils/utilFuncs'
import { SiteInfoQueryType, StyleRotate } from 'src/types/common.type'
import { SpinDataType, SpinResultType, SpinType } from 'src/types/spin.type'
import './SpinWheel.css'

const ID = 'luckywheel'
const CURRENT_TIME_DURATION_NEEDLE_ROTATE = 0.6

const App: React.FC = () => {
  const [styleRotate, setStyleRotate] = useState<StyleRotate>({
    deg: 0,
    timingFunc: 'ease-in-out',
    timeDuration: 0
  })
  const [spinning, setSpinning] = useState<boolean>(false)
  const [time, setTime] = useState<Dayjs>()
  const [timeDuration, setTimeDuration] = useState<number>(3)
  const [timeNeedleRotate, setTimeNeedleRotate] = useState<number>(0)
  /**
   * State for case call api get prize on server
   * If use case random index, you should remove this useState
   */
  const [indexPrizeWon, setIndexPrizeWon] = useState<number | null>(null)

  const dispatch = useDispatch()

  const { data: dataSiteInfo, refetch: refetchSiteInfo } = useQuery<SiteInfoQueryType>(SiteInfoQuery, {
    context: { apiName: 'siteInfo' }
  })

  const { data } = useQuery<SpinType>(ROULETTE, {
    context: { apiName: 'roulette' }
  })

  const [rouletteMutation] = useMutation<SpinResultType>(ROULETTE_MUTATION, {
    context: { apiName: 'roulette' }
  })

  const [reloadMember] = useLazyQuery(getMember, {
    context: { apiName: 'member' },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })

  /**
   * Function to spin and call api get prize on server
   */
  const handleSpin = () => {
    setSpinning(true)
    setTime(dayjs())
    rouletteMutation({
      variables: {
        _id: null
      },
      onCompleted(dataMutate) {
        if (dataMutate?.rouletteMutation?.amount || dataMutate?.rouletteMutation?.amount === 0) {
          data?.roulette?.find((item: SpinDataType, index: number) => {
            if (item?.amount === dataMutate?.rouletteMutation?.amount) setIndexPrizeWon(index)
          })
        }

        if (dataMutate?.rouletteMutation?.seconds) setTimeDuration(dataMutate?.rouletteMutation?.seconds)
      }
    })

    let d = styleRotate.deg
    d = d + (360 - (d % 360)) + 360 * 10

    setStyleRotate({ timingFunc: 'ease-in-out', deg: d, timeDuration: timeDuration })
    setTimeNeedleRotate(CURRENT_TIME_DURATION_NEEDLE_ROTATE)
  }

  const alertAfterTransitionEnd = () => {
    const ele = document.getElementById(ID)
    if (ele) {
      const container = ele.querySelector('.luckywheel-container')
      if (container) {
        container.addEventListener(
          'transitionend',
          () => {
            setSpinning(false)
          },
          false
        )
      }
    }
  }

  /**
   * useEffect for case call api get prize on server
   * If you use function handleSpin for case random index in array prize at file constant,
   * you should remove or comment this useEffect
   */
  useEffect(() => {
    if (indexPrizeWon !== null && time) {
      const timeCallApi = getTimeDifference(time, dayjs())
      let d = styleRotate.deg
      d = d + (360 - (d % 360)) + (360 * 10 - indexPrizeWon * (360 / (data?.roulette?.length || 0)))
      const timeRotate = timeDuration - timeCallApi
      setStyleRotate({
        deg: d,
        timingFunc: 'ease',
        timeDuration: timeRotate
      })
      setTimeNeedleRotate(((timeRotate / 10) * 1) / 4)

      /**
       * Decrease the speed of the needle after the lucky wheel's rotation speed for a period of time with a constant acceleration time = (timeRotate / 10) * 3 / 4) * 10000
       */
      setTimeout(
        () => {
          setTimeNeedleRotate(((timeRotate / 10) * 3) / 4)
          setTimeout(() => {
            toast.success(data?.roulette?.[indexPrizeWon].amount)
            reloadMember()
            refetchSiteInfo()
            setIndexPrizeWon(null)
          }, timeDuration + 600)
        },
        (((timeRotate / 10) * 3) / 4) * 10000
      )

      alertAfterTransitionEnd()
    }
  }, [indexPrizeWon, data])

  return (
    <section className='mt-2 rounded canvas-spin'>
      <div className='py-10 md:py-32 overflow-hidden'>
        <div className='relative flex flex-col justify-center items-center'>
          <div className='wrap-spin'>
            <LuckyWheel
              id={ID}
              handleSpin={handleSpin}
              styleRotate={styleRotate}
              spinning={spinning}
              timeNeedleRotate={timeNeedleRotate}
              spinData={data}
              memberRoulette={dataSiteInfo?.SiteInfoQuery?.roulette_rules?.member_roulette}
            />
            <div className='absolute top-[calc(50%-100px)] right-1/2 translate-x-1/2 md:top-[calc(50%+5px)] md:right-14 md:translate-y-1/2 md:translate-x-0 rounded-full bg-primary outline h-10 flex px-4 md:h-12 md:px-12'>
              <div className='flex items-center gap-2'>
                <p className='text-white text-14 md:text-20 font-medium'>보유쿠폰 :</p>
                <p className='text-white text-14 md:text-20 font-medium'>
                  {dataSiteInfo?.SiteInfoQuery?.roulette_rules?.member_roulette}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default App
