import { TableColumnsType } from 'antd'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { DataMoneyType } from './ReCharge'
import Button from 'src/components/ui/Button'

const statusClass = {
  // pending
  대기: 'text-[#2167a3] bg-[#0d1f2d]',
  // approved
  승인완료: 'text-[#11b485] bg-[rgba(17,180,133,.12)]',
  // cancel
  취소처리: 'text-[#dc3644] bg-[rgba(220,54,68,.12)]',
  // rollback
  환수처리: 'text-[#b4ad38] bg-[#292d1f]'
}

export const getRechargeColumns: (width: number) => TableColumnsType<DataMoneyType> = (width) => {
  return [
    { title: '신청시간', dataIndex: 'miRegDate', key: 'miRegDate', width: '150px', align: 'center' },
    { title: '타입', dataIndex: 'miWallet', key: 'miWallet', width: '100px', align: 'center', hidden: width < 1024 },
    {
      title: '입금금액',
      dataIndex: 'miBankMoney',
      key: 'miBankMoney',
      width: '80px',
      align: 'center',
      render: (value) => formatCurrency(value) + ' 원'
    },
    {
      title: '보너스금액',
      dataIndex: 'miBonus',
      key: 'miBonus',
      width: '150px',
      align: 'center'
    },
    {
      title: '합계',
      dataIndex: 'miTotalMoney',
      key: 'miTotalMoney',
      width: '100px',
      align: 'center',
      hidden: width < 1024,
      render: (value) => formatCurrency(value) + ' 원'
    },
    { title: '처리시간', dataIndex: 'mProcessDate', key: 'mProcessDate', width: '150px', align: 'center' },
    {
      title: '상태',
      dataIndex: 'miStatus',
      key: 'miStatus',
      width: '100px',
      align: 'center',
      hidden: width < 1024,
      render: (value: string) => {
        return (
          <Button
            className={` w-[70px] h-8 flex items-center justify-center rounded text-14 mx-auto ${statusClass[value as keyof typeof statusClass]}`}
          >
            {value}
          </Button>
        )
      }
    }
  ]
}
