import { MAX_MOVE_MONEY_VALUE, MIN_MOVE_MONEY_DEFAULT_VALUE } from 'src/components/MoveMoneyForm/MoveMoneyForm'
import { MAX_RECHARGE_VALUE } from 'src/pages/ReCharge/ReCharge'
import { MAX_WITHDRAW_VALUE } from 'src/pages/WithDraw/WithDraw'
import { InfoQueryType, SiteInfoType } from 'src/types/common.type'
import * as yup from 'yup'
import { formatCurrency, reFormatCurrency } from './utilFuncs'

// Lowercase and uppercase letters, numbers, and special characters
// const passwordValidator = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%*#?&]).+$/

// const priceValidator = () => {
//   return yup
//     .string()
//     .trim()
//     .required('환전하실 금액(원) 입력해주세요.')
//     .test('min', '최소 10,000 이상 환전신청을 해야합니다.', (value) => {
//       if (value && reFormatCurrency(value) < 10000) return false

//       return true
//     })
//     .test('max', '2.000.000.000 이하 환전신청을 해야합니다', (value) => {
//       if (value && reFormatCurrency(value) > 2000000000) return false

//       return true
//     })
// }

export const searchFormSchema = () => {
  const schema = yup.object({
    search: yup.string().trim()
  })

  return schema
}

export const withDrawSchema = (dataInfo: any) => {
  const schema = yup.object({
    amount: yup
      .string()
      .trim()
      .required(dataInfo?.InfoQuery?.validation_withraw?.required ?? '가격은 필수 항목입니다.')
      // .test(
      //   'min',
      //   dataInfo?.SiteInfoQuery?.min_withraw?.toString() ??
      //     `출금금액은 최소 ${formatCurrency(MIN_WITHDRAW_DEFAULT_VALUE)}원 이상이어야 합니다.`,
      //   (value) => {
      //     if (
      //       (dataInfo?.SiteInfoQuery?.min_withraw && reFormatCurrency(value) < dataInfo?.SiteInfoQuery?.min_withraw) ??
      //       reFormatCurrency(value) < MIN_WITHDRAW_DEFAULT_VALUE
      //     ) {
      //       return false
      //     }

      //     return true
      //   }
      // )
      .test('max', formatCurrency(MAX_WITHDRAW_VALUE) + ' 이하 환전신청을 해야합니다', (value) => {
        if (reFormatCurrency(value) > MAX_WITHDRAW_VALUE) {
          return false
        }

        return true
      })
  })

  return schema
}

export const reChargeSchema = (dataInfo: SiteInfoType) => {
  const schema = yup.object({
    price: yup
      .string()
      .trim()
      .required(dataInfo?.SiteInfoQuery?.validation_deposit?.required ?? '가격은 필수 항목입니다.')
      // .test(
      //   'min',
      //   dataInfo?.SiteInfoQuery?.validation_deposit?.min ??
      //     `입금금액은 최소 ${formatCurrency(MIN_RECHARGE_DEFAULT_VALUE)}원 이상이어야 합니다.`,
      //   (value) => {
      //     console.log('value', value, dataInfo?.SiteInfoQuery?.validation_deposit?.min)
      //     if (
      //       (dataInfo?.SiteInfoQuery?.min_deposit &&
      //         reFormatCurrency(value) < Number(dataInfo?.SiteInfoQuery?.min_deposit)) ??
      //       reFormatCurrency(value) < MIN_RECHARGE_DEFAULT_VALUE
      //     ) {
      //       return false
      //     }

      //     return true
      //   }
      // )
      .test('max', formatCurrency(MAX_RECHARGE_VALUE) + ' 이하 환전신청을 해야합니다', (value) => {
        if (reFormatCurrency(value) > MAX_RECHARGE_VALUE) {
          return false
        }

        return true
      })
  })

  return schema
}

export const moveMoneySchema = (dataInfo: SiteInfoType) => {
  const schema = yup.object({
    amount: yup
      .string()
      .trim()
      .required(dataInfo?.SiteInfoQuery?.validation_deposit?.required ?? '가격은 필수 항목입니다.')
      .test('min', `최소 ${formatCurrency(MIN_MOVE_MONEY_DEFAULT_VALUE)} 이상 환전신청을 해야합니다.`, (value) => {
        if (reFormatCurrency(value) < MIN_MOVE_MONEY_DEFAULT_VALUE) {
          return false
        }

        return true
      })
      .test('max', '신청 금액이 보유 금액보다 많습니다.', (value) => {
        if (reFormatCurrency(value) > MAX_MOVE_MONEY_VALUE) {
          return false
        }

        return true
      })
  })

  return schema
}
