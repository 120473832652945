import React from 'react'
import { TabViewMobile as ETabViewMobile } from '../hooks/useVirtualSports'

type Props = {
  isShow: boolean
  tabActive: ETabViewMobile
  handleChangeTabShow: (tab: ETabViewMobile) => void
}

const listTab: { title: string; tab: ETabViewMobile; classNames: string }[] = [
  {
    title: '회차선택',
    tab: ETabViewMobile.BOARD_BET,
    classNames: 'bg-gray-2'
  },
  {
    title: '지난결과',
    tab: ETabViewMobile.HISTORY,
    classNames: 'bg-green-600'
  },
  {
    title: '배팅내역',
    tab: ETabViewMobile.HISTORY_BET,
    classNames: 'bg-yellow-500'
  }
]

export const TabViewMobile = ({ isShow, tabActive, handleChangeTabShow }: Props) => {
  if (!isShow) return null

  return (
    <div className='flex items-center justify-between'>
      {listTab.map(({ title, tab, classNames }) => {
        return (
          <button
            key={title}
            className={`flex-1 py-2 px-4 text-sm font-medium ${classNames}`}
            onClick={() => {
              if (tabActive === tab) return
              handleChangeTabShow(tab)
            }}
          >
            {title}
          </button>
        )
      })}
    </div>
  )
}
