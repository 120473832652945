import { gql } from '@apollo/client'

const getConsultation = gql`
  query Consultation($page: Int, $limit: Int) {
    consultation(page: $page, limit: $limit) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        id
        title
        content
        content_reply
        author
        created_at
      }
    }
    checkRequireReplyQuery {
      status
    }
  }
`

export { getConsultation }
