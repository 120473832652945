import { gql } from '@apollo/client'

export const ROULETTE = gql`
  query Roulette {
    roulette {
      id
      bgcolor
      amount
    }
  }
`

export const GET_SPIN_AMOUNT = gql`
  query SiteInfoQuery {
    SiteInfoQuery {
      roulette_rules {
        member_roulette
      }
    }
  }
`
