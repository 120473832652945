import cn from 'classnames'
import { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { ArrowUpIcon, LoadingIcon } from 'src/components/Icons'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import { useLiveSportSearchStore } from 'src/libs/hooks/store/useLiveSportSearchStore'
import {
  resetDataBoardBetLive,
  setEventIdLiveSport,
  setOpenBoardBetLiveSport
} from 'src/libs/stores/live-sport-search.reducer'
import { formatTimeFromSeconds } from 'src/libs/utils/minigame.func'
import {
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  LiveSportItem,
  LiveSportItemClient
} from 'src/types/live-sports.type'
import { useSelectOptBettingLiveSport } from '../hooks/useSelectOptBettingLiveSport'
import { LIST_HANDICAP_ID, LIST_UNDER_OVER_ID_ALL, LIST_WIN_LOSE_ID, LIST_WIN_TIE_LOSE_ID } from 'src/types/sports.type'
import { renderRating } from 'src/libs/utils/utilFuncs'
import { checkIsCanBettingRate, checkIsLockAllBetting } from 'src/libs/utils/live-sports.func'
import { config } from 'src/constants/config'

type Props = {
  domesticData: DomesticWithKeyMatch
  isLastItem?: boolean
  sportData: Partial<LiveSportItemClient>
  render?: ({
    isActiveSideRight,
    isOpenBoardBetSport
  }: {
    isActiveSideRight: boolean
    isOpenBoardBetSport: boolean
  }) => ReactNode
}

export const DomesticItemStartGame = ({ domesticData, isLastItem = false, render, sportData }: Props) => {
  const { handleGetRateInfoList, handleSelectBetting } = useSelectOptBettingLiveSport()
  const dispatch = useDispatch()
  const { dataRateInfoList, isOpenBoardBetSport, isLoadingRateInfo, isLoadingLiveSports } = useLiveSportSearchStore()
  const { cart: cartSport } = useCartLiveSportsStore()
  const count = sportData.count || 0
  const [isLoading, setIsLoading] = useState(false)
  const isActiveSideRight =
    dataRateInfoList.length > 0 &&
    isOpenBoardBetSport &&
    dataRateInfoList[0].fixture_idx === domesticData.fixture_idx &&
    dataRateInfoList[0].league_idx === domesticData.league_idx &&
    dataRateInfoList[0].sports_idx === domesticData.sports_idx
      ? true
      : false
  const chosenOptionIndex = cartSport.pick_list.findIndex((cartItem) => {
    return cartItem.domesticData.idx === domesticData.idx
  })
  let isSelectOptionBetId1 = false
  let isSelectOptionBetId2 = false
  let isSelectOptionBetId3 = false
  if (chosenOptionIndex !== -1) {
    isSelectOptionBetId1 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid1
    isSelectOptionBetId2 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid2
    isSelectOptionBetId3 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid3
  }
  const isLockAll = checkIsLockAllBetting({
    rate1: domesticData.rate1,
    rate2: domesticData.rate2 && domesticData.betid2 ? domesticData.rate2 : undefined,
    rate3: domesticData.rate3
  })

  const renderIconLeft = (gameId: string, gameType: number) => {
    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return <ArrowUpIcon className='size-6 text-red-1 hidden md:block' />
    }

    if ([...LIST_WIN_LOSE_ID, ...LIST_WIN_TIE_LOSE_ID].includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/1x2.png'
          alt='sport icon'
          className='w-3 h-[5px] mr-[5px] text-red-1 hidden md:block'
        />
      )
    }

    if (LIST_HANDICAP_ID.includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/handicap-icon.png'
          alt='sport icon'
          className='w-[6px] h-[7px] text-red-1 mr-[8px] hidden md:block'
        />
      )
    }
    return null
  }
  const renderIconRight = (gameId: string, gameType: number) => {
    if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return <ArrowUpIcon className='size-6 rotate-180 text-blue-3 hidden md:block' />
    }

    if ([...LIST_WIN_LOSE_ID, ...LIST_WIN_TIE_LOSE_ID].includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/1x2.png'
          alt='sport icon'
          className='w-3 h-[5px] ml-[5px] text-red-1 hidden md:block'
        />
      )
    }

    if (LIST_HANDICAP_ID.includes(gameId)) {
      return (
        <img
          src='/assets/images/sports/handicap-icon.png'
          alt='sport icon'
          className='w-[6px] h-[7px] text-red-1 ml-[8px] hidden md:block'
        />
      )
    }
    return null
  }
  return (
    <div className='flex flex-col gap-2 bg-black relative'>
      <div className='flex items-center justify-between py-1.5 px-2 '>
        <div className='flex items-center gap-2'>
          <span className='text-10 sm:text-12 md:text-14 bg-secondary px-1 py-1.5 rounded'>
            시간: {Number(sportData.play_time) > 0 ? formatTimeFromSeconds(Number(sportData.play_time)) : '00:00'}
          </span>
          <span className='text-10 sm:text-12 md:text-14 bg-secondary px-1 py-1.5 rounded'>
            {/* 상태: <span className='text-primary'>2쿼터</span> */}
            상태: <span className='text-primary'>{sportData.period_name || ''}</span>
          </span>
        </div>
        {config.demoMode ? <span>fixture: {sportData.idx}</span> : null}
        <button
          className={cn(`px-1 py-1.5 font-light rounded duration-150 border bg-secondary flex items-center gap-2`, {
            'text-white border-transparent': !isActiveSideRight,
            'text-primary border-primary ': isActiveSideRight,
            'flex items-center gap-2': isLoadingLiveSports
          })}
          onClick={async () => {
            // if (count <= 0) {
            //   return
            // }
            if (sportData.block_all) {
              return
            }
            try {
              setIsLoading(true)
              if (isLoadingRateInfo) {
                return
              }
              if (isActiveSideRight) {
                dispatch(setOpenBoardBetLiveSport(!isOpenBoardBetSport))
                dispatch(resetDataBoardBetLive())
              } else {
                dispatch(setEventIdLiveSport(domesticData.fixture_idx.toString()))
                await handleGetRateInfoList({
                  domesticData,
                  sportData: sportData as LiveSportItem,
                  isForceOpen: true
                })
              }
            } catch (error) {
              toast.error('실패. 나중에 다시 시도 해주십시오.')
              setIsLoading(false)
            } finally {
              setIsLoading(false)
            }
          }}
        >
          <LoadingIcon
            className={cn(' text-gray-2 fill-primary-2 text-11 sm:text-12 md:text-14 animate-spin', {
              'opacity-0 invisible size-0': !isLoading,
              'opacity-100 visible size-4': isLoading
            })}
          />
          <span className='text-11 sm:text-12 md:text-14'>{`+${count < 0 ? 0 : count} 추가 배팅옵션`}</span>
        </button>
      </div>

      <div key={domesticData.idx} className='grid grid-cols-[5fr_2fr_5fr] py-2 bg-black gap-y-6'>
        <div className={'w-full flex items-center justify-end'}>
          <div className='size-10 border border-primary flex items-center justify-center'>
            <span className='text-16 font-bold'>{sportData.home_score}</span>
          </div>
        </div>
        <div className='w-full flex flex-col items-center justify-center  size-10'>
          <span className='text-12'>VS</span>
        </div>
        <div className={'w-full flex justify-start'}>
          <div className='size-10 border border-primary flex items-center justify-center'>
            <span className='text-16 font-bold'>{sportData.away_score}</span>
          </div>
        </div>
        <div className='col-span-3 grid grid-cols-[1fr_60px_1fr] gap-1 px-2'>
          <button
            className={cn('h-[40px] relative cursor-pointer rounded duration-150 hover:bg-primary-2', {
              'bg-secondary-2': !isSelectOptionBetId1,
              'bg-primary-2': isSelectOptionBetId1,
              'pointer-events-none relative bg-secondary-2':
                domesticData.status === 2 ||
                domesticData.status1 === 2 ||
                !checkIsCanBettingRate({
                  rate: domesticData.rate1,
                  sports_idx: domesticData.sports_idx,
                  game_id: domesticData.game_id
                })
            })}
            onClick={() => {
              if (domesticData.status === 2 || domesticData.status1 === 2) {
                return
              }

              if (isLockAll) {
                return
              }
              if (
                !checkIsCanBettingRate({
                  rate: domesticData.rate1,
                  sports_idx: domesticData.sports_idx,
                  game_id: domesticData.game_id
                })
              ) {
                return
              }
              handleSelectBetting({
                domesticData,
                betId: domesticData.betid1,
                keyMatch: domesticData.keyMatch,
                rate: domesticData.rate1,
                betTeam: domesticData.home_team
              })
            }}
          >
            <div className='flex items-center justify-between px-2 md:px-3'>
              <div className='text-10 md:text-12 text-left md:max-w-full cut-text flex items-center gap-1'>
                {/* <img src={domesticData.} alt="" /> */}
                <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>{domesticData.home_team}</span>
              </div>
              {
                <div className='flex items-center'>
                  {renderIconLeft(domesticData.game_id, domesticData.game_type)}
                  <span className='text-12 min-w-8'>{renderRating(domesticData.rate1)}</span>
                </div>
              }
              {/* <span>{domesticData.rate1}</span> */}
            </div>
            {(domesticData.status === 2 ||
              domesticData.status1 === 2 ||
              !checkIsCanBettingRate({
                rate: domesticData.rate1,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id
              })) && (
              <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-black-4/80 pointer-events-none'>
                <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
              </div>
            )}
            {domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
              <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
            ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
              <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
            ) : null}
          </button>
          <button
            className={cn('relative flex items-center justify-center w-full rounded duration-150 cursor-pointer', {
              'text-primary': !domesticData.betid2 && domesticData.rate2,
              'bg-secondary-2': !isSelectOptionBetId2,
              'bg-primary-2': isSelectOptionBetId2
            })}
            onClick={() => {
              if (domesticData.status === 2 || domesticData.status2 === 2) {
                return
              }

              if (isLockAll) {
                return
              }
              domesticData.rate2 &&
                domesticData.betid2 &&
                handleSelectBetting({
                  domesticData,
                  betId: domesticData.betid2,
                  keyMatch: domesticData.keyMatch,
                  rate: domesticData.rate2,
                  betTeam: '무'
                })
            }}
          >
            {domesticData.rate2 ? renderRating(domesticData.rate2) : 'VS'}
            {domesticData.updateType?.rate2 === EUpdateDomesticType.UP ? (
              <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
            ) : domesticData.updateType?.rate2 === EUpdateDomesticType.DOWN ? (
              <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
            ) : null}
          </button>
          <button
            className={cn('h-[40px] relative cursor-pointer rounded duration-150 hover:bg-primary-2', {
              'bg-secondary-2': !isSelectOptionBetId3,
              'bg-primary-2': isSelectOptionBetId3,
              'pointer-events-none relative bg-secondary-2':
                domesticData.status === 2 ||
                domesticData.status3 === 2 ||
                !checkIsCanBettingRate({
                  rate: domesticData.rate3,
                  sports_idx: domesticData.sports_idx,
                  game_id: domesticData.game_id
                })
            })}
            onClick={() => {
              if (domesticData.status === 2 || domesticData.status3 === 2) {
                return
              }

              if (isLockAll) {
                return
              }
              if (
                !checkIsCanBettingRate({
                  rate: domesticData.rate3,
                  sports_idx: domesticData.sports_idx,
                  game_id: domesticData.game_id
                })
              ) {
                return
              }

              handleSelectBetting({
                domesticData,
                betId: domesticData.betid3,
                keyMatch: domesticData.keyMatch,
                rate: domesticData.rate3,
                betTeam: domesticData.away_team
              })
            }}
          >
            <div className='flex items-center justify-between px-2 md:px-3'>
              {
                <div className='flex items-center'>
                  <span className='text-12 min-w-8'>{renderRating(domesticData.rate3)}</span>
                  {renderIconRight(domesticData.game_id, domesticData.game_type)}
                </div>
              }
              <div className='text-10 md:text-12 text-left md:max-w-full cut-text flex items-center gap-1'>
                <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>{domesticData.away_team}</span>
                {/* <img src={domesticData.} alt="" /> */}
              </div>
              {/* <span>{domesticData.rate3}</span> */}
            </div>
            {(domesticData.status === 2 ||
              domesticData.status3 === 2 ||
              !checkIsCanBettingRate({
                rate: domesticData.rate3,
                sports_idx: domesticData.sports_idx,
                game_id: domesticData.game_id
              })) && (
              <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-black-4/80 pointer-events-none'>
                <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
              </div>
            )}
            {domesticData.updateType?.rate3 === EUpdateDomesticType.UP ? (
              <div className='absolute top-0 left-0 border-l-[14px] border-b-[14px] border-transparent border-l-blue-500 animate-pulse'></div>
            ) : domesticData.updateType?.rate3 === EUpdateDomesticType.DOWN ? (
              <div className='absolute bottom-0 left-0 border-r-[14px] border-b-[14px] border-transparent border-b-red-1 animate-pulse'></div>
            ) : null}
          </button>
        </div>
      </div>
      {render && render({ isActiveSideRight, isOpenBoardBetSport })}
      {sportData.block_all && (
        <div className='absolute w-full h-full flex items-center justify-center bg-black-2/80'>
          <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-12'></img>
        </div>
      )}
    </div>
  )
}
