import { gql } from '@apollo/client'

export const ROULETTE_MUTATION = gql`
  mutation RouletteMutation($_id: String) {
    rouletteMutation(_id: $_id) {
      id
      amount
      seconds
      roulette_left
    }
  }
`
