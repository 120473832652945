import { gql } from '@apollo/client'

const MoneyInfoListQuery = gql`
  query MoneyInfoListQuery($page: Int, $limit: Int, $type: String) {
    MoneyInfoListQuery(page: $page, limit: $limit, type: $type) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      data {
        miNo
        miType
        miStatus
        miBankMoney
        miRegDate
        mProcessDate
        from
        to
        miWallet
        miBonus
        miTotalMoney
        mMoney
        mSportsMoney
      }
    }
  }
`

export const EXCHANGE_MONEY_CHECK_QUERY = gql`
  query ExchangeMoneyCheckQuery {
    ExchangeMoneyCheckQuery {
      is_enable_exchange_money_sports_to_casino
      is_enable_exchange_money_casino_to_sports
      sports_current_money
      sports_withdraw_money
      casino_current_money
      casino_withdraw_money
    }
  }
`

export { MoneyInfoListQuery }
