import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { reChargeSchema } from 'src/libs/utils/rules'
import { formatCurrency, reFormatCurrency } from 'src/libs/utils/utilFuncs'
import { InfoQueryType, RechargeOption } from 'src/types/common.type'
import { Wallet } from 'src/types/wallet.type'
import Input from '../Input'
import Button from '../ui/Button'

type ReChargeForm = {
  price: string
  bonus?: string
}

type ReChargeFormProps = {
  dataConfig: InfoQueryType
  recharge: (data: any) => Promise<any>
  wallet: Wallet
  moneyUser: {
    value: string
    render: boolean
  }
  mMoney?: string
  isLoadingSubmit: boolean
  couponId?: string
  bonusSelected: RechargeOption
}

export default function ReChargeForm({
  dataConfig,
  recharge,
  wallet,
  bonusSelected,
  isLoadingSubmit,
  couponId
}: ReChargeFormProps) {
  const { key: bonus } = bonusSelected
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ReChargeForm>({
    mode: 'onBlur',
    resolver: yupResolver(reChargeSchema(dataConfig))
  })
  const refreshInput = () => {
    setError('price', {})
  }
  const onSubmit = handleSubmit(async (formData: ReChargeForm) => {
    const result = await recharge({
      variables: {
        amount: reFormatCurrency(formData.price),
        wallet,
        bonus: bonus,
        couponId
      },
      context: { apiName: 'money-info' }
    })

    if (result.data && result.data.RechargeMutation) {
      const isSuccess = result.data.RechargeMutation?.miType
      refreshInput()
      if (isSuccess === 'UD') toast.success('입금신청되었습니다')

      setValue('price', '')
    }
  })

  const dataButton = [
    {
      name: '1만원',
      value: 10000
    },
    {
      name: '5만원',
      value: 50000
    },
    {
      name: '10만원',
      value: 100000
    },
    {
      name: '20만원',
      value: 200000
    },
    {
      name: '30만원',
      value: 300000
    },
    {
      name: '50만원',
      value: 500000
    },
    {
      name: '100만원',
      value: 1000000
    },
    {
      name: '500만원',
      value: 5000000
    },
    {
      name: '1000만원',
      value: 10000000
    }
  ]

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const numericValue = value?.replace(/\D/g, '')
    event.target.value = formatCurrency(numericValue)
  }

  const handleButtonClick = (value: number) => {
    const currentValue = watch('price') || '0'
    const newValue = parseInt(currentValue?.replace(/[^\d]/g, '')) || 0
    setValue('price', formatCurrency((newValue + value).toString()))
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        name='price'
        type='text'
        register={register}
        placeholder='입금(충전) 금액'
        errorMessage={errors?.price?.message}
        disabled={dataConfig.recharge_config.rc_manual_recharge}
        required
        classNameInput='placeholder:text-gray-500 focus:outline-none bg-black placeholder:text-[#757575] text-dark text-14 h-10 p-3 rounded border border-primary bg-black'
        className='w-full'
        classNameError='text-red-1 mt-1 text-12'
        onChange={(e) => onInputChange(e)}
      />
      <div className='grid grid-cols-3 gap-2 mt-4'>
        {dataButton?.map((item, index) => (
          <Button
            className='transition-all h-10 w-full rounded bg-secondary-2 text-primary hover:bg-primary hover:text-white px-4 min-w-20 font-medium text-14'
            type='button'
            key={index}
            onClick={() => handleButtonClick(item.value)}
          >
            {item?.name}
          </Button>
        ))}
        <Button
          className='h-8 rounded bg-secondary-2 hover:bg-[#ccc] text-primary hover:text-secondary-2 transition-all w-full px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
          type='button'
          onClick={() => setValue('price', '')}
        >
          <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
        </Button>
      </div>
      <Button
        className='h-10 rounded bg-primary text-secondary-2 w-fit px-4 min-w-[90px] font-medium text-14 mt-4'
        type='submit'
        isLoading={isLoadingSubmit}
        disabled={isLoadingSubmit}
      >
        입금신청
      </Button>
    </form>
  )
}
