import cn from 'classnames'
import React from 'react'
import { ArrowBottomIcon } from 'src/components/Icons/ArrowBottomIcon'

const levels = [
  { level: 'LV.1', preMatch: '프리매치', betLimit: '3,000,000 원', winLimit: '10,000,000 원' },
  { level: 'LV.2', preMatch: '프리매치', betLimit: '5,000,000 원', winLimit: '15,000,000 원' },
  { level: 'LV.3', preMatch: '프리매치', betLimit: '10,000,000 원', winLimit: '30,000,000 원' },
  { level: 'LV.4', preMatch: '프리매치', betLimit: '20,000,000 원', winLimit: '50,000,000 원' },
  { level: 'LV.5', preMatch: '프리매치', betLimit: '50,000,000 원', winLimit: '100,000,000 원' }
]

const mockListBet: {
  home_team: string
  away_team: string
  home_rate?: string
  away_rate?: string
  rate: string
}[] = [
  {
    home_team: '홈팀승',
    away_team: '오버',
    away_rate: '132.5',
    rate: '7.86'
  },
  {
    home_team: '홈팀승',
    away_team: '오버',
    away_rate: '132.5',
    rate: '2.11'
  },
  {
    home_team: '홈팀승',
    away_team: '오버',
    away_rate: '132.5',
    rate: '7.86'
  },
  {
    home_team: '홈팀승',
    away_team: '오버',
    away_rate: '132.5',
    rate: '2.11'
  },
  {
    home_team: '홈팀 핸디캡',
    home_rate: '10.5',
    away_team: '오버',
    away_rate: '132.5',
    rate: '3.27'
  },
  {
    home_team: '홈팀 핸디캡',
    home_rate: '-10.5',
    away_team: '오버',
    away_rate: '132.5',
    rate: '3.27'
  },
  {
    home_team: '홈팀 핸디캡',
    home_rate: '10.5',
    away_team: '오버',
    away_rate: '132.5',
    rate: '3.27'
  },
  {
    home_team: '홈팀 핸디캡',
    home_rate: '-10.5',
    away_team: '오버',
    away_rate: '132.5',
    rate: '3.27'
  }
]

const ruleBetClassic: {
  name: string
  maxBet: string
  maxAward: string
  award?: string
}[] = [
  {
    name: '스포츠',
    maxBet: '500,000',
    maxAward: '3,000,000',
    award: '10,000,000'
  },
  {
    name: '실시간 스포츠',
    maxBet: '500,000',
    maxAward: '3,000,000'
  }
]
const ruleBetSpecial: {
  name: string
  maxBet: string
  maxAward: string
  award?: string
}[] = [
  {
    name: '스포츠',
    maxBet: '1,000,000~<br />3,000,000',
    maxAward: '3,000,000~<br />5,000,000',
    award: '10,000,000~<br />20,000,000'
  },
  {
    name: '실시간 스포츠',
    maxBet: '100,000',
    maxAward: '3,000,000'
  }
]
const ruleBetVip: {
  name: string
  maxBet: string
  maxAward: string
  award?: string
}[] = [
  {
    name: '스포츠',
    maxBet: '5,000,000~<br />8,000,000',
    maxAward: '6,000,000~<br />15,000,000',
    award: '20,000,000~<br />30,000,000'
  },
  {
    name: '실시간 스포츠',
    maxBet: '1,000,000',
    maxAward: '30,000,000'
  }
]
const ruleBetVVip: {
  name: string
  maxBet: string
  maxAward: string
  award?: string
}[] = [
  {
    name: '스포츠',
    maxBet: '50,000,000',
    maxAward: '150,000,000',
    award: '200,000,000'
  },
  {
    name: '실시간 스포츠',
    maxBet: '10,000,000',
    maxAward: '30,000,000'
  }
]

const RuleBetItem = ({
  name,
  maxAward,
  maxBet,
  award
}: {
  name: string
  maxBet: string
  maxAward: string
  award?: string
}) => {
  return (
    <div className='flex gap-2 items-start'>
      <div className="flex-1 flex items-center justify-center w-full bg-[url('/background-item-policy.png')] bg-no-repeat bg-[length:100%_100%] h-[34px]">
        <span className='text-[11px] pt-[5px] text-nowrap'>{name}</span>
      </div>
      <div className='flex-1 flex flex-col items-center gap-3'>
        <span className='text-12 font-bold'>배팅상한</span>
        <span className='text-12 font-bold' dangerouslySetInnerHTML={{ __html: maxBet }} />
      </div>
      <div className='flex-1 flex flex-col items-center gap-3'>
        <span className='text-12 font-bold'>당첨상한</span>
        <span className='text-12 font-bold' dangerouslySetInnerHTML={{ __html: maxAward }} />
      </div>
      {name !== '실시간 스포츠' && (
        <div className='flex-1 flex flex-col items-center gap-3'>
          <span className='text-12 font-bold'>축상한</span>
          <span className='text-12 font-bold' dangerouslySetInnerHTML={{ __html: award || '' }} />
        </div>
      )}
    </div>
  )
}

const ItemMock = ({
  home_team,
  away_team,
  home_rate,
  away_rate,
  rate
}: {
  home_team: string
  away_team: string
  home_rate?: string
  away_rate?: string
  rate: string
}) => {
  return (
    <div className='flex items-center justify-between p-2 bg-[#2e302d] cursor-pointer'>
      <div className='flex flex-col'>
        <span className='text-white text-[12px]'>
          {`${home_team}${home_rate ? '(' : ''}`}
          {home_rate ? <span className='text-primary'>{`${home_rate}`}</span> : ''}
          {home_rate ? ')' : ''}+
        </span>
        <span className='text-white text-[12px]'>
          {`${away_team}${away_rate ? '(' : ''}`}
          {away_rate ? <span className='text-primary'>{`${away_rate}`}</span> : ''}
          {away_rate ? ')' : ''}
        </span>
      </div>
      <span className='text-[12px]'>{rate}</span>
    </div>
  )
}
export const TablePolicySports = ({ isLoading = false }: { isLoading?: boolean }) => {
  // return (
  //   <div
  //     className={cn('grid grid-cols-1 gap-4 bg-secondary-2 p-2 rounded-md', {
  //       'animate-pulse': isLoading
  //     })}
  //   >
  //     <img
  //       src='/sports-policy.jpg'
  //       onError={(e) => {
  //         e.currentTarget.src = '/logo-mm.png'
  //         e.currentTarget.onerror = null
  //       }}
  //       className='w-full'
  //       alt='policy'
  //     />
  //   </div>
  // )
  return (
    <div
      className={cn('grid grid-cols-1 gap-4 bg-secondary-2 px-2 py-5 rounded-md', {
        'animate-pulse': isLoading
      })}
    >
      <img src='/sports-rules-prematch.png' alt='sport' className='w-full' />
      {/* 1 */}
      {/* <div className='flex flex-col gap-3'>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' />
          <div className='flex flex-col gap-3'>
            <div className='text-[13px] text-yellow font-black'>두폴더</div>
            <div className='flex flex-col gap-1'>
              <span className='text-12 text-white font-medium'>이벤트 포인트를 지급 받아도</span>
              <span className='text-12 text-white font-medium'>정상 베팅이 가능합니다.</span>
              <span className='text-12 text-white font-medium'>1.41 이상의 폴더만 배팅 가능합니다.</span>
            </div>
          </div>
        </div>
        <div className='w-full'>
          <div className='flex flex-col justify-center items-center w-full'>
            <div className='flex-1 flex flex-col items-center justify-center py-4 px-2 border border-primary w-full'>
              <span className='text-14 text-yellow font-black'>​※PLUS - 레벨별 배팅 / 당첨 상한​※</span>
            </div>
            {levels.map(({ level, preMatch, betLimit, winLimit }) => (
              <div key={level} className='flex items-center justify-center border-t-0 border border-primary w-full'>
                <div className='w-[20%] h-[100px] flex items-center justify-center border-r border-primary'>
                  {level}
                </div>
                <div className='w-[20%] h-[100px] flex items-center justify-center border-r border-primary'>
                  {preMatch}
                </div>
                <div className='w-[40%] h-[100px] flex flex-col items-center justify-between border-r border-primary'>
                  <span className='text-12 text-yellow font-black border-b border-primary w-full h-[30%] text-center flex items-center justify-center'>
                    배팅상한
                  </span>
                  <span className='text-12 text-white flex justify-center items-center h-[70%]'>{betLimit}</span>
                </div>
                <div className='w-[40%] h-[100px] flex flex-col items-center justify-between border-r border-primary'>
                  <span className='text-12 text-yellow font-black border-b border-primary w-full h-[30%] text-center flex items-center justify-center'>
                    당첨상한
                  </span>
                  <span className='text-12 text-white flex justify-center items-center h-[70%]'>{winLimit}</span>
                </div>
              </div>
            ))}
            <div className='flex flex-col justify-center items-center gap-1 border border-primary w-full border-t-0 p-3'>
              <span className='text-12 text-white'>게임 이용 전 베팅 규정을 필히 숙지하여 불이익을 받지 않게</span>
              <span className='text-12 text-white'>유의하여 주시기 바랍니다.</span>
              <span className='text-12 text-white'>
                배팅 상한 금액이 초과 될 경우 , 초과된 금액은 인정되지 않습니다.
              </span>
            </div>
            <div className='flex flex-col justify-center items-center gap-1 border border-primary w-full border-t-0 p-3'>
              <span className='text-12 text-yellow font-black'>※인플레이 베팅 전 필독 사항 안내※</span>
            </div>
            <div className='flex flex-col justify-center items-center gap-1 border border-primary w-full border-t-0 p-3'>
              <span className='text-12 text-yellow font-black'>☞관리자 취소 가능의 경우</span>
            </div>
            <div className='flex flex-col justify-center items-center gap-1 border border-primary w-full border-t-0 p-3'>
              <span className='text-12 text-white text-center'>
                저희 PLUS 는 항상 회원님들의 쾌적한 베팅환경을 위하여 모든 배팅은 24시간 모니터링 중이기에 비정상적인
                플레이 또는 비인가 프로그램 사용이 확인될 경우에는 제재되오니 참고 부탁드립니다. <br />
                <br />
                지인 플레이어 간 조작을 통한 작업 배팅 적발시 경고없이 전액 몰수 처리될 수 있으니 참고 바랍니다. <br />
                <br />
                동일 아이피 ,다계정을 이용한 작업 배팅 적발시 경고없이 전액 몰수 처리될 수 있으니 참고 바랍니다. <br />
                <br />
                인터넷/모바일 환경으로 이루어진 배팅이기에 회원님들의 인터넷 환경으로 인한 접속지연 , 배팅거절 등의
                불이익은 본사가 책임질 수 없는 점 참고 부탁드립니다. <br />
                <br />
                게임 특성상 실시간으로 플레이가 이루어지며 배팅 이후 취소는 가능하지 않으니 신중한 배팅 부탁드립니다.
                <br />
              </span>
            </div>
            <div className='flex flex-col justify-center items-center gap-1 border border-primary w-full border-t-0 p-3'>
              <span className='text-12 text-white text-center'>
                최근 악용배팅 회원분들이 늘어나고 있으며 저희 플러스에서는 정상적으로 <br />
                이용 하는 회원분들에게 피해가 가지 않게 하기 위해 악용배팅에 대해 <br />
                강력 제재를 하고자 합니다. <br />
                <br />
                악용배팅이 확인되는 회원분들은 배팅에 관해 인정되지 않으며 지속된 경고에도 불구하고 악용배팅을 하는 경우
                이용한 게임은 졸업처리 됩니다. <br />
                당사 규정상 졸업된 이외 게임으로 롤링 완료 후 출금 가능합니다. <br />
                <br />
                PLUS 는 회원님들의 쾌적한 베팅환경을 위해 항상 노력하고 있습니다. <br />
                <br />
                오늘도 PLUS 이용해 주셔서 대단히 감사드립니다. <br />
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
  return (
    <div
      className={cn('grid grid-cols-1 gap-4 bg-secondary-2 px-2 py-5 rounded-md', {
        'animate-pulse': isLoading
      })}
    >
      {/* 1 */}
      <div className='flex flex-col gap-3'>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' />
          <div className='flex flex-col gap-3'>
            <div className='text-[13px] font-black text-white'>단폴/두폴/다폴 배팅규정 안내</div>
            <div className='flex flex-col gap-1'>
              <span className='text-[11px]'>
                단폴 배팅 시에는 배당률 1.41 이상만 시스템에서 허용되며 다른 규제는 없습니다.
              </span>
              <span className='text-[11px]'>
                두폴 이상의 경우 배당률 1.41 이상의 한 폴더가 반드시 포함되어야 하며 포함되지 않을 경우.
              </span>
              <span className='text-[11px]'>해당 배팅은 시스템에서 허용되지 않습니다.</span>
              <span className='text-[11px]'>
                다폴 구성 시 1.41 이하 배당경기는 롤링 계산시 폴더 수에 포함되지 않습니다.
              </span>
            </div>
          </div>
        </div>
        <div className='flex gap-3'>
          <div className='flex-1 flex flex-col items-center justify-center py-4 px-3 border border-primary'>
            <span className='text-14 text-white'>단폴</span>
            <span className='mt-4 text-[11px] text-primary-2 text-center'>배당률 1.41 이상만 배팅 가능</span>
            <span className='mt-2 text-[11px] text-[#ff1515] text-center'>
              1.41 이상은 허용되나 연속적인
              <br />
              단폴배팅은 제재대상이 될수 있음
            </span>
          </div>
          <div className='flex-1 flex flex-col items-center justify-center py-4 px-3 border border-primary'>
            <span className='text-14 text-white'>두폴/다폴</span>
            <span className='mt-4 text-[11px] text-primary-2 text-center'>Ex: A 경기(1.10)+B경기(1.15)</span>
            <span className='mt-2 text-[11px] text-[#ff1515] text-center'>
              폴더 구성에 1.41 이상의 배당률이
              <br />
              없어 시스템에서 거절됨
            </span>
          </div>
          <div className='flex-1 flex flex-col items-center justify-center py-4 px-3 border border-primary'>
            <span className='text-14 text-white'>다폴</span>
            <span className='mt-4 text-[11px] text-primary-2 text-center'>
              Ex: A 경기(1.89)+
              <br />B 경기(1.70)+C 경기(1.15)
            </span>
            <span className='mt-2 text-[11px] text-[#ff1515] text-center'>
              C 경기는 1.41 이하이므로
              <br />
              해당 배팅은 두폴로 간주됨
            </span>
          </div>
          {/* {infoContents.map((infoItem) => {
            return (
            )
          })} */}
        </div>
      </div>
      <div className='h-px bg-gray-2'></div>
      {/* 2 */}
      <div className='flex flex-col gap-3'>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' />
          <div className='flex flex-col gap-3'>
            <div className='text-[13px] font-black text-white'>단폴/두폴/다폴 배팅규정 안내</div>
            <div className='flex flex-col gap-1'>
              <span className='text-12 text-primary-2 text-center'>
                야구와 농구경기에서 &quot;추가배팅옵션&quot;
                <span className='text-white'>을 클릭하면 아래 예시와 같은 크로스 옵션을 제공합니다.</span>
              </span>
            </div>
          </div>
        </div>
        <div className='flex gap-3'>
          <div className='flex-1 flex flex-col items-center py-2 px-3 border border-primary'>
            <img src='/RuleBasket.png' alt='RuleBasket' className='size-[26px]' />
            <span className='mt-1 text-14 text-white'>농구</span>
            <span className='mt-4 text-[11px] text-primary font-bold text-center'>
              승패 + 오버언더
              <br />
              핸디캡 + 오버언더
            </span>
          </div>
          <div className='flex-1 flex flex-col items-center py-2 px-3 border border-primary'>
            <img src='/RuleBase.png' alt='RuleBase' className='size-[26px]' />
            <span className='mt-1 text-14 text-white'>야구</span>
            <span className='mt-4 text-12 text-primary font-bold text-center'>
              승패 + 오버언더
              <br />
              핸디캡 + 오버언더
            </span>
          </div>
        </div>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' className='opacity-0 invisible' />
          <span className='text-12 text-primary-2'>
            승패 + 오버언더 조합 / 핸디캡 + 오버언더 조합이
            <span className='text-white'>야구와 농구 경기에서 아래와 같이 조합된 상태로 발매됩니다.</span>
          </span>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='px-2 py-1 flex items-center justify-between cursor-pointer'>
            <span className='text-12 text-primary-2'>크로스 [동일경기 조합]</span>
            <ArrowBottomIcon color='#dc992e' size={12} className='text-primary-2' />
          </div>
          <div className='grid grid-cols-2 gap-1'>
            {mockListBet.map((item, index) => (
              <ItemMock key={index} {...item} />
            ))}
          </div>
        </div>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' className='opacity-0 invisible' />
          <div className='flex flex-col gap-3'>
            <span className='text-12 text-primary-2'>위의 이미지는 농구에서 발매되는 크로스배팅의 예시입니다.</span>
            <div className='flex flex-col gap-1'>
              <span className='text-[11px] text-white'>
                *크로스 배팅으로 단폴 다폴 구성시 등급에 상관없이
                <span className='text-primary'> 1회 최대 배팅한도는 30만원입니다.</span>
              </span>
              <span className='text-[11px] text-white'>
                *크로스 배팅은 해당일자에 동일 리그의 경우 최대 1회까지만 배팅이 가능합니다.
                <span className='text-primary'>(리그당 1일 1회)</span>
              </span>
              <span className='text-[11px]'>
                *다른 배팅 없이 지속적으로 크로스만 배팅하실 경우 회원자격을 정지할수 있습니다.
              </span>
              <span className='text-[11px]'>
                *다양한 배팅옵션 제공을 위해 발매하는 크로스 배팅은 위의 규정사항을 준수하시여 이용하셔야 합니다.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='h-px bg-gray-2'></div>
      {/* 3 */}
      <div className='flex flex-col gap-3'>
        <div className='flex items-start gap-2'>
          <img src='/down-arrow.png' alt='Down arrow icon policy' />
          <div className='flex-1 flex flex-col gap-3'>
            <div className='text-[13px] font-black text-white'>멤버쉽 별 배팅 / 당첨 상한</div>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                {/* <span className='text-14 font-bold text-primary-2'>CLASSIC 멤버쉽 회원</span> */}
                <div className='grid grid-cols-2 gap-2'>
                  {ruleBetClassic.map((item) => {
                    return (
                      <div key={item.name} className={'flex gap-2 items-start'}>
                        <div className="flex-1 flex items-center justify-center w-full bg-[url('/background-item-policy.png')] bg-no-repeat bg-[length:100%_100%] h-[34px]">
                          <span className='text-[11px] pt-[5px] text-nowrap'>{item.name}</span>
                        </div>
                        <div className='flex-1 flex flex-col items-center gap-3'>
                          <span className='text-12 font-bold'>배팅상한</span>
                          <span className='text-12 font-bold' dangerouslySetInnerHTML={{ __html: item.maxBet }} />
                        </div>
                        <div className='flex-1 flex flex-col items-center gap-3'>
                          <span className='text-12 font-bold'>당첨상한</span>
                          <span className='text-12 font-bold' dangerouslySetInnerHTML={{ __html: item.maxAward }} />
                        </div>
                        {item.name !== '실시간 스포츠' && (
                          <div className='flex-1 flex flex-col items-center gap-3'>
                            <span className='text-12 font-bold'>축상한</span>
                            <span
                              className='text-12 font-bold'
                              dangerouslySetInnerHTML={{ __html: item.award || '' }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                {/* <span className='text-14 font-bold text-primary-2'>SPECIAL, PREMIUM 멤버쉽 회원</span> */}
                <div className='grid grid-cols-2 gap-2'>
                  {ruleBetSpecial.map((item) => {
                    return <RuleBetItem {...item} key={item.name} />
                  })}
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                {/* <span className='text-14 font-bold text-primary-2'>VIP 멤버쉽 회원</span> */}
                <div className='grid grid-cols-2 gap-2'>
                  {ruleBetVip.map((item) => {
                    return <RuleBetItem {...item} key={item.name} />
                  })}
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                {/* <span className='text-14 font-bold text-primary-2'>VVIP 멤버쉽 회원</span> */}
                <div className='grid grid-cols-2 gap-2'>
                  {ruleBetVVip.map((item) => {
                    return <RuleBetItem {...item} key={item.name} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='h-px bg-gray-2'></div>
      {/* 4 */}
      <div className='flex items-start gap-2'>
        <img src='/down-arrow.png' alt='Down arrow icon policy' />
        <div className='flex-1 flex flex-col gap-5'>
          <div className='flex flex-col gap-3'>
            <div className='text-[13px] font-black text-white'>단폴/두폴/다폴 배팅규정 안내</div>
            <div className='flex flex-col gap-1'>
              <span className='text-[11px]'>
                단폴 배팅 시에는 배당률 1.41 이상만 시스템에서 허용되며 다른 규제는 없습니다.
              </span>
              <span className='text-[11px]'>
                두폴 이상의 경우 배당률 1.41 이상의 한 폴더가 반드시 포함되어야 하며 포함되지 않을 경우.
              </span>
              <span className='text-[11px]'>해당 배팅은 시스템에서 허용되지 않습니다.</span>
              <span className='text-[11px]'>
                다폴 구성 시 1.41 이하 배당경기는 롤링 계산시 폴더 수에 포함되지 않습니다.
              </span>
            </div>
          </div>
          <div className='w-full border border-primary px-3 py-2 flex flex-col gap-2'>
            <span className='text-12'>
              야구, 배구 등 각종 경기의 경우 배팅한 시점으로 최대 1분 이내에 득점 발생시 적특처리 되실 수 있습니다.
            </span>
            <span className='text-12'>
              * 1분이내에 득점이 발생한 모든 경기가 적특처리가 되는 것은 아니며,
              <br />
              실제 경기 상황과 배팅하신 피드의 시스템상 오차가 있는 경우에만 처리 되십니다.
            </span>
            <span className='text-12 mt-2'>
              데이터 송수신에 대한 시간차를 노려 라이브 배팅을 하시는 경우 강력 제재 처리 되십니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
