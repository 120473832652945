import { DomesticWithKeyMatch, EUpdateDomesticType } from 'src/types/sports.type'
import { DomesticWithKeyMatch as DomesticWithKeyMatchLive } from 'src/types/live-sports.type'
import { useSelectOptBettingLiveSport } from 'src/pages/LiveSport/hooks/useSelectOptBettingLiveSport'
import { useSelectOptBettingSport } from 'src/pages/Sports/hooks/useSelectOptBettingSport'
import { useCartLiveSportsStore } from 'src/libs/hooks/store/useCartLiveSportsStore'
import { useCartSportStore } from 'src/libs/hooks/store/useCartSportStore'
import cn from 'classnames'
import { renderRating } from 'src/libs/utils/utilFuncs'

type Props = {
  wrapperClassNames?: string
  classNames?: string
  domesticData: DomesticWithKeyMatch | DomesticWithKeyMatchLive
  isLiveSports?: boolean
}

export const BoardItemOneScore = ({
  wrapperClassNames = '',
  classNames = '',
  domesticData,
  isLiveSports = false
}: Props) => {
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardLive } = useSelectOptBettingLiveSport()
  const { handleSelectBettingFromBoard: handleSelectBettingFromBoardSport } = useSelectOptBettingSport()
  const { cart: cartPreMatch } = useCartSportStore()
  const { cart: cartInPlay } = useCartLiveSportsStore()
  const cartSport = isLiveSports ? cartInPlay : cartPreMatch
  const chosenOptionIndex = cartSport.pick_list.findIndex((cartItem) => {
    return cartItem.domesticData.idx === domesticData.idx
  })
  const handleSelectBettingFromBoard = isLiveSports
    ? handleSelectBettingFromBoardLive
    : handleSelectBettingFromBoardSport
  let isSelectOptionBetId1 = false

  if (chosenOptionIndex !== -1) {
    isSelectOptionBetId1 = cartSport.pick_list[chosenOptionIndex].betId === domesticData.betid1
  }

  return (
    <div className={cn(wrapperClassNames)}>
      <button
        onClick={() => {
          if (domesticData.status === 2 || domesticData.status1 === 2) {
            return
          }
          handleSelectBettingFromBoard({
            betId: domesticData.betid1,
            domesticData,
            keyMatch: domesticData.keyMatch,
            rate: domesticData.rate1,
            betTeam: domesticData.home_team_sub
          })
        }}
        className={cn(
          'w-full lg:hover:bg-primary-2 relative cursor-pointer flex justify-between items-center min-w-[0px]  rounded',
          classNames,
          {
            'bg-secondary': !isSelectOptionBetId1,
            'bg-primary-2': isSelectOptionBetId1,
            'pointer-events-none relative': domesticData.status === 2 || domesticData.status1 === 2
          }
        )}
      >
        <div className='flex items-center justify-between w-full px-2 md:px-4 py-2'>
          <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>{domesticData.home_team_sub}</span>
          <span className='text-10 md:text-12'>{renderRating(domesticData.rate1)}</span>
        </div>
        {(domesticData.status === 2 || domesticData.status1 === 2) && (
          <div className='absolute top-0 w-full h-full flex items-center justify-center !bg-black/80 pointer-events-none'>
            <img src='/assets/images/sports/Lock1.png' alt='lock item betting' className='size-5' />
          </div>
        )}
        {domesticData.updateType?.rate1 === EUpdateDomesticType.UP ? (
          <div className='absolute top-0 right-0 border-l-[14px] border-t-[14px] border-transparent border-t-blue-500 animate-pulse'></div>
        ) : domesticData.updateType?.rate1 === EUpdateDomesticType.DOWN ? (
          <div className='absolute bottom-0 right-0 border-r-[14px] border-t-[14px] border-transparent border-r-red-1 animate-pulse'></div>
        ) : null}
      </button>
    </div>
  )
}
