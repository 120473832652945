import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  calculatorStatus,
  checkIsShowInBoardSports,
  generateMapKeySportList,
  groupDataSearchPure,
  updateRate
} from '../utils/sports.func'
import {
  BetItemPusher,
  DomesticWithKeyMatch,
  EUpdateDomesticType,
  ExchangeRateInfoItem,
  FilteredSports,
  GroupSportListByKey,
  rateKeyList,
  SportItemClient,
  statusKeyList,
  ValidSelectOptionRuleItem
} from './../../types/sports.type'

interface SportSearchState {
  dataSportSearchPure: SportItemClient[]
  dataSportSearch: GroupSportListByKey[]
  dataRateInfoList: DomesticWithKeyMatch[]
  isLoadingSports: boolean
  isLoadingRateInfo: boolean
  isOpenBoardBetSport: boolean
  isFirstTimeFetch: boolean
  rules: ValidSelectOptionRuleItem[]
  exchangeRateInfoList: ExchangeRateInfoItem[]
  eventId: string
  filteredSports: FilteredSports
}

const initialState: SportSearchState = {
  dataSportSearchPure: [],
  dataSportSearch: [],
  dataRateInfoList: [],
  isLoadingSports: false,
  isLoadingRateInfo: false,
  isOpenBoardBetSport: false,
  isFirstTimeFetch: true,
  eventId: '',
  rules: [],
  exchangeRateInfoList: [],
  filteredSports: {
    isFavorite: false,
    isSortBy24h: false,
    isSortByTomorrow: false,
    isSortByLeague: false
  }
}

export const addDataToSearchListPure = createAction(
  'addDataToSearchListPure',
  function ({ sportList }: { sportList: SportItemClient[] }) {
    if (!sportList.length) {
      return {
        payload: []
      }
    }

    return {
      payload: sportList
    }
  }
)

export const updateDataToSearchListPure = createAction(
  'updateDataToSearchListPure',
  function ({ sportItem }: { sportItem: SportItemClient }) {
    return { payload: sportItem }
  }
)

export const addDataToSearchList = createAction(
  'addDataToSearchList',
  function ({ sportList }: { sportList: SportItemClient[] }) {
    if (!sportList.length) {
      return {
        payload: []
      }
    }
    const objGroupSportList: {
      [key: string]: SportItemClient[]
    } = {}
    sportList.forEach((sportItem) => {
      const key = generateMapKeySportList({ sportItem })
      if (key in objGroupSportList) {
        objGroupSportList[key].push(sportItem)
        return
      }

      objGroupSportList[key] = [sportItem]
    })
    const listGroupSportByKeysArr: GroupSportListByKey[] = Object.entries(objGroupSportList).map(
      ([key, listGroupSportByTime]) => {
        return {
          key: key,
          listGroupSport: listGroupSportByTime
        }
      }
    )
    return {
      payload: listGroupSportByKeysArr
    }
  }
)

export const lockDataSearchPure = createAction(
  'lockDataSearchPure',
  ({ isLock, index }: { isLock: boolean; index: number }) => {
    return {
      payload: {
        isLock,
        index
      }
    }
  }
)

export const addDataRateInfoList = createAction(
  'addDataRateInfoList',
  function ({ rateInfoList }: { rateInfoList: DomesticWithKeyMatch[] }) {
    return {
      payload: rateInfoList
    }
  }
)

export const updateDataRateInfoList = createAction(
  'updateDataRateInfoList',
  function ({
    betItemPusher,
    fixture_idx,
    index
  }: {
    betItemPusher: BetItemPusher
    fixture_idx: number
    index: number
  }) {
    return {
      payload: {
        betItemPusher,
        fixture_idx,
        index
      }
    }
  }
)

export const updateDataFromSearchList = createAction(
  'updateDataFromSearchList',
  function ({
    betItemPusher,
    fixture_idx,
    index
  }: {
    betItemPusher: BetItemPusher
    fixture_idx: number
    index: number
  }) {
    return {
      payload: {
        betItemPusher,
        fixture_idx,
        index
      }
    }
  }
)

export const setFetchAllApi = createAction('setFetchAllApi', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setFetchApiGetAllSports = createAction('setFetchApiGetAllSports', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setFetchApiGetAllRateInfo = createAction('setFetchApiGetAllRateInfo', (loading: boolean) => {
  return {
    payload: loading
  }
})

export const setEventId = createAction('setEventId', (id: string) => {
  return {
    payload: id
  }
})

export const setOpenBoardBetSport = createAction('setOpenBoardBetSport', (isOpen: boolean) => {
  return {
    payload: isOpen
  }
})

export const setIsFirstTimeFetch = createAction('setIsFirstTimeFetch', (isFirstTime: boolean) => {
  return {
    payload: isFirstTime
  }
})

export const resetDataSportSearch = createAction('resetDataSportSearch')

export const resetDataBoardBet = createAction('resetDataBoardBet')

export const setRulesSport = createAction('setRulesSport', (rules: ValidSelectOptionRuleItem[]) => {
  return {
    payload: rules
  }
})

export const setExchangeRateSport = createAction(
  'setExchangeRateSport',
  (exchangeRateInfoList: ExchangeRateInfoItem[]) => {
    return {
      payload: exchangeRateInfoList
    }
  }
)
export const updateSingleDataSearchPure = createAction(
  'updateSingleDataSearchPure',
  ({ sportData }: { sportData: SportItemClient }) => {
    return {
      payload: sportData
    }
  }
)

export const updateAllStatusDefaultRateInfo = createAction('updateAllStatusDefaultRateInfo')
export const updateAllStatusDefault = createAction('updateAllStatusDefault')

export const setFilteredSports = createAction('setFilteredSports', (filteredSports: FilteredSports) => {
  return {
    payload: filteredSports
  }
})

const sportSearchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addDataToSearchListPure, (state, action) => {
      state.dataSportSearchPure = action.payload
      state.dataSportSearch = groupDataSearchPure(action.payload)
    })
    // .addCase(updateDataToSearchListPure, (state, action) => {
    // didn't use anymore
    // let updateDataPure = [...state.dataSportSearchPure]
    // const fixtureIdList = updateDataPure.map((i) => i.idx)
    // let isDelete = false
    // if (action.payload.status !== 1 && fixtureIdList.includes(action.payload.idx)) {
    //   isDelete = true
    //   updateDataPure = updateDataPure.filter((item) => item.idx !== action.payload.idx)
    // } else {
    //   updateDataPure.unshift(action.payload)
    // }
    // if (state.isOpenBoardBetSport) {
    //   if (state.dataRateInfoList.length && state.dataRateInfoList[0].fixture_idx === action.payload.idx && isDelete) {
    //     state.isOpenBoardBetSport = false
    //   }
    // }
    // state.dataSportSearchPure = [...updateDataPure]
    // state.dataSportSearch = groupDataSearchPure(updateDataPure)
    // })
    .addCase(updateSingleDataSearchPure, (state, action) => {
      const existingIndex = state.dataSportSearchPure.findIndex((item) => item.idx === action.payload.idx)
      if (existingIndex !== -1) {
        const updateSportSearchPure = [...state.dataSportSearchPure]
        updateSportSearchPure[existingIndex] = action.payload
        state.dataSportSearchPure = [...updateSportSearchPure]
        state.dataSportSearch = groupDataSearchPure(state.dataSportSearchPure)
      }
    })
    .addCase(updateDataFromSearchList, (state, action) => {
      const updatedDataSportSearchPure = [...state.dataSportSearchPure]
      const dataSportPureTarget = updatedDataSportSearchPure[action.payload.index]
      let listDomesticDataSportPureTarget = [...dataSportPureTarget.domestic]
      for (let index = 0; index < listDomesticDataSportPureTarget.length; index++) {
        if (action.payload.betItemPusher.status === 3) {
          listDomesticDataSportPureTarget = listDomesticDataSportPureTarget.filter(
            (_, indexDomestic) => index !== indexDomestic
          )
          dataSportPureTarget.domestic = listDomesticDataSportPureTarget
          break
        }
        const domestic = { ...listDomesticDataSportPureTarget[index] }
        const existingBetIdIndex = [domestic.betid1, domestic.betid2, domestic.betid3].indexOf(
          action.payload.betItemPusher.betid
        )
        // const existingBetIdIndex = betIdList.findIndex((item) => item === action.payload.betItemPusher.betid)

        if (existingBetIdIndex !== -1) {
          if (
            domestic[rateKeyList[existingBetIdIndex]] !== action.payload.betItemPusher.rate ||
            domestic[statusKeyList[existingBetIdIndex]] !== action.payload.betItemPusher.status
          ) {
            const domesticUpdate = updateRate({
              domestic,
              rateKey: rateKeyList[existingBetIdIndex],
              betItemPusher: action.payload.betItemPusher,
              statusKey: statusKeyList[existingBetIdIndex]
            })
            domesticUpdate.status = calculatorStatus(domesticUpdate)
            listDomesticDataSportPureTarget[index] = domesticUpdate
            break
          }
        }
      }
      dataSportPureTarget.domestic = listDomesticDataSportPureTarget
      updatedDataSportSearchPure[action.payload.index] = dataSportPureTarget
      if (
        dataSportPureTarget.domestic[0].status === 2 &&
        state.dataRateInfoList.length &&
        state.dataRateInfoList[0].fixture_idx === dataSportPureTarget.idx &&
        state.isOpenBoardBetSport
      ) {
        state.isOpenBoardBetSport = false
      }
      state.dataSportSearchPure = [...updatedDataSportSearchPure]
      state.dataSportSearch = groupDataSearchPure(updatedDataSportSearchPure)
    })
    .addCase(addDataRateInfoList, (state, action) => {
      state.dataRateInfoList = action.payload
    })
    .addCase(updateDataRateInfoList, (state, action) => {
      let updatedDataRateInfoList = [...state.dataRateInfoList]
      const domestic: DomesticWithKeyMatch = { ...updatedDataRateInfoList[action.payload.index] }
      if (action.payload.betItemPusher.status === 3) {
        updatedDataRateInfoList = updatedDataRateInfoList.filter((item) => {
          return ![item.betid1, item.betid2, item.betid3].includes(action.payload.betItemPusher.betid)
        })
        state.dataRateInfoList = [...updatedDataRateInfoList]
        return
      }

      if (action.payload.betItemPusher.status === 2) {
        updatedDataRateInfoList = updatedDataRateInfoList.map((item) => {
          if (![item.betid1, item.betid2, item.betid3].includes(action.payload.betItemPusher.betid)) {
            return { ...item, isShowInBoard: checkIsShowInBoardSports(item) }
          }
          return {
            ...item,
            status: action.payload.betItemPusher.status,
            isShowInBoard: false
          }
        })
        state.dataRateInfoList = [...updatedDataRateInfoList]
        return
      }

      const betIdList = [domestic.betid1, domestic.betid2, domestic.betid3]
      const existingBetIdIndex = betIdList.findIndex((item) => item === action.payload.betItemPusher.betid)

      if (existingBetIdIndex !== -1) {
        const domesticUpdate = updateRate({
          domestic,
          rateKey: rateKeyList[existingBetIdIndex],
          betItemPusher: action.payload.betItemPusher,
          statusKey: statusKeyList[existingBetIdIndex]
        })
        domesticUpdate.status = calculatorStatus(domesticUpdate)
        updatedDataRateInfoList[action.payload.index] = domesticUpdate
      }
      state.dataRateInfoList = [
        ...updatedDataRateInfoList.map((item) => ({
          ...item,
          isShowInBoard: checkIsShowInBoardSports(item)
        }))
      ]
    })
    .addCase(setFetchAllApi, (state, action) => {
      state.isLoadingRateInfo = action.payload
      state.isLoadingSports = action.payload
    })
    .addCase(setFetchApiGetAllSports, (state, action) => {
      state.isLoadingSports = action.payload
    })
    .addCase(setFetchApiGetAllRateInfo, (state, action) => {
      state.isLoadingRateInfo = action.payload
    })
    .addCase(setEventId, (state, action) => {
      state.eventId = action.payload
    })
    .addCase(setOpenBoardBetSport, (state, action) => {
      state.isOpenBoardBetSport = action.payload
    })
    .addCase(setIsFirstTimeFetch, (state, action) => {
      state.isFirstTimeFetch = action.payload
    })
    .addCase(resetDataBoardBet, (state) => {
      state.dataRateInfoList = []
    })
    .addCase(resetDataSportSearch, () => {
      return { ...initialState }
    })
    .addCase(setRulesSport, (state, action) => {
      state.rules = action.payload
    })
    .addCase(setExchangeRateSport, (state, action) => {
      state.exchangeRateInfoList = action.payload
    })
    .addCase(updateAllStatusDefault, (state) => {
      const updatedDataSportSearchPure = [...state.dataSportSearchPure].map((sportItem) => {
        sportItem.domestic.forEach((domestic) => {
          domestic.updateType = {
            rate1: EUpdateDomesticType.DEFAULT,
            rate2: EUpdateDomesticType.DEFAULT,
            rate3: EUpdateDomesticType.DEFAULT
          }
        })
        return sportItem
      })
      state.dataSportSearchPure = updatedDataSportSearchPure
      state.dataSportSearch = groupDataSearchPure(updatedDataSportSearchPure)
    })
    .addCase(updateAllStatusDefaultRateInfo, (state) => {
      const updatedDataRateInfoList = [...state.dataRateInfoList].map((domestic) => {
        return {
          ...domestic,
          updateType: {
            rate1: EUpdateDomesticType.DEFAULT,
            rate2: EUpdateDomesticType.DEFAULT,
            rate3: EUpdateDomesticType.DEFAULT
          }
        }
      })
      state.dataRateInfoList = updatedDataRateInfoList
    })
    .addCase(lockDataSearchPure, (state, action) => {
      const updateDataPure = [...state.dataSportSearchPure]
      updateDataPure[action.payload.index].block_all = action.payload.isLock
      if (state.isOpenBoardBetSport) {
        if (
          state.dataRateInfoList.length &&
          state.dataRateInfoList[0].fixture_idx === updateDataPure[action.payload.index].idx
        ) {
          state.isOpenBoardBetSport = false
        }
      }
    })
    .addCase(setFilteredSports, (state, action) => {
      state.filteredSports = action.payload
    })
})

export default sportSearchReducer
