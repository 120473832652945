/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { groupVirtualSportsByIdx } from 'src/libs/utils/virtual-sports.func'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import GroupByIdx from './GroupByIdx'

type Props = {
  leaguesMenu: string[]
  virtualSportsDataList: VirtualSportsDataItem[]
}

export const GroupVirtualSport = ({ leaguesMenu, virtualSportsDataList }: Props) => {
  const [leagueActive, setLeagueActive] = useState(leaguesMenu[0])
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)
  const handleMouseDown = (e: { pageX: number }) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e: { preventDefault: () => void; pageX: number }) => {
    if (!isDragging || !sliderRef.current) return

    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const filteredVirtualSportsDataList = useMemo(
    () =>
      leagueActive === '모든 리그'
        ? virtualSportsDataList
        : virtualSportsDataList.filter((item) => item.title === leagueActive),
    [leagueActive, virtualSportsDataList]
  )

  const listGroupVirtualSports = useMemo(() => {
    return groupVirtualSportsByIdx(filteredVirtualSportsDataList)
  }, [filteredVirtualSportsDataList])

  useEffect(() => {
    setLeagueActive(leaguesMenu[0])
  }, [location.pathname])

  return (
    <div className='space-y-3 max-w-full'>
      <div className='bg-secondary-2 p-2 flex items-center gap-4'>
        <button
          className={cn('text-12 h-6 min-w-[120px] whitespace-nowrap text-white border-b-2 duration-150', {
            'border-transparent hover:border-primary': leagueActive !== leaguesMenu[0],
            'border-primary': leagueActive === leaguesMenu[0]
          })}
          onClick={() => {
            setLeagueActive(leaguesMenu[0])
          }}
        >
          {leaguesMenu[0]}
        </button>
        <nav
          ref={sliderRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className='max-w-full overflow-x-auto scrollbar-hide'
        >
          <div className='flex items-center gap-2'>
            {leaguesMenu.slice(1).map((item) => {
              return (
                <button
                  key={item}
                  className={cn('text-12 h-6 min-w-[120px] whitespace-nowrap text-white border-b-2 duration-150', {
                    'border-transparent hover:border-primary': leagueActive !== item,
                    'border-primary': leagueActive === item
                  })}
                  onClick={(e) => {
                    setLeagueActive(item)
                    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
                  }}
                >
                  {item}
                </button>
              )
            })}
          </div>
        </nav>
      </div>
      <GroupByIdx listGroupVirtualSports={listGroupVirtualSports} />
    </div>
  )
}
