import { DomesticWithKeyMatch, LiveSportItemClient } from 'src/types/live-sports.type'
import { DomesticItem } from './DomesticItem'
import { BoardBetSport } from './BoardBetSport'
import { DomesticItemStartGame } from './DomesticItemStartGame'

type Props = {
  listDomestic: DomesticWithKeyMatch[]
  sportData: Partial<LiveSportItemClient>
}

export const DomesticList = ({ listDomestic, sportData }: Props) => {
  return (
    <div className='flex flex-col gap-6'>
      {listDomestic.map((domestic, index) => {
        if (sportData.status === 2) {
          return (
            <DomesticItemStartGame
              key={domestic.idx}
              domesticData={domestic}
              sportData={sportData}
              isLastItem={index === listDomestic.length - 1}
              render={({ isActiveSideRight, isOpenBoardBetSport }) => {
                if (isActiveSideRight && index === listDomestic.length - 1) {
                  return (
                    <div className='px-1 xl:hidden'>
                      <BoardBetSport isOpen={isOpenBoardBetSport} />
                    </div>
                  )
                }
              }}
            />
          )
        }
        return (
          <DomesticItem
            key={domestic.idx}
            domesticData={domestic}
            sportData={sportData}
            isLastItem={index === listDomestic.length - 1}
            render={({ isActiveSideRight, isOpenBoardBetSport }) => {
              if (isActiveSideRight && index === listDomestic.length - 1) {
                return (
                  <div className='px-1 3xl:hidden'>
                    <BoardBetSport isOpen={isOpenBoardBetSport} />
                  </div>
                )
              }
            }}
          />
        )
      })}
    </div>
  )
}
