import { ArrowUpIcon } from 'src/components/Icons'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import { isGreyhoundsOrHorse } from '../hooks/useVirtualSports'
import cn from 'classnames'
import { useSelectOptBettingVirtualSport } from '../hooks/useSelectOptBettingVirtualSport'
import { useCartVirtualSportsStore } from 'src/libs/hooks/store/useCartVirtualSportsStore'

type Props = {
  data: VirtualSportsDataItem
  list: VirtualSportsDataItem[]
}

export const VirtualSportsOptionItem = ({ data, list }: Props) => {
  const { handleSelectBetting } = useSelectOptBettingVirtualSport()
  const { isAlreadyPicked } = useCartVirtualSportsStore()
  if (isGreyhoundsOrHorse.includes(data.cate2)) {
    return (
      <button
        className={cn('px-1 border-b border-gray-5 lg:hover:bg-primary text-12 lg:hover:text-black duration-150', {
          'bg-secondary text-white': !isAlreadyPicked(data.betid1),
          'bg-primary text-white': isAlreadyPicked(data.betid1)
        })}
        onClick={() => {
          handleSelectBetting({
            virtualData: data,
            betId: data.betid1,
            rate: data.ratio1,
            betTeam: data.home,
            virtualDataForRacing: list
          })
        }}
      >
        <div className='flex items-center justify-between px-4 py-2'>
          <div className='flex items-center gap-2'>
            {data.country1 && <img src={data.country1} alt={data.home} className='size-[22px]' />}
            <span>{data.home}</span>
          </div>
          <span>{data.ratio1.toFixed(2)}</span>
        </div>
      </button>
    )
  }
  const isUnderOver = data.cate1 === 'underover'
  const home = isUnderOver ? '오버' : data.home
  const away = isUnderOver ? '언더' : data.away

  return (
    <div className='grid grid-cols-[1fr_72px_1fr] text-12 gap-1 px-3'>
      <button
        className={cn('cursor-pointer rounded duration-150 lg:hover:bg-primary', {
          'bg-secondary text-white': !isAlreadyPicked(data.betid1),
          'bg-primary text-white': isAlreadyPicked(data.betid1)
        })}
        onClick={() => {
          handleSelectBetting({
            virtualData: data,
            betId: data.betid1,
            rate: data.ratio1,
            betTeam: data.home,
            virtualDataForRacing: list
          })
        }}
      >
        <div className='flex items-center justify-between px-2 md:px-3'>
          <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>{home}</span>
          {
            <div className='flex items-center'>
              {data.cate1 === 'underover' ? <ArrowUpIcon className='size-6 text-red-1 hidden md:block' /> : null}
              {data.cate1 === 'wintielose' ? (
                <img
                  src='/assets/images/sports/1x2.png'
                  alt='sport icon'
                  className='w-3 h-[5px] mr-[5px] text-red-1 hidden md:block'
                />
              ) : null}
              {data.cate1 === 'handicap' ? (
                <img
                  src='/assets/images/sports/handicap-icon.png'
                  alt='sport icon'
                  className='w-[6px] h-[7px] text-red-1 mr-[8px] hidden md:block'
                />
              ) : null}
              <span className='text-12 min-w-8'>{Number(data.ratio1).toFixed(2)}</span>
            </div>
          }
        </div>
      </button>
      <button
        className={cn(
          'flex items-center justify-center w-full h-[36px] rounded duration-150',
          {
            'bg-secondary text-white': !isAlreadyPicked(data.betid2) && data.cate1 === 'wintielose',
            'bg-primary text-white': isAlreadyPicked(data.betid2) && data.cate1 === 'wintielose',
            'text-primary bg-secondary pointer-events-none': data.cate1 !== 'wintielose'
          },
          data.ratio2 === 0 ? '' : 'cursor-pointer lg:hover:bg-primary'
        )}
        onClick={() => {
          if (data.cate1 !== 'wintielose' || !data.betid2 || data.ratio2 === 0) {
            return
          }
          handleSelectBetting({
            virtualData: data,
            betId: data.betid2,
            rate: data.ratio2,
            betTeam: '무',
            virtualDataForRacing: list
          })
        }}
      >
        {data.ratio2}
      </button>
      <button
        className={cn('cursor-pointer rounded duration-150 lg:hover:bg-primary', {
          'bg-secondary text-white': !isAlreadyPicked(data.betid3),
          'bg-primary text-white': isAlreadyPicked(data.betid3)
        })}
        onClick={() => {
          handleSelectBetting({
            virtualData: data,
            betId: data.betid3,
            rate: data.ratio3,
            betTeam: data.away,
            virtualDataForRacing: list
          })
        }}
      >
        <div className='flex items-center justify-between px-2 md:px-3'>
          {
            <div className='flex items-center'>
              <span className='text-12 min-w-8'>{Number(data.ratio3).toFixed(2)}</span>
              {data.cate1 === 'underover' ? (
                <ArrowUpIcon className='size-6 rotate-180 text-blue-3 hidden md:block' />
              ) : null}
              {data.cate1 === 'wintielose' ? (
                <img
                  src='/assets/images/sports/1x2.png'
                  alt='sport icon'
                  className='w-3 h-[5px] ml-[5px] text-red-1 hidden md:block'
                />
              ) : null}
              {data.cate1 === 'handicap' ? (
                <img
                  src='/assets/images/sports/handicap-icon.png'
                  alt='sport icon'
                  className='w-[6px] h-[7px] text-red-1 ml-[8px] hidden md:block'
                />
              ) : null}
            </div>
          }
          <span className='text-10 md:text-12 text-left md:max-w-full cut-text'>{away}</span>
        </div>
      </button>
    </div>
  )
}
