import { BoardItem12 } from 'src/components/BoardItemLayout/BoardItem12'
import { BoardItem1VS2 } from 'src/components/BoardItemLayout/BoardItem1VS2'
import { BoardItemHandicap } from 'src/components/BoardItemLayout/BoardItemHandicap'
import { BoardItemLine1VS2 } from 'src/components/BoardItemLayout/BoardItemLine1VS2'
import { BoardItemNeither } from 'src/components/BoardItemLayout/BoardItemNeither'
import { BoardItemOddEven } from 'src/components/BoardItemLayout/BoardItemOddEven'
import { BoardItemOneScore } from 'src/components/BoardItemLayout/BoardItemOneScore'
import { BoardItemUnderOver } from 'src/components/BoardItemLayout/BoardItemUnderOver'
import { BoardItemYesNo } from 'src/components/BoardItemLayout/BoardItemYesNo'
import {
  DomesticWithKeyMatch,
  LIST_HANDICAP_ID,
  LIST_NEITHER_ID,
  LIST_NO_GOAL_ID,
  LIST_ODD_EVEN_ID,
  LIST_SCORE_ID,
  LIST_UNDER_OVER_ID_ALL,
  LIST_WIN_LOSE_ID,
  LIST_WIN_TIE_LOSE_ID,
  LIST_WIN_TIE_LOSE_LINE_ID,
  LIST_YES_NO_ID
} from 'src/types/sports.type'

interface Props {
  domesticData: DomesticWithKeyMatch
}

export const BoardBetItem = ({ domesticData }: Props) => {
  const renderByGameType = ({ gameId }: { gameType: number; gameId: string }) => {
    const gameTypeMapping = {
      1: <BoardItem1VS2 domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      2: <BoardItemHandicap domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      3: <BoardItemUnderOver domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      4: <BoardItem12 domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      5: <BoardItemYesNo domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      6: <BoardItemNeither domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      7: <BoardItemNeither domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      8: <BoardItemOneScore domesticData={domesticData} isLiveSports={true} />,
      9: <BoardItemLine1VS2 domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />,
      10: <BoardItemOddEven domesticData={domesticData} wrapperClassNames='col-span-3' isLiveSports={true} />
    }
    if (LIST_WIN_TIE_LOSE_ID.includes(gameId)) {
      return gameTypeMapping[1]
    } else if (LIST_HANDICAP_ID.includes(gameId)) {
      return gameTypeMapping[2]
    } else if (LIST_UNDER_OVER_ID_ALL.includes(gameId)) {
      return gameTypeMapping[3]
    } else if (LIST_WIN_LOSE_ID.includes(gameId)) {
      return gameTypeMapping[4]
    } else if (LIST_YES_NO_ID.includes(gameId)) {
      return gameTypeMapping[5]
    } else if (LIST_NO_GOAL_ID.includes(gameId)) {
      return gameTypeMapping[6]
    } else if (LIST_NEITHER_ID.includes(gameId)) {
      return gameTypeMapping[7]
    } else if (LIST_SCORE_ID.includes(gameId)) {
      return gameTypeMapping[8]
    } else if (LIST_WIN_TIE_LOSE_LINE_ID.includes(gameId)) {
      return gameTypeMapping[9]
    } else if (LIST_ODD_EVEN_ID.includes(gameId)) {
      return gameTypeMapping[10]
    }
  }

  return renderByGameType({ gameType: domesticData.game_type, gameId: domesticData.game_id })
}
