/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode, useState } from 'react'
import cn from 'classnames'

interface CollapseProps {
  head?: ReactNode
  renderSuffixIcon?: ({ open, toggleHandler }: { open: boolean; toggleHandler: () => void }) => ReactNode
  children?: ReactNode
  classHead?: string
  imgCollapse?: string
  classRoot?: string
  classWrapper?: string
  classContent?: string
  classChildren?: string
  variant?: string
  disabledExpand?: boolean
  activeDefault?: boolean
  noIcon?: boolean
}

export default function Collapse({
  renderSuffixIcon,
  head,
  children,
  classHead,
  imgCollapse,
  variant,
  classRoot,
  classWrapper,
  disabledExpand,
  activeDefault,
  classContent,
  classChildren,
  noIcon = false
}: CollapseProps) {
  const [open, setOpen] = useState(activeDefault ? activeDefault : false)
  const toggleHandler = () => {
    setOpen(!open)
  }

  if (variant === 'basic') {
    return (
      <div className={classRoot}>
        <div onClick={disabledExpand ? () => {} : toggleHandler} className={classWrapper}>
          <div className={`${open ? 'bg-black rounded-md text-primary' : ''} ${classHead ? classHead : ''}`}>
            {!noIcon && (
              <button className={`duration-150 mr-2.5 ${open ? 'rotate-180' : ''}`}>
                <span className='flex h-6 w-6 items-center justify-center'>
                  {/* <img
                  src={imgCollapse ? imgCollapse : '/assets/images/icons/icon-arrow-bottom-yellow.svg'}
                  alt='icon arrow'
                  title='icon arrow'
                  width='12'
                  height='8'
                /> */}
                </span>
              </button>
            )}

            {head}
          </div>
        </div>
        <div className={`grid overflow-hidden transition-all ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
          <div className='min-h-0 overflow-hidden text-12'>{children}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={`relative ${classRoot}`}>
      <div
        onClick={toggleHandler}
        className={`cursor-pointer  ${classWrapper ? classWrapper : 'p-4 md:px-4 md:py-[17px]'}`}
      >
        <div className={`flex items-center justify-between ${classHead ? classHead : ''}`}>
          {!noIcon && (
            <button className={`duration-150 mr-2.5 ${open ? 'rotate-180' : ''}`}>
              <span className='flex h-6 w-6 items-center justify-center'>
                <img
                  src={imgCollapse ? imgCollapse : '/assets/images/icons/icon-arrow-bottom-yellow.svg'}
                  alt='icon arrow'
                  title='icon arrow'
                  width='12'
                  height='8'
                />
              </span>
            </button>
          )}

          {head}
          {renderSuffixIcon && renderSuffixIcon({ open, toggleHandler })}
        </div>
      </div>
      <div
        className={`grid overflow-hidden transition-all ${classContent} ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}
      >
        <div className={cn('min-h-0 overflow-hidden text-12', classChildren)}>{children}</div>
      </div>
    </div>
  )
}
