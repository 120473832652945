/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReChargeForm from 'src/components/ReChargeForm'
import ReChargeWithDrawInfo from 'src/components/ReChargeWithDrawInfo'
import DataTable from 'src/components/Table/DataTable'
import Button from 'src/components/ui/Button'
import { MEMBER_AUTO_CREATE_MUTATION } from 'src/libs/apis/graphql/mutations/consultation.mutation'
import { DELETE_MONEY_INFO_BY_PERIOD_MUTATION, RechargeMutation } from 'src/libs/apis/graphql/mutations/money.mutation'
import { getMember } from 'src/libs/apis/graphql/queries/auth.query'
import { getConsultation } from 'src/libs/apis/graphql/queries/consultation.queries'
import { MoneyInfoListQuery } from 'src/libs/apis/graphql/queries/money.query'
import { InfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import { login } from 'src/libs/stores/auth.reducer'
import { setIsLoadingPage } from 'src/libs/stores/common.reducer'
import { showErrorToast } from 'src/libs/utils/error'
import { RootState } from 'src/libs/utils/store'
import { formatCurrency } from 'src/libs/utils/utilFuncs'
import { InfoQueryResponse, RechargeOption } from 'src/types/common.type'
import { Wallet } from 'src/types/wallet.type'
import { getRechargeColumns } from './rechargeColumns'
import { TableColumnsType } from 'antd'

export type DataMoneyType = {
  mProcessDate?: string
  miBankMoney?: number
  miNo?: number
  miRegDate?: string
  miStatus?: string
  miType?: string
  from?: string
  to?: string
  miBonus?: string
  __typename?: string
}
export const MIN_RECHARGE_DEFAULT_VALUE = 10_000
export const MAX_RECHARGE_VALUE = 2_000_000_000
const RECHARGE_TYPE = 'recharge'

export interface GraphQLErrorWithValidations {
  message: string
  validations: Record<string, string[]>
}

export const statusClass = {
  // pending
  대기: 'text-[#2167a3] bg-[#0d1f2d]',
  // approved
  승인완료: 'text-[#11b485] bg-[rgba(17,180,133,.12)]',
  // cancel
  취소처리: 'text-[#dc3644] bg-[rgba(220,54,68,.12)]',
  // rollback
  환수처리: 'text-[#b4ad38] bg-[#292d1f]'
}

const TYPE_OF_PROJECT: 'casino_slot_only' | 'casino_slot_sports' = 'casino_slot_sports'

export default function ReCharge() {
  const [dataRecharge, setDataRecharge] = useState([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5, total: 0 })
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const [moneyUser, setMoneyUser] = useState({ value: '', render: false })
  const [rcBonusAllWallet, setRcBonusAllWallet] = useState<{ casino_slot: RechargeOption[]; sports: RechargeOption[] }>(
    {
      casino_slot: [],
      sports: []
    }
  )
  const [listBonus, setListBonus] = useState<RechargeOption[]>([])
  const [bonusSelected, setBonusSelected] = useState<RechargeOption>()
  const [wallet, setWallet] = useState<Wallet>(TYPE_OF_PROJECT === 'casino_slot_only' ? 'casino_slot' : 'sports')
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const sliderRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft)
      setScrollLeft(sliderRef.current.scrollLeft)
    }
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !sliderRef.current) return

    e.preventDefault()
    const x = e.pageX - sliderRef.current.offsetLeft
    const walk = (x - startX) * 1.2 // tốc độ kéo
    sliderRef.current.scrollLeft = scrollLeft - walk
  }

  const user = useSelector((state: RootState) => state.auth.user)
  const config = useSelector((state: RootState) => state.common.pageSiteQuery)
  const dispatch = useDispatch()
  const { mBankOwner = '', mBankNumber = 0 } = user || {}

  const { data: dataInfo, loading: loadingDataInfo } = useQuery<InfoQueryResponse>(InfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRcBonusAllWallet(data.InfoQuery.recharge_config.rc_bonus)
      setListBonus(data.InfoQuery.recharge_config.rc_bonus[wallet])
      setBonusSelected(
        data.InfoQuery.recharge_config.rc_bonus[wallet].length
          ? data.InfoQuery.recharge_config.rc_bonus[wallet][0]
          : undefined
      )
    }
  })

  const chooseWallet = (wallet: Wallet) => {
    setWallet(wallet)
    setListBonus(rcBonusAllWallet[wallet])
    setBonusSelected(
      rcBonusAllWallet[wallet].length ? dataInfo?.InfoQuery?.recharge_config.rc_bonus[wallet][0] : undefined
    )
  }

  const { data, refetch, loading } = useQuery(MoneyInfoListQuery, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, type: RECHARGE_TYPE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    context: { apiName: 'money-info' }
  })

  const { data: dataConsultation, refetch: refetchConsultation } = useQuery(getConsultation, {
    variables: { limit: 10, page: 1 },
    context: { apiName: 'consultation' }
  })

  const [recharge, reChargeMutations] = useMutation(RechargeMutation)
  const [deleteMoney, DeleteMoneyInfoMutations] = useMutation(DELETE_MONEY_INFO_BY_PERIOD_MUTATION)

  const [reqConsultation, MemberCreateMutations] = useMutation(MEMBER_AUTO_CREATE_MUTATION, {
    onError: (error) => {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    if (data && data.MoneyInfoListQuery && data.MoneyInfoListQuery?.data) {
      setDataRecharge(
        data.MoneyInfoListQuery.data.map((item: DataMoneyType, index: number) => ({
          ...item,
          key: index
        }))
      )
    }
  }, [data, mBankNumber, mBankOwner, refetch])

  useEffect(() => {
    if (data && data?.MoneyInfoListQuery && data?.MoneyInfoListQuery?.total) {
      setPagination({
        ...pagination,
        total: data.MoneyInfoListQuery.total
      })
    }
  }, [data])

  // useEffect(() => {
  //   if (loading) {
  //     dispatch(setIsLoadingPage(true))
  //   }

  //   return () => {
  //     dispatch(setIsLoadingPage(false))
  //   }
  // }, [dispatch, loading])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleDeleteMoney = (id: Array<any>) => {
    deleteMoney({
      variables: {
        type: RECHARGE_TYPE,
        days: 3
      },
      context: { apiName: 'money-info' },
      onCompleted: (data) => {
        refetch()
        if (!data.DeleteByDays) {
          toast.error('삭제에 실패하였습니다')
          return
        }
        toast.success('입금내역이 삭제되었습니다.')
      },
      onError: (error) => {
        showErrorToast(error)
      }
    })
  }

  const handleSelectMoney = (): DataMoneyType[] => {
    const selected = selectedRowKeys.map((index: number) => dataRecharge[index])

    return selected
  }

  const handleDeleteChoose = () => {
    const data = handleSelectMoney()
    handleDeleteMoney(data)
    setSelectedRowKeys([])
  }

  const renderWalletList = () => {
    if (TYPE_OF_PROJECT === 'casino_slot_only') return

    return (
      <div className='w-full mt-6 pt-0 py-3'>
        <h3 className='text-primary text-14 font-bold px-2'>입금 지갑선택</h3>
        <div className='px-2 flex w-full gap-2 mt-5 justify-between flex-wrap'>
          <Button
            className={`flex flex-auto justify-between text-primary rounded-md p-3 items-center text-14 border border-[#616161] hover:border-primary ${wallet === 'sports' ? 'border-primary' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('sports')
            }}
          >
            <span>스포츠캐쉬</span>
            <span>{formatCurrency(user?.mSportsMoney)} 원</span>
          </Button>
          <Button
            className={`flex flex-auto justify-between text-primary rounded-md p-3 items-center text-14 border border-[#616161] hover:border-primary ${wallet === 'casino_slot' ? 'border-primary' : ''}`}
            type='button'
            onClick={() => {
              chooseWallet('casino_slot')
            }}
          >
            <span>카지노캐쉬</span>
            <span>{formatCurrency(user?.mMoney)} 원</span>
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const errorText = DeleteMoneyInfoMutations?.error?.graphQLErrors[0]?.message
    if (errorText) {
      toast.error(errorText)
    }
  }, [DeleteMoneyInfoMutations?.error])

  useEffect(() => {
    const isSuccess = DeleteMoneyInfoMutations?.data?.DeleteByDays
    if (isSuccess) {
      toast.success('입금내역이 삭제되었습니다.')
    }
  }, [DeleteMoneyInfoMutations?.data])

  useEffect(() => {
    try {
      // Get validation errors from server first(after vailidate on client side)
      const errorWithValidations = reChargeMutations?.error?.graphQLErrors[0] as unknown as GraphQLErrorWithValidations

      if (errorWithValidations && errorWithValidations.validations) {
        const validationErrors = errorWithValidations.validations

        for (const error in validationErrors) {
          toast.error(validationErrors[error][0])
        }
      }

      const errorMessage = reChargeMutations?.error?.message
      const isValidationError = errorMessage === 'validation'

      // If it's not a validation error, show the error message from server
      if (!isValidationError) {
        toast.error(errorMessage)
        return
      }
    } catch (error) {
      const errorText = error || '실패. 나중에 다시 시도 해주십시오.'
      if (errorText) {
        toast.error(errorText?.toString())
      }
    }
  }, [reChargeMutations?.error])

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoadingPage(true))
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading])

  const handleQuickReqChat = async () => {
    // if (dataConsultation?.checkRequireReplyQuery?.status) return

    await reqConsultation({
      variables: { title: '입금계좌문의', content: '입금계좌문의' },
      context: { apiName: 'consultation' },
      onCompleted: () => {
        refetchConsultation()
      }
    })
  }

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const textError = (MemberCreateMutations?.error?.graphQLErrors[0] as any)?.validations?.title[0]
      toast.error(textError)
    } catch (error) {
      console.log(error)
      toast.error('error')
    }
  }, [MemberCreateMutations.error])

  useEffect(() => {
    // console.log('MemberCreateMutations', MemberCreateMutations)
    try {
      const textSuccess = MemberCreateMutations?.data?.memberAutoCreateMutation?.status
      if (textSuccess) {
        toast.success('문의되었습니다')
      }
    } catch (error) {
      console.log(error)
      toast.error('error')
    }
  }, [MemberCreateMutations.data])

  useQuery(getMember, {
    onCompleted(data) {
      dispatch(login(data?.MemberQuery))
    }
  })

  return (
    dataInfo?.InfoQuery && (
      <section className='mt-2' data-aos='fade-up'>
        <div className='px-2 flex flex-col xl:flex-row justify-between gap-8'>
          <div className='w-full'>
            {renderWalletList()}
            <div className='w-full mt-5'>
              {config?.enable_consultation && !dataConsultation?.checkRequireReplyQuery?.status ? (
                <Button
                  className='flex justify-center rounded-[20px] border-[2px] border-primary items-center h-10 bg-black w-full max-w-[160px] text-14'
                  type='button'
                  onClick={handleQuickReqChat}
                >
                  <span>빠른계좌문의</span>
                </Button>
              ) : (
                <div className='bg-gray-2 border border-gray-2 justify-center h-10 p-2 rounded-sm flex items-center gap-2 w-full max-w-[160px]'>
                  <span className='text-14'>빠른계좌문의</span>
                </div>
              )}
            </div>
            <div className='py-5 w-full border-b-[3px] border-gray-800 mt-6'>
              <div className='flex items-center gap-1'>
                <h3 className='text-white text-[14px] font-bold'>보너스 선택</h3>
                <span> - </span>
                <p className='text-[14px] text-white'>{bonusSelected?.description}</p>
              </div>

              <div className='flex flex-col mt-4'>
                {listBonus.map((item) => {
                  return (
                    <BonusCheckbox
                      key={item.key}
                      item={item}
                      isSelected={bonusSelected?.key === item.key}
                      onSelect={() => setBonusSelected(item)}
                    />
                  )
                })}
              </div>
              {/* {dataInfo?.data?.SiteInfoQuery?.recharge_config?.rc_enable_bonus ? (
            ) : null} */}
            </div>
            <div className='py-5 w-full border-b-[3px] border-gray-800 mt-6'>
              <h3 className='text-white text-[14px] font-bold'>입금신청 금액</h3>
              <div className='mt-5'>
                <ReChargeForm
                  isLoadingSubmit={reChargeMutations.loading}
                  wallet={wallet}
                  recharge={recharge}
                  mMoney={user?.mMoney}
                  moneyUser={moneyUser}
                  bonusSelected={
                    bonusSelected || {
                      description: '',
                      key: '',
                      title: '',
                      couponId: ''
                    }
                  }
                  dataConfig={dataInfo?.InfoQuery}
                  couponId={bonusSelected?.couponId}
                />
              </div>
            </div>
          </div>
          <ReChargeWithDrawInfo
            title={dataInfo.InfoQuery.recharge_config?.rc_rules || ''}
            titleButton='입금규정 확인하기'
          />
        </div>
        <div className='mt-[30px] mb-3 relative'>
          <h2 className='text-white text-[14px] font-bold pl-4 text-center'>입금내역</h2>
          <Button
            className='absolute right-0 top-[-12px] rounded bg-secondary-2 hover:bg-primary text-white hover:text-secondary-2 transition-all w-fit px-4 py-2 min-w-20 font-medium text-14 flex justify-center items-center gap-2'
            type='button'
            onClick={handleDeleteChoose}
          >
            <img src='/assets/images/icons/Ic_delete_other.png' alt='Delete' title='Delete' width='24' height='24' />
            3일전 내역 삭제
          </Button>
        </div>
        <div className='table-responsive'>
          <DataTable
            columns={getRechargeColumns()}
            loading={loading}
            dataSource={dataRecharge}
            rowClassName={(index) => {
              return index % 2 === 0 ? 'even' : 'odd'
            }}
            pagination={{
              position: ['bottomCenter'],
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) => {
                if (loading) return
                setPagination({ ...pagination, current: page, pageSize })
                setSelectedRowKeys([])
                refetch()
              }
            }}
          />
        </div>
      </section>
    )
  )
}

const formatCurrencyWithWon = (value: number) => formatCurrency(value) + ' 원'

const renderStatusButton = (value: string) => (
  <Button
    className={`w-[70px] h-8 flex items-center justify-center rounded text-14 mx-auto ${value === '대기' ? 'text-[#dc3644] bg-[rgba(220,54,68,.12)]' : 'text-[#11b485] bg-[rgba(17,180,133,.12)]'}`}
  >
    {value}
  </Button>
)

const BonusCheckbox = ({
  item,
  isSelected,
  onSelect
}: {
  item: RechargeOption
  isSelected: boolean
  onSelect: () => void
}) => {
  const { color } = item

  return (
    <div className='mb-5'>
      <input
        type='checkbox'
        id={`custom-checkbox-${item.key}`}
        className='hidden'
        checked={isSelected}
        onChange={onSelect}
      />
      <label htmlFor={`custom-checkbox-${item.key}`} className='flex items-center cursor-pointer'>
        <span
          className={`w-6 h-6 border-2 border-white rounded-full flex justify-center items-center mr-2 ${isSelected ? 'bg-white' : ''}`}
        >
          {isSelected && <span className='w-3 h-3 bg-primary-2 rounded-full'></span>}
        </span>
        <div>
          <p className={`text-12 ${color ? `text-[${color}]` : 'text-white'}`}>{item.title}</p>
          <p className='text-12 text-[#cff536]'>{item.description}</p>
        </div>
      </label>
    </div>
  )
}
