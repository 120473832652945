/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate } from 'react-router-dom'
import Collapse from 'src/components/Collapse'
import path from 'src/constants/path'
import { CntInfo } from 'src/types/sports.type'
import { checkPathTypeLiveSport, getGameType } from '../SideBar'

export enum GAME_TYPE {
  DOMESTIC = '1',
  ABROAD = '2',
  LIVE_SPORT = '3'
}

type Props = {
  cntInfo: CntInfo[]
}

const CountryInfo = (props: Props) => {
  const { cntInfo } = props
  const navigate = useNavigate()
  const sportImages: { [key: string]: string } = {
    football: '//mupad.demofwd.com/upload/sports/6046.png',
    basketball: 'https://sportstest2.spobulls.net/win/images/sports/basket.png',
    baseball: 'https://sportstest2.spobulls.net/win/images/sports/baseball.png',
    ice_hockey: 'https://sportstest2.spobulls.net/win/images/sports/hockey.png',
    volleyball: 'https://sportstest2.spobulls.net/win/images/sports/valleyball.png',
    e_sports: 'https://sportstest2.spobulls.net/win/images/sports/gamepad.png',
    american_football: 'https://sportstest2.spobulls.net/win/images/sports/usa_football.png'
  }

  const handleClickLeague = (leagueCode?: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.size === 0) {
      // use default query sport params
      const defaultSportParams = {
        sports_code: '',
        league_code: leagueCode?.toString() || '',
        game_type: '3',
        page_start: '0',
        page_length: '15',
        search: ''
      }
      const newSearchParams = new URLSearchParams(defaultSportParams)
      navigate({
        pathname: path.live_sport,
        search: '?' + newSearchParams.toString()
      })

      return
    }
    searchParams.set('family', '0')
    searchParams.set('sports_code', '')
    searchParams.set('game_type', getGameType())
    searchParams.set('league_code', leagueCode?.toString() || '')
    navigate({
      pathname: checkPathTypeLiveSport(),
      search: '?' + searchParams.toString()
    })
  }

  return (
    <div className='country-info flex flex-col list-sport'>
      {cntInfo?.map((item: CntInfo, index) => {
        return (
          <Collapse
            key={index}
            variant='basic'
            classHead='flex flex-row-reverse flex-auto justify-between hover:bg-black rounded-md'
            classRoot={`text-[12px] relative flex-auto ${item?.nationCntList?.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabledExpand={item.nationCntList?.length === 0}
            noIcon
            head={
              <div key={index} className='flex gap-3 p-[10px] w-full'>
                <div className='family-logo'>
                  {item?.sportsNameEn && (
                    <img className='w-[26px] h-[24px]' src={sportImages[item.sportsNameEn]} alt='' />
                  )}
                </div>
                <div className='flex justify-between flex-auto'>
                  <p>{item.sportsName}</p>
                  <p>{cntInfo?.[index]?.count}</p>
                </div>
              </div>
            }
          >
            {item?.nationCntList?.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  variant='basic'
                  classHead='flex flex-row-reverse flex-auto justify-between'
                  classRoot='text-[12px] relative flex-auto'
                  head={
                    <div key={index} className='flex gap-3 p-[10px] ml-3 items-center'>
                      <div className='family-logo'>
                        {item?.nationName && <img className='w-[28px] h-[19px]' src={item.imgPath} alt='' />}
                      </div>
                      <h3>
                        {item.nationName} - {item?.count || 0}
                      </h3>
                    </div>
                  }
                  disabledExpand={item.leagueCntList?.length === 0}
                >
                  {item.leagueCntList?.map((item, index) => {
                    return (
                      <p
                        key={index}
                        className='pl-12 my-2 hover:text-primary-2 block'
                        onClick={() => handleClickLeague(item?.leagueCode)}
                      >
                        {item.leagueName} - {item.count}
                      </p>
                    )
                  })}
                </Collapse>
              )
            })}
          </Collapse>
        )
      })}
    </div>
  )
}

export default CountryInfo
