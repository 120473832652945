import { gql } from '@apollo/client'

const MemberCreateMutation = gql`
  mutation memberCreateMutation($title: String, $content: String) {
    memberCreateMutation(title: $title, content: $content) {
      status
    }
  }
`

export const MEMBER_AUTO_CREATE_MUTATION = gql`
  mutation memberAutoCreateMutation($title: String, $content: String) {
    memberAutoCreateMutation(title: $title, content: $content) {
      status
    }
  }
`

export const DELETE_ITEMS_MUTATION = gql`
  mutation DeleteItemsMutation($ids: String) {
    deleteItemsMutation(ids: $ids) {
      status
    }
  }
`

export { MemberCreateMutation }

