import { GroupSportListByKey } from 'src/types/sports.type'
import { GroupSportItem } from './GroupSportItem'

type Props = {
  listGroupSportByKey: GroupSportListByKey
}

export const GroupSportList = ({ listGroupSportByKey }: Props) => {
  return (
    <div className='relative grid grid-cols-1 gap-4 p-1 bg-black-4'>
      {listGroupSportByKey.listGroupSport.map((groupSportItem) => {
        return <GroupSportItem sportData={groupSportItem} key={groupSportItem.idx} />
      })}
    </div>
  )
}
