import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from 'src/components/Input'
import Button from 'src/components/ui/Button'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { searchFormSchema } from 'src/libs/utils/rules'
import { formatQueryParams } from 'src/libs/utils/utilFuncs'

type SearchFormData = {
  search?: string
}

interface SearchFormSideBarLeft {
  HanldeSearch: any
  inputSearch: any
}

export default function SearchFormSideBarLeft({ HanldeSearch, inputSearch }: SearchFormSideBarLeft) {
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { register, handleSubmit } = useForm<SearchFormData>({
    defaultValues: {
      search: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(searchFormSchema())
  })

  const onSubmit = handleSubmit((formData: SearchFormData) => {
    if (queryParams && formData.search !== undefined) {
      navigate(location.pathname + formatQueryParams({ ...queryParams, search: formData.search }))
    }
  })

  return (
    <form noValidate className='flex justify-between items-center gap-1 py-2' onSubmit={onSubmit}>
      <Input
        name='search'
        type='text'
        value={inputSearch}
        id='search'
        placeholder='스포츠 해외형 검색(국가/리그/팀명)'
        className='relative w-full text-dark'
        classNameInput='text-12 text-[#fff] border-b-2 bg-black-2 !border-black-2 h-[30px] px-1 placeholder:text-gray-500 font-bold'
        register={register}
        required
        maxLength={255}
        onChange={(e) => HanldeSearch(e.target.value)}
      />
      <Button className='h-[30px] px-[11px]'>
        <img src='/assets/images/icons/icon-search-black.svg' alt='Search' title='Search' width='16' height='16' />
      </Button>
    </form>
  )
}
