import { gql } from '@apollo/client'

export const GET_COUPON_LIST = gql`
  query CouponQuery($status: Int) {
    CouponQuery(status: $status) {
      id
      name
      startDate
      endDate
      status
      type
      amount
    }
  }
`

export const GET_WEEKLY_BONUS = gql`
  query WeeklyBonusQuery {
    WeeklyBonusQuery {
      losing_bonus {
        bonus_this_week
        bonus_last_week
        is_redeemable
      }
      payback_bonus {
        bonus_this_week
        bonus_last_week
        is_redeemable
      }
    }
  }
`
