import React, { useEffect, useState } from 'react'
import { ConfigProvider, Table } from 'antd'
import './cashhistory.scss'
import { GET_CASH_POINT_HISTORY, GET_CATEGORY_CASH_POINT_HISTORY } from 'src/libs/apis/graphql/queries/cashpoint.query'
import { useQuery } from '@apollo/client'
import { CashHistory, CashHistoryQuery, Categories } from 'src/types/cash.type'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'

const dataSource = [
  {
    key: '1',
    time: '2024-07-10 11:15:53',
    type: '머니이동',
    des: '[머니이동] 스포츠캐쉬에서 카지노캐쉬로 10,000원 이동 완료',
    bet: '10,000 원',
    total: '12,670 원'
  },
  {
    key: '2',
    time: '2024-07-09 19:18:25',
    type: '스포츠캐쉬',
    des: '[당첨] 슈어파워볼 1분 SUREPOWERBALL1 (파워볼 홀/짝 맞추기) - 1158 (3434036) 회차 [배팅 : 홀 / 결과 : 홀] [지급 : 5000 x 1.95 = 9750원]',
    bet: '9,750 원',
    total: '14,750 원'
  },
  {
    key: '3',
    time: '2024-07-09 19:17:34',
    type: '스포츠캐쉬',
    des: '[배팅] 슈어파워볼 1분 파워볼 홀/짝 맞추기 - 1158회차 홀 / 5,000원 배팅',
    bet: '-5,000 원',
    total: '5,000 원'
  },
  {
    key: '4',
    time: '2024-07-09 19:15:26',
    type: '머니이동',
    des: '[머니이동] 카지노캐쉬에서 스포츠캐쉬로 10,000원 이동 완료',
    bet: '10,000 원',
    total: '10,000 원'
  },
  {
    key: '5',
    time: '2024-07-09 16:06:13',
    type: '카지노캐쉬',
    des: '[카지노출금]: -30,000 출금완료',
    bet: '-30,000 원',
    total: '12,670 원'
  }
]

const dataSource1 = [
  {
    key: '1',
    time: '2024-07-10 11:15:53',
    type: '머니이동',
    des: '[머니이동] 스포츠캐쉬에서 카지노캐쉬로 10,000원 이동 완료',
    bet: '10,000 원',
    total: '12,670 원'
  },
  {
    key: '2',
    time: '2024-07-09 16:06:13',
    type: '카지노캐쉬',
    des: '[카지노출금]: -30,000 출금완료',
    bet: '-30,000 원',
    total: '12,670 원'
  }
]

const columns = [
  {
    title: '발생시간',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => <span className='text-white text-center'>{text}</span>
  },
  {
    title: '타입',
    dataIndex: 'wallet',
    key: 'wallet',
    render: (text) => <span className='text-white text-center'>{text}</span>
  },
  {
    title: '내용',
    dataIndex: 'description',
    key: 'description',
    render: (text, record, index) => {
      const style = record.amount.includes('-') ? 'text-[#dc3644]' : 'text-[#11b485]'
      return <span className={style}>{text}</span>
    }
  },
  {
    title: '금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, record) => {
      const style = record.amount.includes('-') ? 'text-[#dc3644]' : 'text-[#11b485]'

      return <span className={style}>{text}</span>
    }
  },
  {
    title: '보유캐쉬합',
    dataIndex: 'pre_amount',
    key: 'pre_amount',
    render: (text, record, index) => {
      return <span className={'text-white'}>{text}</span>
    }
  }
]

export default function CashHistory() {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })
  const [selectedItem, setSelectedItem] = useState('all')
  const { data: pageSiteQuery } = useQuery(GET_PAGE_SITE_QUERY, {
    context: { apiName: 'auth' }
  })
  const { data, refetch, loading } = useQuery<CashHistory>(GET_CASH_POINT_HISTORY, {
    variables: { limit: pagination.pageSize || 1, page: pagination.current || 1, filter: selectedItem },
    notifyOnNetworkStatusChange: true,
    context: { apiName: 'cash-point-history' },
    onCompleted: (data) => {
      setPagination({
        ...pagination,
        current: data.cashPointHistoryQuery?.current_page || pagination.current,
        total: data.cashPointHistoryQuery.total || 0
      })
    }
  })
  const { data: categories } = useQuery<Categories>(GET_CATEGORY_CASH_POINT_HISTORY, {
    context: { apiName: 'cash-point-history' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  const handleClick = (item: string) => {
    setSelectedItem(item)
  }

  useEffect(() => {
    if (categories?.cashPointHistoryCategoryQuery && categories.cashPointHistoryCategoryQuery.length) {
      setSelectedItem(categories.cashPointHistoryCategoryQuery[0].filter)
    }
  }, [categories])

  useEffect(() => {
    refetch({
      limit: pagination.pageSize ?? 10,
      page: 1,
      filter: selectedItem
    })
  }, [selectedItem])

  return (
    <section className='mt-2' data-aos='fade-up'>
      <div className='mx-5 mt-10'>
        <div className='grid grid-cols-4 md:grid-cols-6 lg:grid-cols-9 w-full mb-10 gap-2'>
          {categories?.cashPointHistoryCategoryQuery &&
            categories.cashPointHistoryCategoryQuery?.map((item) => (
              <button
                key={item.filter}
                onClick={() => handleClick(item.filter)}
                className={`border border-[#14161b] bg-[#14161b] hover:border-primary hover:border text-center cursor-pointer py-2 mr-2 text-12 ${
                  selectedItem === item.filter ? 'border-primary' : ''
                }`}
              >
                {item.text}
              </button>
            ))}
        </div>
        <div className='cashHistory_table'>
          <ConfigProvider
            renderEmpty={() => {
              return (
                <div className='flex items-center justify-center'>
                  <img
                    src={pageSiteQuery?.PageSiteQuery?.siLogo1}
                    alt='Logo'
                    title='Logo'
                    width='118'
                    height='59'
                    onError={(e) => {
                      e.currentTarget.src = '/sample.png'
                      e.currentTarget.onerror = null
                    }}
                    className='max-h-[59px] object-contain max-w-[118px] mx-auto'
                  />
                </div>
              )
            }}
          >
            <Table
              className=''
              dataSource={data?.cashPointHistoryQuery.data}
              columns={columns}
              rowClassName={(record, index) => (index % 2 !== 0 ? 'even-row' : 'not-even-row')}
              scroll={{ x: 400 }}
              pagination={{
                position: ['bottomCenter'],
                total: pagination.total,
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: (page, pageSize) => {
                  if (loading) return
                  setPagination({ ...pagination, current: page, pageSize })
                  refetch()
                }
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    </section>
  )
}
