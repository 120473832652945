import { gql } from '@apollo/client'

const readOneNoteMutation2 = gql`
  mutation ReadOneNoteMutation2($id: Int!) {
    readOneNoteMutation(id: $id) {
      status
    }
  }
`
const readAllNoteMutation = gql`
  mutation ReadAllNoteMutation {
    readAllNoteMutation {
      status
    }
  }
`

const deleteNoteMutation = gql`
  mutation DeleteNoteMutation($ids: String) {
    deleteNoteMutation(ids: $ids) {
      list {
        id
        is_deleted
      }
    }
  }
`
const countNoteNoReadMutation = gql`
  mutation UpdateNoticedNoteMutation2 {
    updateNoticedNoteMutation {
      status
    }
  }
`

export const DELETE_ALL_NOTE_MUTATION = gql`
  mutation DeleteAllNoteMutation {
    deleteAllNoteMutation {
      status
    }
  }
`

export { readOneNoteMutation2, deleteNoteMutation, countNoteNoReadMutation, readAllNoteMutation }
