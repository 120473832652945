import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET_COUPON_LIST } from 'src/libs/apis/graphql/queries/coupon.query'
import { client } from 'src/libs/utils/apollo'
import { RootState } from 'src/libs/utils/store'
import { usePusher } from 'src/providers/PusherProvider'

const useSubscribeCouponChannel = () => {
  const pusher = usePusher()
  const user = useSelector((state: RootState) => state.auth.user)
  useEffect(() => {
    if (!pusher || !user?.mID) return

    const channel = pusher.subscribe(`coupon.${user.mID}`)

    channel.bind(`coupon-created-event`, () => {
      client.refetchQueries({
        include: [GET_COUPON_LIST]
      })
    })

    return () => {
      pusher.unsubscribe(`coupon.${user.mID}`)
      channel.unbind(`coupon-created-event`)
    }
  }, [pusher, user?.mID])
}

export default useSubscribeCouponChannel
