import React from 'react'
import { VirtualSportsGroupByIdx } from 'src/types/virtual-sport'
import { VirtualSportsMatch } from './VirtualSportsMatch'

type Props = {
  listGroupVirtualSports: VirtualSportsGroupByIdx[]
}

const GroupByIdx = ({ listGroupVirtualSports }: Props) => {
  return (
    <div className='flex flex-col gap-2'>
      {listGroupVirtualSports.map((virtualSportsGroupData, index) => {
        return (
          <VirtualSportsMatch
            isFirstItem={listGroupVirtualSports?.[0]?.idx === virtualSportsGroupData?.idx}
            virtualSportsGroupData={virtualSportsGroupData}
            key={JSON.stringify(virtualSportsGroupData)}
          />
        )
      })}
    </div>
  )
}

export default React.memo(GroupByIdx)
