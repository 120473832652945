import { Link, Outlet, useLocation } from 'react-router-dom'
import './coupon.scss'
export default function Coupon() {
  const location = useLocation()
  const battleFilter = [
    {
      title: '사용가능',
      type: 'available'
    },
    {
      title: '사용내역',
      type: 'usage-history'
    }
  ]

  const checkPathName = (type: string) => {
    const pathName = location.pathname.split('/')[2]
    return pathName === type
  }
  return (
    <div className='battle__container mt-2' data-aos='fade-up'>
      <div className='battle__container-tab px-2'>
        {battleFilter.map((item) => {
          return (
            <Link
              to={`${item.type}`}
              className={`w-full text-center h-8 lg:h-[50px] flex justify-center items-center hover:border-b hover:border-primary hover:text-primary ${checkPathName(item.type) ? 'border-b border-primary text-primary' : ''}`}
              key={item.type}
            >
              {item.title}
            </Link>
          )
        })}
      </div>
      <div className='px-2'>
        <Outlet />
      </div>
    </div>
  )
}
