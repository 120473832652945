/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from 'src/components/Input'
import Button from 'src/components/ui/Button'
import path from 'src/constants/path'
import { authApi } from 'src/libs/apis/axios/auth.api'
import { REGISTER_MEMBER } from 'src/libs/apis/graphql/mutations/auth.mutation'
import { HAS_MEMBER } from 'src/libs/apis/graphql/queries/auth.query'
import { GET_PAGE_SITE_QUERY } from 'src/libs/apis/graphql/queries/page.query'
import { setOpenAuthModal } from 'src/libs/stores/auth.reducer'
import { showErrorToast } from 'src/libs/utils/error'
import { Captcha } from 'src/types/common.type'
import * as yup from 'yup'
import s from './index.module.scss'

type RegisterFormData = {
  member_id: string
  nick: string
  bank_name: string
  bank_number: string
  bank_owner: string
  captcha?: string
  member_invite: string
  password: string
  phone: string
  re_password: string
}

type Bank = {
  label: string
  value: string
}

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&()^]).+$/
const checkKoreanLanguage = (value: string) => /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)
const getMinNickLength = (value: string) => (checkKoreanLanguage(value) ? 2 : 4)

const schema = (isCaptcha?: boolean) => {
  return yup.object({
    member_id: yup.string().required('아이디를 입력하세요').min(4, '아이디는 최소 4자 이상이어야 합니다'),
    nick: yup.string().required('닉네임을 입력하세요.').min(4, '닉네임은 4자 이상이어야 합니다.'),
    // .test('is-valid-nick', '닉네임은 최소 한글2자', (value) => {
    //   if (!value) return false
    //   const isKorean = checkKoreanLanguage(value)
    //   return isKorean && value.length >= 2
    // }),
    password: yup.string().required('비밀번호를 입력하세요').min(4, '비밀번호는 최소 4자 이상이어야 합니다.'),
    bank_owner: yup.string().required('예금주를 입력하세요.'),
    captcha: isCaptcha ? yup.string().required('보안 문자 코드를 입력하세요.') : yup.string(),
    bank_name: yup.string().required('은행명을 입력하세요.'),
    bank_number: yup.string().required('계좌번호를 입력하세요.'),
    member_invite: yup
      .string()
      .required('추천인 코드를 입력하세요.')
      .min(4, '추천인 코드는 최소 4자 이상이어야 합니다.'),
    phone: yup.string().required('전화번호를 입력하세요'),
    re_password: yup
      .string()
      .required('비밀번호를 다시 한번 입력하세요.')
      .oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.')
  })
}

const defaultValues = {
  bank_name: '',
  bank_number: '',
  bank_owner: '',
  captcha: '',
  member_id: '',
  member_invite: '',
  nick: '',
  password: '',
  phone: '',
  re_password: ''
}

export default function FormRegister() {
  const [captcha, setCaptcha] = useState<Captcha>({
    img: '',
    key: ''
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [checkedButton, setCheckedButton] = useState({
    member_id: false,
    nick: false,
    member_invite: false
  })

  const configMeta = useQuery(GET_PAGE_SITE_QUERY, { context: { apiName: 'auth' } })
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors }
  } = useForm<RegisterFormData>({
    // @ts-ignore
    resolver: yupResolver(schema(configMeta?.data?.PageSiteQuery?.captcha)),
    defaultValues
  })

  const [registerMember] = useMutation(REGISTER_MEMBER, {
    context: {
      apiName: 'auth'
    },
    onCompleted: (data) => {
      toast.success(data.registerMember.message)
      navigate(path.home)
      dispatch(setOpenAuthModal('login'))
      reset()
    },
    onError: (error) => {
      showErrorToast(error)
      if (!configMeta?.data?.PageSiteQuery?.captcha) return

      // refetch captcha when error
      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    }
  })

  const member_id = watch('member_id')
  const nick = watch('nick')
  const member_invite = watch('member_invite')
  const phone = watch('phone')
  const bank_number = watch('bank_number')

  // fetch captcha when component mounted
  useEffect(() => {
    if (configMeta?.data?.PageSiteQuery?.captcha === true) {
      authApi
        .getCaptcha()
        .then((res) => {
          setCaptcha({
            img: res.data.img,
            key: res.data.key
          })
        })
        .catch(() => {
          toast.error('실제 이미지를 얻을 수 없습니다')
        })
    } else {
      setCaptcha({ img: '', key: '' })
    }
  }, [configMeta?.data?.PageSiteQuery?.captcha])

  useEffect(() => {
    // JUST ALLOW NUMBER
    setValue('phone', phone.replace(/[^0-9]/g, ''))
  }, [phone, setValue])

  useEffect(() => {
    setValue('bank_number', bank_number.replace(/[^0-9]/g, ''))
  }, [bank_number, setValue])

  useEffect(() => {
    // REPLACE SPACE TO EMPTY AND REMOVE SPECIAL CHARACTERS
    setValue('member_id', member_id.replace(/[^\w\s]/g, '').replaceAll(' ', ''))
    setCheckedButton((prev) => ({ ...prev, member_id: false }))
  }, [member_id, setValue])

  useEffect(() => {
    setValue('nick', nick.replace(/[^\w\sㄱ-ㅎ가-힣]/g, '').replaceAll(' ', ''))
    setCheckedButton((prev) => ({ ...prev, nick: false }))
  }, [nick, setValue])

  useEffect(() => {
    setValue('member_invite', member_invite.replace(/[^\w\s]/g, '').replaceAll(' ', ''))
    setCheckedButton((prev) => ({ ...prev, member_invite: false }))
  }, [member_invite, setValue])

  const [checkMemberID] = useLazyQuery(HAS_MEMBER, {
    context: {
      apiName: 'auth'
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { hasMember } = data
      if (hasMember) {
        // toast.error('회원코드가 중복되었습니다')
        setError('member_id', {
          message: '사용중인 아이디입니다'
        })
      } else {
        setCheckedButton((prev) => ({ ...prev, member_id: true }))
        toast.success('사용 가능합니다')
        setError('member_id', {})
      }
    }
  })

  const [checkNick] = useLazyQuery(HAS_MEMBER, {
    context: {
      apiName: 'auth'
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { hasMember } = data
      if (hasMember) {
        // toast.error('닉네임에 이미 사용자가 있습니다')
        setError('nick', {
          message: '닉네임에 이미 사용자가 있습니다'
        })
      } else {
        setCheckedButton((prev) => ({ ...prev, nick: true }))
        toast.success('사용 가능합니다')
        setError('nick', {})
      }
    },
    onError: (error) => {
      if (error.graphQLErrors[0].message === 'validation') {
        setError('nick', {
          // @ts-ignore
          message: error?.graphQLErrors[0]?.validations?.member_nick[0]
        })
      }
    }
  })

  const [checkMemberInvite] = useLazyQuery(HAS_MEMBER, {
    context: {
      apiName: 'auth'
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { hasMember } = data
      if (hasMember) {
        setCheckedButton((prev) => ({ ...prev, member_invite: true }))
        toast.success('사용 가능합니다')
        setError('member_invite', {})
      } else {
        // toast.error('추천인 정보가 없습니다')
        setError('member_invite', {
          message: '추천인 정보가 없습니다'
        })
      }
    },
    onError(error) {
      if (error?.graphQLErrors[0]?.message === 'validation') {
        // @ts-ignore
        toast.error(error?.graphQLErrors[0]?.validations?.member_invite?.[0])
      }
    }
  })

  const handleCheckMemberID = async () => {
    await checkMemberID({ variables: { member_id } })
  }

  const handleCheckNick = async () => {
    await checkNick({ variables: { member_nick: nick } })
  }

  const handleCheckMemberInvite = async () => {
    await checkMemberInvite({ variables: { member_invite: member_invite } })
  }

  const onSubmit: SubmitHandler<RegisterFormData> = (data) => {
    if (!checkedButton.member_id) {
      toast.error('아이디를 확인해주세요')
    }
    if (!checkedButton.nick) {
      toast.error('닉네임을 확인해주세요')
    }
    if (!checkedButton.member_invite) {
      toast.error('추천인 코드를 확인해주세요')
    }
    if (checkedButton.member_id && checkedButton.nick && checkedButton.member_invite) {
      registerMember({ variables: { ...data, key: captcha.key } })
    }
    setIsButtonDisabled(true)
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, 3000)
  }

  const bankOptions = configMeta?.data?.PageSiteQuery?.banks?.map((bank: Bank) => {
    return { label: bank, value: bank }
  })

  console.log(configMeta?.data?.PageSiteQuery?.siPartnerSuggest)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.RegisterForm}>
      <div className='flex items-start gap-3'>
        <div className='flex justify-start w-full max-w-[260px]'>
          <Input
            name='member_id'
            register={register}
            placeholder='아이디를 입력하세요'
            errorMessage={errors.member_id?.message}
            nameLabel='* 아이디'
            classNameLabel='text-left block text-white'
            className='w-full'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.member_id && 'flip-down'}`}
          />
        </div>
        <Button
          className={`bg-primary w-[98px] h-[40px] text-content rounded text-[14px] hover:bg-primary-2 ${member_id?.length < 4 ? 'border-white' : 'border-primary'} mt-[18px]`}
          onClick={handleCheckMemberID}
          type='button'
          disabled={member_id?.length < 4}
        >
          확인
        </Button>
      </div>
      <div className='flex items-start gap-3 flex-wrap'>
        <div className='flex justify-start'>
          <Input
            name='password'
            register={register}
            placeholder='비밀번호를 입력하세요'
            errorMessage={errors.password?.message}
            nameLabel='* 비밀번호'
            classNameLabel='text-left block text-white'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.password && 'flip-down'}`}
            type='password'
            className='w-[260px]'
          />
        </div>
        <div className='flex justify-start'>
          <Input
            name='re_password'
            register={register}
            placeholder='비밀번호를 다시 입력하세요'
            errorMessage={errors.re_password?.message}
            nameLabel='* 비밀번호확인'
            classNameLabel='text-left block text-white'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.re_password && 'flip-down'}`}
            type='password'
            className='w-[260px]'
          />
        </div>
      </div>

      <div className='flex items-start gap-3'>
        <div className='flex justify-start flex-auto md:max-w-[260px]'>
          <Input
            name='nick'
            register={register}
            placeholder='닉네임을 입력하세요'
            errorMessage={errors.nick?.message}
            nameLabel='* 닉네임'
            classNameLabel='text-left block text-white'
            className='w-full md:max-w-[260px]'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.nick && 'flip-down'}`}
          />
        </div>
        <Button
          className={`bg-primary w-[98px] h-[40px] text-white rounded text-[14px] hover:hover:bg-primary-2 ${nick.length < getMinNickLength(nick) ? 'border-white' : 'border-primary'} mt-[18px]`}
          onClick={handleCheckNick}
          type='button'
          disabled={nick.length < getMinNickLength(nick)}
        >
          확인
        </Button>
      </div>

      <div className='flex items-start gap-3'>
        <div className='flex justify-start w-full'>
          <Input
            name='bank_owner'
            register={register}
            placeholder='예금주를 입력하세요'
            errorMessage={errors.bank_owner?.message}
            nameLabel='* 예금주'
            classNameLabel='text-left block text-white'
            className='w-full'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.bank_owner && 'flip-down'}`}
          />
        </div>
      </div>

      <div className='flex gap-3 flex-col md:flex-row flex-wrap lg:flex-nowrap'>
        <div className='flex justify-start flex-col'>
          {/* <Input
            name='bank_name'
            register={register}
            placeholder='은행명을 입력하세요'
            errorMessage={errors.bank_name?.message}
            nameLabel='* 은행 이름'
            classNameLabel='text-left block text-white'
            className='w-[260px]'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.bank_name && 'flip-down'}`}
          /> */}
          <p className='text-left block text-white'>* 은행명</p>
          <Select
            options={bankOptions}
            className='w-[260px] h-11 text-start'
            loading={configMeta.loading}
            placeholder='은행명을 입력하세요'
            onChange={(value) => setValue('bank_name', value)}
            status={errors?.bank_name ? 'error' : undefined}
          />
          <div className={`min-h-[22px] text-start text-[red] ${errors.bank_name && 'flip-down'}`}>
            {errors?.bank_name?.message}
          </div>
        </div>
        <div className='flex justify-start'>
          <Input
            name='bank_number'
            register={register}
            placeholder='계좌번호를 입력하세요'
            errorMessage={errors.bank_number?.message}
            nameLabel='* 계좌 번호'
            classNameLabel='text-left block text-white'
            className='w-[260px]'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.bank_number && 'flip-down'}`}
          />
        </div>
      </div>

      <div className='flex items-start gap-3'>
        <div className='flex justify-start w-full'>
          <Input
            name='phone'
            register={register}
            placeholder='전화번호를 입력하세요'
            errorMessage={errors.phone?.message}
            nameLabel='* 전화번호'
            classNameLabel='text-left block text-white'
            className='w-full'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.phone && 'flip-down'}`}
          />
        </div>
      </div>

      <div className='flex items-start gap-3'>
        <div className='flex justify-start w-full max-w-[260px]'>
          <Input
            name='member_invite'
            register={register}
            placeholder='추천인 코드를 입력하세요'
            errorMessage={errors.member_invite?.message}
            nameLabel={`* 추천인 코드${configMeta?.data?.PageSiteQuery?.siPartnerSuggest ? ` (${configMeta?.data?.PageSiteQuery?.siPartnerSuggest})` : ''}`}
            classNameLabel='text-left block text-white'
            className='w-full'
            mountErrorMessage
            classNameError={`min-h-[22px] ${errors.member_invite && 'flip-down'}`}
          />
        </div>
        <Button
          className={`bg-primary w-[98px] h-[40px] text-content rounded text-[14px] hover:bg-primary-2 ${member_invite?.length < 4 ? 'border-white' : 'border-primary'} mt-[18px]`}
          onClick={handleCheckMemberInvite}
          type='button'
          disabled={member_invite?.length < 4}
        >
          확인
        </Button>
      </div>

      {captcha.key && (
        <div className='flex items-start gap-3'>
          <div className='flex justify-start w-full'>
            <Input
              name='captcha'
              register={register}
              placeholder='보안 문자 코드를 입력하세요'
              errorMessage={errors.captcha?.message}
              nameLabel='* 보안 문자'
              classNameLabel='text-left block text-white'
              className='w-full'
              mountErrorMessage
              classNameError={`min-h-[22px] ${errors.captcha && 'flip-down'}`}
            />
          </div>
        </div>
      )}

      {captcha.img && (
        <div className='mb-[22px]'>
          <img src={captcha.img} className='w-full h-[80px] bg-[#eee]' alt='' />
        </div>
      )}

      <div className='flex items-start'>
        <Button
          disabled={isButtonDisabled}
          className='bg-secondary-2 text-primary hover:text-secondary hover:bg-primary w-full h-[40px] text-content rounded text-[14px]'
          type='submit'
        >
          회원가입
        </Button>
      </div>
    </form>
  )
}
