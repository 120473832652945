export const casinoTabs = [
  {
    link: 'main',
    name: 'Main',
    icon: '/assets/images/icons/main.png',
    icon_active: '/assets/images/icons/main_1.png'
  },
  {
    link: 'live',
    name: '라이브 카지노',
    icon: '/assets/images/icons/live.png',
    icon_active: '/assets/images/icons/live_1.png'
  },
  {
    link: 'slot',
    name: '슬롯 게임',
    icon: '/assets/images/icons/slot.png',
    icon_active: '/assets/images/icons/slot_1.png'
  }
  // {
  //   link: 'provider',
  //   name: '게임사',
  //   icon: '/assets/images/icons/provider.png',
  //   icon_active: '/assets/images/icons/provider_1.png'
  // }
]
