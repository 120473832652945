import { createAction, createReducer } from '@reduxjs/toolkit'
import { LimitSport } from 'src/types/virtual-sport'
import { VirtualSportsDataItem } from 'src/types/virtual-sport.api'
import { generateKeyMatchVirtualSport } from '../utils/virtual-sports.func'

export type BetItem = {
  virtualData: VirtualSportsDataItem
  betId: string
  rate: number
  betTeam: string
  virtualDataForRacing: VirtualSportsDataItem[]
  // for case category basketball
  keyMatch?: string
}
interface VirtualSportState {
  cart: {
    pick_list: BetItem[]
    bettingLimit?: LimitSport
  }
}

const initialState: VirtualSportState = {
  cart: {
    pick_list: [],
    bettingLimit: {
      BettingLimitsVirtualSportQuery: {
        max_bet_amount: 0,
        max_bet_payout: 0,
        max_payout: 0,
        min_bet_amount: 0,
        percent_distribution: 0
      }
    }
  }
}

export const removeAllCartVirtualSport = createAction('removeAllCartVirtualSport')

export const addItemToCartVirtualSport = createAction(
  'addItemToCartVirtualSport',
  function ({ virtualData, betId, rate, betTeam, virtualDataForRacing }: BetItem) {
    const payload: BetItem = { virtualData, betId, rate, betTeam, virtualDataForRacing }
    return {
      payload
    }
  }
)

export const removeItemFromCartVirtualSport = createAction(
  'removeItemFromCartVirtualSport',
  function ({ betId }: { betId: string }) {
    const payload = { betId }
    return {
      payload
    }
  }
)

export const removeAllItemInMatchAndAddNewItemVirtual = createAction(
  'removeAllItemInMatchAndAddNewItemVirtual',
  function ({ idx, dateBet }: { idx: string; dateBet: BetItem }) {
    return {
      payload: { idx, dateBet }
    }
  }
)
export const removeItemAndAddNewItemVirtual = createAction(
  'removeItemAndAddNewItemVirtual',
  function ({ idx, dateBet }: { idx: string; dateBet: BetItem }) {
    return {
      payload: { idx, dateBet }
    }
  }
)

export const updateItemVirtualSport = createAction(
  'updateItemVirtualSport',
  function ({ id, betId, rate, betTeam }: { id: number; betId: string; rate: number; betTeam: string }) {
    return {
      payload: { id, betId, rate, betTeam }
    }
  }
)

export const storeLimitVirtualSportBetting = createAction(
  'storeLimitVirtualSportBetting',
  function ({ limitSport }: { limitSport: LimitSport }) {
    return {
      payload: limitSport
    }
  }
)

const virtualSportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addItemToCartVirtualSport, (state, action) => {
      const updateDataBet: BetItem = {
        ...action.payload,
        keyMatch: generateKeyMatchVirtualSport(action.payload.virtualData)
      }
      state.cart.pick_list.push(updateDataBet)
    })
    .addCase(removeItemFromCartVirtualSport, (state, action) => {
      let updatedCartVirtualSport = [...state.cart.pick_list]
      updatedCartVirtualSport = updatedCartVirtualSport.filter((cartItem) => cartItem.betId !== action.payload.betId)
      state.cart.pick_list = updatedCartVirtualSport
    })
    .addCase(removeAllCartVirtualSport, (state) => {
      state.cart.pick_list = []
    })

    .addCase(removeAllItemInMatchAndAddNewItemVirtual, (state, action) => {
      const updatedCartSport = [...state.cart.pick_list].filter((item) => {
        return item.virtualData.idx !== action.payload.idx
      })
      const updateDataBet: BetItem = {
        ...action.payload.dateBet,
        keyMatch: generateKeyMatchVirtualSport(action.payload.dateBet.virtualData)
      }

      updatedCartSport.push(updateDataBet)
      state.cart.pick_list = updatedCartSport
    })
    .addCase(updateItemVirtualSport, function (state, action) {
      const existingCartItemIndex = state.cart.pick_list.findIndex((item) => item.virtualData.id === action.payload.id)
      if (existingCartItemIndex !== -1) {
        state.cart.pick_list[existingCartItemIndex].betId = action.payload.betId
        state.cart.pick_list[existingCartItemIndex].rate = action.payload.rate
        state.cart.pick_list[existingCartItemIndex].betTeam = action.payload.betTeam
      }
    })
    .addCase(storeLimitVirtualSportBetting, function (state, action) {
      state.cart.bettingLimit = action.payload
    })
})

export default virtualSportReducer
